import {
  RowSectionTitle,
  RowUnderLine,
} from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { useSelector } from 'react-redux';
import { BoxCard1, BoxGreen1 } from '../../../../component/report/Box';
import ReportUtil from '../../../../utilities/report';
interface data {
  createdAt: string;
  quantityRate: number;
  realIncome: number;
  realIncomeRate: number;
  yearOperationCost: number;
  yearSales: number;
  dsr: number;
  irr: number;
  unitPriceRate: number;
}

interface data2 {
  address: string;
  cropName: string;
  crops: number;
  facility: string;
  farmArea: number;
  quantity: number;
  type: string;
}

const Summary2 = ({
  data,
  data2,
  data3,
}: {
  data: data;
  data2: data2;
  data3: any;
}) => {
  const state = useSelector((state: any) => {
    return state.loading;
  });

  const obj = {
    1: '매우 낮음',

    2: '낮음',
    3: '보통',
    4: '높음',
    5: '매우 높음',
  };
  const cropCompetitive = (profitRate: number | string) => {
    return ReportUtil.cropCompetitive(profitRate);
  };
  return (
    <div>
      <RowSectionTitle>
        <TextSectionTitle>예상 경작 수익</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            해당 농지에{' '}
            <span className="color-primary-500 f-size-16 line-h-24">
              {data2.cropName}
            </span>
            을(를) 재배할 경우,
          </div>
          <div
            className="font-bold f-size-16 line-h-24"
            style={{
              color:
                cropCompetitive(data3.profitRate) < 3 ? '#d62554' : '#1E9B6B',
            }}
          >
            성공 가능성은 '
            {state.loading === 'N'
              ? obj[cropCompetitive(data3.profitRate)]
              : '-'}
            '입니다.
          </div>
        </BoxGreen1>
      </div>
      <div className="padding-0-16-24 gap-8">
        <RowUnderLine>
          <BoxCard1
            title="매출"
            value={
              (state.loading === 'N' ? transNum(data.yearSales) : '-') + '원'
            }
          />
          <BoxCard1
            title="월 평균"
            value={
              (state.loading === 'N' ? transNum(data.yearSales / 12) : '-') +
              '원'
            }
          />
        </RowUnderLine>
        <RowUnderLine>
          <BoxCard1
            title="비용"
            value={
              (state.loading === 'N' ? transNum(data.yearOperationCost) : '-') +
              '원'
            }
          />
          <BoxCard1
            title="월 평균"
            value={
              (state.loading === 'N'
                ? transNum(data.yearOperationCost / 12)
                : '-') + '원'
            }
          />
        </RowUnderLine>
        <RowUnderLine>
          <BoxCard1
            title="영업이익(영업이익률)"
            value={
              (state.loading === 'N' ? transNum(data3.profit) : '-') +
              '원(' +
              (state.loading === 'N' ? data3.profitRate : '-') +
              '%)'
            }
          />
          <BoxCard1
            title="월 평균"
            value={
              (state.loading === 'N' ? transNum(data3.profit / 12) : '-') + '원'
            }
          />
        </RowUnderLine>
      </div>
    </div>
  );
};

export default Summary2;
