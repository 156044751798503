import 'chart.js/auto';
import { Doughnut } from 'react-chartjs-2';
import { useEffect } from 'react';
import { isMobile } from '../function/function';
const DoughnutChart3 = ({ list }: any) => {
  const options = {
    responsive: false,

    maintainAspectRatio: false,
    devicePixelRatio: 3,
  };

  const expData = {
    datasets: [
      {
        labels: list.map((element: any) => element.rate),
        data: list.map((element: any) => element.rate),
        borderWidth: 0,
        hoverBorderWidth: 3,
        backgroundColor: list.map((element: any) => element.color),
        fill: true,
      },
    ],
  };

  return (
    <div
      style={{
        display: 'relative',
        flexDirection: 'column',
        gap: '10px',
        width: isMobile(window.innerWidth) ? '100%' : 'fit-content',
        alignItems: 'center',
      }}
    >
      <Doughnut
        data={expData}
        options={options}
        height="100%"
        width="100%"
        style={{
          width: '120px',
          height: '120px',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      />
    </div>
  );
};

export default DoughnutChart3;
