import { styled } from 'styled-components';
import KakaoImg from '../../../images/login/Kakao.svg';
import NaverImg from '../../../images/login/Naver.svg';
import FacebookImg from '../../../images/login/Facebook.svg';
import GoogleImg from '../../../images/login/Google.svg';
interface LoginBox {
  backgroundColor: string;
  borderColor?: string;
}

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const LoginMiddleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const LoginSec1 = styled.div`
  display: flex;
  margin-top: 62px;
`;
export const LoginSec2 = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 42px;
  gap: 12px;
`;
export const LoginInput = styled.input`
  display: flex;
  border-radius: 10px;
  width: 335px;
  height: 56px;
  border: 1px solid #e4e4e4;
  padding: 0px 10px 0px 10px;
  font-size: 17px;
  font-family: 'SpoqaHanSansNeoMedium';
  line-height: 23px;
`;

export const LoginBtn = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  width: 335px;
  height: 56px;
  background-color: black;
  cursor: pointer;
`;

export const LoginSNSSec1 = styled.div`
  display: flex;
  margin-top: 62px;
`;

export const LoginSNSSec2 = styled.div`
  margin-top: 42px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const LoginMiddleSec3 = styled.div`
  margin-top: 48px;
  display: flex;
  flex-direction: row;
  gap: 12px;
  justify-content: center;
  margin-bottom: 92px;
`;

export const BrowserKakao = styled.img.attrs({
  src: `${KakaoImg}`,
})`
  cursor: pointer;
`;

export const BrowserNaver = styled.img.attrs({
  src: `${NaverImg}`,
})`
  cursor: pointer;
`;

export const BrowserFacebook = styled.img.attrs({
  src: `${FacebookImg}`,
})`
  cursor: pointer;
`;

export const BrowserGoogle = styled.img.attrs({
  src: `${GoogleImg}`,
})`
  cursor: pointer;
`;
