import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
// import { ITotalData } from "../../Main";
import { useEffect, useState } from 'react';
import { Tooltip2 } from '../../../../component/common/tooltip';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { useSelector } from 'react-redux';
import { BoxCard1, BoxGreen1 } from '../../../../component/report/Box';
import SignController from '../../../../controller/sign/SignController';

const Summary1 = ({
  data,
  mypage,
  des,
}: {
  data: any;
  mypage?: boolean;
  des?: string;
}) => {
  const [name, setName] = useState('');

  const state2 = useSelector((state2: any) => {
    return state2.type.priceType === 'land';
  });

  useEffect(() => {
    SignController.getName(setName);
  }, []);
  const [tooltip, setTooltip] = useState(false);
  return (
    <div className="browser-bg-FFFFFF">
      <RowSectionTitle>
        <div className="flex-row relative">
          <TextSectionTitle>
            {des === '가격분석 - 농지' || state2
              ? '농지 가격'
              : 'AI 주택 추정가'}
          </TextSectionTitle>
          {!mypage && !state2 ? (
            <>
              <img
                src="/asset/images/warn/Warn20.svg"
                className="pointer"
                onClick={() => {
                  setTooltip(!tooltip);
                }}
              />
              <div style={{ position: 'absolute', zIndex: '1' }}>
                <Tooltip2
                  text="유사한 거래 사례의 빅데이터를 바탕으로 추정합니다."
                  on={tooltip}
                  top="25"
                  left="0"
                  off={() => {
                    setTooltip(false);
                  }}
                />
              </div>
            </>
          ) : (
            ''
          )}
        </div>
      </RowSectionTitle>
      <div className="padding-0-16-24 gap-8">
        <div className="flex-row w-100per">
          <BoxCard1
            title={`AI ${
              state2 || des === '가격분석 - 농지' ? '농지' : '주택'
            } 추정가`}
            value={
              <div className="flex-coloumn">
                <div className="font-bold f-size-18 line-h-26 color-text-secondary">
                  {transNum(
                    state2 || des === '가격분석 - 농지'
                      ? data.estimatedPrice
                      : data.estimatedHousingPrice,
                    true,
                  ) + '원'}
                </div>
                <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                  {transNum(
                    state2 || des === '가격분석 - 농지'
                      ? data.estimatedPricePerPyeong
                      : data.estimatedHousingPricePerPyeong,
                    true,
                  ) + '원/평'}
                </div>
              </div>
            }
          />
          <BoxCard1
            title="실거래가"
            value={
              (state2 && data.landTradePrice !== null) ||
              (!state2 && data.houseTradePrice !== null) ? (
                <div className="flex-coloumn">
                  <div className="font-bold f-size-18 line-h-26 color-text-secondary">
                    {state2
                      ? transNum(data.landTradePrice, true) + '원'
                      : transNum(data.houseTradePrice, true) + '원'}
                  </div>
                  <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                    {state2
                      ? data.landTradeDate &&
                        data.landTradeDate.slice(0, 4) +
                          '.' +
                          data.landTradeDate.slice(4, 6) +
                          '.' +
                          data.landTradeDate.slice(6, 8)
                      : data.houseTradeDate &&
                        data.houseTradeDate.slice(0, 4) +
                          '.' +
                          data.houseTradeDate.slice(4, 6) +
                          '.' +
                          data.houseTradeDate.slice(6, 8)}
                  </div>
                </div>
              ) : (
                <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                  최근 5년 내 <br /> 거래 이력이 없습니다.
                </div>
              )
            }
          />
        </div>
      </div>
      {state2 || des === '가격분석 - 농지' ? (
        <>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                해당 농지의 AI 추정가는{' '}
                {`${transNum(data.estimatedPrice, true)}원`}
                으로
              </div>
              <div
                className="font-bold f-size-16 line-h-24 "
                style={{
                  color:
                    data.estimatedPrice >=
                    data.officialPriceList[2023] * data.area
                      ? '#1E9B6B'
                      : '#d62554',
                }}
              >
                공시지가
                {data.estimatedPrice &&
                data.officialPriceList[2023] &&
                data.estimatedPrice >= data.officialPriceList[2023] * data.area
                  ? `보다 높습니다(${(
                      Math.round(
                        (data.estimatedPrice /
                          (data.officialPriceList[2023] * data.area)) *
                          100 *
                          10,
                      ) / 10
                    ).toFixed(1)}%)`
                  : `보다 낮습니다(${(
                      Math.round(
                        (data.estimatedPrice /
                          (data.officialPriceList[2023] * data.area)) *
                          100 *
                          10,
                      ) / 10
                    ).toFixed(1)}%)`}
              </div>
            </BoxGreen1>
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              {data.surroundingsAvgPrice ? (
                <>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    해당 농지의 평당 추정가는{' '}
                    {Math.round(
                      Number(data.estimatedPricePerPyeong),
                    ).toLocaleString()}
                    원으로
                  </div>
                  <div
                    className="font-bold f-size-16 line-h-24 color-primary-500"
                    style={{
                      color:
                        Number(data.estimatedPricePerPyeong) <
                        Number(data.surroundingsAvgPrice)
                          ? '#d62554'
                          : '#1E9B6B',
                    }}
                  >
                    인근 농지 평당 가격{' '}
                    {Math.round(
                      Number(data.surroundingsAvgPrice),
                    ).toLocaleString()}
                    원
                    {Number(data.estimatedPricePerPyeong) >
                    Number(data.surroundingsAvgPrice) ? (
                      (Number(data.estimatedPricePerPyeong) -
                        Number(data.surroundingsAvgPrice)) /
                        Number(data.surroundingsAvgPrice) <
                      0.05 ? (
                        <span>과 유사</span>
                      ) : (
                        <span>
                          보다 <br /> 높습니다(
                          {Math.abs(
                            Math.round(
                              (data.estimatedPricePerPyeong /
                                data.surroundingsAvgPrice) *
                                1000,
                            ) / 10,
                          ).toFixed(1)}
                          %)
                        </span>
                      )
                    ) : Number(data.estimatedPricePerPyeong) <
                      Number(data.surroundingsAvgPrice) ? (
                      (Number(data.surroundingsAvgPrice) -
                        Number(data.estimatedPricePerPyeong)) /
                        Number(data.surroundingsAvgPrice) <
                      0.05 ? (
                        <span> 과 유사</span>
                      ) : (
                        <span>
                          {' '}
                          보다 <br /> 낮습니다(
                          {Math.abs(
                            Math.round(
                              (data.estimatedPricePerPyeong /
                                data.surroundingsAvgPrice) *
                                1000,
                            ) / 10,
                          ).toFixed(1)}
                          %)
                        </span>
                      )
                    ) : (
                      '과 같음'
                    )}
                  </div>
                </>
              ) : (
                <div className="font-bold f-size-14 line-h-20 color-dark-primary">
                  최근 5년 동안 해당 물건 주변 실거래 사례는 없습니다.
                </div>
              )}
            </BoxGreen1>
          </div>
        </>
      ) : (
        <>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                해당 주택의 AI 추정가는{' '}
                {`${transNum(data.estimatedHousingPrice)}원`}
                으로
              </div>
              <div
                className="font-bold f-size-16 line-h-24 "
                style={{
                  color:
                    data.estimatedHousingPrice >=
                    data.officialPriceList[2023] * data.landArea
                      ? '#1E9B6B'
                      : '#d62554',
                }}
              >
                공시지가
                {data.estimatedHousingPrice &&
                data.officialPriceList[2023] &&
                data.estimatedHousingPrice >=
                  data.officialPriceList[2023] * data.landArea
                  ? `보다 높습니다(${(
                      Math.round(
                        (data.estimatedHousingPrice /
                          (data.officialPriceList[2023] * data.landArea)) *
                          100 *
                          10,
                      ) / 10
                    ).toFixed(1)}%)`
                  : `보다 낮습니다(${(
                      Math.round(
                        (data.estimatedHousingPrice /
                          (data.officialPriceList[2023] * data.landArea)) *
                          100 *
                          10,
                      ) / 10
                    ).toFixed(1)}%)`}
              </div>
            </BoxGreen1>
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              {data.surroundingAvgValue ? (
                <>
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    해당 주택의 평당 추정가는{' '}
                    {Math.round(
                      Number(data.perPyeongEstimatedHousingPrice),
                    ).toLocaleString()}
                    원으로
                  </div>
                  <div
                    className="font-bold f-size-16 line-h-24 color-primary-500"
                    style={{
                      color:
                        Number(data.perPyeongEstimatedHousingPrice) <
                        Number(data.surroundingAvgValue)
                          ? '#d62554'
                          : '#1E9B6B',
                    }}
                  >
                    인근 주택 평당 가격{' '}
                    {Math.round(
                      Number(data.surroundingAvgValue),
                    ).toLocaleString()}
                    원
                    {Number(data.perPyeongEstimatedHousingPrice) >
                    Number(data.surroundingAvgValue) ? (
                      (Number(data.perPyeongEstimatedHousingPrice) -
                        Number(data.surroundingAvgValue)) /
                        Number(data.surroundingAvgValue) <
                      0.05 ? (
                        <span>과 유사</span>
                      ) : (
                        <span>
                          보다 <br /> 높습니다(
                          {Math.abs(
                            Math.round(
                              (data.perPyeongEstimatedHousingPrice /
                                data.surroundingAvgValue) *
                                100,
                            ),
                          ).toFixed(1)}
                          %)
                        </span>
                      )
                    ) : Number(data.perPyeongEstimatedHousingPrice) <
                      Number(data.surroundingAvgValue) ? (
                      (Number(data.surroundingAvgValue) -
                        Number(data.perPyeongEstimatedHousingPrice)) /
                        Number(data.surroundingAvgValue) <
                      0.05 ? (
                        <span> 과 유사</span>
                      ) : (
                        <span>
                          {' '}
                          보다 <br /> 낮습니다(
                          {Math.abs(
                            Math.round(
                              (data.perPyeongEstimatedHousingPrice /
                                data.surroundingAvgValue) *
                                100,
                            ),
                          ).toFixed(1)}
                          %)
                        </span>
                      )
                    ) : (
                      '과 같음'
                    )}
                  </div>
                </>
              ) : (
                <div className="font-bold f-size-14 line-h-20 color-dark-primary">
                  최근 5년 동안 해당 물건 주변 실거래 사례는 없습니다.
                </div>
              )}
            </BoxGreen1>
          </div>
        </>
      )}
    </div>
  );
};

export default Summary1;
