import Request from '../../@api/request';
interface ActDataType {
  redirectUrl: string;
}
// const baseURL = "http://localhost:3333";
const baseURL = `${process.env.REACT_APP_HOST}`;
// const baseURL = "https://back.transfarmer.kr/";

export const request = new Request(baseURL);
export default class GetActService {
  static getNaverAct = async (
    code: string,
    provider: string,
    data: ActDataType,
  ) => {
    return await request.post(
      `users/sign/token?code=${code}&provider=${provider}&state=state`,
      data,
    );
  };
  static getAct = async (code: string, provider: string, data: ActDataType) => {
    const result = await request.post(
      `users/sign/token?code=${code}&provider=${provider}`,
      data,
    );
    return result;
  };

  static logout = async () => {
    return await request.delete('users/sign/logout');
  };
}
