import { useEffect, useState } from 'react';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import EmptyMessage from '../../component/Message/EmptyMessage';
import Banner from '../../component/AIIntroduction/Footer';
import Footer from '../../component/browser/Footer';
import MypageSerivce from '../../service/mypage/mypage';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../component/pagination/Pagination';

const NewsRoom = () => {
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const [totalCount, setTotalCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const [newsRoomList, setNewsRoomList] = useState([
    {
      title: '뉴스룸 테스트',
      writeTime: '2021-09-01 00:00:00',
    },
    {
      title: '뉴스룸 테스트2',
      writeTime: '2021-09-01 00:00:00',
    },
    {
      title: '뉴스룸 테스트3',
      writeTime: '2021-09-01 00:00:00',
    },
  ]);
  const getNewsRoomList = async () => {
    const limit = 10;
    try {
      const result = await MypageSerivce.noticeList('B', offset, 10);
      setNewsRoomList(result.data.rows);
      console.log('result', result);
      setTotalCount(result.data.totalCount);
      setTotalPages(Math.ceil(result.data.totalCount / limit));
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    getNewsRoomList();
  }, []);

  return (
    <div className="notice-wrap">
      <TopNav2 text="뉴스룸" hamburger back />
      <TopNavNewBrowser />
      <div className="notice-inner">
        <div className="notice-wrap-title">뉴스룸</div>
        <div className="notice-content-box">
          {newsRoomList.length > 0 && <div className="top-line"></div>}
          {newsRoomList.length === 0 ? (
            <EmptyMessage message="등록된 뉴스가 없습니다." />
          ) : (
            <div className="notice-list">
              <ul>
                {newsRoomList.map((item: any) => (
                  <li
                    key={item.articleIdx}
                    onClick={() => {
                      navigate(`/newsroom/detail/${item.articleIdx}`);
                    }}
                  >
                    <div className="notice-title-box">
                      <p className="notice-tag">뉴스</p>
                      <span className="notice-title">{item.title}</span>
                    </div>
                    <span className="notice-date no-wrap">
                      {item.writeTime.split(' ')[0]}
                    </span>
                  </li>
                ))}
              </ul>
              {totalPages >= 1 && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageList={Array.from({ length: totalPages }, (_, i) => i + 1)}
                  totalPages={totalPages}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};

export default NewsRoom;
