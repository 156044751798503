import { useLocation, useParams } from 'react-router-dom';
import { TopNav2 } from '../../component/mobile/Topnav';
import ReportService from '../../service/report/reportApi';
import ReportInfo from './components/Info';
import ReportNav from './components/Nav';
import ReportDetail1 from './components/detail/ReportDetail1';
import ReportDetail2 from './components/detail/ReportDetail2';
import ReportDetail3 from './components/detail/ReportDetail3';
import ReportDetail4 from './components/detail/ReportDetail4';
import ReportDetail5 from './components/detail/ReportDetail5';
import ReportDetail6 from './components/detail/ReportDetail6';
import ReportDetail7 from './components/detail/ReportDetail7';
import Summary1 from './components/summary/Summary1';
import Summary2 from './components/summary/Summary2';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from '../../component/function/function';
import ReportDetail8 from './components/detail/ReportDetail8';
import React from 'react';
import { useDispatch } from 'react-redux';
import { LoadingDot } from '../../store/loadingSlice';
import ReportDetail10 from '../reportResultPrice/components/detail/ReportDetail10';
import { SaveBtn } from '../../component/common/saveBtn';
import PriceService from '../../service/price/priceApi';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { SaveReportPopUp } from '../../component/common/saveReportPopUp';
import { ca } from 'date-fns/locale';
const SearchReportMain = ({
  reportIdx,
  setOnFindLandReport,
  setClose,
  type2,
  mypage,
  search,
  setOnPointHistory,
  setCloseSearchPointDetail,
}: any) => {
  const dispatch = useDispatch();
  const location = useLocation();
  console.log('mypage', mypage);
  let param: any;

  const param2 = useParams();
  if (isMobile(window.innerWidth)) {
    param = { reportIdx: param2.reportIdx };
  } else {
    param = { reportIdx: reportIdx };
  }
  const [savePopUp, setSavePopUp] = useState(false);
  const [report_desc, setReport_desc] = useState({
    address: '',
    cropName: '',
    crops: 0,
    facility: 'O',
    farmArea: 0,
    quantity: 0,
    type: '',
    landArea: 0,
    pnuCode: '',
    category: '',
  });
  const [competitivenessResult, setCompetitivenessResult] = useState({
    createdAt: '',
    quantityRate: 0,
    realIncome: 0,
    realIncomeRate: 0,
    yearOperationCost: 0,
    yearSales: 0,
    dsr: 5,
    irr: 5,
    unitPriceRate: 0,
  });

  const [avgCompetitivenessResult, setAvgCompetitivenessResult] = useState({
    realIncome: 0,
    realIncomeRate: 0,
    yearOperationCost: 0,
    yearSales: 0,
  });

  const [cropAnalysis, setCropAnalysis] = useState({
    accumulatedTemp: [],
    avgYearWeather: [{ point: 279, month: 2, temp: 1.8, maxTemp: 7.9 }],
    cropList: {
      recommendCrop: [],
      anotherCrop: [],
    },

    curYearWeather: [
      {
        year: 2022,
        month: 2,
        avgTemp: -0.4672985781990533,
        avgMaxTemp: 5.715809072444147,
      },
    ],

    soilResult: {
      erosion: '없음',
      frstGrd: '임지_4급지',
      fruitGrd: '과수상전_4급지',
      height: '평지',
      pastureGrd: '초지_3급지',
      pfldGrd: '밭_3급지',
      rfldGrd: '논_2급지',
      roadSurface: '중로한면',
      shape: '부정형',
      soilType: '논_보통답',
      soilUseRec: '논',
      soildra: '약간불량',
      soilslope: '경사_2-7%',
      useStatus: '상업용',
    },
  });

  const [farmValue, setFarmValue] = useState({
    perMeterEstimatedPrice: 2730.2,
    perPyeongEstimatedPrice: 9025.49516,
    estimatedPrice: 121239991.39999999,
    useStatus: '토지임야',
    height: '완경사',
    shape: '부정형',
    roadSurface: '세로각지(불)',
    landTradePrice: null,
    landTradeDate: null,
    officialPriceList: {
      '2014': 522,
      '2015': 504,
      '2016': 504,
      '2017': 531,
      '2018': 558,
      '2019': 630,
      '2020': 720,
      '2021': 780,
      '2022': 855,
      '2023': 802,
    },
    difference: 3.404239401496259,
    surroundings: [
      {
        pnuCode: '4677025023100730000',
        address: '전라남도 고흥군 고흥읍 성촌리 73',
        landTradePrice: 158574,
        landTradeDate: '20210615',
        distance: 490.05033168693126,
      },
      {
        pnuCode: '4677025023104620000',
        address: '전라남도 고흥군 고흥읍 성촌리 462',
        landTradePrice: 30556,
        landTradeDate: '20190326',
        distance: 382.87096645543346,
      },
      {
        pnuCode: '4677025023104870000',
        address: '전라남도 고흥군 고흥읍 성촌리 487',
        landTradePrice: 56953,
        landTradeDate: '20190326',
        distance: 424.54585554842134,
      },
    ],
    surroundingsAvgPrice: 82028,
  });
  const [save, setSave] = useState(false);
  const [costAnalysis, setCostAnalysis] = useState({
    SGA: 0,
    avgFarmEquipmentCost: 0,
    avgLaborCost: 0,
    avgLandRentalCost: 0,
    avgMaterialCost: 0,
    avgOrchardCost: 0,
    avgSGA: 0,
    avgWaterHeatingCost: 0,
    farmEquipmentCost: 0,
    laborCost: 0,
    landRentalCost: 0,
    materialCost: 0,
    orchardCost: 0,
    waterHeatingCost: 0,
  });

  const [profitAnalysis, setProfitAnalysis] = useState({
    loanCost: 0,
    loanCostInterest: 0,
    loanCostPrincipal: 0,
    profit: 0,
    profitRate: 0,
    realIncome: 0,
    realIncomeRate: 0,
    yearSales: 0,
    yearSalesCost: 0,
    yearSalesSGA: 0,
  });
  const [investmentAnalysis, setInvestmentAnalysis] = useState({
    avgInvestRate: 0,
    facilityCost: 0,
    farmMachineryCost: 0,
    infracost: 0,
    investRate: 0,
    landPurchaseCost: 0,
    realIncome: 0,
  });

  const [loanAnalysis, setLoanAnalysis] = useState({
    loanList: [
      {
        loanCost: 97227200,
        loanDate: '2023-10',
        loanName: '예시 대출',
        loanRate: 0.02,
        loanType: 'T',
        retentionPeriod: 5,
        redemptionPeriod: 10,
      },
    ],
    recentRepayment: {
      info: [
        {
          round: 1,
          total: 162045,
          principal: 0,
          interest: 162045,
          principalSum: 0,
          remainSum: 97227200,
        },
      ],
    },
    loanPlan: [
      {
        round: 1,
        total: 162045,
        principal: 0,
        interest: 162045,
        principalSum: 0,
        remainSum: 97227200,
      },
    ],
    totalCost: 121534000,
    ownMoney: 24306800,
    totalLoanCost: 97227200,
    loanRequirement: {
      age: 'F',
      farmLivedPeriod: null,
      farmExperience: null,
      farmCareer: null,
      actualHours: 0,
      onlineHours: 0,
    },
  });
  const [createdTime, setCreatedTime] = useState('');
  // function en(x: any): any {
  //   return Object.entries(x)
  //     .map((a: any) => {
  //       if (typeof a[1] === "object") {
  //         if (Array.isArray(a[1])) {
  //           return `${a[0]}: Array<{\r\n${en(a[1][0])}\r\n}>;`;
  //         } else {
  //           if (a[1] === null) {
  //             return `${a[0]}: any;`;
  //           }
  //           return `${a[0]}: {\r\n${en(a[1])}\r\n};`;
  //         }
  //       }
  //       return `${a[0]}: ${typeof a[1]};`;
  //     })
  //     .join("\r\n");
  // }

  const [salesAnalysis, setSalesAnalysis] = useState('');

  const [facility, setFacility] = useState('노지');
  const [type, setType] = useState('L');
  const [isStart, setIsStart] = useState(false);
  const [origin, setOrigin] = useState();
  const getOrigin = async () => {
    try {
      const result = await PriceService.origin();
      setOrigin(result.list);
    } catch (error) {
      console.log(error);
    }
  };

  const report = async () => {
    try {
      if (!mypage) {
        dispatch(LoadingDot('W'));
      } else {
        dispatch(LoadingDot('W2'));
      }

      const data2 = await ReportService.reportFarm(param.reportIdx);

      setCreatedTime(data2.report.createdTime);
      setReport_desc(data2.report.report_desc);
      setCompetitivenessResult(data2.report.competitivenessResult);
      setAvgCompetitivenessResult(data2.report.avgCompetitivenessResult);

      setFarmValue(data2.farmValue);
      setSalesAnalysis(data2.report.salesAnalysis);
      setCostAnalysis(data2.report.costAnalysis);
      setProfitAnalysis(data2.report.profitAnalysis);
      setInvestmentAnalysis(data2.report.investmentAnalysis);
      setLoanAnalysis(data2.report.loanAnalysis);
      setIsStart(true);
      setCropAnalysis(data2.cropAnalysis);
      dispatch(LoadingDot('N'));
      // if (!isMobile(window.innerWidth)) {
      //   dispatch(LoadingDot('N'));
      // }
    } catch (err) {
      // if (!isMobile(window.innerWidth)) {
      //   dispatch(LoadingDot('N'));
      // }
      dispatch(LoadingDot('N'));
      console.log(err);
    }
  };

  useEffect(() => {
    report();
    getOrigin();
  }, []);

  const conRef = useRef<HTMLDivElement>(null);
  const menuRef1 = useRef<HTMLDivElement>(null);
  const menuRef2 = useRef<HTMLDivElement>(null);
  const menuRef3 = useRef<HTMLDivElement>(null);
  const menuRef4 = useRef<HTMLDivElement>(null);
  const menuRef5 = useRef<HTMLDivElement>(null);
  const menuRef6 = useRef<HTMLDivElement>(null);
  const menuRef7 = useRef<HTMLDivElement>(null);
  const menuRef8 = useRef<HTMLDivElement>(null);
  const menuRef9 = useRef<HTMLDivElement>(null);
  const menuRef10 = useRef<HTMLDivElement>(null);
  const temp = useRef<HTMLDivElement>(null);
  const convertElementToImage = async (element: any) => {
    if (element) {
      let canvas = await html2canvas(element);
      return canvas.toDataURL('image/svg');
    }
    return null;
  };
  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);
  const divRef7 = useRef<HTMLDivElement>(null);
  const divRef8 = useRef<HTMLDivElement>(null);
  const divRef9 = useRef<HTMLDivElement>(null);
  const convertHTMLToImages = async () => {
    const elements = [
      divRef.current,
      divRef2.current,
      divRef3.current,
      divRef4.current,
      divRef5.current,
      divRef6.current,
      divRef7.current,
      divRef8.current,
      divRef9.current,
    ];
    const imageDataArray = await Promise.all(
      elements.map(async (element) => {
        return await convertElementToImage(element);
      }),
    );
    return imageDataArray.filter((imageData) => imageData !== null);
  };

  const handleButtonClick = async () => {
    let imgDataArray = await convertHTMLToImages();

    if (imgDataArray.length > 0) {
      let doc = new jsPDF();
      imgDataArray.forEach((imgData: any, index) => {
        doc.addImage(imgData, 'SVG', 0, 0, 210, 297);
        if (index < imgDataArray.length - 1) {
          doc.addPage();
        }
      });
      doc.save('a4.pdf');
    }
  };
  return (
    <div
      style={{
        // overflowY: mypage && "auto",
        paddingBottom: search ? '30px' : mypage ? '100px' : '',
        backgroundColor: '#FFFFFF',
        // height: search
        //   ? '100%'
        //   : mypage
        //   ? '100%'
        //   : `${window.innerHeight - 50}px`,
        height: mypage
          ? search
            ? '100%'
            : isMobile(window.innerWidth)
              ? '100%'
              : '700px'
          : `${window.innerHeight - 50}px`,
        width: '100%',
      }}
      // style={{ height: `${760}px`, width: "100%" }}
      // style={{ height: "760px", width: "100%" }}
    >
      {/* <button
        onClick={() => {
          handleButtonClick();
        }}
      >
        버튼
      </button> */}
      {savePopUp && <SaveReportPopUp setSavePopUp={setSavePopUp} />}
      {(location.pathname.slice(-1) === '/'
        ? location.pathname.slice(0, -1)
        : location.pathname) === '/mypage/point' ? (
        <TopNav2
          browser
          text="농지가치 평가보고서"
          closeBack
          close
          setClose={setClose}
          report
          mypage
        />
      ) : (location.pathname.slice(-1) === '/'
          ? location.pathname.slice(0, -1)
          : location.pathname) === '/mypage/map' ? (
        ''
      ) : (
        <TopNav2
          search
          browser
          text="농지가치 평가보고서"
          closeBack
          close
          setClose={setClose}
        />
      )}
      {search && (
        <div className="flex-row space-between padding-12-16 center-y">
          <img
            src="/asset/images/arrow/arrowBack.svg"
            alt="arrow"
            className="pointer"
            onClick={() => {
              setCloseSearchPointDetail(false);
            }}
          />
          <div className="m-b1-16-b color-dark-primary">
            농지가치 평가보고서
          </div>
          <img
            src="/asset/images/close/CloseBlack24.svg"
            className="pointer"
            alt="close"
            onClick={() => {
              setCloseSearchPointDetail(false);
              setOnPointHistory(false);
            }}
          />
        </div>
      )}
      <div
        className="of-y-auto padding-b-16"
        style={{
          height: mypage
            ? search
              ? '100%'
              : isMobile(window.innerWidth)
                ? '100%'
                : '650px'
            : '100%',
          // height: "700px",
        }}
        ref={temp}
      >
        <div ref={divRef}>
          <ReportInfo
            mypage={mypage}
            data={report_desc}
            data2={competitivenessResult}
            time={createdTime}
          />
          {!mypage && (
            <ReportNav
              refList={{
                conRef,
                menuRef1,
                menuRef2,
                menuRef3,
                menuRef4,
                menuRef5,
                menuRef6,
                menuRef7,
                menuRef8,
                menuRef9,
                menuRef10,

                temp,
              }}
              tab8={investmentAnalysis.investRate}
            />
          )}

          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef1}
          />
          <Summary1 data={farmValue} data2={report_desc} />
          <div
            style={{ height: '16px', background: '#F7F8F9' }}
            ref={menuRef2}
          />
          <Summary2
            data={competitivenessResult}
            data2={report_desc}
            data3={profitAnalysis}
          />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef3} />
        <div ref={divRef2}>
          <ReportDetail1
            mypage={mypage && true}
            data={farmValue}
            data2={report_desc}
          />
        </div>
        <div
          style={{ height: '16px', background: '#F7F8F9' }}
          ref={menuRef4}
        ></div>
        <div ref={divRef3}>
          <ReportDetail2 mypage={mypage && true} data={cropAnalysis} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef5} />
        <div ref={divRef4}>
          <ReportDetail3 data={cropAnalysis} data2={report_desc} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef6} />
        <div ref={divRef5}>
          <ReportDetail4 data={salesAnalysis} />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef7} />
        <div ref={divRef6}>
          <ReportDetail5
            data={costAnalysis}
            data2={{ ...farmValue, ...investmentAnalysis }}
          />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef8} />
        <div ref={divRef7}>
          <ReportDetail6
            mypage={mypage}
            data={loanAnalysis}
            data2={competitivenessResult}
            data3={report_desc}
            report={report}
            isStart={isStart}
            reportIdx={param.reportIdx}
            setProfitData={setProfitAnalysis}
            setLoanAnalysis={setLoanAnalysis}
            save={save}
            setSavePopUp={setSavePopUp}
            setSave={setSave}
          />
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} ref={menuRef9} />
        <div ref={divRef8}>
          <ReportDetail7
            data={profitAnalysis}
            data2={avgCompetitivenessResult}
          />
        </div>
        <div
          style={{ height: '16px', background: '#F7F8F9' }}
          ref={menuRef10}
        />
        <div ref={divRef9}>
          {investmentAnalysis.investRate ? (
            <ReportDetail8 data={investmentAnalysis} />
          ) : (
            ''
          )}
        </div>
        {!mypage && <div style={{ height: '16px', background: '#F7F8F9' }} />}
        {!mypage && <ReportDetail10 originData={origin} />}

        {!mypage && !search && (
          <div className="w-100per flex-center">
            <SaveBtn
              onClick={() => {
                setSave(true);
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SearchReportMain;
