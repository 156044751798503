import { useEffect, useState } from 'react';

export const PointAlert2 = ({
  setOnPointAlert,
  getReportApi,
  save,
  save2,
  point,
}: any) => {
  const [prev, setPrev] = useState(false);

  const tab = sessionStorage.getItem('tab');

  const handleUseService = () => {
    setOnPointAlert(false);

    if (
      tab === 'price' ||
      tab === 'findland' ||
      tab === 'auction' ||
      tab === 'aifarm'
    ) {
      getReportApi();
    } else if (tab === 'annuity') {
      save();
    } else if (tab === 'house') {
      save2();
    }
  };

  const getTabInfo = () => {
    const tabInfo: { [key: string]: { text: string; icon: string } } = {
      price: { text: '가격 분석', icon: '/asset/images/icon/price_alert.svg' },
      auction: {
        text: '매물 분석',
        icon: '/asset/images/icon/auction_alert.svg',
      },
      findland: {
        text: '농지 찾기',
        icon: '/asset/images/icon/findland_alert.svg',
      },
      house: { text: '내 집 짓기', icon: '/asset/images/icon/house_alert.svg' },
      annuity: {
        text: '농지연금 분석',
        icon: '/asset/images/icon/pension_alert.svg',
      },
      aifarm: {
        text: '내 농장 진단',
        icon: '/asset/images/icon/aifarm_alert.svg',
      },
    };
    return tab ? tabInfo[tab] : { text: '', icon: '' };
  };

  const { text, icon } = getTabInfo();

  return (
    <div
      className="fixed z-999 center-x center-y padding-24"
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000075',
      }}
    >
      <div className="bg-FFFFFF padding-24-16-16-16 flex-column flex-center border-radius-16 w-312 ">
        <div className="w-100per end-x ">
          <img
            className="w-24 pointer"
            src="/asset/images/close/CloseGray24-1.svg "
            onClick={() => {
              setOnPointAlert(false);
            }}
            alt=""
          />
        </div>
        <div className="flex-column gap-24">
          <div className="flex-column gap-16">
            <div className="flex-column flex-center w-100per gap-12">
              <img
                className=""
                src={icon}
                alt="Alert Icon"
                style={{ width: '52px', height: '52px' }}
              />
              {tab === 'price' ? (
                <div className="m-b1-16-b color-dark-primary text-center flex-column gap-8">
                  <span className="color-primary-500 text-center">
                    토지거래 전, 토지분석 먼저
                  </span>
                  <span className="font-medium f-size-14 line-h-20 color-text-tertiary">
                    안전한 토지거래를 위해 트랜스파머가 <br />
                    가격분석을 무료로 제공합니다.
                  </span>
                </div>
              ) : (
                <div className="m-b1-16-b color-dark-primary text-center">
                  <span className="color-primary-500 text-center">{text}</span>
                  이용요금 <br /> 2,000P가 차감됩니다.
                </div>
              )}
            </div>
            <div className="flex-column gap-16">
              <div className="flex-column gap-16 padding-16 border-radius-8 bg-light-gray-100 w-100per">
                <div className="flex-row space-between">
                  <div className="m-b2-14-m color-text-tertiary">
                    이용 서비스
                  </div>
                  <div className="m-b2-14-b color-primary-500">{text}</div>
                </div>
                <div className="flex-row space-between">
                  <div className="m-b2-14-m color-text-tertiary">
                    차감 포인트
                  </div>
                  {tab === 'price' ? (
                    <div className="m-b2-14-b color-notice">무료</div>
                  ) : (
                    <div className="m-b2-14-b color-primary-500">-2,000P</div>
                  )}
                </div>
                <div className="flex-row space-between no-wrap">
                  <div className="m-b2-14-m color-text-tertiary">
                    잔여 포인트
                  </div>
                  {tab === 'price' ? (
                    <div className="m-b2-14-b color-notice">무제한</div>
                  ) : (
                    <div className="m-b2-14-b color-dark-primary">
                      {(point - 2000).toLocaleString()}P
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div
              className="w-100per flex-center flex-row padding-12-16 gap-2 border-radius-8"
              style={{ backgroundColor: '#FFFAE9', width: '280px' }}
            >
              {tab === 'price' ? (
                <>
                  <div className="m-c1-12-m color-notice">
                    트랜스파머{' '}
                    <span className="m-c1-12-b color-notice">
                      농지거래 서비스
                    </span>
                    가 곧 시작됩니다
                  </div>
                </>
              ) : (
                <>
                  <div className="m-c1-12-m color-notice">
                    한번 분석한 주소지는{' '}
                    <span className="m-c1-12-b color-notice">
                      90일 동안 재분석 무료!
                    </span>
                  </div>
                  <img src="/asset/images/icon/point_alert.svg" alt="" />
                </>
              )}
            </div>
          </div>
          <div className="flex-row gap-8">
            <div
              className="w-50per bg-blue-gray-100 border-radius-8 padding-y-12 flex-center pointer"
              onClick={() => {
                setOnPointAlert(false);
              }}
            >
              <div className="m-b2-14-m color-disabled">취소</div>
            </div>
            <div
              className="w-50per bg-primary-500 border-radius-8 padding-y-12 flex-center pointer"
              onClick={() => {
                handleUseService();
              }}
            >
              <div className="m-b2-14-m color-FFFFFF">이용하기</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
