import { Content1, Footer, ReportTitle } from '../component';
interface PropsType {
  data: any;

  address: string;
}
export const OfficialPrice = ({ data, address }: PropsType) => {
  const content = [
    `본 물건의 최근 9년(2015-2023) 공시지가 연평균 성장률은 ${
      Math.round(data.CAGR * 1000) / 10
    }%임`,
    `2023년 현재 ㎡당 공시지가는 ${Number(
      data.officialPriceList[2023],
    ).toLocaleString()}원으로 사업지 면적(??) 적용 결과, ${Number(
      data.price,
    ).toLocaleString()}원으로 산정될 수 있음`,
  ];
  return (
    <div className="flex-column w-100per space-between page">
      <div className="flex-column w-100per">
        <ReportTitle
          address="전북특별자치도 김제시 순동 445-19"
          category="공시지가 추이"
        />
        <div className="flex-column padding-24 gap-12">
          <Content1 content={content} />
          <div>그래프</div>
        </div>
      </div>
      <Footer number={6} />
    </div>
  );
};
