import Request from '../@api/request';
import axios from 'axios';

// const baseURL = "https://back.transfarmer.kr/";
// const baseURL = "http://localhost:3333/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

interface LoginData {
  userId: string;
  password: string;
}

export class LoginService {
  static Login = async (data: LoginData) => {
    return await request.post('users/sign/in', data);
  };
  static GetInquiry = async (
    individual: string,
    offset: number,
    limit: number,
  ) => {
    const result = await request.get(
      `/inquiry/base/list?individual=${individual}&offset=0&limit=10`,
    );
    return result.data;
  };
}
