import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { BottomNav } from '../../component/mobile/BottomNav';
import { Topnav } from '../../component/mobile/Topnav';
import { useState, useEffect } from 'react';
import '../../asset/styles/SaleInfo.css';
import axios from 'axios';
import { StatusHandler, TypeHandler } from '../../component/function/function';
import BoardService from '../../service/board/Board';
interface InquiryData {
  content: string;
  createdDate: string;
  email: string;
  file: string | null;
  inquiryIdx: number;
  status: string;
  success: boolean;
  title: string;
  type: string;
}
const InquiryDetail = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [inquiryData, setInquiryData] = useState<InquiryData>();
  const getOneInquiry = async () => {
    const formData = new FormData();
    formData.append('isAdmin', 'A');
    const inquiryIdx = state.inquiryIdx;

    try {
      const result = await BoardService.getInquiryDetail(
        inquiryIdx,
        formData as any,
      );
      setInquiryData(result.data);
    } catch (error: any) {
      console.log(error);
      if (error.response.data.message === 'jwt expired') {
        alert('토큰이 만료되었습니다.');
        navigate('/login', { replace: true });
      }
    }
  };
  useEffect(() => {
    getOneInquiry();
  }, []);
  return (
    <div
      className="flex-column w-100per"
      style={{ height: '100vh', paddingBottom: '73px' }}
    >
      <Topnav text="내 문의" />
      <div className="flex-column padding-0-20 mt-20 w-100per">
        <div className="flex-row space-between padding-b-20 borderbottom-1px-e8ebe6  w-100per">
          <div className="flex-column">
            <div className="font-normal" style={{ fontWeight: '700' }}>
              강원도 원주시 가현동 133-4
            </div>
            <div className="font-small">
              {inquiryData && inquiryData.createdDate}
            </div>
          </div>
          <div className="greybox padding-10-10">
            <div className="font-normal" style={{ color: 'black' }}>
              {inquiryData && StatusHandler(inquiryData.status)}
            </div>
          </div>
        </div>
        <div className="flex-column gap-10 mt-20">
          <li className="font-normal">위치 : 설정 주소</li>
          <li className="font-normal">시공 : 집 전체 시공</li>
          <li className="font-normal">먄작 : 사용자 선택 면적</li>
          <li className="font-normal">층수 : 사용자 선택 층(1/2층)</li>
        </div>
      </div>
      <div
        className="flex-column padding-20-20 mt-20 gap-20"
        style={{
          backgroundColor: '#E3F6EB',
          display: 'flex',
          flex: '1 0 auto',

          //   display: "block",
        }}
      >
        <div className="font-normal" style={{ fontWeight: '700' }}>
          트랜스파머에서 답변드립니다.
        </div>
        <div className="font-normal">안녕하세요 트랜스파머 담당자입니다.</div>
        <div className="font-normal">
          보내주신 소중한 내용은 잘받았습니다 ~~~~ content
        </div>
        <div className="font-normal">고객님께 언제나 행운이 ~~~~ </div>
        <div className="font-normal">■ 트랜스파머 고객센터 :1588-0000</div>
        <div className="font-normal">2023.06.08 17:08</div>
      </div>
    </div>
  );
};

export default InquiryDetail;
