import Sidemenu from './Hamburger';
import greenHamburger from './../../asset/images/new/greenHamburger.svg';
import greenLogo from './../../asset/images/new/greenLogo.svg';
import { Link } from 'react-router-dom';

export const PromotionTopNav = ({ setOpen, open }: any) => {
  return (
    <div className="padding-16 bg-white  w-100per center-y">
      <Sidemenu setOpen={setOpen} open={open} />

      <div className="flex-row space-between w-100per center-y">
        <div className="flex-column gap-6">
          <Link to="/" className="logo-korean">
            <img src={greenLogo} alt="" />
          </Link>
          <div className="ai font-medium f-size-12">
            AI가이드로 농촌생활 똑똑하게!{' '}
          </div>
        </div>
        <img
          src={greenHamburger}
          className="pointer"
          onClick={() => {
            setOpen(!open);
          }}
          alt=""
        />
      </div>
    </div>
  );
};
