const Toast = ({ text, bottom, left, width }: any) => {
  return (
    <div
      className={`fixed z-100 bg-blue-gray-800 border-radius-8 padding-8 w-328 gap-10 flex-row fadeout`}
      style={{ bottom: `${bottom}px`, left: `${left}px` }}
    >
      <div className="font-bold f-size-14 line-h-20 color-white">✓</div>
      <div className="font-bold f-size-14 line-h-20 color-white">{text}</div>
    </div>
  );
};

export default Toast;
