import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider, useDispatch, useSelector } from 'react-redux';
import GlobalStyle from './asset/styles/GlobalStyle';
import { PersistGate } from 'redux-persist/integration/react';
import persistStore from 'redux-persist/es/persistStore';
import store from './store/store';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import { HackleProvider } from '@hackler/react-sdk';
import TrackClickEvents, { hackleClient } from './hackler';

ReactGA.initialize('YOUR_TRACKING_ID');
export const persistor = persistStore(store);

// const container = document.getElementById("root") as HTMLElement;
// const root = ReactDOM.createRoot(container);

// if (container.hasChildNodes()) {
//   ReactDOM.hydrateRoot(
//     container,
//     <BrowserRouter>
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <GlobalStyle />

//           <App />
//         </PersistGate>
//       </Provider>
//     </BrowserRouter>
//   );
// } else {
//   root.render(
//     <BrowserRouter>
//       <Provider store={store}>
//         <PersistGate loading={null} persistor={persistor}>
//           <GlobalStyle />

//           <App />
//         </PersistGate>
//       </Provider>
//     </BrowserRouter>
//   );
// }

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <HackleProvider hackleClient={hackleClient}>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <GlobalStyle />
          <App />
          <TrackClickEvents />
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </HackleProvider>,
);
reportWebVitals();
