import { useRef } from 'react';
import { isMobile } from '../../component/function/function';
import { useLocation } from 'react-router-dom';
import { TopNav2 } from '../../component/mobile/Topnav';
import LandPotential from './LandPotential';
import NearbyLandExamples from './NearbyLandExamples';

interface IProps {
  setClose: (value: boolean) => void;
  reportIdx4: number;
}

const ReportResultLanduse = ({ setClose, reportIdx4 }: IProps) => {
  const viewerRef = useRef(null);
  const divRef = useRef<HTMLDivElement>(null);

  const locationHook = useLocation();

  return (
    <div
      className={`w-100per  browser-h-100per browser-bg-FFFFFF ${
        isMobile(window.innerWidth) && `of-scroll`
      }`}
      style={
        isMobile(window.innerWidth)
          ? { height: window.innerHeight, zIndex: 100 }
          : { overflow: 'hidden', overflowY: 'auto' }
      }
    >
      <div style={{ overflowY: 'hidden', height: '100%' }} ref={viewerRef}>
        <div
          className=""
          style={{
            height: '100%',
            overflowX: 'hidden',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <div ref={divRef} style={{ height: 'fit-content', marginTop: 10 }}>
            {(locationHook.pathname.slice(-1) === '/'
              ? locationHook.pathname.slice(0, -1)
              : locationHook.pathname) === '/mypage/point' ? (
              <TopNav2
                browser
                text="토지 활용 분석"
                closeBack
                close
                setClose={setClose}
                report
                mypage
              />
            ) : (locationHook.pathname.slice(-1) === '/'
                ? locationHook.pathname.slice(0, -1)
                : locationHook.pathname) === '/mypage/map' ? (
              ''
            ) : (
              <TopNav2
                search
                browser
                text="토지 활용 분석"
                closeBack
                close
                setClose={setClose}
              />
            )}
          </div>
          <LandPotential reportIdx4={reportIdx4} />
          <NearbyLandExamples reportIdx4={reportIdx4} />
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
              padding: '16px 16px 72px 16px',
            }}
          >
            <img
              src="/asset/images/landUse/idleLand-banner.svg"
              alt=""
              onClick={() => {
                window.open(
                  'https://transfarmer.co.kr/landMarket/apply',
                  '_blank',
                );
              }}
              style={{ cursor: 'pointer', width: '100%', height: '80px' }}
            />
            <img
              src="/asset/images/landUse/land-utitzation-banner.svg"
              alt=""
              onClick={() => {
                window.open(
                  'https://transfarmer.co.kr/landMarket/product',
                  '_blank',
                );
              }}
              style={{ cursor: 'pointer', width: '100%', height: '80px' }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
export default ReportResultLanduse;
