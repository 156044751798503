import { styled } from 'styled-components';
import CloseImg from './../../../images/login/close.svg';
interface InputType {
  width: string;
}
interface font {
  fontSize: string;
  fontWeight: string;
  color: string;
  lineHeight: string;
}
export const FullContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
`;
export const FullContainerWithNav = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  padding-bottom: 45px;
`;

export const TextFont = styled.div<font>`
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => `${props.fontSize}px`};
  color: ${(props) => props.color};
  line-height: ${(props) => `${props.lineHeight}px`};

  font-family: 'Spoqa Han Sans Neo';
  text-align: center;
`;

export const InputContainer = styled.div`
  display: flex;
  margin-top: 32px;
  flex-direction: column;
  gap: 12px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgba(250, 250, 250, 1);
  border-radius: 13px;
  width: 335px;
  height: 74px;
  padding: 0px 16px 0px 16px;
  justify-content: center;
  gap: 10px;
`;

export const Inputbox = styled.input`
  display: flex;
  background-color: rgba(250, 250, 250, 1);
  font-size: 17px;
  font-family: 'SpoqaHanSansNeoRegular';
  line-height: 30px;
  color: black;
  border: none;
  width: ${(props) => `${props.width}px` || '100%'};
  &::placeholder {
    font-family: 'SpoqaHanSansNeoRegular';
    line-height: 30px;
    color: rgba(136, 139, 144, 1);
    font-size: 17px;
  }
`;

export const CloseBtnWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
`;

export const Close = styled.img.attrs({
  src: `${CloseImg}`,
})`
  cursor: pointer;
`;
