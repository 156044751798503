import { memo } from 'react';
import type { FC } from 'react';

import resets from './_resets.module.css';
import { CloseIcon } from './CloseIcon';
import classes from './Contents.module.css';
import { Frame43149Icon } from './Frame43149Icon';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
}
/* @figmaId 504:8910 */
const AIFarmingTicketPurchase: FC<Props> = memo(function Contents(props = {}) {
  const navigate = useNavigate();
  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.frame43151}>
        <div className={classes.frame43150}>
          <div
            className={classes.frame43149}
            onClick={() => navigate('/aiFarming/map')}
          >
            <Frame43149Icon className={classes.icon} />
          </div>
          <div className={classes.frame42995}>
            <div className={classes.unnamed}>
              <div className={classes.textBlock}>
                사업타당성 분석 보고서 이용권
              </div>
              <div className={classes.textBlock2}>구매가 완료 되었습니다.</div>
            </div>
            <div className={classes.unnamed2}>
              <div className={classes.textBlock3}>구매하신 이용권은</div>
              <div className={classes.textBlock4}>
                이용권 구매 내역에서 확인하실 수 있습니다.
              </div>
            </div>
          </div>
        </div>
        <div className={classes.btn}>
          <div
            className={classes.unnamed3}
            onClick={() => navigate('/aiFarming/map#1')}
          >
            확인
          </div>
        </div>
      </div>
      <div className={classes.frame1237}>
        <div className={classes.top}>
          <div className={classes.closeArea}>
            <div className={classes.close}>
              <CloseIcon className={classes.icon2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AIFarmingTicketPurchase;
