import { createSlice } from '@reduxjs/toolkit';

const initialType = {
  type: '',
  sidetype: '',
  priceType: '',
};

const typeSlice = createSlice({
  name: 'type',
  initialState: initialType,
  reducers: {
    ChangeType(state, action) {
      state.type = action.payload;
    },
    ChangeSideType(state, action) {
      state.sidetype = action.payload;
    },
    ChangePriceType(state, action) {
      state.priceType = action.payload;
    },
  },
});

export const { ChangeType, ChangeSideType, ChangePriceType } =
  typeSlice.actions;
export default typeSlice.reducer;
