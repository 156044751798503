import { useEffect, useState } from 'react';
import { ITotaldata } from '../Main';
import { transNum } from '../../../component/function/function';
import PensionService from '../../../service/pension/pensionApi';
import Toast from '../../../component/common/Toast';
import { ButtonSelectorBox } from '../../../component/report/Button';

const Calculator = ({
  data,
  reportIdx,
  report,
  period,
  isValid,
  mypage,
  mypage2,
  setManageTab,
  setFixedTab,
  manageTab,
  fixedTab,
  saveReport,
}: {
  data: ITotaldata;
  reportIdx: any;
  report: any;
  period: any;
  isValid: boolean;
  mypage?: boolean;
  mypage2?: boolean;
  setManageTab?: any;
  setFixedTab?: any;
  manageTab?: any;
  fixedTab?: any;
  saveReport?: any;
}) => {
  const [tab, setTab] = useState(1);

  const btnArr = [
    { children: '월 연금', selected: 1 },
    '',
    { children: '1년 연금', selected: 2 },
  ];
  function calculateAge(birthdate: string) {
    var currentDate = new Date();

    var birthdateDate = new Date(birthdate);

    var age = currentDate.getFullYear() - birthdateDate.getFullYear();

    if (
      currentDate.getMonth() < birthdateDate.getMonth() ||
      (currentDate.getMonth() === birthdateDate.getMonth() &&
        currentDate.getDate() < birthdateDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  useEffect(() => {
    if (
      ageObj[data.managementTypePeriod as 5] <= calculateAge(data.age) ||
      ageObj[data.managementTypePeriod as 5] <= data.tmpAge
    ) {
      period.setManagementTypePeriod(data.managementTypePeriod);
    } else {
      period.setManagementTypePeriod(undefined);
    }
    if (
      ageObj[data.fixedTypePeriod as 5] <= calculateAge(data.age) ||
      ageObj[data.fixedTypePeriod as 5] <= data.tmpAge
    ) {
      period.setFixedTypePeriod(data.fixedTypePeriod);
    } else {
      period.setFixedTypePeriod(undefined);
    }
  }, [data]);

  const ageObj = {
    20: 63,
    15: 68,
    10: 73,
    5: 78,
  };

  // const patchFixedTypePeriod = async (fixedTypePeriod: any) => {
  //   try {
  //     if (
  //       ageObj[fixedTypePeriod as 5] <= calculateAge(data.age) ||
  //       ageObj[fixedTypePeriod as 5] <= data.tmpAge
  //     ) {
  //       await PensionService.patchFixedTypePeriod(
  //         Number(fixedTypePeriod),
  //         reportIdx
  //       );
  //       await report();
  //     } else {
  //       setToastAge(fixedTypePeriod);
  //       setOnToast(true);
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const patchManagementTypePeriod = async (managementTypePeriod: any) => {
    try {
      if (
        ageObj[managementTypePeriod as 5] <= calculateAge(data.age) ||
        ageObj[managementTypePeriod as 5] <= data.tmpAge
      ) {
        await PensionService.patchManagementTypePeriod(
          Number(managementTypePeriod),
          reportIdx,
        );
        await report();
      } else {
        setToastAge(managementTypePeriod);
        setOnToast(true);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [onToast, setOnToast] = useState(false);
  const [toastAge, setToastAge] = useState(0);
  useEffect(() => {
    if (onToast) {
      setTimeout(() => {
        setOnToast(false);
      }, 2900);
    }
  }, [onToast]);

  return (
    <div className="flex-column">
      {onToast && (
        <div className="flex-center z-100 ">
          <Toast
            text={`만 나이 ${
              ageObj[toastAge as 10]
            }세 이상이 아니므로 해당사항 없습니다.`}
            bottom={mypage ? 150 : 100}
          />
        </div>
      )}
      <div className="flex-column">
        {/* <div className="padding-24-16-8 font-bold f-size-18 line-h-26 color-text-primary">
          년월로 보는 연금 금액
        </div> */}

        <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

        {/* <div className="padding-16-16-0 font-bold f-size-16 line-h-24 color-text-secondary">
          수령방식별 월 연금
        </div> */}
        <div className="padding-t-24 padding-x-16 gap-24 flex-column">
          <div className="padding-b-24 border-b-1-171E2610 flex-column gap-8">
            <div className="flex-row space-between center-y">
              <div className="flex-row gap-4 center-y">
                <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                  종신정액형
                </div>
                <div
                  className="border-radius-6 padding-4-8 font-medium f-size-12 line-h-14"
                  style={{ color: '#166F4D', background: '#EAF8F2' }}
                >
                  종신형
                </div>
              </div>
              <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                {isValid
                  ? transNum(
                      tab === 2
                        ? data.fixedAmountForLife?.pension
                        : data.fixedAmountForLife?.pension / 12,
                    )
                  : '?'}
                원
              </div>
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              사망 시까지 매월 일정한 연금을 안정적으로 지급받는 방식
            </div>
          </div>
        </div>
        <div className="padding-t-24 padding-x-16 gap-24 flex-column">
          <div className="padding-b-24 border-b-1-171E2610 flex-column gap-8">
            <div className="flex-row space-between start-y">
              <div className="flex-row gap-4 center-y">
                <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                  전후후박형
                </div>
                <div
                  className="border-radius-6 padding-4-8 font-medium f-size-12 line-h-14"
                  style={{ color: '#166F4D', background: '#EAF8F2' }}
                >
                  종신형
                </div>
              </div>
              <div className="flex-column">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  {isValid
                    ? transNum(
                        tab === 2
                          ? data.backAndForthThinning?.pension?.before
                          : data.backAndForthThinning?.pension?.before / 12,
                      )
                    : '?'}
                  원 (10년간)
                </div>
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  {isValid
                    ? transNum(
                        tab === 2
                          ? data.backAndForthThinning?.pension?.after
                          : data.backAndForthThinning?.pension?.after / 12,
                      )
                    : '?'}
                  원 (10년후)
                </div>
              </div>
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              가입 초기 10년은 더많은 연금을 지급받고, 이후 사망시 까지 매월
              일정한 연금을 안정적으로 지급받는 방식
            </div>
          </div>
        </div>
        <div className="padding-t-24 padding-x-16 gap-24 flex-column">
          <div className="padding-b-24 border-b-1-171E2610 flex-column gap-8">
            <div className="flex-column">
              <div className="flex-row space-between center-y">
                <div className="flex-row gap-4 center-y">
                  <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                    수시인출형
                  </div>
                  <div
                    className="border-radius-6 padding-4-8 font-medium f-size-12 line-h-14"
                    style={{ color: '#166F4D', background: '#EAF8F2' }}
                  >
                    종신형
                  </div>
                </div>
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  {isValid
                    ? transNum(
                        tab === 2
                          ? data.occasionalWithdrawal?.pension
                          : data.occasionalWithdrawal?.pension / 12,
                      )
                    : '?'}
                  원
                </div>
              </div>
              <div className="flex-row space-between center-y">
                <div className="flex-row gap-4 center-y">
                  <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                    수시인출금
                  </div>
                </div>
                <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                  {isValid
                    ? transNum(
                        data.occasionalWithdrawal?.occasionalWithdrawal
                          ?.withdrawal,
                      )
                    : '?'}
                  원
                </div>
              </div>
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              목돈 필요시 연금의 30%까지 일시에 인출할 수 있고, 이후 사망 시까지
              매월 일정한 연금을 안정적으로 지급받는 방식
            </div>
          </div>
        </div>
        <div className="padding-t-24 padding-x-16 gap-24 flex-column">
          <div className="padding-b-24 border-b-1-171E2610 flex-column gap-16">
            <div className="flex-column gap-8">
              <div className="flex-row space-between center-y">
                <div className="flex-row gap-4 center-y">
                  <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                    기간정액형
                  </div>
                  <div
                    className="border-radius-6 padding-4-8 font-medium f-size-12 line-h-14"
                    style={{ color: '#163F6F', background: '#EAF3F8' }}
                  >
                    기간선택형
                  </div>
                </div>
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  {isValid
                    ? fixedTab === 1
                      ? tab === 1
                        ? transNum(
                            data.fixedTerm?.fixedTerm?.year_5.pensionResult[0]
                              .pension,
                          )
                        : transNum(
                            data.fixedTerm?.fixedTerm?.year_5.pensionResult[0]
                              .pension / 12,
                          )
                      : fixedTab === 2
                        ? tab == 1
                          ? transNum(
                              data.fixedTerm?.fixedTerm?.year_10
                                .pensionResult[0].pension,
                            )
                          : transNum(
                              data.fixedTerm?.fixedTerm?.year_10
                                .pensionResult[0].pension / 12,
                            )
                        : fixedTab === 3
                          ? tab === 1
                            ? transNum(
                                data.fixedTerm?.fixedTerm?.year_15
                                  .pensionResult[0].pension,
                              )
                            : transNum(
                                data.fixedTerm?.fixedTerm?.year_15
                                  .pensionResult[0].pension / 12,
                              )
                          : fixedTab === 4
                            ? tab === 1
                              ? transNum(
                                  data.fixedTerm?.fixedTerm?.year_20
                                    ?.pensionResult[0].pension,
                                )
                              : transNum(
                                  data.fixedTerm?.fixedTerm?.year_20
                                    ?.pensionResult[0].pension / 12,
                                )
                            : ''
                    : '?'}
                  원
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                일정 기간 동안 매월 일정한 연금을 지급받는 방식
              </div>
            </div>
            <div className="flex-row gap-8">
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 78) {
                      setFixedTab(1);
                    } else {
                      setOnToast(true);
                      setToastAge(5);
                    }
                  }
                }}
                style={{
                  color: fixedTab === 1 ? '#1E9B6B' : '#171E2640',
                  border:
                    fixedTab === 1
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  5년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 78세 이상
                </div>
              </div>
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 73) {
                      setFixedTab(2);
                    } else {
                      setOnToast(true);
                      setToastAge(10);
                    }
                  }
                }}
                style={{
                  color: fixedTab === 2 ? '#1E9B6B' : '#171E2640',
                  border:
                    fixedTab === 2
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  10년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 73세 이상
                </div>
              </div>
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 68) {
                      setFixedTab(3);
                    } else {
                      setOnToast(true);
                      setToastAge(15);
                    }
                  }
                }}
                style={{
                  color: fixedTab === 3 ? '#1E9B6B' : '#171E2640',
                  border:
                    fixedTab === 3
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  15년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 68세 이상
                </div>
              </div>
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 63) {
                      setFixedTab(4);
                    } else {
                      setOnToast(true);
                      setToastAge(20);
                    }
                  }
                }}
                style={{
                  color: fixedTab === 4 ? '#1E9B6B' : '#171E2640',
                  border:
                    fixedTab === 4
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  20년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 63세 이상
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="padding-t-24 padding-x-16 gap-24 flex-column">
          <div className="padding-b-24 border-b-1-171E2610 flex-column gap-16">
            <div className="flex-column gap-8">
              <div className="flex-row space-between center-y">
                <div className="flex-row gap-4 center-y">
                  <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                    경영이양형
                  </div>
                  <div
                    className="border-radius-6 padding-4-8 font-medium f-size-12 line-h-14"
                    style={{ color: '#163F6F', background: '#EAF3F8' }}
                  >
                    기간선택형
                  </div>
                </div>
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  {/* {isValid
                    ? transNum(
                        (tab === 2
                          ? data.managementTransfer?.pension?.[
                              `year_${period.managementTypePeriod}` as "year_15"
                            ]
                          : data.managementTransfer?.pension?.[
                              `year_${period.managementTypePeriod}` as "year_15"
                            ] / 12) || 0
                      )
                    : "?"}
                  원 */}
                  {isValid
                    ? manageTab === 1
                      ? tab === 1
                        ? transNum(
                            data.managementTransfer?.managementTransfer?.year_5
                              .pensionResult[0].pension,
                          )
                        : transNum(
                            data.managementTransfer?.managementTransfer?.year_5
                              .pensionResult[0].pension / 12,
                          )
                      : manageTab === 2
                        ? tab === 1
                          ? transNum(
                              data.managementTransfer?.managementTransfer
                                ?.year_10.pensionResult[0].pension,
                            )
                          : transNum(
                              data.managementTransfer?.managementTransfer
                                ?.year_10.pensionResult[0].pension / 12,
                            )
                        : manageTab === 3
                          ? tab === 1
                            ? transNum(
                                data.managementTransfer?.managementTransfer
                                  ?.year_15.pensionResult[0].pension,
                              )
                            : transNum(
                                data.managementTransfer?.managementTransfer
                                  ?.year_15.pensionResult[0].pension / 12,
                              )
                          : manageTab === 4
                            ? tab === 1
                              ? transNum(
                                  data.managementTransfer?.managementTransfer
                                    ?.year_20?.pensionResult[0].pension,
                                )
                              : transNum(
                                  data.managementTransfer?.managementTransfer
                                    ?.year_20?.pensionResult[0].pension / 12,
                                )
                            : ''
                    : '?'}
                </div>
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                일정 기간 동안 매월 연금을 지급 받고, 농어촌공사에 담보 농지
                매도를 약정하는 방식
              </div>
            </div>
            <div className="flex-row gap-8">
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  // if (isValid) {
                  //   setManageTab(1);
                  // }
                  if (isValid) {
                    if (calculateAge(data.age) > 78) {
                      setManageTab(1);
                    } else {
                      setOnToast(true);
                      setToastAge(5);
                    }
                  }
                }}
                style={{
                  color: manageTab === 1 ? '#1E9B6B' : '#171E2640',
                  border:
                    manageTab === 1
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  5년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 78세 이상
                </div>
              </div>
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 73) {
                      setManageTab(2);
                    } else {
                      setOnToast(true);
                      setToastAge(10);
                    }
                  }
                }}
                style={{
                  color: manageTab === 2 ? '#1E9B6B' : '#171E2640',
                  border:
                    manageTab === 2
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  10년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 73세 이상
                </div>
              </div>
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 68) {
                      setManageTab(3);
                    } else {
                      setOnToast(true);
                      setToastAge(15);
                    }
                  }
                }}
                style={{
                  color: manageTab === 3 ? '#1E9B6B' : '#171E2640',
                  border:
                    manageTab === 3
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  15년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 68세 이상
                </div>
              </div>
              <div
                className={`padding-y-6 border-radius-8 flex-column gap-8 center-y flex-1 ${`hover`}`}
                onClick={() => {
                  if (isValid) {
                    if (calculateAge(data.age) > 63) {
                      setManageTab(4);
                    } else {
                      setOnToast(true);
                      setToastAge(20);
                    }
                  }
                }}
                style={{
                  color: manageTab === 4 ? '#1E9B6B' : '#171E2640',
                  border:
                    manageTab === 4
                      ? '1px solid #1e9b6b'
                      : '1px solid #171E2640',
                }}
              >
                <div className="font-bold f-size-14 line-h-20 no-wrap">
                  20년 수령
                </div>
                <div className="font-medium f-size-12 line-h-14 no-wrap">
                  만 63세 이상
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="padding-16-16-24">
        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
          ※ 예상 농지연금 지급액은 트랜스파머 인공지능이 자동 산출한 것으로
          실재값과 1%이내 일부 차이가 있을 수 있습니다.
        </div>
      </div>
    </div>
  );
};

export default Calculator;
