import { BottomNav } from '../../component/mobile/BottomNav';
import { TopNav2 } from '../../component/mobile/Topnav';
import logo from './../../asset/images/logo/logo.svg';
import { useLocation, useNavigate } from 'react-router-dom';

import { useState, useEffect } from 'react';
const Report = () => {
  const navigate = useNavigate();
  const [list, setList] = useState([]);
  const locationHook = useLocation();
  console.log(locationHook.state);
  useEffect(() => {
    setList(JSON.parse(sessionStorage.areaList || ''));
  }, []);

  return (
    <div className="flex-column w-100per center-y browser-h-100per browser-padding-b-48 browser-border-b-4-f7f8f9">
      <TopNav2 text="내 농장 진단보고서" close back />

      {/* <BottomNav number={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname) === "/operate/report" ? 2 : 1} /> */}
      <div className="flex-column w-100per max-w-681 browser-mt-48 ">
        <div className="flex-column w-100per padding-24-16 gap-30 border-0d8-b  browser-border-radius-10 browser-padding-0 browser-border-none">
          <div className="browser font-medium f-size-18 line-h-26 color-text-primary">
            내 농장 진단보고서 안내
          </div>
          <div className="flex-column border-radius-8 padding-16 bg-primary-200 start-y gap-10">
            <img src={logo} className="w-16 h-18" />
            <div className="font-bold f-size-18 line-h-26 color-primary-500">
              {localStorage.getItem('username')}님의 소중한 농장 경영 진단을
              위해 아래 내용을 확인해 주세요
            </div>
            {[
              '트랜스파머가 개발한 AI 알고리즘으로 농장 경영을 진단합니다.',
              '내 농장 경영 비용을 정확히 입력할수록 진단 신뢰도가 높아집니다.',
              '내 비용 입력 시, AI가 추정한 지역 평균치가 제공되오니 참고하시기 바랍니다.',
            ].map((el) => (
              <div className="flex-row gap-5">
                <div className="font-medium f-size-12 line-h-18 color-text-secondary">
                  ✔
                </div>
                <div className="font-medium f-size-12 line-h-18 color-text-secondary">
                  {el}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="padding-20-20 tablet w-100per">
          <div
            className="hover bg-primary flex-center padding-20-20 border-radius-8 w-100per"
            onClick={() => {
              navigate(
                (locationHook.pathname.slice(-1) === '/'
                  ? locationHook.pathname.slice(0, -1)
                  : locationHook.pathname) + '/current',
                { state: { address: locationHook.state.address } },
              );
            }}
          >
            <div className="font-bold f-size-18 line-h-26 color-white pointer">
              내 농장 경영 진단 시작하기
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
