import { Title } from './Content';
const Base = ({ title, children, warn, onClick }: any) => {
  return (
    <div className="padding-x-16 relative">
      <div className="flex-column report_result_box bg-white browser-bg-FFFFFF  border-radius-8 gap-8 padding-b-16">
        <Title text={title} warn={warn} onClick={onClick} />
        {children}
      </div>
    </div>
  );
};

export default Base;
