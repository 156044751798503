import { BottomNav } from '../../component/mobile/BottomNav';
import { Tooltip2 } from '../../component/common/tooltip';
import GreenBox from '../../component/mobile/GreenBox';
import ReportTitle from '../../component/mobile/ReportTitle';
import Step from '../../component/mobile/Step';
import { TopNav2 } from '../../component/mobile/Topnav';
import { useRef, useState } from 'react';
import close from './../../asset/images/close/close_black.svg';
import Button from '../../component/mobile/Button';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LandService from '../../service/land/landApi';
import { SectionTitle, CostControl } from './component';

import { dummyCost, dummyCost2, dummyCost3 } from '../../dummy/dummy';
import { isMobile } from '../../component/function/function';
import UserService from '../../service/checkAccount/CheckAccount';
import { PointAlert2 } from '../../component/common/pointAlert2';
import { PointAlert } from '../../component/common/pointAlert';
import { PointColumn } from '../../component/common/pointColumn';
import WarnPoint from '../../component/common/warnPoint';
import ReportService from '../../service/report/reportApi';

const Cost = ({
  setReportIdx,
  selected,
  setLeft,
  setTabWidth,
  setClose,
  isCropAvailable,
  ownLaborYN,
  address,
}: any) => {
  const navigate = useNavigate();
  const locationHook = useLocation();
  const [tooltip2, setTooltip2] = useState(false);
  const element = useRef<HTMLDivElement>(null);
  const element2 = useRef<HTMLDivElement>(null);
  const element3 = useRef<HTMLDivElement>(null);
  const element4 = useRef<HTMLDivElement>(null);
  console.log(locationHook.state);
  const next = async () => {
    try {
      const res = await LandService.analysisResult({
        ...reportData,
        type:
          (locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname
          ).slice(0, 8) === '/operate'
            ? 'O'
            : 'S',
        loanInfo: reportData.loanInfo.map((el: any) => {
          return {
            ...el,
            redemptionPeriod:
              Number(el.redemptionPeriod) - Number(el.retentionPeriod),
          };
        }),
      });
      navigate(`/aiFarming/report/${res.reportIdx}`);
      //  if(isMobile()  navigate(`/report/main/${res.reportIdx}`))
    } catch (err: any) {
      if (
        err.response.data.message === 'This pnuCodes of report already exist'
      ) {
        alert('해당 토지 목록 보고서가 이미 존재합니다.');
      }
      console.log(err);
    }
  };
  const dummy = [
    { name: '원리금균등', type: 'T' },
    { name: '원금균등', type: 'P' },
    { name: '만기일시', type: 'B' },
  ];
  const [loanType, setLoanType] = useState('');
  const [onPointAlert, setOnPointAlert] = useState(false);
  const [point, setPoint] = useState(0);
  const [reportData, setReportData]: any = useState({
    pnuCodes: [''],
    type: 'O',
    facility: 'O',
    cropName: '',
    crops: null,
    landCostType: 'P',
    quantity: null,
    unitPrice: null,
    landPurchaseCost: null,
    avgLandPurchaseCost: null,
    landRentalCost: null,
    avgLandRentalCost: null,
    facilityCost: null,
    avgFacilityCost: null,
    farmMachineryCost: null,
    avgFarmMachineryCost: '0',
    ownLaborYN: 'Y',
    laborCost: null,
    avgEmpLaborCost: null,
    avgOwnLaborCost: null,
    materialCost: null,
    avgMaterialCost: null,
    orchardCost: null,
    avgOrchardCost: null,
    waterHeatingCost: null,
    avgWaterHeatingCost: null,
    farmEquipmentCost: null,
    avgFarmEquipmentCost: null,
    SGA: null,
    avgSGA: null,
    loanInfo: [
      // {
      //   loanName: "",
      //   loanDate: "",
      //   loanRate: "",
      //   loanCost: "",
      //   retentionPeriod: "",
      //   redemptionPeriod: "",
      // },
    ],
  });

  const SetCostControl = (item: any) => {
    return (
      <CostControl
        title={item.title}
        item={item}
        value={Number(reportData[item.state as 'facility']).toLocaleString()}
        minusClick={() =>
          setReportData((prev: any) => {
            return {
              ...prev,
              [item.state]: Math.round(
                (Number(reportData[item.state as 'facility']) * 90) / 100,
              ),
            };
          })
        }
        disabled={
          (locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname) === '/search/report/cost'
        }
        onChange={(e: any) =>
          updateReportData({
            [item.state]: Number(e.target.value.split(',').join('')),
            [`avg${item.state.charAt(0).toUpperCase() + item.state.slice(1)}`]:
              Number(e.target.value.split(',').join('')),
          })
        }
        plusClick={() =>
          setReportData((prev: any) => {
            return {
              ...prev,
              [item.state]: Math.round(
                (Number(reportData[item.state as 'facility']) * 110) / 100,
              ),
            };
          })
        }
      ></CostControl>
    );
  };

  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();

      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    CheckPoint();
  }, []);

  const ReportApi = async () => {
    try {
      setClose(false);
      const result = await LandService.analysisResult(reportData);
      setTabWidth(0);
      setLeft(0);
      setReportIdx(result.reportIdx);
      setClose(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    const {
      SGA,
      type,
      cropName,
      crops,
      empLaborCosts,
      facility,
      facilityCost,
      farmEquipmentRentalCost,
      income,
      farmArea,
      materialCost,
      orchardAdjustmentCost,
      ownLaborCosts,
      ownLaborYN,
      pnuCodes,
      productionCost,
      quantity,
      rate,
      realIncome,
      sales,
      sido,
      avgQuantity,
      totalLaborCosts,
      unitPrice,
      waterHeatingCost,
      landPurchaseCost,
      landRentalCost,
    } = JSON.parse(sessionStorage.analyzeResultPayload);
    setReportData((prev: any) => {
      return {
        ...prev,
        quantity,
        unitPrice,
        crops,

        SGA,
        avgSGA: SGA,
        avgQuantity,
        pnuCodes,
        farmArea,
        landPurchaseCost,
        avgLandPurchaseCost: landPurchaseCost,
        landRentalCost,
        avgLandRentalCost: landRentalCost,
        type,
        cropName,

        avgEmpLaborCost: empLaborCosts,
        facility,
        facilityCost,
        avgFacilityCost: facilityCost,
        farmEquipmentCost: farmEquipmentRentalCost,
        avgFarmEquipmentCost: farmEquipmentRentalCost,
        materialCost,
        avgMaterialCost: materialCost,
        orchardCost: orchardAdjustmentCost,
        ownLaborYN,
        avgOwnLaborCost: ownLaborCosts,
        laborCost:
          ownLaborYN === 'Y' ? empLaborCosts + ownLaborCosts : empLaborCosts,

        waterHeatingCost,
        avgWaterHeatingCost: waterHeatingCost,
      };
    });
  }, [selected, sessionStorage.analyzeResultPayload]);

  useEffect(() => {
    const {
      empLaborCosts,
      ownLaborCosts,
      ownLaborYN: own,
    } = JSON.parse(sessionStorage.analyzeResultPayload);
    setReportData((prev: any) => {
      return {
        ...prev,
        laborCost:
          (isMobile(window.innerWidth) ? own : ownLaborYN) === 'Y'
            ? empLaborCosts + ownLaborCosts
            : empLaborCosts,
      };
    });
  }, [ownLaborYN]);

  const updateReportData = (data: any) => {
    setReportData({ ...reportData, ...data });
  };

  const LandCostTypeButton = ({ type }: { type: 'P' | 'R' }) => (
    <div
      className={
        reportData.landCostType === type
          ? 'padding-12-12-12-8 bg-primary-600 w-50per border-radius-8 flex-center hover'
          : 'padding-12-12-12-8 bg-light-gray-200 w-50per border-radius-8 flex-center hover'
      }
      onClick={() => updateReportData({ landCostType: type })}
    >
      <div
        className={
          reportData.landCostType === type
            ? 'font-bold f-size-14 line-h-20 color-white'
            : 'font-medium f-size-14 line-h-20 color-disabled'
        }
      >
        {type === 'R' ? '임차' : '매입'}
      </div>
    </div>
  );
  console.log(locationHook.state);
  const checkReport = async () => {
    try {
      const result = await ReportService.check(
        'FO',
        isMobile(window.innerWidth) ? locationHook.state.address : address,
      );
      console.log('reuslt', result);

      if (result.isExist === false) {
        setOnPointAlert(true);
      } else {
        showReport();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showPointAlert = () => {
    return async () => {
      checkReport();
    };
  };

  const showReport = () => {
    return isMobile(window.innerWidth)
      ? next()
      : isCropAvailable
        ? ReportApi()
        : () => {};
  };

  return (
    <div className="flex-column w-100per bg-light-gray padding-b-50 center-y browser-w-380">
      <TopNav2 text="내 농장 진단보고서" close back />
      {onPointAlert ? (
        point >= 2000 ? (
          <PointAlert2
            point={point}
            setOnPointAlert={setOnPointAlert}
            getReportApi={showReport}
          />
        ) : (
          <WarnPoint setOnPointAlert={setOnPointAlert} point={point} />
        )
      ) : (
        ''
      )}
      <div className="flex-column w-100per max-w-681 z-10 browser-mt-48 browser-w-100per">
        <Step num="2" />
        <ReportTitle text="내 농장 경영 비용 입력하기" />
        <div className="padding-20-20-40-20 flex-column gap-20 browser-w-100per">
          <GreenBox />
          <PointColumn />
          <div
            className="flex-column padding-20-20 border-radius-8 bg-white gap-20"
            ref={element}
          >
            <SectionTitle
              title="1. 농지 소유 여부"
              // description="현지 농지의 매입 형태를 선택해주세요"
            />
            <div className="flex-row gap-10">
              <LandCostTypeButton type="R" />
              <LandCostTypeButton type="P" />
            </div>
          </div>
          {reportData.landCostType && (
            <div className="flex-column gap-20">
              <div
                className="flex-column padding-20-20 border-radius-8 bg-white gap-20"
                ref={element2}
              >
                <SectionTitle title="2. 초기 투자비" />
                {dummyCost.map((item) => {
                  if (
                    item.title === '토지 매입비' &&
                    reportData.landCostType === 'R'
                  ) {
                    return '';
                  }
                  return SetCostControl(item);
                })}

                <div className="flex-row space-between">
                  <div className="font-bold f-size-14 line-h-20 color-text-primary">
                    총액
                  </div>
                  <div className="font-bold f-size-16 line-h-24 color-text-primary">
                    {`${Math.round(
                      dummyCost.reduce((acc, cur, i) => {
                        if (
                          cur.title === '토지 매입비' &&
                          reportData.landCostType === 'R'
                        ) {
                          return acc;
                        }
                        return Number(acc) + Number(reportData[cur.state]);
                      }, 0),
                    ).toLocaleString()} `}
                    원
                  </div>
                </div>
              </div>
              <div
                className="flex-column padding-20-20 border-radius-8 bg-white gap-20"
                ref={element3}
              >
                <SectionTitle title="3. 연간 운영비" />
                <div className="font-bold f-size-14 line-h-20 color-text-primary">
                  매출 원가
                </div>
                {dummyCost2.map((item) => {
                  if (
                    item.title === '농지 임차료' &&
                    reportData.landCostType === 'P'
                  ) {
                    return '';
                  } else if (
                    item.title === '과수원 조성비' &&
                    reportData.orchardCost === 0
                  ) {
                    return '';
                  }
                  return SetCostControl(item);
                })}
                <div className="font-bold f-size-14 line-h-20 color-text-primary">
                  판매관리비
                </div>
                {dummyCost3.map((item) => {
                  if (
                    item.title === '농지 임차료' &&
                    reportData.landCostType === 'P'
                  ) {
                    return '';
                  } else if (
                    item.title === '과수원 조성비' &&
                    reportData.orchardCost === 0
                  ) {
                    return '';
                  }
                  return SetCostControl(item);
                })}
                <div className="flex-row space-between">
                  <div className="font-bold f-size-14 line-h-20 color-text-primary">
                    총액
                  </div>
                  <div className="font-bold f-size-16 line-h-24 color-text-primary">
                    {`${Math.round(
                      dummyCost2.reduce((acc, cur, i) => {
                        if (
                          cur.title === '농지 임차료' &&
                          reportData.landCostType === 'P'
                        ) {
                          return acc;
                        } else if (
                          cur.title === '과수원 조성비' &&
                          reportData.orchardCost === 0
                        ) {
                          return acc;
                        }
                        return Number(acc) + Number(reportData[cur.state]);
                      }, 0),
                    ).toLocaleString()} `}{' '}
                    원
                  </div>
                </div>
              </div>
              <div className="flex-column padding-20-20 border-radius-8 bg-white gap-20">
                <div className="flex-column padding-b-20 border-b-171E26">
                  <div
                    className="font-bold f-size-18 line-h-24 color-text-primary"
                    ref={element4}
                  >
                    4. 농업자금 대출 현황
                  </div>
                </div>
                {reportData.loanInfo.map((el: any, i: number) => (
                  <div className="flex-column gap-16 border-b-171E26  padding-b-20">
                    <div className="flex-row space-between">
                      <div className="font-bold f-size-16 line-h-24 color-text-primary">
                        대출 {i + 1}
                      </div>
                      <img
                        className="hover"
                        src={close}
                        alt=""
                        onClick={() => {
                          reportData.loanInfo.splice(i, 1);
                          updateReportData({
                            loanInfo: [...reportData.loanInfo],
                          });
                        }}
                      />
                    </div>
                    <div className="flex-row space-between center-y w-100per gap-24">
                      <div
                        className="font-bold f-size-14 line-h-20 color-text-secondary"
                        style={{ width: '20%' }}
                      >
                        대출명
                      </div>
                      <input
                        placeholder="대출 이름을 입력해주세요"
                        className="padding-6-14 border-radius-8 border-171E26 font-medium f-size-14 line-h-24 text-end"
                        style={{ width: '80%' }}
                        value={el.loanName}
                        onChange={(e: any) => {
                          reportData.loanInfo[i]['loanName'] = e.target.value;
                          updateReportData({
                            loanInfo: [...reportData.loanInfo],
                          });
                        }}
                      />
                    </div>
                    <div className="flex-row space-between center-y">
                      <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                        대출실행일
                      </div>
                      <input
                        className="font-medium f-size-14 line-h-24 color-text-secondary padding-6-14 border-radius-8 border-171E26 "
                        type="month"
                        value={el.loanDate}
                        onChange={(e: any) => {
                          reportData.loanInfo[i]['loanDate'] = e.target.value;
                          updateReportData({
                            loanInfo: [...reportData.loanInfo],
                          });
                        }}
                      />
                    </div>
                    <div className="flex-column gap-20">
                      <div className="flex-row center-y w-100per gap-20">
                        <div className="w-50per font-bold f-size-14 line-h-20 font-text-secondary">
                          대출금액
                        </div>
                        <div className="font-bold f-size-14 line-h-20 font-text-secondary">
                          연이자율
                        </div>
                      </div>
                      <div className="flex-row gap-20 ">
                        <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                          <input
                            className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                            placeholder="입력해주세요"
                            value={Number(el.loanCost).toLocaleString()}
                            onChange={(e: any) => {
                              reportData.loanInfo[i]['loanCost'] = Number(
                                e.target.value.split(',').join(''),
                              );
                              updateReportData({
                                loanInfo: [...reportData.loanInfo],
                              });
                            }}
                          />
                          <div className="font-medium f-size-14 line-h-20 font-text-primary">
                            원
                          </div>
                        </div>
                        <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                          <input
                            className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                            placeholder="입력해주세요"
                            type="number"
                            value={el.loanRate * 100}
                            onChange={(e: any) => {
                              reportData.loanInfo[i]['loanRate'] =
                                e.target.value / 100;
                              updateReportData({
                                loanInfo: [...reportData.loanInfo],
                              });
                            }}
                          />
                          <div className="font-medium f-size-14 line-h-20 font-text-primary">
                            %
                          </div>
                        </div>
                      </div>
                      <div className="flex-row center-y w-100per gap-20">
                        <div className="w-50per font-bold f-size-14 line-h-20 font-text-secondary">
                          대출기간
                        </div>
                        <div className="font-bold f-size-14 line-h-20 font-text-secondary">
                          거치기간
                        </div>
                      </div>
                      <div className="flex-row gap-20 ">
                        <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                          <input
                            className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                            placeholder="입력해주세요"
                            type="number"
                            value={el.redemptionPeriod}
                            onChange={(e: any) => {
                              reportData.loanInfo[i]['redemptionPeriod'] =
                                e.target.value;
                              updateReportData({
                                loanInfo: [...reportData.loanInfo],
                              });
                            }}
                          />
                          <div className="font-medium f-size-14 line-h-20 font-text-primary">
                            년
                          </div>
                        </div>
                        <div className="flex-row center-y border-171E26 border-radius-8 w-50per padding-6-0 padding-r-10 end-x gap-5">
                          <input
                            className="border-none margin-r-10 w-50per font-medium f-size-14 line-h-24 font-disabled text-end browser-w-100per"
                            placeholder="입력해주세요"
                            type="number"
                            value={el.retentionPeriod}
                            onChange={(e: any) => {
                              reportData.loanInfo[i]['retentionPeriod'] =
                                e.target.value;
                              updateReportData({
                                loanInfo: [...reportData.loanInfo],
                              });
                            }}
                          />
                          <div className="font-medium f-size-14 line-h-20 font-text-primary">
                            년
                          </div>
                        </div>
                      </div>
                      <div className="flex-column gap-8">
                        <div className="flex-row center-y relative">
                          <div className="font-bold f-size-14 line-h-20 color-text-secondary relative">
                            상환방식
                          </div>
                          <img
                            className="hover"
                            src="/asset/images/warn/Warn20.svg"
                            alt=""
                            onClick={() => {
                              setTooltip2(!tooltip2);
                            }}
                          />
                          <div style={{ position: 'absolute', zIndex: '11' }}>
                            <Tooltip2
                              on={tooltip2}
                              left="-2"
                              top="-113"
                              width
                              off={() => {
                                setTooltip2(false);
                              }}
                              text={
                                <div className="text-left">
                                  <ul
                                    className="line-h-14 mr-l-10"
                                    style={{
                                      listStyle: 'disc',
                                      paddingLeft: '16px',
                                    }}
                                  >
                                    <li>
                                      만기일시상환 : 약정기간동안 대출원금에
                                      대한 상환없이 이자만 부담
                                    </li>
                                    <br />
                                    <li>
                                      원리금균등상환 : 대출 원금과 이자를 합친
                                      금액 즉 원리금을 만기일까지 균등하게 상환
                                    </li>
                                    <br />
                                    <li>
                                      원금균등상환 : 대출한 돈을 일정 기간마다
                                      대출 원금에 대해서는 매월 동일한 금액으로
                                      상환하고 남은 대출 원금에 대한 이자를
                                      상환. 즉 매달 갚아나가는 원금이 일정하고
                                      이자가 변하는 상환방식
                                    </li>
                                  </ul>
                                </div>
                              }
                            />
                          </div>
                        </div>
                        <div className="flex-row w-100per gap-12">
                          {dummy.map((item) => {
                            return (
                              <div
                                className={`padding-6-8 border-radius-8 w-33per flex-center hover ${
                                  item.type === loanType
                                    ? 'bg-primary-600'
                                    : 'bg-light-gray-200'
                                }`}
                                onClick={() => {
                                  setLoanType(item.type);
                                  reportData.loanInfo[i]['loanType'] =
                                    item.type;
                                  updateReportData({
                                    loanInfo: [...reportData.loanInfo],
                                  });
                                }}
                              >
                                <div
                                  className={`font-bold f-size-12 line-h-24 ${
                                    item.type === loanType
                                      ? 'color-white'
                                      : 'color-disabled'
                                  }`}
                                >
                                  {item.name}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
                <div
                  className="flex-center padding-10-16 bg-blue-gray-100 border-radius-8  hover"
                  onClick={() =>
                    updateReportData({
                      loanInfo: [
                        ...reportData.loanInfo,
                        {
                          loanName: '',
                          loanDate: '',
                          loanRate: '0.02',
                          loanCost: '',
                          retentionPeriod: '15',
                          redemptionPeriod: '5',
                          loanType: '',
                        },
                      ],
                    })
                  }
                >
                  <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                    + 대출 내역 추가
                  </div>
                </div>
              </div>
              <div className="flex-column ">
                <div className="text-end f-size-12 font-medium line-h-2 color-blue-gray-700 text-deco-under">
                  입력에 어려움을 겪고 계신가요?
                </div>
                <div className="text-end f-size-12 font-medium line-h-2 color-blue-gray-700 text-deco-under">
                  언제든지 전화로 상담요청이 가능합니다.
                </div>
              </div>
              <Button
                on={
                  window.innerWidth < 1024
                    ? true
                    : isCropAvailable
                      ? true
                      : false
                }
                description={
                  isMobile(window.innerWidth)
                    ? '결과보기'
                    : '내 농장 진단보고서 보기'
                }
                onClick={showPointAlert()}
              />
              {/* <Button
                on={
                  window.innerWidth < 1024
                    ? true
                    : isCropAvailable
                    ? true
                    : false
                }
                description={
                  isMobile(window.innerWidth)
                    ? '결과보기'
                    : '내 농장 진단보고서 보기'
                }
                onClick={
                  isMobile(window.innerWidth)
                    ? next
                    : isCropAvailable
                    ? ReportApi
                    : () => {}
                }
              /> */}
            </div>
          )}
        </div>
      </div>
      {/* <BottomNav
        number={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname) === "/operate/report/cost" ? 2 : 1}
      /> */}
    </div>
  );
};

export default Cost;
