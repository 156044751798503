import logo from './../../asset/images/logo/logo.svg';
const GreenBox = () => {
  return (
    <div className="flex-row bg-DDEFE9 border-radius-8 padding-20-20 gap-10">
      <img src={logo} />
      <div className="font-medium f-size-14 lien-h-20 color-primary-600">
        현재 운영 중인 시설을 선택하시면 시설에 따른 작물의 생산량과 매출을
        추정할 수 있습니다.
      </div>
    </div>
  );
};
export default GreenBox;
