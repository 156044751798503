import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

import {
  CheckOffBtn,
  CheckOnBtn,
} from '../../asset/styles/mobile/signUp/Terms';
import {
  AllCheck,
  Location,
  Notification,
  Privacy,
  TransfarmerService,
} from '../../store/checkSlice';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Terms = ({ SNS, setTermsOpen, setType, joinTerms }: any) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const state = useSelector((state: any) => {
    return state.check;
  });
  const state2 = useSelector((state: any) => {
    return state.signUpInfo;
  });
  const Temp = (type: any) => {
    setTermsOpen(true);
    setType(type);
  };
  useEffect(() => {
    // if (state.privacy && state.transfarmerService && state.location) {
    //   dispatch(TermsHandler(true));
    // } else {
    //   dispatch(TermsHandler(false));
    // }
    console.log(state.privacy, state.transfarmerService, state.location);
  }, [state]);

  const [open, setOpen] = useState(false);
  return (
    <div className="flex-column w-100per center-y h-100per ">
      <Helmet>
        <title>이메일 회원가입</title>
      </Helmet>
      <div className="flex-column w-100per gap-16  browser-max-w-500 center-x ">
        <div className="flex-row">
          <div
            className="flex-row w-100per padding-12-14 center-y border-radius-13 pointer bg-FAFAFA space-between"
            onClick={() => {
              dispatch(AllCheck());
            }}
          >
            <div className="font-medium f-size-16 line-h-22 color-18181b">
              약관 전체 동의
            </div>
            {state.allCheck &&
            state.privacy &&
            state.transfarmerService &&
            state.location &&
            state.notification ? (
              <CheckOnBtn />
            ) : (
              <CheckOffBtn />
            )}
          </div>
        </div>
        <div className="flex-column">
          {[
            {
              state: 'privacy',
              redux: Privacy,
              content: '[필수] 개인정보 처리방침',
              detail: '/terms/privacy_policy',
              type: 'p',
            },
            {
              state: 'transfarmerService',
              redux: TransfarmerService,
              content: '[필수] 트랜스파머 서비스 이용약관',
              detail: '/terms/service',
              type: 's',
            },
            {
              state: 'location',
              redux: Location,
              content: '[필수] 위치기반서비스 이용약관',
              detail: '/terms/location',
              type: 'l',
            },
            {
              state: 'notification',
              redux: Notification,
              content: '[선택] 혜택 정보 푸시 알림 수신',
              detail: '/terms/info',
              type: 'n',
            },
          ].map((el) => (
            <div
              className="flex-row space-between pointer padding-12-14"
              onClick={() => {
                dispatch(el.redux());
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div className="font-regular f-size-15  color-18181B browser-f-size-16">
                  {el.content}
                </div>
                <img
                  src="/asset/images/arrow/Arrow-Right-Small-24.svg"
                  alt="오른쪽 화살표"
                  onClick={() => {
                    navigate(el.detail);
                  }}
                />
              </div>
              {!state[el.state] ? <CheckOffBtn /> : <CheckOnBtn />}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Terms;
