import { useEffect, useRef, useState } from 'react';

import Slider from '@mui/material/Slider';

import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../../../component/function/function';

const LandingBanner = () => {
  const navigate = useNavigate();
  const [cardIdx, setCardIdx] = useState(0);
  const temp = useRef<HTMLDivElement>(null);
  const cardRefs = {
    card1: useRef<HTMLDivElement>(null),
    card2: useRef<HTMLDivElement>(null),
    card3: useRef<HTMLDivElement>(null),
    card4: useRef<HTMLDivElement>(null),
    card5: useRef<HTMLDivElement>(null),
  };
  const [scrollXPosition, setScrollXPosition] = useState(0);
  const [scrollableDistance, setScrollableDistance] = useState(0);
  useEffect(() => {
    const ofScrollContainer = temp.current;

    if (!ofScrollContainer) {
      return;
    }

    const handleScroll = () => {
      console.log('temp', temp.current?.offsetWidth);
      const distance =
        ofScrollContainer.scrollWidth - ofScrollContainer.clientWidth;
      setScrollableDistance(distance);
      console.log('distance', distance);
      const currentScrollX = ofScrollContainer.scrollLeft || 0;
      setScrollXPosition(currentScrollX);
      // console.log("scroll", currentScrollX);
    };

    handleScroll();

    ofScrollContainer.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
    return () => {
      ofScrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [temp]);
  const dummy = [
    {
      text1: '이 토지/주택,',
      text2: '얼마나 될까?',
      text3: '가격 분석 가이드',
      src: isMobile(window.innerWidth)
        ? '/asset/images/guide/guideImg1_mo.svg'
        : '/asset/images/guide/guideImg1_tablet.svg',
      hash: '#실거래가 #공시지가 #AI 농지추정가 #AI 주택추정가',
      ref: cardRefs.card1,
      url: '/promotion/%EC%A0%95%ED%99%95%ED%95%9C-%ED%86%A0%EC%A7%80-%EB%86%8D%EC%A7%80-%EC%A3%BC%ED%83%9D-%EA%B0%80%EA%B2%A9%EC%9D%84-%EC%95%8C%EC%95%84%EB%B3%B4%EB%8A%94-%EB%B0%A9%EB%B2%95',
    },
    {
      text1: '내 농지연금,',
      text2: '얼마나 받을 수 있을까?',
      text3: '농지연금 분석 가이드',
      src: isMobile(window.innerWidth)
        ? '/asset/images/guide/guideImg2_mo.svg'
        : '/asset/images/guide/guideImg2_tablet.svg',
      hash: '#농지연금 가입가능성 #농지연금 예상수령액 #농사수익',
      ref: cardRefs.card2,
      url: '/promotion/%EB%86%8D%EC%A7%80_%EA%B0%80%EA%B2%A9_%ED%99%95%EC%9D%B8%EB%B6%80%ED%84%B0_%EB%86%8D%EC%A7%80%EC%97%B0%EA%B8%88_%EA%B3%84%ED%9A%8D%EA%B9%8C%EC%A7%80_%ED%95%9C%EB%B2%88%EC%97%90',
    },
    {
      text1: '이 농지, 가격과',
      text2: '농사수익은 얼마일까?',
      text3: '농지 찾기 가이드',
      src: isMobile(window.innerWidth)
        ? '/asset/images/guide/guideImg3_mo.svg'
        : '/asset/images/guide/guideImg3_tablet.svg',
      hash: '#AI추천작물 #예상경작수익 #AI 농지추정가 #영농사업',
      ref: cardRefs.card3,
      url: '/promotion/%EB%86%8D%EC%82%AC-%EC%88%98%EC%9D%B5%EA%B3%BC-%EB%B9%84%EC%9A%A9%EB%B6%84%EC%84%9D-AI%EB%A1%9C-%EB%86%8D%EC%97%85-%EA%B2%BD%EC%98%81-%EA%B3%84%ED%9A%8D-%EC%84%B8%EC%9A%B0%EA%B8%B0',
    },
    {
      text1: '내 농장 매출/운영비',
      text2: '높을까, 낮을까?',
      text3: '내 농장 진단 가이드',
      src: isMobile(window.innerWidth)
        ? '/asset/images/guide/guideImg4_mo.svg'
        : '/asset/images/guide/guideImg4_tablet.svg',
      hash: '#농사수익분석 #적합작물추천 #농업경영계획',
      ref: cardRefs.card4,
      url: '/promotion/AI%EB%A1%9C-%EB%86%8D%EC%9E%A5-%EC%A7%84%EB%8B%A8-%EB%86%8D%EC%97%85-%EA%B2%BD%EC%98%81-%EC%86%94%EB%A3%A8%EC%85%98',
    },
    {
      text1: '나의 첫 전원주택,',
      text2: '예상 건축비 얼마나 될까?',
      text3: '내 집 짓기 가이드',
      src: isMobile(window.innerWidth)
        ? '/asset/images/guide/guideImg5_mo.svg'
        : '/asset/images/guide/guideImg5_tablet.svg',
      hash: '#주택설계 #설계도확인 #시공비확인',
      ref: cardRefs.card5,
      url: '/promotion/%EC%A0%84%EC%9B%90%EC%A3%BC%ED%83%9D-%EC%84%A4%EA%B3%84%EC%99%80-%EA%B1%B4%EC%B6%95%EB%B9%84-%EB%B6%84%EC%84%9D%EC%9D%84-AI%EB%A1%9C-%EA%B0%84%ED%8E%B8%ED%95%98%EA%B2%8C',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      if (cardIdx === 2) {
        setCardIdx(0);
      } else {
        setCardIdx((prevIdx) => prevIdx + 1);
      }
    }, 5000);

    return () => clearInterval(interval);
  }, [cardIdx]);

  const handleArrowClick = (direction: 'left' | 'right') => {
    if (direction === 'left') {
      temp.current?.scrollTo({
        left: temp.current?.scrollLeft - 312,
        behavior: 'smooth',
      });
    } else {
      temp.current?.scrollTo({
        left: temp.current?.scrollLeft + 312,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className=" landing-banner-wrap">
      <p className="banner-title">첫 방문 가이드</p>
      <div
        className="padding-x-16 flex-column gap-16 browser-gap-24 bg-FFFFFF w-100per  browser-max-w-1200"
        onTouchEnd={() => {
          if (scrollXPosition < 312) {
            setCardIdx(0);
          } else if (scrollXPosition < 624) {
            setCardIdx(1);
          } else {
            setCardIdx(2);
          }
        }}
      >
        <div className="flex-column w-100per ">
          <div className="flex-row gap-8 of-x-auto padding-y-16" ref={temp}>
            {dummy.map((item, index) => {
              return (
                <div
                  onClick={() => {
                    navigate(item.url);
                  }}
                  ref={item.ref}
                  className="banner-card border-radius-8 flex-column gap-16 padding-24 border-171E26 pointer"
                  style={{
                    minWidth:
                      window.innerWidth < 1024
                        ? '312px'
                        : window.innerWidth > 1024 && window.innerWidth < 1920
                          ? '360px'
                          : '389px',
                  }}
                >
                  <div className="flex-row space-between w-100per padding-b-16 border-b-171E26 text-left">
                    <div className="flex-column gap-16">
                      <div
                        className="m-b1-16-b color-dark-primary"
                        style={{ width: '158px' }}
                      >
                        {item.text1} <br /> {item.text2}
                      </div>
                      <span className="m-c1-12-b color-primary-500 border-1-1E9B6B border-radius-999 padding-8-16 flex-row flex-center fit-content">
                        {item.text3}
                      </span>
                    </div>
                    <img src={item.src} />
                  </div>
                  <div className="m-c1-12-m color-dark-secondary text-left">
                    {item.hash}
                  </div>
                </div>
              );
            })}
          </div>
          {(temp.current?.offsetWidth || 0) < 1097 && (
            <Slider
              size="small"
              aria-label="Small"
              valueLabelDisplay="auto"
              value={(scrollXPosition / scrollableDistance) * 100}
              sx={{
                height: '3px',
                // width: window.innerWidth === 1920 ? "1008px" : "712px",
                '& .MuiSlider-thumb': {
                  display: 'none', // Hide the Thumb
                },
                '& .MuiSlider-track': { color: 'black' },
                '& .MuiSlider-rail': { color: '#F0F0F0' },

                '& .Mui-active': {
                  boxShadow: '#1E9B6B30 0px 0px 0px 12px',
                },
                '& .MuiSlider-valueLabel': { background: 'none' },
                '& .MuiSlider-mark': {
                  backgroundColor: '#FFFFFF',
                  height: 8,
                },
              }}
            />
          )}
          {window.innerWidth >= 1200 && (
            <div className="flex-row end-x gap-8 padding-t-16">
              <img
                src="/asset/images/arrow/ArrowWithCircleLeft.svg"
                alt=""
                onClick={() => handleArrowClick('left')}
              />
              <img
                src="/asset/images/arrow/ArrowWithCircleRight.svg"
                alt=""
                onClick={() => handleArrowClick('right')}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default LandingBanner;
