import { useEffect, useLayoutEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import StarRatings from 'react-star-ratings';

export default class RowCom {
  static StarRow = ({ ratings }: any) => (
    <div className="flex-row gap-8">
      <StarRatings
        rating={ratings}
        starDimension="16px"
        starSpacing="1px"
        starRatedColor="#1E9B6B"
      />
      <div className="font-bold f-size-16 line-h-24 color-primary-500">
        {ratings}
      </div>
    </div>
  );
}

export const RowSectionTitle = ({ children }: { children: JSX.Element }) => (
  <div className={`padding-24-16-8`}>{children}</div>
);

export const RowUnderLine = ({
  children,
}: {
  children: JSX.Element[] | JSX.Element;
}) => <div className="flex-row border-b-1-171E2610 w-100per">{children}</div>;

export const RowTableValue2 = ({
  title,
  value,
}: {
  title: string;
  value: string | number | undefined;
}) => (
  <div className="flex-row w-100per space-between">
    <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
      {title}
    </div>
    <div className="font-medium f-size-16 line-h-24 color-text-tertiary">
      {value}
    </div>
  </div>
);

export const RowTableGreenBold = ({
  title,
  value,
  warn,
  onClick,
}: {
  title: string;
  value: string | number | undefined;
  warn?: boolean;
  onClick?: any;
}) => (
  <div className="flex-row w-100per space-between">
    <div className="font-bold f-size-16 line-h-24 color-text-secondary center-y">
      {title}{' '}
      {warn ? (
        <img
          className="hover"
          src="/asset/images/warn/Warn24.svg"
          alt=""
          onClick={onClick}
        />
      ) : (
        ''
      )}
    </div>
    <div className="font-bold f-size-18 line-h-26 color-primary-500">
      {value}
    </div>
  </div>
);

export const RowTableValue = ({
  title,
  value,
  warn,
  onClick,
}: {
  title: string;
  value: string | number | undefined;
  warn?: boolean;
  onClick?: any;
}) => (
  <div className="flex-row w-100per space-between center-y">
    <div className="font-medium f-size-16 line-h-24 color-text-tertiary center-y">
      {title}{' '}
      {warn ? (
        <img
          src="/asset/images/warn/Warn24.svg"
          className="hover"
          onClick={onClick}
          alt=""
        />
      ) : (
        ''
      )}
    </div>
    <div className="font-medium f-size-16 line-h-24 color-text-secondary">
      {value}
    </div>
  </div>
);

export const RowTableValueBold = ({
  title,
  value,
}: {
  title: string;
  value: string | number | undefined | JSX.Element;
}) => (
  <div className="flex-row w-100per space-between">
    <div className="font-bold f-size-16 line-h-24 color-text-secondary">
      {title}
    </div>
    <div className="font-medium f-size-16 line-h-24 color-text-secondary">
      {value}
    </div>
  </div>
);

export const RowTableGreen = ({
  title,
  value,
  warn,
}: {
  title: string;
  value: string | number | undefined;
  warn?: boolean;
}) => (
  <div className="flex-row w-100per space-between">
    <div className="font-bold f-size-16 line-h-24 color-text-secondary">
      {title}{' '}
      {warn ? (
        <img className="hover" src="/asset/images/warn/Warn24.svg" alt="" />
      ) : (
        ''
      )}
    </div>
    <div className="font-medium f-size-16 line-h-24 color-primary-500">
      {value}
    </div>
  </div>
);

//

export const RowBold1 = ({
  title,
  value,
  warn,
  onClick,
  green,
}: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses=""
      containerStyle="row_table_font_bold center-y"
      textStyle={`row_table_font_bold no-wrap ${
        green ? ' color-primary-500' : ' color-D62554'
      }`}
    ></RowTableBase>
  );
};

export const RowBold2 = ({
  title,
  value,
  warn,
  onClick,
  green,
}: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses=""
      containerStyle="row_table_font_bold center-y"
      textStyle={`row_table_font_bold ${
        green ? ' color-primary-500' : ' color-D62554'
      }`}
    ></RowTableBase>
  );
};

type PropRowTable = {
  title: string | JSX.Element;
  value: string | number | undefined | JSX.Element;
  warn?: boolean;
  green?: boolean;
  onClick?: any;
  half?: boolean;
  start?: any;
  end?: any;
  type?: number;
  layoutClasses?: string;
  textStyle?: string;
  containerStyle?: string;
  pension?: boolean;
  showTooltip2?: boolean;
  setShowTooltip2?: any;
  value2?: any;
  arrow?: boolean;
  boxTitle?: string | JSX.Element;
  boxItem?: string | JSX.Element;
};

export const RowTableBase = ({
  title,
  value,
  warn,
  onClick,
  layoutClasses,
  textStyle,
  containerStyle,
  pension,
  showTooltip2,
  setShowTooltip2,
  value2,
  arrow,
  boxTitle,
  boxItem,
}: PropRowTable) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const location = useLocation();
  const [showDetails, setShowDetails] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      setShowTooltip(true);
    }, 100);
  }, []);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  return (
    <div className="flex-column flex-1 gap-8">
      <div
        className={`row_table gap-5 relative h-auto ${
          !value2 && layoutClasses
        }`}
      >
        <div
          className={`color-text-tertiary gap-2 flex-row h-100per   ${containerStyle}`}
          style={{ textWrap: 'nowrap' }}
        >
          {title}
          {warn && (
            <img
              className="hover"
              onClick={onClick}
              src="/asset/images/warn/Warn24.svg"
              alt=""
            />
          )}
        </div>
        <div className={`${value2 && `flex-column`}`}>
          <div
            className={`color-text-secondary  ${textStyle} `}
            style={{
              flexDirection: 'column',
              textAlign: 'end',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {value}
            {arrow && (
              <img
                className="hover"
                src={
                  showDetails
                    ? '/asset/images/arrow/ArrowTop24.svg'
                    : '/asset/images/arrow/ArrowBottom24.svg'
                }
                alt=""
                onClick={toggleDetails}
              />
            )}
          </div>
          <div
            className={`color-text-secondary  ${textStyle} `}
            style={{
              textAlign: 'right',
              display: 'flex',
              justifyContent: 'right',
              alignItems: 'center',
              gap: '8px',
            }}
          >
            {value2}
          </div>
        </div>
        {/* {location.pathname.split("/")[1] === "mypage" &&
          title === "분석일" &&
          showTooltip && (
            <div
              className={`${
                showTooltip
                  ? "tooltip absolute speech-bubble3 font-bold f-size-11 line-h-12 border-radius-2 flex-row gap-8 visible fade-in animate-tooltip"
                  : ""
              }`}
              style={{
                backgroundColor: "#3D5066",
                borderRadius: "8px",
                right: "0px",
                padding: "8px",
                marginLeft: 0,
                marginBottom: 0,
                top: "35px",
              }}
            >
              <div className='m-c1-12-b color-FFFFFF'>
                분석 시점의 결과는 현재와 다를 수 있어요. <br />
                최신 분석 결과는 주소지 조회 후 다시 이용해 주세요.
              </div>
              <img
                className='pointer'
                alt='close'
                src='/asset/images/close/CloseWhite12.svg'
                onClick={() => {
                  setShowTooltip(false);
                }}
              />
            </div>
          )} */}
      </div>
      {showDetails && (
        <div
          style={{
            backgroundColor: '#F7F8F9',
            padding: '16px',
            borderRadius: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '4px',
          }}
        >
          <p className="f-size-14 font-medium color-text-tertiary">
            {boxTitle}
          </p>
          <p className="f-size-14 font-medium color-text-secondary">
            {boxItem}
          </p>
        </div>
      )}
    </div>
  );
};

//--

export const RowTable5 = ({
  title,
  value,
  warn,
  onClick,
  value2,
}: PropRowTable) => {
  let textColor = '';
  if (value === '맹지') {
    textColor = '#EC5151';
  }

  return (
    <RowTableBase
      title={title}
      value={
        <span style={{ color: textColor, fontSize: '14px' }}>{value}</span>
      }
      warn={warn}
      onClick={onClick}
      layoutClasses="row_table_bottom end-y"
      containerStyle="end-y h-100per"
      textStyle=""
      value2={value2}
    ></RowTableBase>
  );
};

export const RowTable1 = ({
  title,
  value,
  warn,
  onClick,
  half,
}: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses={`row_table_bottom center-y ${half && `auto`}`}
      containerStyle="center-y"
    ></RowTableBase>
  );
};

//--

export const RowTable3 = ({ title, value, warn, onClick }: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses={`row_table_bottom end-y`}
      containerStyle="end-y"
      textStyle="no-wrap"
    ></RowTableBase>
  );
};

//--
export const RowTable4 = ({
  title,
  value,
  warn,
  onClick,
  arrow,
  boxTitle,
  boxItem,
}: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses={`row_table_bottom center-y`}
      containerStyle="center-y"
      textStyle=""
      arrow={arrow}
      boxTitle={boxTitle}
      boxItem={boxItem}
    ></RowTableBase>
  );
};
export const RowTable2 = ({ title, value, warn, onClick }: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses={`row_table_bottom center-y`}
      containerStyle="center-y flex-1"
      textStyle="w-50per"
    ></RowTableBase>
  );
};

export const RowTable6 = ({ title, value, warn, onClick }: PropRowTable) => {
  return (
    <RowTableBase
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses="center-y row_table_bottom"
    ></RowTableBase>
  );
};

export const RowTableX = ({
  title,
  value,
  warn,
  onClick,
  start,

  showTooltip2,
  setShowTooltip2,
}: PropRowTable) => {
  return (
    <RowTableBase
      showTooltip2={showTooltip2}
      setShowTooltip2={setShowTooltip2}
      pension
      title={title}
      value={value}
      warn={warn}
      onClick={onClick}
      layoutClasses={start ? 'start-y' : 'center-y'}
      containerStyle="center-y"
      textStyle="row_table_font_bold"
    ></RowTableBase>
  );
};

//--
