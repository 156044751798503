import { useEffect, useRef, useState } from 'react';
import Slider2 from '@mui/material/Slider';
import { setOptions } from 'react-chartjs-2/dist/utils';
export const Carousel2Browser = ({ scroll }: any) => {
  const [isDrag, setIsDrag] = useState(false);

  const ofScrollRef = useRef<HTMLDivElement>(null); // "of-scroll" 클래스가 적용된 요소에 대한 ref
  const imageRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const [scrollXPosition, setScrollXPosition] = useState(0);
  const [imgIdx, setImgIdx] = useState(0);

  const [scrollableDistance, setScrollableDistance] = useState(0);
  const imageList2 = [
    {
      src: '/asset/images/profile/leader1.png',
      name: '김기현 CEO',
      education: '성균관대 경영학/한양대 경영학(Ph.D.)',
      career1: 'DAMA(국제 데이터매니지먼트협회) 한국 대표',
      career2: '녹현리서치㈜ 대표이사',
      career3: '한림국제대학원대학교 교수(데이터 매니지먼트)',
      career4: '삼정KPMG 전략컨설팅본부 이사',
      career5: '삼성서울병원 정보전략실 책임',
      career6: '삼성 오픈타이드 시니어매니저',
    },
    {
      src: '/asset/images/profile/leader2.png',
      name: '신연성 CTO',
      education: '포항공대 산업공학',
      career1: '㈜앤서 CTO',
      career2: 'DAMA Korea 이사',
      career3: '㈜크래프트이더 연구소장',
      career4: '이더리움기업연합 한국지사 연구소장',
    },
    {
      src: '/asset/images/profile/leader3.png',
      name: '조윤경 CFO',
      education: '북경대 역사학',
      career1: '녹현리서치 전략기획실장',
      career2: 'DAMA Korea 이사',
      career3: 'TV조선 기자',
      career4: '뉴스토마토 기자',
      career5: 'KB증권 해외파생상품 영업(농산물)',
      career6: 'Calyon Bank 자본시장 영업',
    },
    {
      src: '/asset/images/profile/leader4.png',
      name: '이봉석 COO',
      education: '서울대 토목공학/도쿄대 도시공학 박사',
      career1: '노무라종합연구소 팀장',
      career2: 'DAMA Korea 이사',
      career3: '삼정KPMG 부동산본부 이사',
      career4: '삼성물산 건설부문 부장',
      career5: 'Inpact G.C.F 컨설턴트',
      career6: '일본 토지종합연구소 조사부 연구원',
    },
    {
      src: '/asset/images/profile/leader5.png',
      name: '박성필 CIO',
      education: '성균관대 빅데이터학 석사',
      career1: '㈜앤서 개발이사',
      career2: '㈜디에스앤텍 연구소 차장',
    },
    {
      src: '/asset/images/profile/leader6.png',
      name: '김일숙 CDO',
      education: '한국외대 정치외교학/중앙대 GHRD 석사',
      career1: '대통령비서실 디지털소통 행정관 (3급)',
      career2: 'JTBC 디지털콘텐트 팀장 (부장)',
      career3: 'SBS 미디어서비스 담당 (부장)',
      career4: 'CBSi 노컷뉴스 런칭 팀장 (차장)',
    },
    {
      src: '/asset/images/profile/leader7.png',
      name: '조환익 감사',
      education: '서울대/NYU/한양대 경영학(Ph.D.)',
      career1: '유니슨㈜ 회장',
      career2: '녹현리서치㈜ 회장',
      career3: '한국전력 사장',
      career4: 'KOTRA 사장',
      career5: '수출보험공사 사장',
      career6: 'KIAT(전 산업기술재단) 설립자',
      career7: '제6대 산업자원부 차관',
    },
    {
      src: '/asset/images/profile/leader8.png',
      name: '남재작 사외이사',
      education: '경북대 농화학/경북대 분석화학(Ph.D.)',
      career1: '한국정밀농업연구소 소장',
      career2: '농어업농어촌특별위원회 위원',
      career3: '농림식품과학기술위원회 위원',
      career4: 'UN 기후변화전문가',
      career5: 'KOICA 농업ODA 전문가',
      career6: '한국농업기술진흥원 기획조정실장',
      career7: '농촌진흥청 농업과학원 농업연구사',
    },
  ];

  useEffect(() => {
    const ofScrollContainer = ofScrollRef.current;

    if (!ofScrollContainer) {
      return;
    }

    const handleScroll = () => {
      const distance =
        ofScrollContainer.scrollWidth - ofScrollContainer.clientWidth;
      setScrollableDistance(distance);
      console.log('distance', distance);
      const currentScrollX = ofScrollContainer.scrollLeft || 0;
      setScrollXPosition(currentScrollX);
      console.log('scroll', currentScrollX);
      updateImgIdx(currentScrollX, distance);
    };

    const updateImgIdx = (currentScrollX: any, distance: any) => {
      let imgWidth = 296;
      let gap = 16;

      if (distance) {
        if (currentScrollX / distance <= 1 / 8) {
          setImgIdx(0);
        } else if (currentScrollX / distance <= 2 / 8) {
          setImgIdx(1);
        } else if (currentScrollX / distance <= 3 / 8) {
          setImgIdx(2);
        } else if (currentScrollX / distance <= 4 / 8) {
          setImgIdx(3);
        } else if (currentScrollX / distance <= 5 / 8) {
          setImgIdx(4);
        } else if (currentScrollX / distance <= 6 / 8) {
          setImgIdx(5);
        } else if (currentScrollX / distance <= 7 / 8) {
          setImgIdx(6);
        } else if (currentScrollX / distance <= 1) {
          setImgIdx(7);
        }
      }
    };

    handleScroll();

    ofScrollContainer.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
    return () => {
      ofScrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [ofScrollRef]);
  return (
    <div className={`flex-column flex-center ${scroll > 3409 && `fade-in-up`}`}>
      <div
        className="flex-row gap-40 tablet browser "
        style={{
          width: window.innerWidth < 1920 ? '904px' : '1200px',
        }}
      >
        <img
          className="pointer"
          src="/asset/images/arrow/ArrowWithCircleLeft.svg"
          onClick={() => {
            if (imgIdx <= 1) {
              setImgIdx(0);
              ofScrollRef.current?.scrollTo({
                left: 0,
                behavior: 'smooth',
              });
            } else {
              setImgIdx(imgIdx - 1);
              ofScrollRef.current?.scrollTo({
                left: (imgIdx / 8) * scrollableDistance - 40 * (8 - imgIdx),
                behavior: 'smooth',
              });
            }
          }}
        ></img>
        <div className="flex-row gap-40">
          <div
            className="flex-row of-scroll  gap-16 padding-x-40  "
            ref={ofScrollRef}
            style={{
              width: window.innerWidth < 1920 ? '712px' : '975px',
              overflow: 'hidden',
            }}
          >
            {imageList2.map((image, index) => (
              <div
                className="flex-column"
                key={index}
                ref={imageRefs[index]}
                draggable={true}
                onTouchStart={(e) => {
                  setIsDrag(true);
                }}
                onTouchEnd={() => {
                  setIsDrag(false);
                }}
              >
                <img className="" src={image.src} />
                <div className="flex-column gap-8 padding-24-16">
                  <div className="flex-column gap-4">
                    <div className="font-bold f-size-16 line-h-24 color-dark-primary">
                      {image.name}
                    </div>
                    <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                      {image.education}
                    </div>
                  </div>
                  <div className="flex-column">
                    <div className="font-medium f-size-14 line-h-20 color-dark-secondary">
                      {image.career1} <br /> {image.career2} <br />{' '}
                      {image.career3} <br /> {image.career4} <br />{' '}
                      {image.career5} <br /> {image.career6} <br />{' '}
                      {image.career7}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img
          className="pointer"
          src="/asset/images/arrow/ArrowWithCircleRight.svg"
          onClick={() => {
            if (imgIdx >= 6) {
              setImgIdx(7);
              ofScrollRef.current?.scrollTo({
                left: scrollableDistance,
                behavior: 'smooth',
              });
            } else {
              setImgIdx(imgIdx + 1);
              ofScrollRef.current?.scrollTo({
                left:
                  ((imgIdx + 1) / 8) * scrollableDistance + 40 * (imgIdx + 1),
                behavior: 'smooth',
              });
            }
          }}
        ></img>
      </div>
      {/* window.innerWidth > 498 && scrollXPosition > 350 */}
      <Slider2
        className="w-90per "
        value={(scrollXPosition / scrollableDistance) * 100}
        onChange={(e: any) => {
          ofScrollRef.current?.scrollTo({
            left: (e.target.value / 100) * scrollableDistance,
            behavior: 'smooth',
          });
        }}
        size="small"
        aria-label="Small"
        valueLabelDisplay="auto"
        sx={{
          marginTop: '30px',
          height: '3px',
          width: window.innerWidth === 1920 ? '1008px' : '712px',
          '& .MuiSlider-thumb': {
            display: 'none', // Hide the Thumb
          },
          '& .MuiSlider-track': { color: 'black' },
          '& .MuiSlider-rail': { color: '#F0F0F0' },

          '& .Mui-active': {
            boxShadow: '#1E9B6B30 0px 0px 0px 12px',
          },
          '& .MuiSlider-valueLabel': { background: 'none' },
          '& .MuiSlider-mark': {
            backgroundColor: '#FFFFFF',
            height: 8,
          },
        }}
      />
      <div className="end-x flex w-100per mt-10">
        <div className="flex mt-16 gap-10"></div>
      </div>
    </div>
  );
};
