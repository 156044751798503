import Banner from '../../../component/AIIntroduction/Footer';
import { isMobile } from '../../../component/function/function';
import { TopNav2 } from '../../../component/mobile/Topnav';
import Button from '../../../component/mobile/Button';
import './edit.css';
import InquiryWrite from '../../inquiry/InquiryWrite';
import { useDispatch, useSelector } from 'react-redux';
import { TextBox3 } from '../../signUp/component';
import { useEffect, useState } from 'react';
import UserService from '../../../service/checkAccount/CheckAccount';
import { jobOptions, areaOptions2 } from '../../../dummy/dummy';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { BoxDateSelector3 } from '../../../component/report/Box';
import { EditPopUp } from './EditPopUp';
import MypageSerivce from '../../../service/mypage/mypage';
import { Category, SignUpType } from '../../../store/signUpInfoSlice';

const Edit = ({ search, setClose, tab, setTab }: any) => {
  const [onPopUp, setOnPopUp] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedOption, setSelectedOption] = useState('jobOptions[0].value');
  const [showInput, setShowInput] = useState(false);
  const [showAgi, setShowAgri] = useState('');
  const [focused, setFocused] = useState(false);
  const [onDate, setOnDate] = useState(false);
  const [age, setAge] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [userPhone, setUserPhone] = useState('');
  const [userBirthDate, setUserBirthDate] = useState('');
  const [userAgriculturalNum, setUserAgriculturalNum] = useState('');
  const [userCategory, setUserCategory] = useState('');
  const [userAreaOfInterest, setUserAreaOfInterest] = useState('');
  const [areaOfInterest, setAreaOfInterest] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [category, setCategory] = useState('');
  const [agriculturalNum, setAgriculturalNum] = useState('');
  const [categoryDesc, setCategoryDesc] = useState('');
  const [loginType, setLogintype] = useState('');
  const [inquiryPopUp, setInquiryPopUp] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('');
  const ageOrBirthDate =
    (typeof age === 'string' && (age as any).slice(0, 12)) || userBirthDate;
  const birthResult =
    ageOrBirthDate !== null
      ? ageOrBirthDate !== 'yyyy-mm-dd'
        ? ageOrBirthDate
        : 'yyyy-mm-dd'
      : 'yyyy-mm-dd';
  const Areaplaceholder =
    userAreaOfInterest !== null ? userAreaOfInterest : '선택해 주세요.';
  const selectedLabel = jobOptions.find(
    (option) => option.value === userCategory,
  )?.label;
  // if (selectedLabel === "농업인") {
  //   setShowInput(true);
  //   // Assuming userAgriculturalNum is now a state variable
  //   // You can set it using setUserAgriculturalNum
  //   setUserAgriculturalNum(userAgriculturalNum);
  // }
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleFocusBlur = (isFocused: any) => {
    setFocused(isFocused);
  };

  const handleInputChange = (selectedOption: any) => {
    setSelectedOption(selectedOption);

    if (selectedOption === 'W') {
      setShowInput(true);
      setPlaceholderText('농업 경영체 등록 번호를 입력해 주세요.');
    } else if (selectedOption === 'G') {
      setShowInput(true);
      setPlaceholderText('기타 하는 일 혹은 관심사를 기재해 주세요.');
    } else {
      setShowInput(false);
      setPlaceholderText('기타 하는 일 혹은 관심사를 기재해 주세요.');
    }
  };

  const getMyInfo = async () => {
    try {
      const result = await MypageSerivce.getMyInfo();

      setUserCategory(result.data.data.category);
      setUserAreaOfInterest(result.data.data.areaOfInterest);
      setUserBirthDate(result.data.data.birthDate);
      setUserAgriculturalNum(result.data.data.agriculturalNum);
    } catch (error) {
      console.log(error);
    }
  };

  const checkUserInfo = async () => {
    try {
      const result = await UserService.checkInfo();
      setLogintype(result.loginType);
      setUserName(result.name);
      setUserEmail(result.email);
      setUserPhone(result.phone);
      setUserCategory(result.category);
    } catch (error) {
      console.log(error);
    }
  };
  const stateUserInfo = useSelector((state: any) => {
    return state.userInfo;
  });

  useEffect(() => {
    getMyInfo();
    checkUserInfo();
  }, [stateUserInfo]);

  const LoginTypeMessage = () => {
    if (loginType === 'K') {
      return <div className="editTexts">카카오로 가입한 계정입니다.</div>;
    } else if (loginType === 'N') {
      return <div className="editTexts">네이버로 가입한 계정입니다.</div>;
    } else if (loginType === 'G') {
      return <div className="editTexts">구글로 가입한 계정입니다.</div>;
    } else if (loginType === 'F') {
      return <div className="editTexts">페이스북으로 가입한 계정입니다.</div>;
    } else {
      return (
        <div className="editTexts">이메일 아이디로 가입한 계정입니다.</div>
      );
    }
  };

  const UpdateUserInfo = async () => {
    const data = {
      birthDate: age,
      category: category,
      categoryDesc: categoryDesc,
      agriculturalNum: agriculturalNum,
      areaOfInterest: areaOfInterest,
    };

    try {
      console.log('업데이트 할 내용:', data);
      const result = await UserService.UserInfoUpdate(data);
      setOnPopUp(true);
      console.log('result', result);
    } catch (error: any) {
      console.error(error.message);
    }
  };

  // useEffect(() => {
  //   if (selectedLabel === "w" || "농업인") {
  //     setShowInput(true);
  //     setPlaceholderText(userAgriculturalNum);

  //   } else {
  //     setShowInput(false);

  //   }
  // }, [selectedLabel]);

  return (
    <div
      className={`flex-column h-100per ${
        search ? `w-360 of-scroll z-1 ` : `w-100per `
      }`}
    >
      {inquiryPopUp && <InquiryWrite setOnInquiry={setInquiryPopUp} footer />}
      <TopNav2 text="내 정보 수정" hamburger back />
      {onPopUp && <EditPopUp />}
      {search && (
        <div className="flex-row space-between padding-12-16 center-y">
          <div></div>
          <div className="m-b1-16-b color-dark-primary">내 정보 수정</div>
          <img
            src="/asset/images/close/CloseBlack24.svg"
            className="pointer"
            alt="close"
            onClick={() => {
              setClose(false);
            }}
          />
        </div>
      )}
      <div
        className={`flex-column gap-24 mb-80 ${
          search ? `padding-32-16-0-16` : `padding-32-16-0-16 browser-padding-0`
        }`}
      >
        <div className="flex-column gap-32 flex-center pointer">
          <img
            className="w-72 h-72 tablet-only"
            alt="profile"
            src="/asset/images/userimage/defaultUser.svg"
          />
        </div>
        <div className="flex-column gap-24">
          <div className="font-medium f-size-16 color-text-primary">
            내 정보
          </div>

          <div className="flex-column gap-8">
            <div className="editText">
              <div className="label">
                이름 <div className="asterisk">*</div>
              </div>
            </div>
            <input
              className="customzeah-input"
              value={userName}
              placeholder="이름 입력"
              readOnly
            />
          </div>

          <div className="flex-column gap-8">
            <div className="editText">
              <div className="label">
                아이디 <div className="asterisk">*</div>
              </div>
            </div>
            <input
              className="customzeah-input"
              value={userEmail}
              placeholder="아이디 입력"
              readOnly
            />
            {LoginTypeMessage()}
          </div>

          <div className="flex-column gap-8">
            <div className="editText">
              <div className="label">
                휴대폰 번호 <div className="asterisk">*</div>
              </div>
            </div>
            <input
              className="customzeah-input"
              value={userPhone}
              placeholder="010-0000-0000"
              readOnly
            />
            <div className="editTexts">
              휴대폰 번호 변경은 하단 ‘문의하기’로 요청해 주세요.
            </div>
          </div>
          <div className="flex-column gap-24"></div>
        </div>

        <div className="flex-column gap-24">
          <div className="font-medium f-size-16 color-text-primary">
            추가 정보 (선택사항)
          </div>
          <div className="editText">
            소속 혹은 농촌에 대한 관심사
            <Select
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  marginTop: '8px',
                  padding: '0px',
                  border: `1px solid ${
                    state.isFocused ? '#09CE7D' : '#171E261A'
                  }`,
                  height: '56px',
                  fontFamily: 'SpoqaHanSansNeoRegular',
                  fontSize: '16px',
                  borderRadius: '8px',
                  '&:hover': {
                    borderColor: state.isFocused ? '#09CE7D' : '#09CE7D',
                  },
                }),
                dropdownIndicator: (baseStyles, state) => ({
                  ...baseStyles,
                  transform: state.selectProps.menuIsOpen
                    ? 'rotate(180deg)'
                    : '',
                }),
                option: (baseStyles, { isSelected, isFocused }) => ({
                  ...baseStyles,
                  color: isFocused ? '#09CE7D' : 'inherit',
                  backgroundColor: isFocused ? '#fff' : '#fff',
                  '&:hover': {
                    backgroundColor: !isSelected && isFocused ? '#fff' : '#fff',
                  },
                }),
              }}
              options={jobOptions}
              placeholder={selectedLabel}
              onChange={(e: any) => {
                dispatch(Category(e.value));
                setCategory(e.value);
                handleInputChange(e.value);
              }}
            />
          </div>

          {showInput && (
            <input
              className={`customzeah-input2 ${focused ? 'focused' : ''}`}
              onFocus={() => handleFocusBlur(true)}
              onBlur={() => handleFocusBlur(false)}
              type="text"
              placeholder={placeholderText}
              value={
                category === 'G'
                  ? categoryDesc
                  : category === 'W'
                    ? agriculturalNum
                    : inputValue
              }
              onChange={(e) => {
                if (category === 'G') {
                  setCategoryDesc(e.target.value);
                  setAgriculturalNum('');
                } else if (category === 'W') {
                  setAgriculturalNum(e.target.value);

                  setCategoryDesc('');
                }
              }}
            />
          )}

          <div className="flex-column gap-16">
            <div className="editText">
              관심지역
              <Select
                styles={{
                  control: (baseStyles, state) => ({
                    ...baseStyles,
                    marginTop: '8px',
                    padding: '0px',
                    border: '1px solid #171E261A',
                    height: '56px',
                    fontFamily: 'SpoqaHanSansNeoRegular',
                    fontSize: '16px',
                    borderColor: state.isFocused ? '#09CE7D' : '#09CE7D',
                    borderRadius: '8px',
                    '&:hover': {
                      borderColor: state.isFocused ? '#09CE7D' : '#09CE7D',
                    },
                  }),
                  dropdownIndicator: (baseStyles, state) => ({
                    ...baseStyles,
                    transform: state.selectProps.menuIsOpen
                      ? 'rotate(180deg)'
                      : '',
                  }),
                  option: (baseStyles, { isSelected, isFocused }) => ({
                    ...baseStyles,
                    color: isFocused ? '#09CE7D' : 'inherit',
                    backgroundColor: isFocused ? '#fff' : '#fff',
                    '&:hover': {
                      backgroundColor:
                        !isSelected && isFocused ? '#fff' : '#fff', // 선택된 옵션이 아니고 hover 시 배경색 변경
                    },
                  }),
                }}
                options={areaOptions2}
                placeholder={Areaplaceholder}
                value={areaOptions2.find(
                  (option) => option.value === areaOfInterest,
                )}
                onChange={(e: any) => {
                  setAreaOfInterest(e.value);
                }}
              />
            </div>
          </div>
          <div className="flex-column gap-16">
            <div className="editText">
              생년월일
              <div className="space-between flex-row mt-8">
                <div className="w-100per mb-24">
                  <div className="button">
                    {birthResult}
                    <img
                      className="calender pointer"
                      onClick={() => {
                        isMobile(window.innerWidth);
                        setOnDate(true);
                        setBirthDate(age);
                      }}
                      src="/asset/images/calender/Calender.svg"
                      alt=""
                    />
                  </div>
                  <div className="editTexts mt-4">
                    각종 이벤트 혜택과 서비스 이용에 도움이 됩니다.
                  </div>
                </div>
              </div>
            </div>
            <div className="flex-column gap-24"></div>
          </div>
          {onDate ? (
            <BoxDateSelector3
              close={() => setOnDate(false)}
              date={age}
              setDate={setAge}
            />
          ) : (
            ''
          )}

          <div className="flex-column gap-24">
            <div className="font-medium f-size-16 color-text-primary">
              회원관리
            </div>
            {loginType === 'C' && (
              <div
                className="space-between flex-row pointer"
                onClick={() => {
                  isMobile(window.innerWidth);
                  navigate(`/find`, { state: { tab: 1 } });
                }}
              >
                <div className="w-100per">
                  <div className="button">
                    비밀번호 재설정
                    <img
                      className="arrow1"
                      src="/asset/images/arrow/ArrowRightBlack.svg"
                      alt="arrow"
                    />
                  </div>
                </div>
              </div>
            )}

            <div
              className="space-between flex-row pointer"
              onClick={() =>
                isMobile(window.innerWidth)
                  ? navigate('/inquiry/write', { state: { address: '' } })
                  : setInquiryPopUp(true)
              }
            >
              <div className="w-100per">
                <div className="button">
                  문의하기
                  <img
                    className="arrow1"
                    src="/asset/images/arrow/ArrowRightBlack.svg"
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
            <div
              className="space-between flex-row pointer"
              onClick={() => {
                isMobile(window.innerWidth);
                dispatch(SignUpType(loginType));
                navigate('/delete');
              }}
            >
              <div className="w-100per">
                <div className="button">
                  회원탈퇴
                  <img
                    className="arrow1"
                    src="/asset/images/arrow/ArrowRightBlack.svg"
                    alt="arrow"
                  />
                </div>
              </div>
            </div>
            <div className="w-100per mt-32">
              <Button
                className="editbtn"
                description="저장"
                on={true}
                onClick={() => {
                  UpdateUserInfo();
                }}
              ></Button>
            </div>
          </div>
        </div>
        <Banner />
      </div>
    </div>
  );
};

export default Edit;
