import { useLocation, useNavigate } from 'react-router-dom';
import { GuideData } from './component/GuideBannerData';

const GuideBannerWeb = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const currentGuide = GuideData.find(
    (item) => item.path === location.pathname,
  );

  const handleNavigate = () => {
    if (currentGuide) {
      navigate(currentGuide.promotionPath);
    }
  };

  return (
    <div
      className="flex-row border-171E26 border-radius-16 bg-F2F4FA space-between w-328 padding-24 pointer"
      onClick={handleNavigate}
    >
      <div className="flex-column gap-8">
        <div
          className="m-b1-16-b color-dark-primary"
          style={{ width: '158px' }}
        >
          {currentGuide && currentGuide.titleText}
          <br />
          {currentGuide && currentGuide.subtitleText}
        </div>
        <span className="m-c1-12-b color-FFFFFF bg-171E26 flex-center padding-8-16 border-radius-999 fit-content">
          {currentGuide && currentGuide.buttonText}
        </span>
      </div>
      <img
        src={
          location.pathname === '/annuity/map'
            ? '/asset/images/banner/pension_banner_web.svg'
            : location.pathname === '/findland/map'
              ? '/asset/images/banner/findland_banner_web.svg'
              : location.pathname === '/aiFarming/map'
                ? '/asset/images/banner/aiFarming_banner_web.svg'
                : '/asset/images/banner/price_banner_web.svg'
        }
      />
    </div>
  );
};

export default GuideBannerWeb;
