import { useNavigate } from 'react-router-dom';
import Button from '../../component/mobile/Button';
import { useEffect, useRef, useState } from 'react';
import { isMobile } from '../../component/function/function';
import PointMain from './Main';
import { is } from 'date-fns/locale';

export const PopUp3 = ({ setOnPopUp2, onPointPopUp }: any) => {
  const temp = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    // 모달이 닫힐 때 body에 스크롤 허용
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const navigate = useNavigate();
  const Title = ({ text }: any) => (
    <div className="bg-primary-800 padding-8-16 border-radius-20 fit-content">
      <div className="font-bold f-size-14 line-h-20 color-FFFFFF">{text}</div>
    </div>
  );
  const Box = ({ text, text2, text3, radius, type }: any) => (
    <div
      className={`flex-column padding-16 border-b-171E26  ${
        type === 1 ? `bg-primary-500` : `bg-primary-700`
      } gap-8 flex-center ${
        radius
          ? type === 1
            ? `border-radius-t-l-16`
            : `border-radius-t-r-16`
          : ``
      }`}
    >
      <div className="padding-2-11 flex-center bg-white-primary-100 border-radius-14">
        <div className="font-medium f-size-12 line-h-14 color-dark-secondary text-center">
          {text}
        </div>
      </div>
      <div className="font-bold f-size-14 line-h-20 color-white-primary text-center">
        {text2} <br /> {text3}
      </div>
    </div>
  );
  const Box2 = ({ src, text1, text2 }: any) => (
    <div className="padding-16 flex-row gap-16 start-y border-b-171E26">
      <img src={src} alt="" />
      <div className="flex-column gap-8">
        <div className="font-bold f-size-14 line-h-20 color-dark-primary">
          {text1}
        </div>
        <div className="font-medium f-size-14 line-h-20 color-dark-secondary">
          {text2}
        </div>
      </div>
    </div>
  );
  const PriceRow = ({ text1, text2 }: any) => (
    <div className="flex-row w-50per space-between border-b-171E26">
      <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
        {text1}
      </div>
      <div className="font-medium f-size-14 line-h-20 color-text-secondary">
        {text2}
      </div>
    </div>
  );

  const [bottomOffset, setBottomOffset] = useState<string | null>(null);

  useEffect(() => {
    // 컴포넌트가 마운트된 후에 offsetHeight를 가져오고 스타일을 설정
    if (temp.current) {
      setBottomOffset(`${temp.current.offsetHeight}px`);
    }
  }, []); // 빈 배열을 넣어 한 번만 실행되도록 설정
  return (
    <div
      className="flex-column fixed h-100per z-9999 browser-flex-center browser w-100per"
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        // paddingBottom: `${140}px`,
      }}
    >
      {/* <div
        className='flex-column w-1024-w-360 h-700 bg-FFFFFF of-scroll border-radius-8 '
        style={{ position: "relative" }}
      >
        <div className='flex-row space-between padding-24-16 '>
          <div className='mobile-body-1-bold color-dark-primary '>
            포인트 안내
          </div>
          <img
            alt=''
            src='/asset/images/close/CloseBlack24.svg'
            className='pointer'
            onClick={() => {
              setOnPopUp2(false);
            }}
          />
        </div>
        <img src='/asset/images/banner/point_banner.svg' alt='' />
        <div className='flex-column'>
          <div className='flex-column padding-42-16-12-16 gap-16'>
            <Title text='포인트란?' />
            <div className='font-bold f-size-18 line-h-26 color-dark-primary'>
              <span className='color-primary-300'> 포인트</span>는 트랜스파머의{" "}
              <br /> 서비스 이용에 필요한 포인트입니다.
            </div>
            <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
              1P는 트랜스파머 서비스 내에서 1원에 해당합니다.
            </div>
          </div>
          <div className='padding-12-16 flex'>
            <div className='flex-row border-radius-16 border-1-171E26 flex-center gap-16 w-100per padding-y-24'>
              <div className='flex-row gap-8 flex-center '>
                <img src='/asset/images/icon/point_icon.svg' alt='' />
                <div className='color-dark-primary font-bold f-size-16 line-h-24'>
                  1P
                </div>
              </div>
              <img src='/asset/images/point/equal.svg' alt='' />
              <div className='flex-row gap-8 flex-center'>
                <img src='/asset/images/point/money.svg' alt='' />
                <div className='color-dark-primary font-bold f-size-16 line-h-24'>
                  1원
                </div>
              </div>
            </div>
          </div>
          <div className='flex padding-16 '>
            <div className='flex-column border-radius-16 padding-16 gap-16 w-100per  bg-light-gray border-171E26'>
              <div className='flex-row space-between center-y w-100per'>
                <div className='font-bold f-size-14 line-h-20 color-000000'>
                  서비스 이용 가격
                </div>
                <div className='font-bold f-size-12 line-h-14 color-primary-400'>
                  1회 기준
                </div>
              </div>
              <div className='flex-row gap-16'>
                <PriceRow text1='가격분석' text2='1,000원' />
                <PriceRow text1='농지연금' text2='1,000원' />
              </div>
              <div className='flex-row gap-16'>
                <PriceRow text1='매물분석' text2='1,000원' />
                <PriceRow text1='농지 찾기' text2='2,000원' />
              </div>
              <div className='flex-row gap-16'>
                <PriceRow text1='내 집 짓기' text2='2,000원' />
                <PriceRow text1='내 농장 진단' text2='2,000원' />
              </div>
            </div>
          </div>
        </div>
        <div className='flex-column padding-42-16-12-16 gap-16'>
          <Title text='충전형 & 기간형' />
          <div className=' flex-column'>
            <div className='font-bold f-size-16 line-h-26 color-text-primary'>
              포인트 충전 안내
            </div>
          </div>
          <div className='font-medium f-size-14 line-h-20 color-text-secondary'>
            포인트 충전으로 농촌·농업 경영 컨설팅 서비스를 다양하게 경험할 수
            있습니다.
          </div>
          <div className='flex-row w-100per gap-16'>
            <div className='flex-column w-50per'>
              <div className='flex-center bg-primary-500 padding-16 border-radius-t-r-24 border-radius-b-l-24 border-b-2-171E2610'>
                <div className='font-bold f-size-16 line-h-24 color-white-primary'>
                  충전형
                </div>
              </div>
              <Box
                text='간편하게'
                text2='1,000원 단위로'
                text3='간편하게 충전'
                radius
                type={1}
              />
              <Box
                text='목적이 있다면'
                text2='원하는 서비스만'
                text3='이용하고 싶다면'
                type={1}
              />
              <Box
                text='원할때만'
                text2='이용하고 싶을때만'
                text3='자유롭게 이용 가능'
                type={1}
              />
            </div>
            <div className='flex-column w-50per'>
              <div className='flex-center bg-primary-700 padding-16 border-radius-t-l-24 border-radius-b-r-24 border-b-2-171E2610 relative'>
                <div className='font-bold f-size-16 line-h-24 color-white-primary'>
                  기간형
                </div>
                <div
                  className='absolute bg-increase flex-center border-radius-11 padding-4-8'
                  style={{ top: "-10px", right: "-10px" }}
                >
                  <div className='font-medium f-size-12 line-h-14 color-FFFFFF'>
                    추천
                  </div>
                </div>
              </div>
              <Box
                text='걱정없이'
                text2='이용기간 동안'
                text3='무제한 이용 가능'
                radius
                type={2}
              />
              <Box
                text='특별혜택'
                text2='매일 출석 시'
                text3='5,000  지금'
                type={2}
              />
              <Box
                text='기간동안'
                text2='가격 변동 시에도 추가'
                text3='결제없이 이용 가능'
                type={2}
              />
            </div>
          </div>
          <div className='flex-column'>
            <div className='flex-column gap-16 padding-42-16-12-16'>
              <Title text='무료 포인트' />
              <div className='font-bold f-size-18 line-h-26 color-dark-primary'>
                포인트는 회원님의 다양한 활동에 따라 <br />
                선물로 지급받으실 수 있습니다.{" "}
              </div>
              <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                선물 받은 포인트는 마이페이지 선물함에서 확인 가능합니다.
              </div>
            </div>
          </div>
          <div className='flex-column'>
            <Box2
              src='/asset/images/point/add_user.svg'
              text1='회원가입'
              text2='회원가입 시 5,000P 지급'
            />
            <Box2
              src='/asset/images/point/calendar.svg'
              text1='출석체크'
              text2='출석체크 시  매일 2,000P 지급'
            />
            <Box2
              src='/asset/images/point/invite.svg'
              text1='친구초대'
              text2='친구초대 시 친구도 나도 5,000P 지급'
            />
            <Box2
              src='/asset/images/point/coupon.svg'
              text1='쿠폰등록'
              text2='이벤트 쿠폰 등록 시 최대 0000P 지급'
            />
          </div>
          <div className='flex-column gap-24 padding-42-16'>
            <div className='flex-column gap-16'>
              <Title text='추가 안내' />
              <div className='flex-row gap-8'>
                <div className='color-dark-secondary font-bold f-size-14 line-h-20'>
                  ・법인 이용 안내
                </div>
              </div>
              <div className='font-medium f-size-16 line-h-24 color-text-tertiary'>
                법인회원(기관, 조합, 기업, 단체 등) 가입과 이용권 구매 할인 및
                세금계산서 발행을 도와 드립니다.
              </div>
            </div>
            <div className='flex-column gap-16'>
              <div className='font-bold f-size-14 line-h-20 color-dark-secondary'>
                이용 문의
              </div>
              <div className='flex-row gap-8'>
                <img src='/asset/images/point/tel.svg' alt='' />
                <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                  02) 555-2822
                </div>
              </div>
              <div className='flex-row gap-8'>
                <img src='/asset/images/point/email.svg' alt='' />
                <div className='font-medium f-size-14 line-h-20 color-dark-secondary'>
                  biz@transfarmer.co.kr
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className='flex border-t-171E26 w-360 padding-16-16-48-16 bg-white border-radius-b-8 browser-w-100per'
          style={{ bottom: "-1px", position: "sticky" }}
        >
          <Button
            on={true}
            description='충전소로 가기'
            onClick={() => {
              isMobile(window.innerWidth)
                ? navigate("/point/recharge")
                : setOnPopUp2(false);
            }}
          />
        </div>
      </div> */}
      <div
        style={{
          width: isMobile(window.innerWidth) ? '100%' : '370px',
          height: '80%',
          zIndex: '9999',
          backgroundColor: '#FFFFFF',
          overflowY: 'auto',
          overflowX: 'hidden',
          borderRadius: '16px',
        }}
      >
        <PointMain setOpen={setOnPopUp2} />
      </div>
    </div>
  );
};
