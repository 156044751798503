import { useEffect, useState } from 'react';
import { TopNav2, TopNavNewBrowser } from '../../../component/mobile/Topnav';
import { useLocation } from 'react-router-dom';
import Footer from '../../../component/browser/Footer';
import { FindIdPopUp } from './PopUp';

const Find3 = () => {
  const [onPopUp, setOnPopUp] = useState(false);
  const [pwd, setPwd] = useState('');
  const [pwd2, setPwd2] = useState('');
  const [pwdShow, setPwdShow] = useState(false);
  const location = useLocation();
  const [selectedId, setSelectedId] = useState('');
  const [step, setStep] = useState(0);
  const [type, setType] = useState('');
  const [pwdStep, setPwdStep] = useState(0);
  const [pwdShow2, setPwdShow2] = useState(false);
  const [pwdCheck, setPwdCheck] = useState(false);
  const [pwdEqualCheck, setPwdEqualCheck] = useState(false);

  return (
    <div className="w-100per  flex-column space-between ">
      {onPopUp && <FindIdPopUp type={type} setOnPopUp={setOnPopUp} />}
      <div
        className="flex-column h-100per w-1024-center-y"
        style={{ overflow: 'auto' }}
      >
        <TopNav2 text="비밀번호 재설정" />
        <TopNavNewBrowser />
        <div className="flex w-1024-max-w-468 w-1024-w-100per">
          <div className="browser w-1024-padding-t-42 f-size-26 line-h-34 font-bold color-dark-primary">
            비밀번호 재설정
          </div>
        </div>
        <div className="flex-column padding-24-16-0-16">
          <div className="flex-column gap-32">
            <div className="flex-column gap-12">
              <div
                className="flex-column gap-2 bg-FAFAFA padding-12-12-12-16  border-radius-13"
                // style={{ backgroundColor: "rgba(23, 30, 38, 0.1)" }}
              >
                <div className="font-regular f-size-12 line-h-18 color-888B90">
                  현재 비밀번호
                </div>

                <div className="flex-row space-between ">
                  <input
                    type={pwdShow ? 'text' : 'password'}
                    className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                    placeholder="현재 비밀번호 입력"
                    value={pwd}
                    onChange={(e) => {
                      setPwd(e.target.value);
                    }}
                    onBlur={() => {
                      // RegPassword.test(pwd)
                      //   ? setPwdCheck(true)
                      //   : setPwdCheck(false);
                    }}
                  />
                  <div className="flex-row gap-5">
                    <img
                      className="pointer"
                      onClick={() => {
                        setPwdShow(!pwdShow);
                      }}
                      src={
                        pwdShow
                          ? '/asset/images/pwd/pwdNoShow.svg'
                          : '/asset/images/pwd/pwdShow.svg'
                      }
                    />
                    {pwd.length > 0 && (
                      <img
                        className="pointer"
                        src="/asset/images/close/close_with_circle.svg"
                        onClick={() => {
                          setPwd('');
                        }}
                      />
                    )}
                  </div>
                </div>
              </div>

              <div className="flex-column gap-4">
                <div
                  className={`flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13 ${
                    !pwdCheck && pwd.length > 0 && `border-increase`
                  } `}
                >
                  <div className="font-regular f-size-12 line-h-18 color-888B90">
                    새 비밀번호 입력
                  </div>
                  <div className="flex-row space-between bg-FAFAFA">
                    <input
                      type={pwdShow ? 'text' : 'password'}
                      className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                      placeholder="새 비밀번호 입력"
                      value={pwd}
                      onChange={(e) => {
                        setPwd(e.target.value);
                      }}
                      onBlur={() => {
                        // RegPassword.test(pwd)
                        //   ? setPwdCheck(true)
                        //   : setPwdCheck(false);
                      }}
                    />
                    <div className="flex-row gap-5">
                      <img
                        className="pointer"
                        onClick={() => {
                          setPwdShow(!pwdShow);
                        }}
                        src={
                          pwdShow
                            ? '/asset/images/pwd/pwdNoShow.svg'
                            : '/asset/images/pwd/pwdShow.svg'
                        }
                      />
                      {pwd.length > 0 && (
                        <img
                          className="pointer"
                          src="/asset/images/close/close_with_circle.svg"
                          onClick={() => {
                            setPwd('');
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!pwdCheck && pwd.length > 0 && (
                  <div className="flex padding-x-16">
                    <div className="mobile-body-2-medium color-text-increase mobile-tite-2-medium">
                      영문/숫자/특수문자를 사용하여 8자~16자로 입력
                    </div>
                  </div>
                )}
              </div>
              <div className="flex-column gap-4">
                <div className="flex-column gap-2 padding-12-12-12-16 bg-FAFAFA border-radius-13">
                  <div className="font-regular f-size-12 line-h-18 color-888B90">
                    새 비밀번호 확인
                  </div>
                  <div className="flex-row space-between bg-FAFAFA">
                    <input
                      type={pwdShow2 ? 'text' : 'password'}
                      className="border-none w-90per bg-FAFAFA font-medium f-size-17 line-h-23 color-dark-primary"
                      placeholder="새 비밀번호 입력"
                      value={pwd2}
                      onChange={(e) => {
                        setPwd2(e.target.value);
                      }}
                      //   onBlur={() => {
                      //     pwd === pwd2
                      //       ? setPwdEqualCheck(true)
                      //       : setPwdEqualCheck(false);
                      //   }}
                    />
                    <div className="flex-row gap-5 ">
                      <img
                        className="pointer"
                        onClick={() => {
                          setPwdShow2(!pwdShow2);
                        }}
                        src={
                          pwdShow2
                            ? '/asset/images/pwd/pwdNoShow.svg'
                            : '/asset/images/pwd/pwdShow.svg'
                        }
                      />
                      {pwd2.length > 0 && (
                        <img
                          className="pointer"
                          src="/asset/images/close/close_with_circle.svg"
                          onClick={() => {
                            setPwd2('');
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
                {!pwdEqualCheck && pwd2.length > 0 && (
                  <div className="flex padding-x-16">
                    <div className="mobile-body-2-medium color-text-increase">
                      패스워드가 일치하지 않습니다.
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Find3;
