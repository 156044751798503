import { useEffect, useState } from 'react';
import {
  RowTableGreenBold,
  RowSectionTitle,
  RowTableValue,
  RowTableValueBold,
} from '../../../../../component/report/Row';
import { TextSectionTitle } from '../../../../../component/report/Text';
import { transNum } from '../../../../../component/function/function';
import DoughnutChart4 from '../../../../../component/mobile/DoughnutChart4';
import DoughnutChart2 from '../../../../../component/mobile/DoughnutChart2';
import { Line, LineChart, Tooltip, XAxis, YAxis } from 'recharts';
import { Tooltip2 } from '../../../../../component/common/tooltip';
import {
  ButtonSelect1N,
  ButtonSelectorBox,
} from '../../../../../component/report/Button';
import {
  BoxGreen1,
  BoxTable2,
  BoxTable9,
} from '../../../../../component/report/Box';
import SignController from '../../../../../controller/sign/SignController';

const ReportDetail3 = ({ data, data2, mypage }: any) => {
  const [tab, setTab] = useState(1);
  const [onToolTip1, setOnToolTip1] = useState(false);
  const [onToolTip2, setOnToolTip2] = useState(false);
  const [onToolTip3, setOnToolTip3] = useState(false);
  const [onToolTip4, setOnToolTip4] = useState(false);
  const [cropTab, setCropTab] = useState(1);
  const [rate1, setRate1]: any = useState(0);
  const [rate2, setRate2]: any = useState(0);
  const [rate3, setRate3]: any = useState(0);
  const [rate4, setRate4]: any = useState(0);
  const [rate5, setRate5]: any = useState(0);
  const btnArr = [
    { children: '예상 매출액', selected: 1 },
    '',
    { children: '오늘의 작물', selected: 2 },
    '',
    { children: '유통처별 매출', selected: 3 },
  ];
  const cropBtnArr = [
    // { children: '산지 도매단가', selected: 1 },
    { children: '도매단가', selected: 1, title: 'wholesaleUnitPrice' },
    { children: '공판단가', selected: 2, title: 'jointMarketUnitPrice' },
    { children: '중도매단가', selected: 3, title: 'intermediarySaleUnitPrice' },
    { children: '소매단가', selected: 4, title: 'retailUnitPrice' },
  ];

  const costArr = [
    {
      color: '#FF497A',
      text: '산지 도매상',
      rate: rate1,
      setRate: setRate1,
      value: (data.unitPrice * data2.quantity * rate1) / 100,
    },
    {
      color: '#F59F00',
      text: '공영 도매시장',
      rate: rate2,
      setRate: setRate2,
      value:
        ((data.wholesaleUnitPrice
          ? data.wholesaleUnitPrice[data.wholesaleUnitPrice.length - 1].price
          : 0) *
          data2.quantity *
          rate2) /
        100,
    },
    {
      color: '#1E9B6B',
      text: '산지 공판장',
      rate: rate3,
      setRate: setRate3,
      value:
        ((data.jointMarketUnitPrice
          ? data.jointMarketUnitPrice[data.jointMarketUnitPrice.length - 1]
              .price
          : 0) *
          data2.quantity *
          rate3) /
        100,
    },
    {
      color: '#1D74F7',
      text: '중도매',
      rate: rate4,
      setRate: setRate4,
      value:
        ((data.intermediarySaleUnitPrice
          ? data.intermediarySaleUnitPrice[
              data.intermediarySaleUnitPrice.length - 1
            ].price
          : 0) *
          data2.quantity *
          rate4) /
        100,
    },
    {
      color: '#9775FA',
      text: '소매',
      rate: rate5,
      setRate: setRate5,
      value:
        ((data.retailUnitPrice
          ? data.retailUnitPrice[data.retailUnitPrice.length - 1].price
          : 0) *
          data2.quantity *
          rate5) /
        100,
    },
  ];

  const table2 = {
    th: ['', '유통처', '판매 비율', '매출액'],
    tb: costArr.map((el) => {
      return [
        <div className="padding-4">
          <div
            style={{
              width: '8px',
              height: '8px',
              borderRadius: '100%',
              background: el.color,
            }}
          ></div>
        </div>,
        el.text,
        <div className="font-medium f-size-14 ">
          <input
            type="text"
            style={{
              width: '50px',
              marginRight: '5px',
              paddingLeft: '3px',
              marginLeft: '-10px',
            }}
            value={el.rate}
            onChange={(e) => {
              el.setRate(
                Number(e.target.value) >= 0
                  ? e.target.value.slice(-1) === '.'
                    ? e.target.value
                    : Number(e.target.value) * 10 -
                          Math.round(Number(e.target.value) * 10) >
                        0
                      ? el.rate
                      : rate1 +
                            rate2 +
                            rate3 +
                            rate4 +
                            rate5 -
                            el.rate +
                            Number(e.target.value) >
                          100
                        ? el.rate
                        : Number(e.target.value)
                  : 0,
              );
            }}
          />
          %
        </div>,
        `${transNum(Number(el.value))}원`,
      ];
    }),
  };
  const [name, setName] = useState('');

  useEffect(() => {
    SignController.getName(setName);
  }, []);
  return (
    <div className="padding-b-24 relative">
      <RowSectionTitle>
        <TextSectionTitle>3. 예상 매출</TextSectionTitle>
      </RowSectionTitle>

      <ButtonSelectorBox tab={tab} setTab={setTab} btnArr={btnArr} />

      {tab === 1 ? (
        <div className="flex-column">
          <div className="padding-16-16-0">
            <RowTableGreenBold
              title={`매출액(${data.crops}기작)`}
              value={`${transNum(data.yearSales)}원`}
            />
          </div>
          <div className="padding-8-16-16 gap-8 flex-column end-y">
            <RowTableValue
              title={`생산량(${data.crops}기작)`}
              value={`${data.quantity && data.quantity.toLocaleString()}kg`}
            />
            <RowTableValue
              warn
              onClick={() => setOnToolTip2(true)}
              title="판매단가"
              value={`${transNum(data.unitPrice)}원`}
            />

            {/* <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 지역내 산지 도매상 평균 판매단가를 적용함
            </div> */}
          </div>
          <Tooltip2
            bottom="-111"
            on={onToolTip2}
            off={() => setOnToolTip2(false)}
            text="지역내 산지 도매상 평균 판매단가를 적용함"
          />
          {/* <div className="padding-16-16-0">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              최근 10년, 사과의 지역 평균 판매단가
            </div>
          </div> */}
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                AI 예상 매출액은 {transNum(data.yearSales)}원으로
              </div>
              <div
                className="font-bold f-size-16 line-h-24 color-primary-500"
                style={{
                  color:
                    data.yearSales < data.avgYearSales ? '#d62554' : '#1E9B6B',
                }}
              >
                지역 평균 {transNum(data.avgYearSales)}원
                {data.yearSales > data.avgYearSales
                  ? `보다 ${Math.abs(
                      Math.round(data.differenceRate * 10) / 10,
                    )}% 높습니다.`
                  : data.yearSales < data.avgYearSales
                    ? `보다 ${Math.abs(
                        Math.round(data.differenceRate * -1 * 10) / 10,
                      )}% 낮습니다.`
                    : '과 같습니다.'}
              </div>
            </BoxGreen1>
          </div>
        </div>
      ) : tab === 2 ? (
        <div className="flex-column">
          <div className="padding-16-16-0 flex-row gap-2">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              오늘의 작물 판매단가 분석
            </div>
            <img
              src="/asset/images/warn/Warn24.svg"
              className="hover"
              onClick={() => setOnToolTip1(true)}
              alt=""
            />
          </div>
          <Tooltip2
            bottom="0"
            on={onToolTip1}
            off={() => setOnToolTip1(false)}
            text="가장 보수적인 판매가격은 산지도매상 납품가 기준입니다."
          />
          <div className="padding-8-16-12 flex-row gap-8  of-x-auto w-100per">
            {cropBtnArr.map((el) => (
              <ButtonSelect1N
                selected={cropTab === el.selected}
                onClick={() => setCropTab(el.selected)}
              >
                {el.children}
              </ButtonSelect1N>
            ))}
          </div>
          {true && (
            <div className="padding-16-16-0">
              <RowTableValueBold
                title={`일일 ${cropBtnArr[cropTab - 1].children}`}
                // value="작년 대비"
                value=""
              />
            </div>
          )}
          {cropTab !== 1 && (
            <div className="flex- row padding-12-16 gap-8 end-x">
              <div className="flex-row gap-4 center-y">
                <div
                  style={{
                    width: '12px',
                    height: '2px',
                    background: '#09CE7D',
                  }}
                />
                <div className="font-medium f-size-11 line-h-12 color-primary-400">
                  {`일일 ${cropBtnArr[cropTab - 1].children}`}
                </div>
              </div>
            </div>
          )}
          <div className="flex-column of-x-auto ">
            {Array.isArray(data[cropBtnArr[cropTab - 1].title as any]) ? (
              <LineChart
                className="flex-medium f-size-11 center-x"
                width={360 - 16}
                height={181}
                data={data[cropBtnArr[cropTab - 1].title as any].map(
                  (el: any, i: number) => {
                    return {
                      day: `${(cropTab >= 3 ? el.regday : el.saleDate).slice(
                        0,
                        4,
                      )}-${(cropTab >= 3 ? el.regday : el.saleDate).slice(
                        4,
                        6,
                      )}-${(cropTab >= 3 ? el.regday : el.saleDate).slice(
                        6,
                        8,
                      )}`,
                      price: el.price,
                    };
                  },
                )}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <Line
                  type="monotone"
                  dataKey="price"
                  name={cropBtnArr[cropTab - 1].children}
                  stroke="#09CE7D"
                />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
              </LineChart>
            ) : (
              <img src="/asset/images/noData/contents.png" alt="" />
            )}
          </div>
          <div className="padding-8-16-16 gap-8 flex-column end-y">
            {Array.isArray(data[cropBtnArr[cropTab - 1].title as any]) ? (
              <RowTableValue
                title={`일평균 ${cropBtnArr[cropTab - 1].children}`}
                value={`${transNum(
                  data[cropBtnArr[cropTab - 1].title as any][
                    data[cropBtnArr[cropTab - 1].title as any].length - 1
                  ].price,
                )}원/kg`}
              />
            ) : cropTab === 1 ? (
              <RowTableValue
                title={`일평균 ${cropBtnArr[cropTab - 1].children}`}
                value={`${data.unitPrice}원/kg`}
              />
            ) : (
              ''
            )}

            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 일평균 가격은 매일 업데이트됩니다.
            </div>
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                유통처별 일일 가격 정보는 <br />
                출하시점에서 다시 검토가 필요합니다.
              </div>
            </BoxGreen1>
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                유통처별 판매가격을 감안하여 <br />
                매출 포트폴리오를 구성할 수 있습니다.
              </div>
            </BoxGreen1>
          </div>
        </div>
      ) : tab === 3 ? (
        <div className="flex-column">
          <div className="padding-16-16-0">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              유통처별 매출 계획
            </div>
          </div>
          <div className="padding-24-16 flex-row center-y space-between">
            {costArr.filter((el) => el.rate).length > 0 ? (
              <div className="w-100per center-y  center-x">
                <DoughnutChart4 list={costArr} />
              </div>
            ) : (
              <div className="flex-column center-y w-100per gap-12 padding-y-17">
                <img src="/asset/images/graph/donut_graph.svg" alt="" />
                <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
                  판매 비율을 직접 입력해주세요
                </div>
              </div>
            )}
            {/* <div className="flex-column gap-8" style={{ width: "144px" }}>
              {costArr.map((el) => (
                <div className="flex-row space-between">
                  <div className="flex-row center-y gap-8">
                    <div
                      style={{
                        width: "8px",
                        height: "8px",
                        borderRadius: "100%",
                        background: el.color,
                      }}
                    ></div>
                    <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                      {el.text}
                    </div>
                  </div>
                  <div className="font-medium f-size-14 line-h-20 color-text-primary">
                    {Math.round(el.rate * 10) / 10}%
                  </div>
                </div>
              ))}
            </div> */}
          </div>
          <div className="padding-8-16 w-100per relative">
            <BoxTable9
              table={table2}
              onPrice
              onClick2={() => setOnToolTip4(true)}
              onClick={() => setOnToolTip3(true)}
            />
            <Tooltip2
              bottom="0"
              on={onToolTip3}
              off={() => setOnToolTip3(false)}
              text="작물 유통처 분포 합계는 100%를 넘을 수 없습니다."
            />
            <Tooltip2
              bottom="0"
              on={onToolTip4}
              off={() => setOnToolTip4(false)}
              text="소매 매출은 대형 유통사 평균 판매가격의 50%를 적용합니다."
            />
          </div>
          <div className="padding-8-16 flex-row end-x">
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 본 유통처별 매출 계획은 참고용으로 제공됩니다.
            </div>
          </div>
          <div className="padding-8-16 gap-10">
            <BoxGreen1>
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                {name}님 작물은{' '}
                {costArr.sort((a, b) => b.rate - a.rate)[0].text}에서 가장 많이
                유통되며, C2B(농부-도매상), C2C(농부-소비자) 유통 비율은 각각{' '}
                {rate1 + rate2 + rate3 + rate4}%, {rate5}%로 분석됩니다.
              </div>
            </BoxGreen1>
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default ReportDetail3;
