import { useLocation, useNavigate } from 'react-router-dom';
import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  MouseEvent,
} from 'react';
import LoginPopUp from '../../../../component/common/LoginPopUp';
import { isMobile } from '../../../../component/function/function';
import { SideTopnav } from '../../../../component/browser/SideTopnav';
import InquiryWrite from '../../../inquiry/InquiryWrite';
import SideSearch from '../../../../component/browser/sidebar/Search';
import HousePreview from './House';
import LandValueReport from '../../../house/report/Report';
import HouseReportMain from '../../../reportResultHouse/Main';
import PriceReportMain from '../../../reportResultPrice/Main';
import { LoginPopUp2 } from '../../../../component/common/LoginPopUp2';

interface ISaleRentalPreview2Data {
  address: string;
  fixMarker: Function;
  currentMarker: any;
  query: string;
  areaList: any;
  currentInfo: any;
  setOnMyLandList: any;
  markerArray: Array<any>;
  setAreaList: React.Dispatch<React.SetStateAction<Array<any>>>;
  setMarkerArray: React.Dispatch<React.SetStateAction<Array<any>>>;
  onMyLandList: boolean;
  setOnToggle?: any;
  setKmTab?: any;
  kmTab?: any;
}

const HousePreview2 = (props: ISaleRentalPreview2Data) => {
  const element = useRef<HTMLDivElement>(null);
  const element2 = useRef<HTMLDivElement>(null);
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const locationHook = useLocation();

  const [query, setQuery] = useState('');
  useEffect(() => {
    if (
      locationHook.search.split('=')[1] &&
      !locationHook.search.includes('n_media')
    ) {
      setQuery(decodeURI(locationHook.search.split('=')[1]));
    } else {
      setQuery('');
    }
  }, [locationHook]);
  const [selectedDesignFloor, setSelectedDesignFloor] = useState(1);

  const [menu, setMenu] = useState('tab1');
  const [subCategory, setSubCategory] = useState('subcategory1');
  const [onInquiry, setOnInquiry] = useState(false);
  const [onReport, setOnReport] = useState(false);
  const [onReport3, setOnReport3] = useState(false);
  const [priceReport, setPriceReport] = useState(false);
  const [close, setClose] = useState(false);
  const [onDetail, setOnDetail] = useState(true);
  const [onLoginPopup, setOnLoginPopup] = useState(false);
  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);

  const [top, setTop] = useState(0);
  const [tabHeight, setTabHeight] = useState(0);
  const [menuIndex, setMenuIndex] = useState(666666);

  const [reportIdx, setReportIdx] = useState(0);
  const [priceHouseReportIdx, setPriceReportHouseIdx] = useState(0);
  const [houseReportIdx, setHouseReportIdx] = useState(0);
  const [area, setArea] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickPoint, setClickPoint] = useState(0);

  const [menuHeight, setMenuHeight] = useState<number>(0);
  const [current, setCurrentHeight] = useState<number>(0);
  const [costHeight, setCostHeight] = useState<number>(0);
  const [initWidth, setInitWidth] = useState(0);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    // 마우스 클릭하면
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음

    e.preventDefault();

    if (containerRef.current) {
      // clickPoint는 onMouseDown에서 처음 클릭한 위치가 할당된다.
      // walk = 마우스를 끌고간 최종 위치 - 처음 마우스를 클릭한 위치
      // 오른쪽에서 왼쪽으로 끌면 음수이다.
      const walk = e.pageX - clickPoint;

      // walk가 음수, 즉 오른쪽에서 왼쪽으로 끌었을때 scrollLeft - walk는 양수가 되면서
      // containerRef의 scrollLeft가 증가하면서 스크롤이 오른쪽으로 움직인다.
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  // const onMoveBox = () => {
  //   element.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  // };
  // const onMoveBox2 = () => {
  //   element2.current?.scrollIntoView({ behavior: "smooth", block: "start" });
  // };

  useLayoutEffect(() => {
    const obj = {
      tab1: tab1 as any,
      tab2: tab2,
    };
    const tab = obj[menu as 'tab1'];
    if (tab?.current) {
      setTabWidth(tab.current.offsetWidth);
      setTop(tab.current.offsetTop + tab.current.offsetHeight);
      setLeft(tab.current.offsetLeft);
      setInitWidth(tab.current.offsetWidth);
      setTabHeight(tab.current.offsetHeight);
    }
  }, [menu]);
  useEffect(() => {
    setMenuHeight(tab1?.current?.getBoundingClientRect().top || 0);
    setCurrentHeight(element?.current?.getBoundingClientRect().top || 0);
    setCostHeight(element2?.current?.getBoundingClientRect().top || 0);
    setMenu('tab1');
  }, [onReport]);

  const [position, setPosition] = useState<number>(0);
  const temp = useRef<HTMLDivElement>(null);
  function onScroll() {
    setPosition(temp.current?.scrollTop || 0);
  }
  useEffect(() => {
    temp.current?.addEventListener('scroll', onScroll);
    return () => {
      temp.current?.removeEventListener('scroll', onScroll);
    };
  }, []);

  const scrollTo = (text: string) => {
    if (text === 'current') {
      temp.current?.scrollTo(0, 600);
    } else if (text === 'cost') {
      temp.current?.scrollTo(0, 1603);
    } else if (text === 'subcategory1') {
      temp.current?.scrollTo(0, 709);
    } else if (text === 'subcategory2') {
      temp.current?.scrollTo(0, 858);
    } else if (text === 'subcategory3') {
      temp.current?.scrollTo(0, 1003);
    } else if (text === 'subcategory4') {
      temp.current?.scrollTo(0, 1421);
    } else if (text === 'subcategory5') {
      temp.current?.scrollTo(0, 1868);
    } else if (text === 'subcategory6') {
      temp.current?.scrollTo(0, 2050);
    } else if (text === 'subcategory7') {
      temp.current?.scrollTo(0, 2480);
    } else if (text === 'subcategory8') {
      temp.current?.scrollTo(0, 2802);
    }
  };
  useEffect(() => {
    setOnReport3(false);
    setClose(false);
    setPriceReport(false);
  }, [props.address]);
  useEffect(() => {
    if (position > 599 && position < 1602) {
      setMenu('tab1');
      if (position > 708 && position < 857) {
        setSubCategory('subcategory1');
      } else if (position > 857 && position < 1002) {
        setSubCategory('subcategory2');
      } else if (position > 1002 && position < 1420) {
        setSubCategory('subcategory3');
      } else if (position > 1420 && position < 1602) {
        setSubCategory('subcategory4');
      }
    } else if (position > 1602) {
      setMenu('tab2');
      if (position > 1602 && position < 1867) {
        setSubCategory('');
      }
      if (position > 1867 && position < 2049) {
        setSubCategory('subcategory5');
      } else if (position > 2049 && position < 2479) {
        setSubCategory('subcategory6');
      } else if (position > 2479 && position < 2802) {
        setSubCategory('subcategory7');
      } else if (position < 2802) {
        setSubCategory('subcategory8');
      }
    }
  }, [position]);
  useEffect(() => {
    setMenuIndex(1000);
  }, [menu]);
  useEffect(() => {
    setArea(props.currentInfo.area);
  }, [props]);
  useEffect(() => {
    setOnReport3(false);
    setReportIdx(0);
  }, [props.currentInfo.area]);

  return (
    <div
      className={`bg-FFFFFF fixed z-1 w-100per padding-8-16-48 border-top-radius-16 flex-column gap-16 transition browser-absolute browser-h-100per browser-top-0 browser-left-0 browser-border-radius-0 browser-flex-row browser-gap-0 browser-padding-0 browser-bg-F7F8F9" +
        ${!close ? 'browser-w-449' : 'browser-w-824'}`}
      style={
        isMobile(window.innerWidth)
          ? {
              height: props.currentInfo.estimatedPrice ? '600px' : '50px',
              bottom: onDetail ? 0 : '-570px',
            }
          : {}
      }
    >
      {onInquiry && <InquiryWrite address={props.address} />}
      <div className="browser browser-flex w-74">
        <SideTopnav />
      </div>
      <div
        className={`flex-column browser-border-b-171E26 browser-w-375 browser-h-100per browser-of-scroll ${
          (locationHook.pathname.slice(-1) === '/'
            ? locationHook.pathname.slice(0, -1)
            : locationHook.pathname) === '/price/map' && `border-r-171E2610`
        }`}
        ref={temp}
      >
        {onLoginPopup ? (
          // <LoginPopUp
          //   setOnLoginPopup={setOnLoginPopup}
          //   address={props.address}
          //   pathName={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname)}
          // />
          <LoginPopUp2
            address={props.address}
            setOnLoginPopup={setOnLoginPopup}
            // setOnLoginPopup={setOnLoginPopup}
            // address={props.address}
            // pathName={(locationHook.pathname.slice(-1) === "/" ? locationHook.pathname.slice(0,-1): locationHook.pathname)}
          />
        ) : (
          ''
        )}
        {props.currentInfo.estimatedHousingPrice !== undefined ? (
          <div>
            <SideSearch
              on={false}
              recycle={true}
              location={
                query &&
                (decodeURI(query).slice(0, 4) !== 'list'
                  ? decodeURI(query)
                  : query.split(']')[1])
              }
            />
            <div className="flex-column gap-16  browser-bg-FFFFFF border-b-171E26 ">
              <HousePreview
                address={query}
                fixMarker={props.fixMarker}
                currentMarker={props.currentMarker}
                query={query}
                areaList={props.areaList}
                currentInfo={props.currentInfo}
                setOnMyLandList={props.setOnMyLandList}
                markerArray={props.markerArray}
                setAreaList={props.setAreaList}
                setMarkerArray={props.setMarkerArray}
                onMyLandList={props.onMyLandList}
                setOnReport={setOnReport3}
                onReport={onReport3}
                setPriceReport={setPriceReport}
                priceReport={priceReport}
                onLoginPopup={onLoginPopup}
                setClose={setClose}
                close={close}
                setPriceHouseReportIdx={setPriceReportHouseIdx}
              />
            </div>
          </div>
        ) : (
          <>
            <SideSearch
              noList={true}
              on={false}
              location={
                query &&
                (decodeURI(query).slice(0, 4) !== 'list'
                  ? decodeURI(query)
                  : query.split(']')[1])
              }
            />
          </>
        )}
        {!isMobile(window.innerWidth) && onReport3 ? (
          <div className="flex-column browser padding-b-24">
            <LandValueReport
              setReportIdx={setHouseReportIdx}
              setClose={setClose}
              estimatedHousingPrice={props.currentInfo.estimatedHousingPrice}
              setSelectedDesignFloor={setSelectedDesignFloor}
              selectedDesignFloor={selectedDesignFloor}
              address={props.address}
            />
          </div>
        ) : (
          ''
        )}
      </div>
      {/* <div className="bg-white z-10" style={{ marginLeft: "5px" }}>
        <HouseReportMain reportIdx={houseReportIdx} />
      </div> */}
      {houseReportIdx > 0 && close && (
        <div
          className="flex-column browser-border-b-171E26 browser-h-100per"
          style={{ marginLeft: '0px', width: '375px' }}
        >
          <HouseReportMain
            reportIdx={houseReportIdx}
            setClose={setClose}
            selectedDesignFloor={selectedDesignFloor}
          />
        </div>
      )}
      {priceReport && close && (
        <div
          className="flex-column browser-border-b-171E26 browser-h-100per"
          style={{ marginLeft: '0px', width: '375px' }}
        >
          <PriceReportMain
            reportIdx={priceHouseReportIdx}
            setClose={setClose}
            info={props.currentInfo}
            setKmTab={props.setKmTab}
            kmTab={props.kmTab}
          />
        </div>
      )}
    </div>
  );
};

export default HousePreview2;
