import { useDispatch } from 'react-redux';
import UserService from '../../service/checkAccount/CheckAccount';
import GetActService from '../../service/act/Act';
import { hackleClient } from '../../hackler';

export default class SignController {
  static check = async (navigate: any, pathname: string) => {
    // pathname은 location.pathname
    try {
      await UserService.checkLogin();
      return true;
    } catch (error: any) {
      if (error.response.data.message === 'jwt expired') {
        alert('토큰이 만료되었습니다.');
        navigate('/login?redirect=operate');
        sessionStorage.setItem('pathname', pathname);
      }
      if (error.response.data.message === 'jwt malformed') {
        alert('로그인이 필요한 서비스입니다.');
        navigate('/login');
        sessionStorage.setItem('pathname', pathname);
      }
    }
  };

  static checkAgriculturalNum = async (navigate: any) => {
    try {
      let res = await UserService.checkInfo();
      if (!res.agriculturalNum) {
        throw new Error('agriculturalNum invalid');
      } else {
        return true;
      }
    } catch (error: any) {
      if (error.message === 'agriculturalNum invalid') {
        alert('농업 경영체 등록 번호가 필요합니다.');
        navigate('/update/agricultural');
      }
    }
  };

  static LogoutApi = async (onLogout: any) => {
    try {
      await onLogout();
      GetActService.logout();
      hackleClient.setUserId('');
      hackleClient.resetUser();
      window.location.reload();
    } catch (err: any) {
      console.log(err);
    }
  };

  static getName = async (setName: any) => {
    try {
      const res = await UserService.checkInfo();
      setName(res.name);
    } catch (err) {
      console.log(err);
    }
  };
}
