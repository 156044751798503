import './SignUpPromotion.css';
import './Font.css';
import Banner from '../../../component/AIIntroduction/Footer';
import FooterBrowser from '../../../component/browser/Footer';
import { Helmet } from 'react-helmet';
import { TopNavNewBrowser } from '../../../component/mobile/Topnav';
import { useEffect, useState } from 'react';
import { PromotionTopNav } from '../../../component/mobile/PromotionTopNav';
import CTA from '../ctaBtn/CTA';
import CompanyInfoSlider from '../infoSlider/InfoSlider';
import ABTest from '../../../service/promotion/Promotion';
import TopBanner from '../../../component/banner/TopBanner';
import SliderTab from './components/SliderTab';
import { Link, useNavigate } from 'react-router-dom';
import LandingBanner from './components/Landing-banner';
import ScrollToTopButton from '../../../component/common/ScrollToTop';

const SignupAiFarming = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    sessionStorage.setItem('landingType', 'aifarm');
    const fetchData = async () => {
      try {
        const data = { type: 'X', title: '내 농장 진단', action: 'N' };
        const result = await ABTest.ABLog(data);
        console.log(result);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const actionUpdate = async () => {
    try {
      const data = { type: 'X', title: '내 농장 진단', action: 'Y' };
      const result = await ABTest.ABLog(data);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    sessionStorage.setItem('landingType', 'aifarm');
    // sessionStorage.setItem('beforeUrl', document.referrer);
    sessionStorage.setItem('beforeUrl', window.location.href);
  }, []);
  return (
    <div className="wrap">
      <Helmet>
        <title>
          농작물 수익 분석, 농업 비용 계산, 농장 수익성 확인 등 농장 경영 진단을
          한번에{' '}
        </title>
        <meta
          name="description"
          content="농지의 토질 분석, 적합 작물 추천, 운영 수익 계산, 비용 분석 등 21억 건의 빅데이터가 말하는 성공 농사 농지테크 트랜스파머"
        />
        <meta name="robots" content="index, follow" />
        <meta
          name="keyword"
          content=" 농장 경영 진단, 농사 수익 확인, 농업경영, 영농계획, 농업경영계획, 작물수익, 작물추천, 작물선택, 무료 농업 컨설팅"
        />
        <link
          rel="canonical"
          href="https://www.transfarmer.co.kr/promotion/AI로-농장-진단-농업-경영-솔루션"
        />
        {/* 오픈그래프 영역 */}
        <meta property="og:title" content="트랜스파머" />
        <meta property="og:description" content="AI로 농사를 똑똑하게" />
        <meta property="og:image" content="/asset/images/logo/og-img.png" />
        <meta
          property="og:url"
          content="https://www.transfarmer.co.kr/promotion/AI로-농장-진단-농업-경영-솔루션"
        />
      </Helmet>
      <div className="container">
        <ScrollToTopButton bottom={'130px'} mbottom={'90px'} isVisible={true} />
        {/* <TopBanner />
        <TopNavNewBrowser /> */}
        <PromotionTopNav open={open} setOpen={setOpen} />
        <div className="section section01">
          <div className="section01-title-box">
            <h1 className="menu-tag">내 농장 진단</h1>
            <div className="section-description">
              모든 농부들을 위한 <br />
              온라인 농업 컨설팅
            </div>
            <div className="section-title-box">
              <div className="section-title">
                <div className="text34">
                  AI 농장 경영 진단
                  <span className="text34 text-light-grey">으로</span>
                  <br />내 농장 수익 UP!
                </div>
              </div>
            </div>
          </div>
          <div className="section01-content-box section01-content-box-2">
            <img
              src="/asset/images/promotion/aifarmingMain.svg"
              alt="농지가치 평가보고서"
            />
          </div>
        </div>
        <div className="section section02-1 aifarming-content">
          <div className="section02-title-box">
            <img
              src="/asset/images/promotion/worry-face.svg"
              alt="걱정하는 이모지"
            />
            <p className="text40 text-light-green">내 농장</p>
            <p className="text40 text-grey">경쟁력</p>
            <p className="text40 text-light-green">알고 계세요?</p>
          </div>
          <div className="section02-content-box">
            <div>
              <img
                src="/asset/images/promotion/green-circle-question-mark.svg"
                alt="초록색물음표"
              />
              <span className="text17-white">작물 경쟁력은 검토하셨나요?</span>
            </div>
            <div>
              <img
                src="/asset/images/promotion/green-circle-question-mark.svg"
                alt="초록색물음표"
              />
              <span className="text17-white">판매단가는 알아보셨나요?</span>
            </div>
            <div>
              <img
                src="/asset/images/promotion/green-circle-question-mark.svg"
                alt="초록색물음표"
              />
              <span className="text17-white">생산량은 비교해보셨나요? </span>
            </div>
          </div>
        </div>
        <div className="section section05">
          <div className="section05-title-box">
            <div className="text32">AI 내 농장 진단으로</div>
            <div className="section05-title--box-1">
              <p className="text32 text-color-box"> 비용은 줄이고 </p>
              <p className="text32 text-color-box">수익은 늘릴 수 있는 </p>
              <p className="text32">
                내 농장 성공 가능성을
                <br />
                높이세요!
              </p>
            </div>
            <div className="line-column"></div>
          </div>
          <div className="section05-content-box">
            <div className="section05-speech-bubble-box">
              <div className="section05-speech-bubble">
                <div className="text20">
                  이웃님들 성공스토리 부러워요
                  <br />
                  <span>나도 내 농사로 성공하고 싶어요.</span>
                </div>
                <img
                  className="color-bubble"
                  src="/asset/images/promotion/grey-bubble.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img">
                <img
                  src="/asset/images/promotion/people_illust_01.svg"
                  alt="사람1"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-right">
              <div className="section05-speech-bubble">
                <div className="text20">
                  수익과 지출비용을 비교해서
                  <br />
                  <span>보다 좋은 작물을 심어보고 싶어요.</span>
                </div>
                <img
                  className="line-bubble"
                  src="/asset/images/promotion/line-bubble.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img people-img-right">
                <img
                  src="/asset/images/promotion/people_illust_03.svg"
                  alt="사람2"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box">
              <div className="section05-speech-bubble">
                <div className="text20">
                  초보 주말농장러인데 <br />
                  <span>텃밭에 뭘 심어야 좋을까요?</span>
                </div>
                <img
                  className="color-bubble"
                  src="/asset/images/promotion/grey-bubble.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img">
                <img
                  src="/asset/images/promotion/people_illust_02.svg"
                  alt="사람3"
                />
              </div>
            </div>
            <div className="section05-speech-bubble-box-right">
              <div className="section05-speech-bubble">
                <div className="text20">
                  하우스? 스마트팜?
                  <br />
                  제가 가진 자금으로 할 수 있는 <br />
                  <span>
                    수익성 좋은 농사방법 <br />
                    찾고 싶어요.
                  </span>
                </div>
                <img
                  className="line-bubble line-bubble-big"
                  src="/asset/images/promotion/line-bubble-big2.svg"
                  alt="말풍선"
                />
              </div>
              <div className="people-img people-img-right">
                <img
                  src="/asset/images/promotion/people_illust_04.svg"
                  alt="사람4"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="section section06 section06-1">
          <div className="section06-title-box">
            <div className="section06-title-1">
              <p className="text27 text27-green">가장 쉬운</p>
              <p className="text27 text-round-box">농지테크</p>
            </div>
            <div className="text27">트랜스파머에서</div>
            {/* <div className='section06-title-2'>
              <p className='text27 text-dot'>쉽고 편리하게</p>
              <p className='text27'>농업 경영 컨설팅하세요!</p>
            </div> */}
            <div className="flex-column flex-center relative">
              <div className="text27">쉽고 편리하게</div>
              <div className="text27">농업 경영 컨설팅하세요!</div>
              <div
                className="flex-row  absolute"
                style={{ bottom: '75px', left: '112px', gap: '16px' }}
              >
                {[1, 2, 3, 4].map(() => {
                  return (
                    <div
                      className="border-radius-999"
                      style={{
                        width: '9px',
                        height: '9px',
                        backgroundColor: '#00EA00',
                      }}
                    >
                      &nbsp;
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="section06-content-box">
            <div className="section06-content-01">
              <div className="content-01-title">
                <div className="content-01-grey-line"></div>
                <p className="text20">농업 경영에 필요한</p>
                <p className="text27 text-27-heavy">빅 데이터를 활용</p>
              </div>
              <SliderTab />
            </div>
            <div className="section06-content-02 text20">
              전국 <span>21억 건 이상</span> 누적 데이터로 <br />
              맞춤형 농가 경영 정보를 제공합니다.
            </div>
          </div>
        </div>
        <div className="section findland-add1">
          <div className="findland-add1-title-box">
            <span className="text32">
              내 농사, <br />
              이대로 계속 해도
            </span>
            <img src="/asset/images/promotion/better2.svg" alt="" />
          </div>
          <div className="findland-add1-content-box">
            <img
              src="/asset/images/promotion/farmer-bubble.svg"
              alt="비교이미지"
              style={{ marginTop: '40px' }}
            />
          </div>
        </div>
        <div className="section findland-add2">
          <div className="findland-add2-title-box">
            <div className="text32">
              트랜스파머
              <br />
              <span className="text32 text-color-box">내 농장 진단</span>을{' '}
              <br />
              이용하면
            </div>
            <img
              src="/asset/images/promotion/red-arrow.svg"
              alt="빨간 화살표"
            />
          </div>
          <div className="findland-add2-content-box">
            <img
              className="polygon-bg2"
              src="/asset/images/promotion/Polygon3.svg"
              alt=""
            />
            <div className="findland-add2-content-01">
              <div className="findland-add2-content-block">
                <div className="findland-add2-content-block-title">
                  <p className="text27">작물 선택</p>
                </div>
                <img
                  src="/asset/images/promotion/aiFarming-choose.svg"
                  alt=""
                />
              </div>
              <div className="green-line"></div>
              <div className="findland-add2-content-block">
                <div className="findland-add2-content-block-title">
                  <p className="text27">작물 경쟁력 진단과 컨설팅</p>
                </div>
                <img
                  src="/asset/images/promotion/FaiFarming-consulting.svg"
                  alt=""
                />
              </div>
              <div className="green-line"></div>
              <div className="findland-add2-content-block">
                <div className="findland-add2-content-block-title">
                  <p className="text27">수익 / 비용 진단과 컨설팅</p>
                </div>
                <img
                  src="/asset/images/promotion/aiFarming-consulting2.svg"
                  alt=""
                />
              </div>
            </div>
            <div className="findland-add2-content-02">
              <div className="content-02-title">
                <p className="text20">
                  빅 데이터 분석으로 <br />
                  누구나 쉽고 간편하게
                </p>
                <img src="/asset/images/promotion/aiFarming-plan.svg" alt="" />
                <div className="findland-table2">
                  <img src="/asset/images/promotion/aiFarming-ex.svg" alt="" />
                  <div className="table-hidden-text">
                    복숭아, 상추,총 생산량,예상 매출,월 평균 매출,예상 비용,작물
                    판매 단가,순이익
                  </div>
                </div>
              </div>
            </div>
            <img src="/asset/images/promotion/human-with-bulb.svg" alt="전구" />
          </div>
        </div>
        <div className="section section08 section08-1">
          <div className="section08-title-box">
            <img src="/asset/images/promotion/Lightbulb.svg" alt="전구" />
            <p className="text27">이렇게 이용하세요</p>
            <p className="text20">
              전문가의 도움 없이도 <br />{' '}
              <span>농업 컨설턴트 수준의 농업 계획</span>을
              <br />
              쉽고 간편하게 이용해 보세요.
            </p>
          </div>
          <div className="section08-01-content-box">
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                1 <br />
                홈에서 '내 농장 진단' 선택
              </div>
              <div className="usage-step-content2">
                <img
                  className="aiFarming-gif"
                  src="/asset/images/promotion/aiFarming-gif1.gif"
                  alt="이용가이드1"
                />
              </div>
            </div>
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                2 <br />내 농장 경영 입력
              </div>
              <div className="usage-step-content2">
                <img
                  className="aiFarming-gif"
                  src="/asset/images/promotion/aiFarming-gif2.gif"
                  alt="이용가이드2"
                />
              </div>
            </div>
            <div>
              <div className="usage-step-title text-20-extra-bold  green">
                {' '}
                3 <br />내 농장 진단보고서 확인하기
              </div>
              <div className="usage-step-content2">
                <img
                  className="aiFarming-gif"
                  src="/asset/images/promotion/aiFarming-gif3.gif"
                  alt="이용가이드3"
                />
              </div>
            </div>
          </div>
          <div
            className="w-100per flex-center padding-12 pointer"
            style={{ backgroundColor: '#171E26' }}
            onClick={() => {
              navigate(
                '/promotion/%ED%8A%B8%EB%9E%9C%EC%8A%A4%ED%8C%8C%EB%A8%B8-%EB%A7%9E%EC%B6%A4-%ED%86%A0%EC%A7%80-%EC%BB%A8%EC%84%A4%ED%8C%85?utm_source=TF&utm_medium=banner&utm_campaign=consulting_banner&utm_id=promotion&utm_content=consulting_banner',
              );
            }}
          >
            <div
              className="flex-row space-between w-100per"
              style={{ maxWidth: '1200px' }}
            >
              <div className="flex-row gap-8 w-100per center-y">
                <div
                  className="bg-primary-400 border-radius-2"
                  style={{ padding: '2px 6px' }}
                >
                  <div className="m-b2-14-b color-dark-primary">
                    맞춤 컨설팅
                  </div>
                </div>
                <div className="m-b1-16-b color-FFFFFF">
                  토지 매매, 이제 <span style={{ color: '#09CE7D' }}>안심</span>
                  하세요
                </div>
              </div>
              <img
                className="w-24"
                src="/asset/images/arrow/ArrowRightWhite20.svg"
              />
            </div>
          </div>
          <LandingBanner />
        </div>
        <div className="section section09">
          <div className="section09-title-box">
            <img src="/asset/images/promotion/Logo_Symbol.svg" alt="로고" />
            <p className="text32">트랜스파머는</p>
            <p className="text20">
              농지 검색에서 수익창출까지 <br />
              농지 위의 모든 것을 데이터화하여 <br />
              가치를 만들어 갑니다
            </p>
          </div>
          <div className="section09-content-box">
            <CompanyInfoSlider />
          </div>
        </div>
        <Banner />
        <FooterBrowser />
      </div>
      <CTA
        text1="내 농장 매출 · 비용"
        text3="지역농가와 비교하세요"
        btnText1="온라인 농장·농업"
        btnText2="컨설팅 시작하기"
        actionUpdate={actionUpdate}
        greenTextColor="#00EA00"
        actionBtnBackground="#00EA00"
      />
    </div>
  );
};
export default SignupAiFarming;
