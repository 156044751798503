import { Content1, Footer, ReportTitle } from '../component';

export const Estimated2 = () => {
  const content = [
    '본 물건에서 비닐하우스 재배(200평 기준)로 수박(반촉성)을 재배할 경우, ',
    '예상되는 순수익(매출-비용=영업이익)은 약 153만원으로 추정되며,  ',
    '한국개발연구원(KDI)의 수익환원율을 고려한 조정 수익환원률 4.0%를 적용할 경우, 물건의 가치는 약 3,825만원으로 분석됨',
  ];
  return (
    <div className="flex-column w-100per space-between page">
      <div className="flex-column  w-100per">
        <ReportTitle
          address="전북특별자치도 김제시 순동 445-19"
          category="수익환원법 기반 2차 추정가"
        />
        <div className="flex-column padding-24 gap-12">
          <Content1 content={content} />
        </div>
      </div>
      <Footer number={11} />
    </div>
  );
};
