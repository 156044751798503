import { Link } from 'react-router-dom';
import './CTA.css';

const CTA = ({
  actionUpdate,
  text1,
  text2,
  text3,
  btnText1,
  btnText2,
  greenTextColor,
  actionBtnBackground,
  btnText,
}: any) => {
  return (
    <div className="cta-wrap">
      <div className="cta-inner">
        <div className="cta-text-box">
          <div className="cta-text">
            {text1}
            <br />
            {text2}
            <span className="cta-text-green" style={{ color: greenTextColor }}>
              {text3}
            </span>
          </div>
        </div>
        <div
          className="action-btn"
          style={{ backgroundColor: actionBtnBackground }}
          onClick={actionUpdate}
        >
          <Link
            to="/SignUp"
            className="action-btn-a"
            style={{ textDecoration: 'none' }}
          >
            <span>{btnText1}</span>
            <span>{btnText2}</span>
            <span>{btnText}</span>
          </Link>
        </div>
      </div>
    </div>
  );
};
export default CTA;
