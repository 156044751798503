import { memo, SVGProps } from 'react';

const Frame43149Icon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    preserveAspectRatio="none"
    viewBox="0 0 65 65"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={32.5} cy={32.5} r={32.5} fill="#E3F6EB" />
    <path
      d="M21 31.5L29.5 40L43 26.5"
      stroke="#009673"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const Memo = memo(Frame43149Icon);
export { Memo as Frame43149Icon };
