import { useEffect, useState } from 'react';

const slides = [
  {
    category: '자연',
    description: '토질 분석, 기후 분석, 배수 상태, 경사도, 토지 규모 및 형태',
  },
  {
    category: '농업',
    description: '작물 특성, 재배 방식, 생산량 계산, 작물 판매 금액',
  },
  {
    category: '금융',
    description:
      '투자비, 작물별 수익 데이터, 예상 수익 금액, 운영 비용, 대출계획',
  },
];

const DataSlider = ({ currentSlide, setCurrentSlide }: any) => {
  const [startX, setStartX] = useState(0);
  const [translateX, setTranslateX] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prevSlide: any) =>
      prevSlide === slides.length - 1 ? 0 : prevSlide + 1,
    );
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide: any) =>
      prevSlide === 0 ? slides.length - 1 : prevSlide - 1,
    );
  };

  const handleTouchStart = (e: any) => {
    setStartX(e.touches[0].clientX);
  };

  const handleTouchMove = (e: any) => {
    const deltaX = e.touches[0].clientX - startX;
    setTranslateX(deltaX);
  };

  const handleTouchEnd = () => {
    if (translateX > 100) {
      prevSlide();
    } else if (translateX < -100) {
      nextSlide();
    }
    setTranslateX(0);
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      nextSlide();
    }, 3500);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="data-slider">
      <div
        className="data-slide"
        style={{ transform: `translateX(${translateX}px)` }}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <p className="text20">
          <span>{slides[currentSlide].category}</span> 데이터
        </p>
        <p className="text14">{slides[currentSlide].description}</p>
      </div>
      <div className="indicator">
        {slides.map((slide: any, index: number) => (
          <span
            key={index}
            className={
              index === currentSlide ? 'indicator-dot active' : 'indicator-dot'
            }
            onClick={() => setCurrentSlide(index)}
          />
        ))}
      </div>
    </div>
  );
};

const SliderTab = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const showSlide = (index: number) => {
    setCurrentSlide(index);
  };

  return (
    <div className="sliderTab">
      <div className="section06-text-block">
        {slides.map((slide, index) => (
          <div
            className="section06-block-box"
            key={index}
            onClick={() => showSlide(index)}
          >
            <div>
              <p className="text27 text-27-heavy">{slide.category}</p>
              <p className="text17">데이터</p>
            </div>
            <img src="/asset/images/arrow/Arrow-Bt-24.svg" alt="bottomArrow" />
          </div>
        ))}
        <div className="section06-block-box-middle">
          <img src="/asset/images/promotion/plus.svg" alt="plus" />
        </div>
      </div>
      <DataSlider
        currentSlide={currentSlide}
        setCurrentSlide={setCurrentSlide}
      />
    </div>
  );
};
export default SliderTab;
