import Request from '../../@api/request';

const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

// interface LoginData {
//   userId: string;
//   password: string;
// }

export class TermService {
  static UpdateList = async () => {
    const result = await request.get('/terms/date');
    return result.data;
  };
  static getTerms = async (regDate: string) => {
    const result = await request.get(`/terms/list?regDate=${regDate}`);
    return result.data;
  };
}
