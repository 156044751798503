export const jobOptions = [
  { value: 'W', label: '농업인' },
  { value: 'F', label: '귀농 희망자' },
  { value: 'N', label: '귀촌 희망자' },
  { value: 'E', label: '농산업기업체' },
  { value: 'O', label: '지자체 담당자' },
  { value: 'P', label: '정부부처(공공기관)' },
  { value: 'C', label: '농산물 유통' },
  { value: 'B', label: '부동산 중개' },
  { value: 'G', label: '기타(해당사항 없음)' },
];

export const genderOptions = [
  { value: 'W', label: '여성' },
  { value: 'M', label: '남성' },
];

export const areaOptions = [
  { value: 'S', label: '서울' },
  { value: 'B', label: '부산' },
  { value: 'D', label: '대구' },
];

export const areaOptions2 = [
  { value: '서울', label: '서울' },
  { value: '부산', label: '부산' },
  { value: '대구', label: '대구' },
  { value: '인천', label: '인천' },
  { value: '광주', label: '광주' },
  { value: '대전', label: '대전' },
  { value: '울산', label: '울산' },
  { value: '세종', label: '세종' },
  { value: '경기', label: '경기' },
  { value: '강원', label: '강원' },
  { value: '충북', label: '충북' },
  { value: '충남', label: '충남' },
  { value: '전북', label: '전북' },
  { value: '전남', label: '전남' },
  { value: '경북', label: '경북' },
  { value: '경남', label: '경남' },
  { value: '제주', label: '제주' },
];

export const cropOptions = [
  { value: 'S', label: '보리' },
  { value: 'S', label: '쌀' },
];

export const surveyCategory = [
  '신문/언론',
  '지인 추천',
  'SNS콘텐츠',
  '광고(인쇄 매체)',
  '광고(온라인매체)',
];
export const LinkText = [
  // "AI 농업 즐겨찾기",
  // "개인 정보",
  // "공지사항 및 FAQ",
  // "문의",
  '문의하기',
  '이용 약관',
  '개인정보 처리 방침',
];

export const FinalReportMenu = [
  '농가 매출 분석',
  '농가 비용 분석',
  '정부 지원금',
  '역경매',
  ' 수익성 검토',
  '현금 흐름',
  '상환 계획',
];

export const yearOption = ['1년', '2년', '3년', '4년'];
export const cashCurrent = [
  '매출',
  '운영비',
  '개보수비',
  '원리금 상환',
  '연 수익',
  '연 수익률',
];
export const year = [
  '1년차',
  '2년차',
  '3년차',
  '4년차',
  '5년차',
  '6년차',
  '7년차',
  '8년차',
  '9년차',
  '10년차',
];
export const plantingOptions = [
  { value: '1기작', label: '1기작' },
  { value: '2기작', label: '2기작' },
  { value: '3기작', label: '3기작' },
];
export const searchData = [
  '알림',
  '검색',
  'AI 농업',
  'AI 주택',
  // "운영정책",
  // "서비스",
  // "게시글",
  // "역경매",
];

export const yearOptions = [
  { value: '1940', label: '1940' },
  { value: '1941', label: '1941' },
  { value: '1942', label: '1942' },
  { value: '1943', label: '1943' },
  { value: '1944', label: '1944' },
  { value: '1945', label: '1945' },
  { value: '1946', label: '1946' },
  { value: '1947', label: '1947' },
  { value: '1948', label: '1948' },
  { value: '1949', label: '1949' },
  { value: '1950', label: '1950' },
  { value: '1951', label: '1951' },
  { value: '1952', label: '1952' },
  { value: '1953', label: '1953' },
  { value: '1954', label: '1954' },
  { value: '1955', label: '1955' },
  { value: '1956', label: '1956' },
  { value: '1957', label: '1957' },
  { value: '1958', label: '1958' },
  { value: '1959', label: '1959' },
  { value: '1960', label: '1960' },
  { value: '1961', label: '1961' },
  { value: '1962', label: '1962' },
  { value: '1963', label: '1963' },
  { value: '1964', label: '1964' },
  { value: '1965', label: '1965' },
  { value: '1966', label: '1966' },
  { value: '1967', label: '1967' },
  { value: '1968', label: '1968' },
  { value: '1969', label: '1969' },
  { value: '1970', label: '1970' },
  { value: '1971', label: '1971' },
  { value: '1972', label: '1972' },
  { value: '1973', label: '1973' },
  { value: '1974', label: '1974' },
  { value: '1975', label: '1975' },
  { value: '1976', label: '1976' },
  { value: '1977', label: '1977' },
  { value: '1978', label: '1978' },
  { value: '1979', label: '1979' },
  { value: '1980', label: '1980' },
  { value: '1981', label: '1981' },
  { value: '1982', label: '1982' },
  { value: '1983', label: '1983' },
  { value: '1984', label: '1984' },
  { value: '1985', label: '1985' },
  { value: '1986', label: '1986' },
  { value: '1987', label: '1987' },
  { value: '1988', label: '1988' },
  { value: '1989', label: '1989' },
  { value: '1990', label: '1990' },
  { value: '1991', label: '1991' },
  { value: '1992', label: '1992' },
  { value: '1993', label: '1993' },
  { value: '1994', label: '1994' },
  { value: '1995', label: '1995' },
  { value: '1996', label: '1996' },
  { value: '1997', label: '1997' },
  { value: '1998', label: '1998' },
  { value: '1999', label: '1999' },
  { value: '2000', label: '2000' },
  { value: '2001', label: '2001' },
  { value: '2002', label: '2002' },
  { value: '2003', label: '2003' },
  { value: '2004', label: '2004' },
  { value: '2005', label: '2005' },
  { value: '2006', label: '2006' },
  { value: '2007', label: '2007' },
  { value: '2008', label: '2008' },
  { value: '2009', label: '2009' },
  { value: '2010', label: '2010' },
  { value: '2011', label: '2011' },
  { value: '2012', label: '2012' },
  { value: '2013', label: '2013' },
  { value: '2014', label: '2014' },
  { value: '2015', label: '2015' },
  { value: '2016', label: '2016' },
  { value: '2017', label: '2017' },
  { value: '2018', label: '2018' },
  { value: '2019', label: '2019' },
  { value: '2020', label: '2020' },
  { value: '2021', label: '2021' },
  { value: '2022', label: '2022' },
  { value: '2023', label: '2023' },
];

export const chart2data = [
  {
    name: '2017',
    양력: 20,
    음력: 35,
  },
  {
    name: '2018',
    양력: 20,
    음력: 35,
  },
  {
    name: '2019',
    양력: 20,
    음력: 35,
  },
  {
    name: '2020',
    양력: 20,
    음력: 35,
  },
  {
    name: '2021',
    양력: 20,
    음력: 35,
  },
  {
    name: '2022',
    양력: 10,
    음력: 35,
  },
  {
    name: '2023',
    양력: 35,
    음력: 35,
  },
];

export const dummyCost = [
  { title: '토지 매입비', state: 'landPurchaseCost' },
  { title: '시설 구축비', state: 'facilityCost' },
  { title: '농기계 구입비', state: 'farmMachineryCost' },
];

export const dummyCost2 = [
  { title: '인건비', state: 'laborCost' },
  { title: '재료비', state: 'materialCost' },
];

export const dummyCost3 = [
  { title: '과수원 조성비', state: 'orchardCost' },
  { title: '수도 광열비', state: 'waterHeatingCost' },
  { title: '농구비', state: 'farmEquipmentCost' },
  { title: '농지 임차료', state: 'landRentalCost' },
  { title: '기타 판매관리비', state: 'SGA' },
];
