import { useEffect, useRef, useState } from 'react';
import Slider2 from '@mui/material/Slider';
import { isMobile } from '../../component/function/function';
export const Carousel = ({ scroll }: any) => {
  const [isDrag, setIsDrag] = useState(false);

  const ofScrollRef = useRef<HTMLDivElement>(null); // "of-scroll" 클래스가 적용된 요소에 대한 ref
  const imageRefs = [
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
    useRef<HTMLDivElement>(null),
  ];
  const [scrollXPosition, setScrollXPosition] = useState(0);
  const [imgIdx, setImgIdx] = useState(0);

  const imageList = [
    '/asset/images/profile/carouselImg1.svg',
    '/asset/images/profile/carouselImg2.svg',
    '/asset/images/profile/carouselImg3.svg',
  ];
  const imageListBrowser = [
    '/asset/images/profile/carouselImg_browser_1.svg',
    '/asset/images/profile/carouselImg_browser_2.svg',
    '/asset/images/profile/carouselImg_browser_3.svg',
  ];

  const [scrollableDistance, setScrollableDistance] = useState(0);
  useEffect(() => {
    const ofScrollContainer = ofScrollRef.current;

    if (!ofScrollContainer) {
      return;
    }

    const handleScroll = () => {
      const distance =
        ofScrollContainer.scrollWidth - ofScrollContainer.clientWidth;
      setScrollableDistance(distance);
      console.log('distance', distance);
      const currentScrollX = ofScrollContainer.scrollLeft || 0;
      setScrollXPosition(currentScrollX);
      console.log('scroll', currentScrollX);
      updateImgIdx(currentScrollX, distance);
    };

    const updateImgIdx = (currentScrollX: any, distance: any) => {
      let imgWidth = 296;
      let gap = 16;

      if (distance) {
        if (currentScrollX / distance <= 1 / 3) {
          setImgIdx(0);
        } else if (currentScrollX / distance <= 2 / 3) {
          setImgIdx(1);
        } else if (currentScrollX / distance <= 3 / 3) {
          setImgIdx(2);
        }
      }
    };

    handleScroll();

    ofScrollContainer.addEventListener('scroll', handleScroll);

    // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
    return () => {
      ofScrollContainer.removeEventListener('scroll', handleScroll);
    };
  }, [ofScrollRef]);
  return (
    <div
      className={`flex-column w-100per ${scroll > 312 && `fade-in-up`}`}
      style={{ alignItems: window.innerWidth > 1000 ? 'center' : '' }}
    >
      <div
        className="flex-row of-scroll gap-16 browser-gap-30"
        ref={ofScrollRef}
      >
        {imageList.map((image, index) => (
          // <div
          //   key={index}
          //   ref={imageRefs[index]}
          //   draggable={true}
          //   onTouchStart={(e) => {
          //     setIsDrag(true);
          //   }}
          //   onTouchEnd={() => {
          //     setIsDrag(false);
          //   }}
          // >
          //   <img
          //     className="w-296 h-364 browser-w-380 browser-h-433"
          //     src={image}
          //   />
          // </div>
          <div
            key={index}
            ref={imageRefs[index]}
            draggable={true}
            onTouchStart={(e) => {
              setIsDrag(true);
            }}
            onTouchEnd={() => {
              setIsDrag(false);
            }}
            className="flex-column w-296 h-364 browser-w-380 browser-h-433"
          >
            <img
              className="border-radius-t-l-8 border-radius-t-r-8"
              src={
                index === 0
                  ? '/asset/images/profile/carouselImg1.png'
                  : index === 1
                    ? '/asset/images/profile/carouselImg2.png'
                    : '/asset/images/profile/carouselImg3.png'
              }
            />
            <div className="padding-32 gap-16 flex-column border-radius-b-l-8 border-radius-b-r-8 border-1-171E26 h-100per">
              <div className="font-bold f-size-16 line-h-24 color-dark-primary text-center">
                {index === 0
                  ? 'TRANSFARMER'
                  : index === 1
                    ? 'EASY FARMING'
                    : 'AI FARMING'}
              </div>
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary text-center">
                {index === 0
                  ? '디지털 트랜스포머(Digital Transformer)와 파머(Farmer)를 결합한 브랜드입니다.'
                  : index === 1
                    ? '농업 입문자부터 전문가까지, 맞춤형 영농 정보를 얻을 수 있는 체계적인 시스템을 제공합니다.'
                    : '빠르게 늙어가는 농촌을 위해서는 ‘열심히, 그리고 잘하는’ 현대화된 영농 시스템이 필요합니다. 농업에도 인공지능(AI)이 접목돼야 하는 이유입니다.'}
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* window.innerWidth > 498 && scrollXPosition > 350 */}
      {window.innerWidth < 1000 && (
        <>
          {' '}
          <Slider2
            value={(scrollXPosition / scrollableDistance) * 100}
            onChange={(e: any) => {
              ofScrollRef.current?.scrollTo({
                left: (e.target.value / 100) * scrollableDistance,
                behavior: 'smooth',
              });
            }}
            size="small"
            aria-label="Small"
            valueLabelDisplay="auto"
            sx={{
              marginTop: '30px',
              height: '3px',
              '& .MuiSlider-thumb': {
                display: 'none', // Hide the Thumb
              },
              '& .MuiSlider-track': { color: 'black' },
              '& .MuiSlider-rail': { color: '#F0F0F0' },

              '& .Mui-active': {
                boxShadow: '#1E9B6B30 0px 0px 0px 12px',
              },
              '& .MuiSlider-valueLabel': { background: 'none' },
              '& .MuiSlider-mark': {
                backgroundColor: '#FFFFFF',
                height: 8,
              },
            }}
          />
          <div className="end-x flex w-100per mt-10">
            <div className="flex mt-16 gap-10">
              <img
                className="pointer"
                src="/asset/images/arrow/ArrowWithCircleLeft.svg"
                onClick={() => {
                  if (imgIdx <= 1) {
                    setImgIdx(0);
                    ofScrollRef.current?.scrollTo({
                      left: 0,
                      behavior: 'smooth',
                    });
                  } else {
                    setImgIdx(1);
                    ofScrollRef.current?.scrollTo({
                      left: scrollableDistance / 2,
                      behavior: 'smooth',
                    });
                  }
                }}
              ></img>
              <img
                className="pointer"
                src="/asset/images/arrow/ArrowWithCircleRight.svg"
                onClick={() => {
                  if (imgIdx >= 1) {
                    setImgIdx(2);
                    ofScrollRef.current?.scrollTo({
                      left: scrollableDistance,
                      behavior: 'smooth',
                    });
                  } else {
                    setImgIdx(1);
                    ofScrollRef.current?.scrollTo({
                      left: scrollableDistance / 2,
                      behavior: 'smooth',
                    });
                  }
                }}
              ></img>
            </div>
          </div>
        </>
      )}
    </div>
  );
};
