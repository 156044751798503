import { useState } from 'react';
import { RowTable6 } from '../../../component/report/Row';
import { TriangleTooltip } from '../../../component/common/tooltip';

interface data {
  address: string;
  cropName: string;
  crops: number;
  facility: string;
  farmArea: number;
  quantity: number;
  type: string;
  category: string;
}

const ReportInfo = ({
  data,
  data2,
  time,
  mypage,
}: {
  data: data;
  data2: any;
  time?: string;
  mypage?: boolean;
}) => {
  const [areaType, setAreaType] = useState('P');
  const [dateTooltip, setDateTooltip] = useState(true);
  const facilityCode = {
    O: '노지',
    P: '비닐하우스',
    S: '스마트팜',
    W: '기후맞춤스마트팜',
  };

  return (
    <div className="padding-24-16 gap-16 flex-column relative">
      <RowTable6
        title="분석일"
        value={
          time && time.split('T')[0] + ' ' + time.split('T')[1].split('.')[0]
        }
      />
      <RowTable6 title="주소" value={data.address} />
      <div className="flex-row gap-24">
        <RowTable6
          title="재배 면적"
          value={
            <div className="font-medium f-size-16 line-h-24 color-text-secondary flex-row gap-3">
              {data.farmArea &&
                Math.round(
                  data.farmArea * (areaType === 'P' ? 0.3025 : 1),
                ).toLocaleString()}
              {areaType === 'P' ? '평' : 'm²'}

              <div
                className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                onClick={() =>
                  setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                }
              >
                <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                {areaType === 'P' ? 'm²' : '평'}
              </div>
            </div>
          }
        />
      </div>
      <div className="flex-row gap-24">
        <RowTable6 title="지목" value={data.category} />
        <RowTable6 title="기작" value={data.crops + '기작'} />
      </div>
      {mypage && dateTooltip && (
        <TriangleTooltip
          text="분석 시점의 결과는 현재와 다를 수 있어요."
          text2="최신 분석 결과는 주소지 조회 후 다시 이용해 주세요."
          top="30"
          left="50"
          on={true}
          off={() => setDateTooltip(false)}
          xImg={true}
        />
      )}
    </div>
  );
};

export default ReportInfo;
