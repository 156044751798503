import Button from '../../../component/mobile/Button';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../utilities/auth';
import UserService from '../../../service/checkAccount/CheckAccount';
import SignController from '../../../controller/sign/SignController';
import './edit.css';

export const EditPopUp = () => {
  const [onPopUp, setOnPopUp] = useState(false);
  const navigate = useNavigate();

  return (
    <div
      id="post-meta-col"
      className="flex-column fixed h-100per z-9999 browser-flex-center w-100per flex-center"
      style={{
        backgroundColor: '#00000060',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <div className="bg-FFFFFF padding-24-16-16-16 border-radius-16 gap-12 w-312 flex-center flex-column">
        <div className="flex-column gap-24 w-100per">
          <div className="flex-column gap-12 flex-center">
            <img
              className="w-48 h-48 "
              alt="check"
              src="/asset/images/check/change_info_check.svg"
            />
          </div>
          <div className="flex-column gap-8 flex-center">
            <div className="m-b1-16-b">정보 수정 완료</div>
            <div className="editSuccessText">정보 수정이 완료 되었습니다.</div>
          </div>
          <div className="p flex w-100per gap-8">
            <Button
              on={true}
              description="확인"
              onClick={() => {
                navigate(-0);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
