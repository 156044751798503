import { useEffect, useRef, useState } from 'react';
import { TopNav2, TopNavPointBrowser } from '../../component/mobile/Topnav';
import Button from '../../component/mobile/Button';
import { PointPopUp } from './Popup';
import { useLocation, useNavigate } from 'react-router-dom';
import { PopUp2 } from './PopUp2';
import cookie from 'react-cookies';
import { PopUp3 } from './PopUp3';
import { isMobile } from '../../component/function/function';
import UserService from '../../service/checkAccount/CheckAccount';
interface Type {
  index: number;
  text1?: string;
  text2?: string;
  text3?: string;
  text4?: string;
  type?: number;
  month?: number;
}
const Recharge = () => {
  const navigate = useNavigate();
  const [point, setPoint] = useState(0);
  const temp = useRef<HTMLDivElement>(null);
  const temp2 = useRef<HTMLDivElement>(null);
  const [open, setOpen] = useState(false);
  const [onPopUp, setOnPopUp] = useState(
    cookie.load('noSee')
      ? cookie.load('noSee') === 'nosee'
        ? false
        : true
      : true,
  );

  const [tab, setTab] = useState(1); //충전형:1 기간형:2
  const [chargeAmountTemp, setChargetAmountTemp] = useState(50000); //price Number타입
  const [priceTab, setPriceTab] = useState(2); // 충전형 4가지 선택
  const [priceTab2, setPriceTab2] = useState(0); // 기간형 4가지 선택
  const [chargeAmount, setChargeAmount] = useState<number | string>(''); //숫자4자리 컴마표시 price
  const [hover, setHover] = useState(false);
  const date = new Date();
  const [newDate, setNewDate] = useState(new Date());
  const navigateHandler = () => {
    if (tab === 1 && priceTab === 0) {
      if (chargeAmountTemp === 0) {
        alert('0포인트는 충전하실 수 없습니다');
      } else if (chargeAmountTemp % 1000 !== 0) {
        alert('1000원 단위로 충전 가능합니다');
      } else {
        navigate('/point/recharge/payment', {
          state: { point: chargeAmountTemp, type: 1, ownPoint: point },
        });
      }
    } else {
      navigate('/point/recharge/payment', {
        state: { point: chargeAmountTemp, type: 1, ownPoint: point },
      });
    }
  };

  const changeAmount = (e: any) => {
    setPriceTab(0);
    setChargetAmountTemp(0);

    const newValue = e.target.value;
    const formattedValue = Number(newValue.replace(/\D/g, '')).toLocaleString();
    setChargeAmount(formattedValue);
  };
  // 포인트 확인 api
  const CheckPoint = async () => {
    try {
      const result = await UserService.checkPoint();
      console.log(result);
      setPoint(result.data.data.freePoint + result.data.data.paidPoint);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    console.log(temp.current?.offsetHeight);
  }, []);
  useEffect(() => {
    CheckPoint();
    if (temp.current) {
      // 페이지 초기 렌더링 시에만 paddingBottom 설정
      // document.body.style.paddingBottom = `${temp.current.offsetHeight}px`;
      if (!isMobile(window.innerWidth)) {
        document.body.style.backgroundColor = '#F0F0F0';
      }
    }

    // cleanup 함수
    return () => {
      // document.body.style.paddingBottom = ""; // 이전에 설정한 paddingBottom을 초기화
      document.body.style.backgroundColor = '';
    };
  }, []);
  useEffect(() => {
    setChargetAmountTemp(Number(chargeAmount.toString().replace(/,/g, '')));
  }, [chargeAmount]);

  const [onPopUp2, setOnPopUp2] = useState(false);

  const ChargeCol = ({
    index,
    text1,
    text2,
    text3,
    text4,
    type,
    month,
  }: Type) => (
    <div
      className={`padding-8-16 flex-col border-radius-16 pointer ${
        type === 1
          ? priceTab === index
            ? `border-2-primary-400`
            : `border-1-171E26`
          : priceTab2 === index
            ? `border-2-primary-400`
            : `border-1-171E26`
      }`}
      onClick={() => {
        type === 1 ? setPriceTab(index) : setPriceTab2(index);
        getCurrentDatePlusMonths(month || 0);
        // setChargetAmountTemp(0);
        // setChargeAmount(0);
      }}
    >
      <div className="flex-row space-between padding-12-0 w-100per center-y">
        <div className="flex-row gap-8 w-70per center-y">
          <img
            alt=""
            src={
              type === 1
                ? priceTab === index
                  ? '/asset/images/check/Check24GreenCircle3.svg'
                  : '/asset/images/check/check_off.svg'
                : priceTab2 === index
                  ? '/asset/images/check/Check24GreenCircle3.svg'
                  : '/asset/images/check/check_off.svg'
            }
          />
          <div className="m-b1-16-m w-b2-16-m color-text-primary no-wrap">
            {text1}
          </div>
        </div>
        <div className="flex-row gap-8">
          <div className="m-t1-20-b w-t3-20-b color-text-increase">{text3}</div>
          <div className="flex-column end-y">
            <div className="m-t1-20-b w-t3-20-b color-text-primar no-wrap">
              {text2}
            </div>
            <div className="m-b2-14-m color-text-secondary line-through">
              {text4}
            </div>
          </div>
        </div>
      </div>
      {tab === 2 && index === priceTab2 && (
        <div className="flex-row space-between">
          <div className="m-b2-14-m w-c1-14-m color-disabled">이용기간</div>
          <div className="m-b2-14-m w-c1-14-m color-text-tertiary">
            {`${date.getFullYear()}.${date.getMonth() + 1}.${date.getDate()}`}~
            {newDate.toLocaleDateString().replace(/ /g, '').slice(0, -1)}
          </div>
        </div>
      )}
    </div>
  );

  const ServicePrice = ({ menu, price }: any) => (
    <div className="w-100per flex-row space-between padding-b-4 border-b-disabled">
      <div className="font-medium f-size-14 line-h-20 color-white-secondary">
        {menu}
      </div>
      <div className="font-medium f-size-14 line-h-20 color-FFFFFF">
        {price.toLocaleString()}원
      </div>
    </div>
  );

  const getCurrentDatePlusMonths = (addedMonths: number) => {
    const updatedDate = new Date();
    updatedDate.setMonth(updatedDate.getMonth() + addedMonths);

    console.log(updatedDate);

    setNewDate(updatedDate); // 상태 업데이트
    return updatedDate;
  };
  useEffect(() => {
    if (priceTab === 1) {
      setChargetAmountTemp(10000);
    } else if (priceTab === 2) {
      setChargetAmountTemp(50000);
    } else if (priceTab === 3) {
      setChargetAmountTemp(100000);
    } else if (priceTab === 4) {
      setChargetAmountTemp(200000);
    }
  }, [priceTab]);

  return (
    <div className="w-100per flex-column  browser-flex-center bg-F0F0F0 ">
      <div className="flex-column  w-1024-max-w-768 w-100per bg-FFFFFF">
        <TopNav2 back text="충전소" hamburger />

        {/* {onPopUp && (
          <PopUp2 setOnPopUp={setOnPopUp} setOnPopUp2={setOnPopUp2} />
        )} */}
        {onPopUp2 && <PopUp3 setOnPopUp2={setOnPopUp2} />}
        <PointPopUp open={open} setOpen={setOpen} />
        <div className="flex-column ">
          {sessionStorage.getItem('searchPointRecharge') === 'Y' ? (
            <TopNavPointBrowser text="충전소" close search />
          ) : (
            <TopNavPointBrowser text="충전소" close type="recharge" />
          )}
          <div className="w-100per bg-light-gray flex-row padding-14-16 w-1024-padding-14-40 gap-10">
            <div className="flex-row space-between w-100per">
              <div className="flex-row gap-8 center-y">
                <img src="/asset/images/icon/point_icon.svg" alt="" />
                <div className="m-b1-16-m w-b2-16-m line-h-20 color-secondary">
                  현재 보유한 포인트
                </div>
                <div className="m-b1-16-b w-b2-16-b color-text-primary">
                  {point.toLocaleString()}P
                </div>
              </div>
              <div
                className="m-b1-16-m w-b2-16-m color-disabled pointer underline"
                onClick={() => {
                  isMobile(window.innerWidth)
                    ? navigate('/point')
                    : setOnPopUp2(true);
                }}
              >
                포인트 안내
              </div>
            </div>
          </div>
          <div
            className="padding-16 flex w-100per w-1024-padding-16-40"
            style={{ top: '100px' }}
          >
            <div
              className={`flex-row border-radius-8 border-1-171E26 w-100per bg-light-gray-200`}
            >
              <div
                className={`w-50per flex-center border-radius-t-l-8 border-radius-b-l-8 padding-6-0 pointer ${
                  tab === 1
                    ? `border-radius-t-r-8 border-radius-b-r-8 border-1-171E26 bg-FFFFFF`
                    : `bg-light-gray-200`
                }`}
                onClick={() => {
                  setTab(1);
                }}
              >
                <div
                  className={`m-b1-16-m w-b1-18-m line-h-24 ${
                    tab === 1 ? `color-text-primary` : `color-disabled`
                  }`}
                >
                  충전형
                </div>
              </div>
              <div
                className={`w-50per flex-center border-radius-t-r-8 border-radius-b-r-8 padding-6-0 relative pointer ${
                  tab === 2
                    ? `border-radius-t-l-8 border-radius-b-l-8 border-1-171E26 bg-FFFFFF`
                    : `bg-light-gray-200`
                }`}
                onClick={() => {
                  alert('준비중입니다');
                  // setTab(2);
                }}
              >
                <div
                  className={`m-b1-16-m w-b1-18-m ${
                    tab === 2 ? `color-text-primary` : `color-disabled`
                  }`}
                >
                  기간형
                </div>
                {/* <div
                  ref={temp2}
                  className="absolute bg-increase flex-center border-radius-11 padding-4-8"
                  style={{ top: "-10px", right: "-10px" }}
                >
                  <div className="font-medium f-size-12 line-h-14 color-FFFFFF">
                    추천
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          {tab === 1 ? (
            <div>
              <div className="flex-column gap-16 padding-16 w-1024-padding-16-40">
                <div className="m-b2-14-m color-dark-secondary w-b2-16-m  flex-row space-between">
                  <span>충전 금액을 선택해 주세요.</span>
                  <span className="font-medium f-size-12 color-disabled">
                    (전체 금액 : VAT 포함)
                  </span>
                </div>
                <ChargeCol
                  index={1}
                  text1="10,000P"
                  text2="10,000원"
                  type={1}
                />
                <ChargeCol
                  index={2}
                  text1="50,000P"
                  text2="50,000원"
                  type={1}
                />
                <ChargeCol
                  index={3}
                  text1="100,000P"
                  text2="100,000원"
                  type={1}
                />
                <ChargeCol
                  index={4}
                  text1="200,000P"
                  text2="200,000원"
                  type={1}
                />
                <div className="w-100per  padding-16 bg-F7F8F9 border-radius-8 flex-column gap-8">
                  <span className="font-bold f-size-12 color-text-tertiary">
                    유의 사항
                  </span>
                  <div className="flex-row gap-8 flex-start">
                    <div
                      style={{
                        width: '2px',
                        height: '2px',
                        borderRadius: '50px',
                        backgroundColor: 'rgba(23, 30, 38, 0.4)',
                        boxSizing: 'border-box',
                        marginTop: '6px',
                      }}
                    ></div>
                    <p className="f-size-12 font-medium color-disabled">
                      충전하신 유료 포인트는 충전하신 날로부터 5년 이내에
                      소진해야 합니다.
                    </p>
                  </div>
                  <div className="flex-row gap-8 flex-start">
                    <div
                      style={{
                        width: '2px',
                        height: '2px',
                        borderRadius: '50px',
                        backgroundColor: 'rgba(23, 30, 38, 0.4)',
                        boxSizing: 'border-box',
                        marginTop: '6px',
                      }}
                    ></div>
                    <p className="f-size-12 font-medium color-disabled">
                      충전 취소 및 환불에 대한 자세한 사항은 환불 안내 페이지를
                      확인해 주세요.{' '}
                    </p>
                  </div>
                </div>
              </div>

              {isMobile(window.innerWidth) ? (
                <div className="flex-center w-100per padding-16 ">
                  <div
                    style={{ backgroundColor: '#DCF4E9' }}
                    className="w-100per flex-center border-radius-8"
                  >
                    <img
                      onClick={() => {
                        isMobile(window.innerWidth)
                          ? navigate('/point')
                          : setOnPopUp2(true);
                      }}
                      alt=""
                      style={{ width: `${328}px`, height: '82px' }}
                      className={`border-radius-8  pointer`}
                      src="/asset/images/banner/point_banner3.svg"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-center w-100per padding-16 ">
                  {' '}
                  <img
                    onClick={() => {
                      isMobile(window.innerWidth)
                        ? navigate('/point')
                        : setOnPopUp2(true);
                    }}
                    alt="banner"
                    style={{
                      width: isMobile(window.innerWidth) ? '328px' : `688px`,
                      height: '82px',
                    }}
                    className={`border-radius-8  pointer`}
                    src={
                      isMobile(window.innerWidth)
                        ? '/asset/images/banner/point_banner3.svg'
                        : '/asset/images/banner/point_banner3_web.svg'
                    }
                  />
                </div>
              )}
              <div
                ref={temp}
                className="padding-16-16-16-70 sticky  flex w-100per bg-FFFFFF w-1024-max-w-768 w-1024-16-40-48-40"
                style={{ bottom: '0px', borderTop: '1px solid 171E26' }}
              >
                <Button
                  on={
                    priceTab === 0 &&
                    (chargeAmount === 0 ||
                      chargeAmountTemp === 0 ||
                      chargeAmountTemp % 1000 !== 0)
                      ? false
                      : true
                  }
                  description="충전하기"
                  onClick={() => {
                    // setOpen(true);

                    // priceTab === 0 &&
                    // (chargeAmountTemp % 1000 !== 0 || !(chargeAmount === 0))
                    //   ? alert("포인트를 확인해주세요")
                    //   : navigate("/point/recharge/payment", {
                    //       state: { point: chargeAmountTemp, type: 1 },
                    //     });
                    navigateHandler();
                  }}
                />
              </div>
            </div>
          ) : (
            <div>
              <div className="flex-column gap-16 padding-16 w-1024-padding-16-40 ">
                <div className="m-b2-14-m color-dark-secondary w-b2-16-m">
                  이용권 이용기간을 선택해 주세요.
                </div>
                <ChargeCol
                  text1="1개월"
                  text2="200,000원"
                  index={1}
                  type={2}
                  month={1}
                />
                <ChargeCol
                  text1="6개월"
                  text2="249,000원"
                  index={2}
                  text3="17%"
                  text4="300,000원"
                  type={2}
                  month={6}
                />
                <ChargeCol
                  text1="12개월"
                  text2="449,000원"
                  index={3}
                  text3="25%"
                  text4="600,000원"
                  type={2}
                  month={12}
                />
              </div>
              {isMobile(window.innerWidth) ? (
                <div className="flex-center w-100per padding-16 ">
                  <div
                    style={{ backgroundColor: '#DCF4E9' }}
                    className="w-100per flex-center border-radius-8"
                  >
                    <img
                      onClick={() => {
                        isMobile(window.innerWidth)
                          ? navigate('/point')
                          : setOnPopUp2(true);
                      }}
                      alt=""
                      style={{
                        width: isMobile(window.innerWidth) ? '328px' : `688px`,
                        height: '82px',
                      }}
                      className={`border-radius-8  pointer`}
                      src={
                        isMobile(window.innerWidth)
                          ? '/asset/images/banner/point_banner3.svg'
                          : '/asset/images/banner/point_banner3_web.svg'
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="flex-center w-100per padding-16 ">
                  <img
                    onClick={() => {
                      isMobile(window.innerWidth)
                        ? navigate('/point')
                        : setOnPopUp2(true);
                    }}
                    alt=""
                    style={{
                      width: isMobile(window.innerWidth) ? '328px' : `688px`,
                      height: '82px',
                    }}
                    className="border-radius-8  pointer            "
                    src={
                      isMobile(window.innerWidth)
                        ? '/asset/images/banner/point_banner4.svg'
                        : '/asset/images/banner/point_banner4_web.svg'
                    }
                  />
                </div>
              )}
              <div
                ref={temp}
                className="padding-16-16-16-70  flex w-100per bg-FFFFFF w-1024-max-w-768 w-1024-16-40-48-40"
                style={{ bottom: '0px', borderTop: '1px solid 171E26' }}
              >
                <Button
                  on={priceTab2 !== 0}
                  description={
                    priceTab2 !== 0 ? '결제하기' : '이용권을 선택해주세요'
                  }
                  onClick={() => {
                    tab === 2 && priceTab2 !== 0
                      ? navigate('/point/recharge/payment', {
                          state: {
                            type: 2,
                            period: newDate
                              .toLocaleDateString()
                              .replace(/ /g, '')
                              .slice(0, -1),
                            month:
                              priceTab2 === 1
                                ? 1
                                : priceTab2 === 2
                                  ? 6
                                  : priceTab2 === 3
                                    ? 12
                                    : 0,
                            price:
                              priceTab2 === 1
                                ? 200000
                                : priceTab2 === 2
                                  ? 249000
                                  : priceTab2 === 3
                                    ? 449000
                                    : 0,
                            originalPrice:
                              priceTab2 === 1
                                ? 200000
                                : priceTab2 === 2
                                  ? 300000
                                  : priceTab2 === 3
                                    ? 600000
                                    : 0,
                            sale:
                              priceTab2 === 2 ? 17 : priceTab2 === 3 ? 25 : 0,
                          },
                        })
                      : alert('이용기간을 선택해주세요.');
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Recharge;
