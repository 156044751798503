import { set } from 'lodash';
import { Title } from './Content';

const FacilitySelect = ({
  facility,
  setFacility,
  area,
  setArea,
  setAreaType,
}: any) => {
  return (
    <div className="flex-column w-100per report_result_box bg-white gap-8 padding-b-16">
      <Title text="1. 재배환경" />
      <div className="flex-row w-100per gap-12  padding-x-16">
        <div
          className={`w-33per flex-center padding-12-12-12-8 border-radius-8 pointer ${
            facility === '노지' ? `bg-primary-600` : `bg-light-gray-200`
          }`}
          onClick={() => {
            setFacility('노지');
          }}
        >
          <div
            className={`font-bold f-size-14 line-h-20 ${
              facility === '노지' ? `color-white` : `color-disabled`
            }`}
          >
            노지
          </div>
        </div>
        <div
          className={`w-33per flex-center padding-12-12-12-8 border-radius-8 pointer ${
            facility === '시설' ? `bg-primary-600` : `bg-light-gray-200`
          }`}
          onClick={() => {
            if (area >= 250) {
              setFacility('시설');
            } else {
              alert('250평 이상부터 가능합니다.');
            }
          }}
        >
          <div
            className={`font-bold f-size-14 line-h-20 ${
              facility === '시설' ? `color-white` : `color-disabled`
            }`}
          >
            비닐하우스
          </div>
        </div>
        <div
          className={`w-33per flex-center padding-12-12-12-8 border-radius-8 pointer ${
            facility === '스마트팜' ? `bg-primary-600` : `bg-light-gray-200`
          }`}
          onClick={() => {
            if (area >= 250) {
              setFacility('스마트팜');
            } else {
              alert('250평 이상부터 가능합니다.');
            }
          }}
        >
          <div
            className={`font-bold f-size-14 line-h-20 ${
              facility === '스마트팜' ? `color-white` : `color-disabled`
            }`}
          >
            스마트팜
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacilitySelect;
