import './Detail.css';
import { RowSectionTitle, RowTable5 } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';

const UsageArea = ({ data, house }: { data: any; house?: any }) => {
  return (
    <div className="browser-bg-FFFFFF w-100per">
      <div style={{ height: '16px', background: '#F7F8F9' }}></div>
      <RowSectionTitle>
        <TextSectionTitle>
          <div className="flex-row space-between w-100per">용도 지역</div>
        </TextSectionTitle>
      </RowSectionTitle>
      <div className="flex-column">
        <div className="padding-24-16 gap-16 flex-column">
          <div className="flex-row gap-24">
            <RowTable5
              title="가능 건폐율"
              value={`${
                house
                  ? data.stdBuildingToLandRatio
                  : data.buildingRate
                    ? data.buildingRate.BCR_rate
                    : '-'
              }%`}
            />
            <RowTable5
              title="가능 용적률"
              value={`${
                house
                  ? data.stdFloorAreaRatio
                  : data.buildingRate
                    ? data.buildingRate.FAR_rate
                    : '-'
              }%`}
            />
          </div>
          <div
            className="flex-row space-between"
            style={{
              paddingBottom: '8px',
              borderBottom: '1px solid #171e2610',
            }}
          >
            <span
              className="font-medium f-size-16"
              style={{ color: '#171e268f' }}
            >
              국토 계획법 및 조례
            </span>
            <span
              className="flex-row gap-4 end-x  center-y hover gap-2 decoration-none"
              onClick={() => {
                window.open(
                  `https://www.eum.go.kr/web/ar/lu/luLandDet.jsp?selSido=11&selSgg=470&selUmd=0102&selRi=00&landGbn=1&bobn=711&bubn=1&sggcd=&isNoScr=script&mode=search&selGbn=umd&s_type=1&add=land&pnu=${
                    data && data.pnuCode
                  }`,
                );
              }}
            >
              <p className="font-medium f-size-12 line-h-14 color-text-secondary">
                토지이용계획열람 바로가기
              </p>
              <img src="/asset/images/arrow/ArrowRight16.svg" alt="arrow" />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsageArea;
