import { useEffect, useState } from 'react';
import { RowSectionTitle, RowTable6 } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import UserService from '../../../../service/checkAccount/CheckAccount';
import { transNum } from '../../../../component/function/function';
import RowCom from '../../../../component/report/Row';
import { BoxGreen1 } from '../../../../component/report/Box';
import SignController from '../../../../controller/sign/SignController';

const ReportDetail3 = ({ data, data2 }: any) => {
  const [highRate, setHighRate]: any = useState([]);

  var regex = /[^0-9]/g;

  const soilData = {
    논적성등급: 6 - Number(data.soilResult.rfldGrd.replace(regex, '')),
    밭적성등급: 6 - Number(data.soilResult.pfldGrd.replace(regex, '')),
    과수상전적성등급: 6 - Number(data.soilResult.fruitGrd.replace(regex, '')),
    초지적성등급: 6 - Number(data.soilResult.pastureGrd.replace(regex, '')),
    임지적성등급: 6 - Number(data.soilResult.frstGrd.replace(regex, '')),
  };
  useEffect(() => {
    let sArr: any = [];
    let arr: any = [];
    let max = 0;
    let isThree = 0;
    for (let i = 0; i < Object.keys(soilData).length; i++) {
      if (Object.values(soilData)[i] > max) {
        max = Object.values(soilData)[i] || max;
        arr = ['N', Object.keys(soilData)[i].split('적성등급')[0]];
      } else if (Object.values(soilData)[i] === max) {
        arr.push(Object.keys(soilData)[i].split('적성등급')[0]);
      }
      if (Object.values(soilData)[i] >= 3) {
        isThree++;
        sArr.push(Object.keys(soilData)[i].split('적성등급')[0]);
      }
    }
    if (isThree > 0) {
      setHighRate(sArr);
    } else {
      setHighRate(arr);
    }
  }, [data.soilResult]);

  return (
    <div className="padding-b-24">
      <RowSectionTitle>
        <TextSectionTitle>3. 토양</TextSectionTitle>
      </RowSectionTitle>
      <div className="flex-column">
        <div className="padding-24-16 gap-16 flex-column">
          <div className="flex-row gap-24">
            <RowTable6 title="경사" value={data.soilResult.soilslope} />
            <RowTable6 title="배수등급" value={data.soilResult.soildra} />
          </div>
          <div className="flex-row gap-24">
            <RowTable6 title="토양추천" value={data.soilResult.soilUseRec} />
            <RowTable6 title="침식등급" value={data.soilResult.erosion} />
          </div>
          <div className="flex-row gap-24">
            <RowTable6 title="토양유형" value={data.soilResult.soilType} />
          </div>
        </div>
        <div className="flex-column w-100per gap-16 padding-24-16">
          {[
            { title: '논적성등급', ratio: soilData.논적성등급 },
            { title: '밭적성등급', ratio: soilData.밭적성등급 },
            { title: '과수상전적성등급', ratio: soilData.과수상전적성등급 },
            { title: '초지적성등급', ratio: soilData.초지적성등급 },
            { title: '임지적성등급', ratio: soilData.임지적성등급 },
          ].map((el) => (
            <div className="flex-row w-100per space-between">
              <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                {el.title}
              </div>
              <RowCom.StarRow ratings={el.ratio} />
            </div>
          ))}
        </div>
        <div className="padding-8-16 gap-10">
          <BoxGreen1>
            <div className="font-bold f-size-14 line-h-20 color-text-secondary">
              이 농지는
            </div>
            <div className="font-bold f-size-16 line-h-24 color-primary-500">
              {highRate[0] === 'N'
                ? highRate.slice(1).join(', ') +
                  '농사에 상대적으로 적합한 토양입니다'
                : highRate.join(', ') + ' 농사에 적합한 토양입니다.'}
            </div>
          </BoxGreen1>
        </div>
      </div>
    </div>
  );
};

export default ReportDetail3;
