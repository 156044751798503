import { useEffect, useState } from 'react';
import RowCom from '../../../component/report/Row';
import { Content1, Footer, ReportTitle } from '../component';
interface PropsType {
  data: any;

  address: string;
}
export const SoilRank = ({ data, address }: PropsType) => {
  var regex = /[^0-9]/g;

  const soilData = {
    논적성등급: 6 - Number(data.soilInfo.rfldGrd.replace(regex, '')),
    밭적성등급: 6 - Number(data.soilInfo.pfldGrd.replace(regex, '')),
    과수상전적성등급: 6 - Number(data.soilInfo.fruitGrd.replace(regex, '')),
    초지적성등급: 6 - Number(data.soilInfo.pastureGrd.replace(regex, '')),
    임지적성등급: 6 - Number(data.soilInfo.frstGrd.replace(regex, '')),
  };

  function getMaxValueAndKeysFromObject(obj: any) {
    let maxKeys: any = [];
    let maxValue: any = -Infinity;

    for (const [key, value] of Object.entries(obj)) {
      if (typeof value === 'number') {
        // 타입 좁히기
        if (value > maxValue) {
          maxValue = value;
          maxKeys = [key.split('적성등급')[0]]; // 최대값이 갱신되면 새로운 키로 초기화
        } else if (value === maxValue) {
          maxKeys.push(key.split('적성등급')[0]); // 최대값과 동일하면 키를 추가
        }
      }
    }

    return {
      maxValue,
      maxKeys: maxKeys.join(', '),
    };
  }
  console.log(soilData);
  const content = [
    `본 물건의 토양 등급은 ${getMaxValueAndKeysFromObject(soilData).maxKeys} ${
      6 - getMaxValueAndKeysFromObject(soilData).maxValue
    }등급으로 분석되며,`,
    `농사(논밭) 경작 시, ${
      soilData.논적성등급 > soilData.밭적성등급 ? '논' : '밭'
    }에 적합한 것으로 분석됨`,
    `토양 및 기후를 분석하여 인공지능 추천 작물 선정 결과, 노지(${data.cropList_O.recommendCrop[0].name},${data.cropList_O.recommendCrop[1].name}), 비닐하우스(${data.cropList_P.recommendCrop[0].name}, ${data.cropList_P.recommendCrop[1].name})로 도출됨`,
  ];

  return (
    <div className="flex-column w-100per space-between page">
      <div className="flex-column  w-100per">
        <ReportTitle
          address="전북특별자치도 김제시 순동 445-19"
          category="토양 등급"
        />
        <div className="flex-column padding-24 gap-24">
          <Content1 content={content} />

          <div className="flex-column w-100per gap-24">
            <div className="flex-row w-100per gap-48">
              <div className="flex-column w-50per gap-24">
                <div className="flex-row w-100per space-between">
                  <div className="m-t1-20-b">트랜스파머의 토양 등급 분석</div>
                </div>
                <div className="flex-column gap-24">
                  <div className="flex-row w-100per gap-12">
                    <div className="flex-row border-b-171E26 w-50per space-between padding-b-8">
                      <div className="m-b1-16-m">경사</div>
                      <div className="m-b1-16-m">{data.soilInfo.soilslope}</div>
                    </div>{' '}
                    <div className="flex-row border-b-171E26 w-50per space-between padding-b-8">
                      <div className="m-b1-16-m">배수등급</div>
                      <div className="m-b1-16-m">{data.soilInfo.soildra}</div>
                    </div>
                  </div>
                  <div className="flex-row w-100per gap-12">
                    <div className="flex-row border-b-171E26 w-50per space-between padding-b-8">
                      <div className="m-b1-16-m">토양추천</div>
                      <div className="m-b1-16-m">
                        {getMaxValueAndKeysFromObject(soilData).maxKeys}
                      </div>
                    </div>{' '}
                    <div className="flex-row border-b-171E26 w-50per space-between padding-b-8">
                      <div className="m-b1-16-m">침식등급</div>
                      <div className="m-b1-16-m">{data.soilInfo.erosion}</div>
                    </div>
                  </div>
                  <div className="flex-row w-100per gap-12">
                    <div className="flex-row border-b-171E26 w-100per space-between padding-b-8">
                      <div className="m-b1-16-m">토양유형</div>
                      <div className="m-b1-16-m">{data.soilInfo.soilType}</div>
                    </div>{' '}
                  </div>
                </div>
                <div className="flex-column w-100per gap-16 padding-24-16">
                  {[
                    { title: '논적성등급', ratio: soilData.논적성등급 },
                    { title: '밭적성등급', ratio: soilData.밭적성등급 },
                    {
                      title: '과수상전적성등급',
                      ratio: soilData.과수상전적성등급,
                    },
                    { title: '초지적성등급', ratio: soilData.초지적성등급 },
                    { title: '임지적성등급', ratio: soilData.임지적성등급 },
                  ].map((el) => (
                    <div className="flex-row w-100per space-between">
                      <div className="font-medium f-size-16 line-h-24 color-text-secondary">
                        {el.title}
                      </div>
                      <RowCom.StarRow ratings={el.ratio} />
                    </div>
                  ))}
                </div>
              </div>
              <div className="flex-column w-50per gap-24">
                <div className="m-t1-20-b">
                  트랜스파머의 인공지능 작물 추천 결과
                </div>
                <div className="flex-column gap-12">
                  <div
                    className="flex-center bg-primary-600 border-radius-16 padding-8-16 "
                    style={{ width: '100px' }}
                  >
                    <div className="color-FFFFFF color-white m-b2-14-m text-center">
                      노지
                    </div>
                  </div>
                  <div className="flex-row gap-8" style={{ flexWrap: 'wrap' }}>
                    {data.cropList_O.recommendCrop.map(
                      (item: any, index: number) => {
                        return (
                          <div className="flex-column gap-8 flex-center">
                            <img
                              style={{ width: '40px' }}
                              src={`https://back.transfarmer.kr/img/${item.img}`}
                            />
                            <div className="text-center m-b2-14-m">
                              {item.name}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
                <div className="flex-column gap-12">
                  <div
                    className="flex-center bg-primary-600 border-radius-16 padding-8-16 "
                    style={{ width: '100px' }}
                  >
                    <div className="color-FFFFFF color-white m-b2-14-m text-center">
                      비닐하우스
                    </div>
                  </div>
                  <div className="flex-row gap-8" style={{ flexWrap: 'wrap' }}>
                    {data.cropList_P.recommendCrop.map(
                      (item: any, index: number) => {
                        return (
                          <div className="flex-column gap-8 flex-center">
                            <img
                              style={{ width: '40px' }}
                              src={`https://back.transfarmer.kr/img/${item.img}`}
                            />
                            <div className="text-center m-b2-14-m">
                              {item.name}
                            </div>
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
                {/* <div className='flex-column gap-12'>
                  <div
                    className='flex-center bg-primary-600 border-radius-16 padding-8-16 '
                    style={{ width: '100px' }}
                  >
                    <div className='color-FFFFFF color-white m-b2-14-m text-center'>
                      스마트팜
                    </div>
                  </div>
                  <div>사진</div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer number={5} />
    </div>
  );
};
