const IconButtonBox = (props: any) => {
  return (
    <div
      className="flex-1 flex-column center-y center-x hover"
      onClick={props.on ? () => {} : props.onClick}
    >
      {props.on ? (
        <img className="w-24" src={props.iconOn} alt="" />
      ) : (
        <img className="w-24" src={props.iconOff} alt="" />
      )}
      <div
        className="font-bold f-size-12 line-h-14"
        style={{ color: props.on ? '#1e9b6b' : '#171E2640' }}
      >
        {props.description}
      </div>
    </div>
  );
};

export default IconButtonBox;
