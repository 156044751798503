import { useState } from 'react';
import { Close } from '../../asset/styles/mobile/common/Common';

import { useNavigate } from 'react-router-dom';
import Button from '../../component/mobile/Button';
import Footer from '../../component/browser/Footer';
import Topnav from '../../component/browser/Topnav';
import UserService from '../../service/checkAccount/CheckAccount';

import { TextBox } from './component';

const UpdateAgricultural = () => {
  const navigate = useNavigate();
  const [agriNum, setAgriNum]: any = useState();

  const updateAgri = async () => {
    try {
      if (agriNum) {
        await UserService.updateAgriNum(agriNum);
        navigate('/aiFarming/map');
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div
      className="flex-column w-100per center-y space-between"
      style={{ height: `${window.innerHeight}px` }}
    >
      <Topnav></Topnav>
      <div className="flex-column w-100per center-y padding-12-20 browser-min-w-468 gap-30">
        <div className="flex-row w-100per end-x tablet mt-10">
          <Close
            onClick={() => {
              navigate('/');
            }}
          />
        </div>
        <div className="font-bold f-size-26 line-h-34 color-000000 w-100per mt-30 text-start">
          농업 경영체 등록 번호
        </div>
        <div className="flex-column gap-12 center-x center-y w-100per">
          <TextBox
            placeholder="농업 경영체 등록 번호를 입력해주세요."
            onChange={(e: any) => {
              setAgriNum(e.target.value);
            }}
            text="농업 경영체 등록 번호"
          ></TextBox>
        </div>
        <Button on={agriNum} onClick={updateAgri} description="확인" />
      </div>
      <Footer />
    </div>
  );
};

export default UpdateAgricultural;
