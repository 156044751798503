import React, { useEffect, useState } from 'react';
import { TopNav2, TopNavNewBrowser } from '../../component/mobile/Topnav';
import './Notice.css';
import MypageSerivce from '../../service/mypage/mypage';
import Pagination from '../../component/pagination/Pagination';
import Banner from '../../component/AIIntroduction/Footer';
import EmptyMessage from '../../component/Message/EmptyMessage';
import Footer from '../../component/browser/Footer';
import { useNavigate } from 'react-router-dom';

const Notice = () => {
  const [noticeList, setNoticeList] = useState<NoticeItem[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(1);
  const navigate = useNavigate();

  interface NoticeItem {
    articleIdx: string;
    title: string;
    writeTime: string;
    content: string;
  }

  const limit = 10;
  const board = 'A';

  const GetNoticeList = async (page: number) => {
    try {
      const offset = (page - 1) * limit;
      const result = await MypageSerivce.noticeList(board, offset, limit);
      setNoticeList(result.data.rows);
      setTotalPages(Math.ceil(result.data.totalCount / limit));
    } catch (error) {
      alert(error);
    }
  };

  useEffect(() => {
    GetNoticeList(currentPage);
  }, [currentPage]);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <div className="notice-wrap">
      <TopNav2 text="공지사항" hamburger back />
      <TopNavNewBrowser />
      <div className="notice-inner">
        <div className="notice-wrap-title">공지사항</div>
        <div className="notice-content-box">
          {noticeList.length > 0 && <div className="top-line"></div>}
          {noticeList.length === 0 ? (
            <EmptyMessage message="등록된 공지사항이 없습니다." />
          ) : (
            <div className="notice-list">
              <ul>
                {noticeList.map((item: any) => (
                  <li
                    key={item.articleIdx}
                    onClick={() => navigate(`/noticeDetail/${item.articleIdx}`)}
                  >
                    <div className="notice-title-box">
                      <p className="notice-tag">공지</p>
                      <span className="notice-title">{item.title}</span>
                    </div>
                    <span className="notice-date no-wrap">
                      {item.writeTime.split(' ')[0]}
                    </span>
                  </li>
                ))}
              </ul>
              {totalPages >= 1 && (
                <Pagination
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  pageList={Array.from({ length: totalPages }, (_, i) => i + 1)}
                  totalPages={totalPages}
                />
              )}
            </div>
          )}
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};
export default Notice;
