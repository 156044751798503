import { memo } from 'react';
import type { FC } from 'react';

import resets from './_resets.module.css';
import classes from './Frame1237.module.css';
import { useNavigate } from 'react-router-dom';

interface Props {
  className?: string;
}
/* @figmaId 464:8337 */
const AIFarmingTicket: FC<Props> = memo(function Frame1237(props = {}) {
  const navigate = useNavigate();

  return (
    <div className={`${resets.storybrainResets} ${classes.root}`}>
      <div className={classes.frame43143}>
        <div className={classes.top}>
          <div className={classes.closeArea}>
            <div className={classes.close}>
              {/* <CloseIcon className={classes.icon} /> */}
            </div>
          </div>
        </div>
        <div className={classes.frame43042}>
          <div className={classes.frame43059}>
            <div className={classes.unnamed}>트랜스파머 이용권</div>
          </div>
          <div className={classes.unnamed2}>
            전문 데이터로 분석하는 타당성 분석 보고서
          </div>
        </div>
      </div>
      <div className={classes.frame43144}>
        <div className={classes.frame43136}>
          <div className={classes._1}>1회 이용권</div>
          <div className={classes.frame43135}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
        <div className={classes.frame43137}>
          <div className={classes._1}>2회 이용권</div>
          <div className={classes.frame431352}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
        <div className={classes.frame43138}>
          <div className={classes._1}>3회 이용권</div>
          <div className={classes.frame431353}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
        <div className={classes.frame43139}>
          <div className={classes._1}>5회 이용권</div>
          <div className={classes.frame431354}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
        <div className={classes.frame43140}>
          <div className={classes._1}>10회 이용권</div>
          <div className={classes.frame431355}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
        <div className={classes.frame43141}>
          <div className={classes._1}>20회 이용권</div>
          <div className={classes.frame431356}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
        <div className={classes.frame43142}>
          <div className={classes._1}>30회 이용권</div>
          <div className={classes.frame431357}>
            <div className={classes._1000} onClick={() => navigate('purchase')}>
              ₩ 10,000
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default AIFarmingTicket;
