import { RowSectionTitle } from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { IHousePriceAnalysis, IIndexInfo } from '../../Main';
import { Tooltip2 } from '../../../../component/common/tooltip';
import { useState } from 'react';
import { BoxCard1, BoxGreen1 } from '../../../../component/report/Box';

const Summary2 = ({ data }: { data: IIndexInfo }) => {
  let x = (num: number) => {
    if (num > 100) {
      return '상향 중 ';
    } else if (num < 100) {
      return '하향 중 ';
    } else if (num === 100) {
      return '변화 없음 ';
    }
    return '';
  };
  const [toolTip, setToolTip] = useState(false);
  return (
    <div className="browser-bg-FFFFFF relative">
      <RowSectionTitle>
        <TextSectionTitle warn onClick={() => setToolTip(true)}>
          지역 주택거래 지수
        </TextSectionTitle>
      </RowSectionTitle>
      <div style={{ position: 'absolute', zIndex: '1' }}>
        <Tooltip2
          width
          text={
            <div className="text-left">
              당사가 직접 수집한 3년 주택 거래 데이터를 기반으로 자체 개발한
              지수임.
              <br />
              <br />
              해당 지역의 주택가격 지수가 100 이상이면 주택 가격이 상승 중,
              미만이면 하락 중임을 뜻함. 신축거래/리모델링 거래 활성화 지수가
              100 이상이면 거래 활발, 미만이면 거래 위축을 의미함.
            </div>
          }
          top="-5"
          left="15"
          on={toolTip}
          off={() => {
            setToolTip(false);
          }}
        />
      </div>
      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            해당 주택이 속한 지역의
            <br />
            <span
              className="color-primary-500 f-size-16 line-h-24"
              style={{
                color:
                  data.houseIndexList[data.houseIndexList.length - 1].index <
                  100
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              주택가격 지수
            </span>
            는&nbsp;
            <span
              className="color-primary-500 f-size-16 line-h-24"
              style={{
                color:
                  data.houseIndexList[data.houseIndexList.length - 1].index <
                  100
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              {x(data.houseIndexList[data.houseIndexList.length - 1].index)}
            </span>
            이고,
            <br />
            <span
              className="color-primary-500 f-size-16 line-h-24"
              style={{
                color:
                  data.newConstructionIndexList[
                    data.newConstructionIndexList.length - 1
                  ].index < 100
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              신축거래 활성화 지수
            </span>
            는&nbsp;
            <span
              className="color-primary-500 f-size-16 line-h-24"
              style={{
                color:
                  data.newConstructionIndexList[
                    data.newConstructionIndexList.length - 1
                  ].index < 100
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              {x(
                data.newConstructionIndexList[
                  data.newConstructionIndexList.length - 1
                ].index,
              )}
              ,
            </span>
            <br />
            <span
              className="color-primary-500 f-size-16 line-h-24"
              style={{
                color:
                  data.remodelingIndexList[data.remodelingIndexList.length - 1]
                    .index < 100
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              리모델링거래 활성화 지수
            </span>
            는&nbsp;
            <span
              className="color-primary-500 f-size-16 line-h-24"
              style={{
                color:
                  data.remodelingIndexList[data.remodelingIndexList.length - 1]
                    .index < 100
                    ? '#d62554'
                    : '#1E9B6B',
              }}
            >
              {x(
                data.remodelingIndexList[data.remodelingIndexList.length - 1]
                  .index,
              )}
            </span>{' '}
            입니다.
          </div>
        </BoxGreen1>
      </div>
      <div className="padding-0-16-24 gap-8">
        <div className="flex-row w-100per space-between gap-24">
          <BoxCard1
            underLine
            title="지역 주택가격 지수"
            value={
              Math.round(
                data.houseIndexList[data.houseIndexList.length - 1].index * 10,
              ) / 10
            }
          />
          <BoxCard1
            underLine
            title="신축거래 활성화 지수"
            value={
              Math.round(
                data.newConstructionIndexList[
                  data.newConstructionIndexList.length - 1
                ].index * 10,
              ) / 10
            }
          />
        </div>
        <div className="flex-row w-100per space-between gap-24">
          <BoxCard1
            underLine
            title="리모델링거래 활성화 지수"
            value={
              Math.round(
                data.remodelingIndexList[data.remodelingIndexList.length - 1]
                  .index * 10,
              ) / 10
            }
          />
          <div className="w-100per"></div>
        </div>
      </div>
    </div>
  );
};

export default Summary2;
