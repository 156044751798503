import {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { isMobile } from '../../../component/function/function';
import { ButtonSelect1N } from '../../../component/report/Button';
import { be } from 'date-fns/locale';
const ReportNav = ({ refList, isHave }: any) => {
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [menu, setMenu] = useState('tab1');
  const [subMenu, setSubMenu] = useState('menu1');
  const [myScroll, setMyscroll] = useState(0);
  const [scrollableDistance, setScrollableDistance] = useState(0);
  // // 클릭시 이동
  const moveMenu = (tab: string) => {
    setSubMenu(tab);
  };

  useLayoutEffect(() => {
    const obj = {
      tab1: tab1 as any,
      tab2: tab2,
    };
    const tab = obj[menu as 'tab1'];
    if (tab?.current) {
      setTabWidth(tab.current.offsetWidth);
      setLeft(tab.current.offsetLeft);
    }
  }, [menu]);

  function onScroll() {
    setMyscroll(refList.temp.current.scrollTop);
    for (let i = 10; i > 0; i--) {
      if (
        refList.temp.current.scrollTop >
        refList[`menuRef${i}`].current?.getBoundingClientRect().top +
          refList.temp.current.scrollTop -
          150
      ) {
        if (i > 2) {
          setMenu('tab2');
        } else {
          setMenu('tab1');
        }
        moveMenu(`menu${i}`);
        return;
      }
    }
  }
  useEffect(() => {
    if (
      refList.temp.current.scrollTop >
      refList.menuRef3.current?.getBoundingClientRect().top +
        refList.temp.current.scrollTop -
        150
    ) {
      containerRef.current?.scrollTo(
        ((myScroll - 803) /
          (refList.menuRef9.current?.getBoundingClientRect().top +
            refList.temp.current.scrollTop -
            150)) *
          656,
        0,
      );
    }
  }, [myScroll]);
  useEffect(() => {
    refList.temp.current?.addEventListener('scroll', onScroll);
    return () => {
      refList.temp.current?.removeEventListener('scroll', onScroll);
    };
  }, []);
  const scrollTo = (text: string) => {
    refList.temp.current?.scrollTo({
      behavior: 'smooth',
      top:
        refList[text].current?.getBoundingClientRect().top +
        refList.temp.current.scrollTop -
        145,
    });
  };
  const [dragging, setDragging] = useState(false);
  const [clickPoint, setClickPoint] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const containerRef = useRef<HTMLDivElement>(null);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    // 마우스 클릭하면
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음

    e.preventDefault();

    if (containerRef.current) {
      // clickPoint는 onMouseDown에서 처음 클릭한 위치가 할당된다.
      // walk = 마우스를 끌고간 최종 위치 - 처음 마우스를 클릭한 위치
      // 오른쪽에서 왼쪽으로 끌면 음수이다.
      const walk = e.pageX - clickPoint;

      // walk가 음수, 즉 오른쪽에서 왼쪽으로 끌었을때 scrollLeft - walk는 양수가 되면서
      // containerRef의 scrollLeft가 증가하면서 스크롤이 오른쪽으로 움직인다.
      containerRef.current.scrollLeft = scrollLeft - walk;
      setScrollableDistance(
        containerRef.current.scrollWidth - containerRef.current.clientWidth,
      );
    }
  };

  return (
    <div
      className="w-100per flex-column bg-FFFFFF"
      style={{
        position: 'sticky',
        zIndex: 1,
        top: isMobile(window.innerWidth) ? '0' : '0',
      }}
    >
      <div className="flex-row gap-24 padding-x-16 start-x border-b-1-171E2610 relative">
        {/* //padding-22-16-0  */}
        {[
          { ty: 'tab1', t: '핵심 요약', r: tab1, i: 'tab1' },
          { ty: 'tab2', t: '상세 분석', r: tab2, i: 'tab2' },
        ].map((el) => (
          <div
            className="flex-row center-x center-y padding-y-8 hover"
            onClick={() => {
              moveMenu(el.i);
              setMenu(el.ty);
              if (el.ty === 'tab1') {
                scrollTo('menuRef1');
              } else {
                scrollTo('menuRef3');
              }
            }}
          >
            <div
              className="font-bold f-size-18  line-h-26 nowrap"
              style={{ color: menu === el.ty ? '#171E26' : '#171E2666' }}
              ref={el.r}
            >
              {el.t}
            </div>
          </div>
        ))}
        <div
          className="slider-black2"
          style={{ width: tabWidth, left: left }}
        />
      </div>
      {menu === 'tab1' ? (
        <div
          className="padding-12-16 flex-row gap-8 border-b-1-171E2610 of-x-auto browser-of-scroll-horizontal"
          ref={menu === 'tab1' ? containerRef : null}
          onMouseDown={handleMouseDownEvent}
          onMouseLeave={() => setDragging(false)}
          onMouseUp={() => setDragging(false)}
          onMouseMove={handleMouseMoveEvent}
        >
          {[
            { num: 1, title: 'AI 주택 추정가' },
            { num: 2, title: '지역 주택거래 지수' },
          ].map((el) => (
            <ButtonSelect1N
              selected={subMenu === `menu${el.num}`}
              onClick={() => {
                moveMenu(`menu${el.num}`);
                scrollTo(`menuRef${el.num}`);
              }}
            >
              {el.title}
            </ButtonSelect1N>
          ))}
        </div>
      ) : (
        <div
          className="padding-12-16 flex-row gap-8 border-b-1-171E2610 of-x-auto browser-of-scroll-horizontal"
          ref={menu !== 'tab1' ? containerRef : null}
          onMouseDown={handleMouseDownEvent}
          onMouseLeave={() => setDragging(false)}
          onMouseUp={() => setDragging(false)}
          onMouseMove={handleMouseMoveEvent}
        >
          {[
            { num: 3, title: '1. 주택가격' },
            { num: 4, title: '2. 건축거래 활성화지수' },
            { num: 5, title: '3. 주택정보' },
            { num: 6, title: '4. 토지정보' },
            { num: 7, title: '5. 토지 공시지가' },
            { num: 8, title: '6. 사업비 진단' },
            {
              num: 9,
              title:
                isHave === 'Y'
                  ? '7. 사업비 대출 진단'
                  : '7. 주택매입비 대출 진단',
            },
            { num: 10, title: '8. 시공비 대출 진단' },
          ].map((el) => {
            if (isHave === 'Y' && el.num === 10) {
              return <></>;
            }
            return (
              <ButtonSelect1N
                selected={subMenu === `menu${el.num}`}
                onClick={() => {
                  moveMenu(`menu${el.num}`);
                  scrollTo(`menuRef${el.num}`);
                }}
              >
                {el.title}
              </ButtonSelect1N>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default ReportNav;
