export const Index = () => {
  return (
    <div className="flex-column w-100per h-100per padding-24 gap-24">
      <div className="flex-row space-between w-100per">
        <img src="/asset/images/logo/pdf_logo.png" />
        <div className="m-b2-14-b">Table(목차)</div>
        <div className="m-b2-14-m color-text-tertiary">Report for VIP</div>
      </div>
      <div className="flex-row w-100per gap-24">
        <div
          className="flex-row gap-24"
          style={{ width: '60%', paddingTop: '100px' }}
        >
          <div className="flex-column gap-24" style={{ width: '40%' }}>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-b">I. 물건 요약</div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-b">II. 물건 등급</div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-b">III. 실거래 가격</div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-b">IV. 트랜스파머 추정가</div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-b">기타</div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
              <div className="flex-row space-between">
                <div></div>
                <div className="m-b2-14-m">-------</div>
              </div>
            </div>
          </div>
          <div className="flex-column gap-24" style={{ width: '60%' }}>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-m">물건 위치</div>
                <div className="m-b2-14-m">1</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">물건 사진 및 용도지역</div>
                <div className="m-b2-14-m">2</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">물건 개요</div>
                <div className="m-b2-14-m">3</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-m">토지 등급</div>
                <div className="m-b2-14-m">4</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">토양 등급</div>
                <div className="m-b2-14-m">5</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-m">공시지가 추이</div>
                <div className="m-b2-14-m">6</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">주변 실거래 가격 분석</div>
                <div className="m-b2-14-m">7</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">주변 실거래 가격 추이</div>
                <div className="m-b2-14-m">8</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-m">추정 근거 및 1차 추정가</div>
                <div className="m-b2-14-m">9</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">현재 부동산 매물 매도 호가 분석</div>
                <div className="m-b2-14-m">10</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">수익환원법 기반 2차 추정가</div>
                <div className="m-b2-14-m">11</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">가격 분석 결과</div>
                <div className="m-b2-14-m">12</div>
              </div>
            </div>
            <div className="flex-column gap-16">
              <div className="flex-row space-between">
                <div className="m-b2-14-m">규제법령</div>
                <div className="m-b2-14-m">13</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">전국 토지등급</div>
                <div className="m-b2-14-m">14</div>
              </div>
              <div className="flex-row space-between">
                <div className="m-b2-14-m">트랜스파머 전문가 소개</div>
                <div className="m-b2-14-m">15</div>
              </div>
            </div>
          </div>
        </div>
        <img style={{ width: '400px' }} src="/asset/images/pdf/pdf_photo.png" />
      </div>
    </div>
  );
};
