import React from 'react';
import { AuthContext } from '../../utilities/auth';
import SignUpService from '../../service/signUp/SignUp';

import {
  Routes,
  Route,
  NavLink,
  Navigate,
  useNavigate,
} from 'react-router-dom';
import { red } from '@mui/material/colors';

type AuthProviderProp = {
  children: JSX.Element;
};

const AuthProvider = ({ children }: AuthProviderProp) => {
  const navigate = useNavigate();
  const [token, setToken] = React.useState('');

  const getToken = () => {
    let token = localStorage.getItem('accessToken');
    if (token == null) {
      token = '';
    }
    return token;
  };

  const isLogin = () => {
    if (getToken() === '') {
      return false;
    }
    return true;
  };

  const handleLogin = (token: string) => {
    localStorage.setItem('accessToken', token);
    setToken(token);
    if (sessionStorage.getItem('pathname')) {
      navigate(
        `${sessionStorage.getItem('pathname')}?area=${sessionStorage.getItem(
          'pathArea',
        )}`,
        { replace: true },
      );
    } else {
      navigate('/', { replace: true });
    }
  };

  const handleLogout = async () => {
    if (isLogin()) {
      try {
        await SignUpService.Logout();
      } catch {}
    }
    localStorage.clear();
    sessionStorage.clear();
    navigate('/');
    setToken('');
  };

  const value = {
    getToken: getToken,
    onLogin: handleLogin,
    onLogout: handleLogout,
    isLogin: isLogin,
    setRedirect: (path: string) => {
      sessionStorage.setItem('path', path);
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
