import ReportTitle from '../../component/mobile/ReportTitle';
import Step from '../../component/mobile/Step';
import { TopNav2 } from '../../component/mobile/Topnav';
import Button from '../../component/mobile/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import GreenBox from '../../component/mobile/GreenBox';
import LandService from '../../service/land/landApi';
import { useEffect, useState, useRef } from 'react';
import Spinner2 from '../../component/browser/Spinner2';
import { isMobile } from '../../component/function/function';
import { CropSelecter } from './component';
import useDidMountEffect from '../../hooks/UseDidMountEffect';
import { PointColumn } from '../../component/common/pointColumn';

const Current = ({
  subcategory1,
  area,
  setArea,
  setSelected,
  selected,
  refresh,
  setIsCropAvailable,
  setOwnLaborYN2,
  address,
}: any) => {
  const element = useRef<HTMLDivElement>(null);
  const element2 = useRef<HTMLDivElement>(null);
  const element3 = useRef<HTMLDivElement>(null);
  const element4 = useRef<HTMLDivElement>(null);

  const facilityCode = {
    노지: 'O',
    비닐하우스: 'P',
    스마트팜: 'S',
    기후맞춤스마트팜: 'W',
  };

  const [mArea, setMArea] = useState(
    JSON.parse(sessionStorage.areaList)[0].info.area || 0,
  );
  const [img, setImg] = useState('');
  const locationHook = useLocation();
  const [tab, setTab] = useState('노지');
  const [areaType, setAreaType] = useState('M');
  const [ownLaborYN, setOwnLaborYN] = useState('Y');
  const [crop, setCrop]: any = useState();
  const [crops, setCrops] = useState(1);
  const [cropList, setCropList] = useState([]);
  const [quantity, setQuantity]: any = useState();
  const [avgQuantity, setAvgQuantity] = useState(0);
  const [unitPrice, setUnitPrice]: any = useState(0);
  const [currentValue, setCurrentValue] = useState();
  const [loading, setLoading] = useState(false);
  const [originalArea, setOriginalArea] = useState(
    JSON.parse(sessionStorage.areaList)[0].info.area || 0,
  );

  const navigate = useNavigate();
  const next = () => {
    let prevData = {};
    if (sessionStorage.analyzeResultPayload) {
      prevData = JSON.parse(sessionStorage.analyzeResultPayload);
    }
    const areaList = JSON.parse(sessionStorage.areaList);
    const pnuCodes = areaList.map((el: any) => {
      return el.info.pnuCode;
    });

    const landPurchaseCost = areaList.reduce((acc: any, cur: any) => {
      return acc + cur.info.estimatedPrice;
    }, 0);
    const landRentalCost = areaList.reduce((acc: any, cur: any) => {
      return acc + cur.info.estimatedRentalPrice;
    }, 0);

    sessionStorage.setItem(
      'analyzeResultPayload',
      JSON.stringify({
        ...prevData,
        pnuCodes,
        landPurchaseCost: Math.round(Number(landPurchaseCost)),
        landRentalCost: Math.round(Number(landRentalCost)),
        facility: facilityCode[tab as '노지'],
        cropName: crop,
        crops,
        quantity,
        avgQuantity: avgQuantity,
        farmArea: isMobile(window.innerWidth)
          ? mArea * (areaType === 'P' ? 3.3058 : 1)
          : area * (areaType === 'P' ? 3.3058 : 1),
        ownLaborYN,
      }),
    );
    navigate(
      (locationHook.pathname.slice(-1) === '/'
        ? locationHook.pathname.slice(0, -1)
        : locationHook.pathname
      ).slice(0, -8) + '/cost',
      { state: { address: locationHook.state.address } },
    );
  };

  const getCropList = async () => {
    try {
      setLoading(true);
      const areaList = JSON.parse(sessionStorage.areaList).map((el: any) => {
        let area2 = el.info.area;
        return {
          pnuCode: el.info.pnuCode,
          area: area2,
          address: el.area,
        };
      });

      // let mArea2 = areaList[0].area;
      // setMArea(mArea2);
      let facility = tab;
      if (tab === '비닐하우스') {
        facility = '시설';
      }
      const list = await LandService.cropListAll(areaList, facility);

      setCropList(list.list);
      // if (list.list[0]) {
      //   saveCropData(list.list[0]);
      // } else {
      setCrop(undefined);
      // }
      setSelected(false);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  const saveData = (data: any) => {
    let prevData = {};
    if (!isMobile(window.innerWidth)) {
      setSelected(true);
    }
    if (sessionStorage.analyzeResultPayload) {
      prevData = JSON.parse(sessionStorage.analyzeResultPayload);
    }

    sessionStorage.setItem(
      'analyzeResultPayload',
      JSON.stringify({ ...prevData, ...data }),
    );
  };

  const saveCropData = (data: any) => {
    const {
      SGA,
      empLaborCosts,
      facility,
      facilityCost,
      farmEquipmentRentalCost,
      income,
      materialCost,
      name,
      orchardAdjustmentCost,
      ownLaborCosts,
      productionCost,
      quantity,
      rate,
      realIncome,
      sales,
      sido,
      totalLaborCosts,
      unitPrice,
      waterHeatingCost,
      img,
    } = typeof data === 'string' ? JSON.parse(data) : data;
    setQuantity(quantity);
    setAvgQuantity(quantity);
    setCrop(name);
    setImg(img);
    setCurrentValue(data);
    setUnitPrice(unitPrice);
    saveData({
      facility: facilityCode[facility as '노지'],
      facilityCost,
      SGA,
      empLaborCosts,
      farmEquipmentRentalCost,
      income,
      materialCost,
      cropName: name,
      orchardAdjustmentCost,
      ownLaborCosts,
      productionCost,
      quantity,
      rate,
      realIncome,
      sales,
      sido,
      totalLaborCosts,
      unitPrice,
      waterHeatingCost,
    });
  };

  useEffect(() => {
    setAreaType('M');
    if (tab === '비닐하우스' || tab === '스마트팜') {
      if (areaType === 'M') {
        if (isMobile(window.innerWidth)) {
          setMArea(
            Math.round(
              Math.floor(Math.round(mArea / 3.3058) / 50) * 50 * 3.3058,
            ),
          );
        } else {
          setArea(
            Math.round(
              Math.floor(Math.round(area / 3.3058) / 50) * 50 * 3.3058,
            ),
          );
        }
      } else {
        if (isMobile(window.innerWidth)) {
          setMArea(Math.floor(mArea / 50) * 50);
        } else {
          setArea(Math.floor(area / 50) * 50);
        }
      }
    } else if (tab === '노지') {
      if (areaType === 'M') {
        if (isMobile(window.innerWidth)) {
          setMArea(Math.round(originalArea));
        } else {
          setArea(Math.round(originalArea));
        }
      } else {
        if (isMobile(window.innerWidth)) {
          setMArea(Math.round(((originalArea / 3.3058) * 10) / 10));
        } else {
          setArea(Math.round(((originalArea / 3.3058) * 10) / 10));
        }
      }
    }
    getCropList();
  }, [tab, refresh]);
  useDidMountEffect(() => {
    if (areaType === 'M') {
      if (isMobile(window.innerWidth)) {
        setMArea(Math.round(mArea * 3.3058));
      } else {
        setArea(Math.round(area * 3.3058));
      }
    } else {
      if (isMobile(window.innerWidth)) {
        setMArea(Math.round(mArea / 3.3058));
      } else {
        setArea(Math.round(area / 3.3058));
      }
    }
  }, [areaType]);

  const OwnLaborBtn = ({ btn }: { btn: string }) => (
    <div
      className={
        ownLaborYN === btn
          ? 'w-50per border-radius-8 padding-12-12-12-8 bg-primary-600 flex-center hover'
          : 'border-radius-8 padding-12-12-12-8 bg-light-gray-200 w-50per flex-center hover'
      }
      onClick={() => {
        setOwnLaborYN(btn);
        if (setOwnLaborYN2) {
          setOwnLaborYN2(btn);
        }
        console.log(btn);
      }}
    >
      <div
        className={
          ownLaborYN === btn
            ? 'font-bold f-size-14 line-h-20 color-white'
            : 'font-medium f-size-14 line-h-20 color-171E2640'
        }
      >
        {btn === 'Y' ? '네' : '아니오'}
      </div>
    </div>
  );

  const UpDownBtn = ({ btn }: { btn: string }) => (
    <div
      className="padding-4-8 bg-blue-gray-500 flex-center border-radius-8 hover"
      style={{ width: '32px', height: '36px' }}
      onClick={() =>
        btn === '+'
          ? setCrops((prev) => prev + 1)
          : setCrops((prev) => prev - 1)
      }
    >
      <div className="font-regular f-size-24 line-h-24 color-white">{btn}</div>
    </div>
  );
  const FacilityTab = ({ facility }: { facility: string }) => (
    <div
      className={
        tab === facility
          ? 'bg-primary-600 border-radius-8 flex-center padding-12-8 hover flex-1'
          : 'bg-light-gray-200 border-radius-8 flex-center padding-12-8 hover flex-1'
      }
      onClick={() => {
        if (originalArea < 250) {
          alert('재배면적 250m² 미만의경우 노지만 가능합니다.');
        } else {
          setTab(facility);
        }
      }}
    >
      <div
        className={
          tab === facility
            ? 'font-bold f-size-14 line-h-20 color-white'
            : 'font-medium f-size-14 line-h-20 color-disabled'
        }
      >
        {facility}
      </div>
    </div>
  );

  useEffect(() => {
    let prevData = {};
    if (sessionStorage.analyzeResultPayload) {
      prevData = JSON.parse(sessionStorage.analyzeResultPayload);
    }
    const areaList = JSON.parse(sessionStorage.areaList);
    const pnuCodes = areaList.map((el: any) => {
      return el.info.pnuCode;
    });

    const landPurchaseCost = areaList.reduce((acc: any, cur: any) => {
      return Math.round(acc + cur.info.estimatedPrice);
    }, 0);
    const landRentalCost = areaList.reduce((acc: any, cur: any) => {
      return Math.round(acc + cur.info.estimatedRentalPrice);
    }, 0);

    sessionStorage.setItem(
      'analyzeResultPayload',
      JSON.stringify({
        ...prevData,
        pnuCodes,
        landPurchaseCost: landPurchaseCost,
        landRentalCost: landRentalCost,
        facility: facilityCode[tab as '노지'],
        cropName: crop,
        crops,
        quantity,
        unitPrice,
        ownLaborYN,
        farmArea: isMobile(window.innerWidth)
          ? mArea * (areaType === 'P' ? 3.3058 : 1)
          : area * (areaType === 'P' ? 3.3058 : 1),
        avgQuantity: avgQuantity,
      }),
    );
  }, [quantity, unitPrice, crops, area, mArea, areaType, ownLaborYN]);

  const [onCropSelecter, setOnCropSelecter] = useState(false);

  return (
    <div className="flex-column w-100per bg-light-gray padding-b-50 center-y browser-padding-0 browser-bg-FFFFFF browser-w-380">
      <TopNav2 text="내 농장 진단보고서" close back />
      {onCropSelecter ? (
        <CropSelecter
          value={currentValue}
          onChange={(e: any) => {
            saveCropData(e);
            setOnCropSelecter(false);
          }}
          off={() => setOnCropSelecter(false)}
          cropList={cropList}
          setIsCropAvailable={setIsCropAvailable}
        />
      ) : (
        ''
      )}
      <div className="flex-column w-100per max-w-681  browser-mt-48">
        <Step num="1" />
        <ReportTitle text="내 농장 현황 입력하기" />
        <div className="padding-20-16-40-16 flex-column gap-20 ">
          <GreenBox />
          <PointColumn
            type="FO"
            address={
              isMobile(window.innerWidth) ? locationHook.state.address : address
            }
          />
          <div className="flex-column padding-20-16 border-radius-8 bg-white gap-20">
            <div className="flex-column gap-5">
              <div
                id={subcategory1}
                className="font-bold f-size-16 line-h-24 color-text-primary"
                ref={element}
              >
                1. 재배 환경
              </div>
            </div>
            <div className="flex-row gap-12 space-between">
              <FacilityTab facility="노지" />
              <FacilityTab facility="비닐하우스" />
              <FacilityTab facility="스마트팜" />
            </div>
          </div>
          <div className="flex-column padding-20-16 border-radius-8 bg-white gap-20">
            <div className="flex-row space-between center-y">
              <div
                className="font-bold f-size-16 line-h-24 color-text-primary"
                ref={element2}
              >
                2. 재배 면적
              </div>
              <div
                className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                onClick={() => {
                  setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'));
                  // if (isMobile(window.innerWidth)) {
                  //   setMArea(
                  //     areaType === "P"
                  //       ? Math.round(mArea / 0.03025) / 10
                  //       : Math.round(mArea * 3.025) / 10
                  //   );
                  // } else {
                  //   setArea(
                  //     areaType === "P"
                  //       ? Math.round(area / 0.03025) / 10
                  //       : Math.round(area * 3.025) / 10
                  //   );
                  // }
                }}
              >
                <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                {areaType === 'P' ? 'm²' : '평'}
              </div>
            </div>
            <div className="flex-row gap-5 border-171E26 w-100per border-radius-8 end-x padding-6-8 center-y">
              <input
                className="border-none color-text-secondary text-end font-medium f-size-14 line-h-24 "
                value={Number(
                  isMobile(window.innerWidth) ? mArea : area,
                ).toLocaleString()}
                onChange={(e: any) => {
                  if (isMobile(window.innerWidth)) {
                    setMArea(Number(e.target.value.split(',').join('')));
                  } else {
                    setArea(Number(e.target.value.split(',').join('')));
                  }
                }}
                onBlur={getCropList}
              />
              <div className="font-medium f-size-14 line-h-20 color-text-primary">
                {areaType === 'P' ? '평' : 'm²'}
              </div>
            </div>
          </div>
          <div className="flex-column padding-20-16 border-radius-8 bg-white gap-20">
            <div className="flex-column gap-20 ">
              <div className="flex-column gap-5">
                <div
                  className="font-bold f-size-16 line-h-24 color-text-primary"
                  ref={element3}
                >
                  3. 작물
                </div>
              </div>

              <label htmlFor="cropList" className="flex-row border-radius-8 ">
                {
                  loading ? (
                    <div className="flex-row w-100per center-x">
                      <Spinner2 />
                    </div>
                  ) : cropList.length === 0 ? (
                    <div className="font-medium f-size-14 line-h-16 color-text-secondary">
                      수확가능한 농작물이 없습니다.
                    </div>
                  ) : crop ? (
                    <></>
                  ) : (
                    <div
                      className="gap-8 center-y flex-column w-100per bg-primary-100 border-radius-8 padding-y-24 hover"
                      onClick={() => setOnCropSelecter(true)}
                    >
                      <img src="/asset/images/plus/plus38.svg" alt="" />
                      <div className="font-bold f-size-14 line-h-20 color-primary-600">
                        원하는 작물을 선택해보세요
                      </div>
                    </div>
                  )
                  // <div className="flex-row gap-10">
                  //   <img src={search} />
                  //   <select
                  //     className="font-medium f-size-14 line-h-20"
                  //     // style={{ visibility: "hidden" }}
                  //     placeholder="작물 이름을 검색하세요"
                  //     id="cropList"
                  //     value={currentValue}
                  //     onChange={(e: any) => saveCropData(e.target.value)}
                  //   >
                  //     {cropList.map((el: any) => {
                  //       return (
                  //         <option value={JSON.stringify(el)}>{el.name}</option>
                  //       );
                  //     })}
                  //   </select>

                  // </div>
                }
              </label>
            </div>
            {crop && (
              <div className="flex-column gap-20">
                <div className="flex-row start-y">
                  <div
                    className="flex-column gap-8 center-y hover center-y"
                    style={{ width: '100px' }}
                  >
                    <div
                      className="relative"
                      style={{
                        width: '80px',
                        height: '80px',
                      }}
                    >
                      <div
                        className=" border-radius-100per of-hidden center-x center-y"
                        style={{
                          width: '80px',
                          height: '80px',
                          border: '2px solid #1E9B6B',
                        }}
                      >
                        <img
                          src={`https://back.transfarmer.kr/img/${img}`}
                          alt=""
                          style={{ width: '70px', height: '70px' }}
                        />
                      </div>

                      <img
                        src="/asset/images/check/Check24GreenCircle.svg"
                        alt=""
                        style={{
                          position: 'absolute',
                          left: 0,
                          bottom: 0,
                        }}
                      />
                    </div>
                    <div
                      className="font-medium f-size-14 line-h-20 text-center"
                      style={{
                        color: '#1e9b6b',
                        wordBreak: 'keep-all',
                      }}
                    >
                      {crop}
                    </div>
                  </div>
                  <img
                    src="/asset/images/close/Close28Ring.svg"
                    alt=""
                    className="hover"
                    onClick={() => {
                      setCrop(null);
                      setSelected(false);
                      if (window.innerWidth >= 1024) {
                        setIsCropAvailable(false);
                      }
                    }}
                  />
                </div>
                <div className="flex-column gap-10">
                  <div className="flex-row w-100per  gap-24">
                    <div className="flex-row space-between gap-8 flex-1">
                      <div className="font-bold f-size-14 line-h-20 color-text-secondary w-50per">
                        1년 재배 횟수
                      </div>
                      <div className="flex-row gap-4  w-50per">
                        <UpDownBtn btn="-" />
                        <div className="padding-4-8 border-1-171E26 flex-center">
                          <div className="font-medium f-size-14 line-h-24">
                            {crops} 기작
                          </div>
                        </div>
                        <UpDownBtn btn="+" />
                      </div>
                    </div>
                  </div>
                  <div className="flex-row gap-10">
                    <div className="flex-column w-50per">
                      <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                        작물 판매단가
                      </div>
                      <div className="gap-4 center-y padding-6-8 flex-row border-radius-8 border-1-171E26 w-100per">
                        <input
                          placeholder="500"
                          className=" w-100per font-medium f-size-14 line-h-24 color-text-secondary text-end border-none"
                          type="text"
                          value={Number(unitPrice).toLocaleString()}
                          onChange={(e) =>
                            setUnitPrice(
                              Number(e.target.value.split(',').join('')),
                            )
                          }
                        />
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          원
                        </div>
                      </div>
                    </div>
                    <div className="flex-column  w-50per">
                      <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                        1기작 생산량
                      </div>
                      <div className="gap-4 center-y padding-6-8 flex-row border-radius-8 border-1-171E26 w-100per">
                        <input
                          placeholder="500"
                          className=" w-100per font-medium f-size-14 line-h-24 color-text-secondary text-end border-none"
                          type="text"
                          value={Number(quantity).toLocaleString()}
                          onChange={(e) =>
                            setQuantity(
                              Number(e.target.value.split(',').join('')),
                            )
                          }
                        />
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          kg
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex-column w-50per none">
                      <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                        총 생산량
                      </div>
                      <div className="gap-4 center-y padding-6-8 flex-row border-radius-8 border-1-171E26 w-100per">
                        <input
                          placeholder="500"
                          className=" w-100per font-medium f-size-14 line-h-24 color-text-secondary text-end border-none"
                          type="text"
                          readOnly
                          value={(quantity * crops).toLocaleString()}
                        />
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          kg
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="flex-column padding-20-16 border-radius-8 bg-white gap-10">
            <div className="flex-column gap-5">
              <div
                className="font-bold f-size-16 line-h-24 color-text-primary"
                ref={element4}
              >
                4. 예상 이익 계산 시, 자가노동비(농장주 월급)를 포함할까요?
              </div>
            </div>
            <div className="flex-row gap-10">
              <OwnLaborBtn btn="Y" />
              <OwnLaborBtn btn="N" />
            </div>
          </div>
          <div className="tablet">
            <Button
              description="다음"
              onClick={!!crop ? next : () => {}}
              on={crop}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Current;
