import { Props } from 'react-select';

interface PropsType {
  num: string;
}

const Step = ({ num }: PropsType) => {
  return (
    <div className="flex-row w-100per  padding-20-20 gap-3">
      {num === '1' ? (
        <div className="flex-row center-y gap-3">
          <div className="border-radius-100 padding-4-8 bg-primary-600">
            <div className="font-medium f-size-12 line-h-14 color-FFFFFF">
              Step 1
            </div>
          </div>
          <div
            style={{ width: '10px', height: '1px', background: '#171E2640' }}
          ></div>
          <div className="border-radius-100 padding-4-8 border-171E26">
            <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
              Step 2
            </div>
          </div>
        </div>
      ) : (
        <div className="flex-row center-y gap-3">
          <div className="border-radius-100 padding-4-8 border-171E26">
            <div className="font-medium f-size-12 line-h-14 color-text-tertiary">
              Step 1
            </div>
          </div>
          <div
            style={{ width: '10px', height: '1px', background: '#171E2640' }}
          ></div>
          <div className="border-radius-100 padding-4-8 bg-primary-600 ">
            <div className="font-medium f-size-12 line-h-14  color-FFFFFF">
              Step 2
            </div>
          </div>
        </div>
      )}{' '}
    </div>
  );
};

export default Step;
