import axios, { AxiosInstance } from 'axios';

export default class Request {
  public customAxios: AxiosInstance;
  constructor(baseURL: string) {
    this.customAxios = axios.create({
      baseURL: baseURL,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });
    this.customAxios.interceptors.request.use(
      (config) => {
        const accessToken = localStorage.getItem('accessToken');
        if (config.data instanceof FormData) {
          config.headers['Content-Type'] = 'multipart/form-data';
        } else {
          config.headers['Content-Type'] = 'application/json';
        }
        config.headers['Authorization'] = `Bearer ${accessToken}`;
        return config;
      },
      (error) => {
        console.log(error);
        return Promise.reject(error);
      },
    );

    this.customAxios.interceptors.response.use(
      async (res) => {
        return res;
      },
      async (err: any) => {
        console.log(err);
        if (err.response?.data.message === 'jwt expired') {
          alert('토큰이 만료되었습니다. 다시 로그인 해주세요.');
          localStorage.clear();
          window.location.href = '/login';
        }
        if (err.response?.data.message === 'invalid signature') {
          alert('로그인을 다시 해주세요.');
          localStorage.clear();
          window.location.href = '/login';
        }
        return Promise.reject(err);
      },
    );
  }
  public get = async (endPoint: string) => {
    return await this.customAxios.get(endPoint);
  };
  public patch = async (endPoint: string, data: any) => {
    return await this.customAxios.patch(endPoint, data);
  };
  public post = async (endPoint: string, data: any) => {
    return await this.customAxios.post(endPoint, data);
  };
  public delete = async (endPoint: string, data?: any) => {
    return await this.customAxios.delete(endPoint, data);
  };
  public put = async (endPoint: string, data: any) => {
    return await this.customAxios.put(endPoint, data);
  };
}
