import React, { useEffect, useState } from 'react';
import './ReportResultLanduse.css';
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  ResponsiveContainer,
} from 'recharts';
import ReportService from '../../service/report/reportApi';
import { isMobile } from '../../component/function/function';

interface IReportData {
  type: string;
  score: number;
  level: number;
  location: number;
  zoning: number;
  category: number;
  shape: number;
  slope: number;
  roadsurface: number;
  area: number;
}

const LandPotential = ({ reportIdx4 }: { reportIdx4: number }) => {
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(0);
  const [grade, setGrade] = useState('');
  const [data, setData] = useState<IReportData[]>([]);
  const [filteredData, setFilteredData] = useState({
    type: '',
    score: 0,
    level: 0,
    location: 0,
    zoning: 0,
    category: 0,
    shape: 0,
    slope: 0,
    roadsurface: 0,
    area: 0,
  });
  const [chartDataSet, setChartDataSet] = useState([
    { category: '태양광발전', value: 80, level: 2 },
    { category: '창고', value: 90, level: 1 },
    { category: '주차장\n차고지', value: 50, level: 3 },
    { category: '펜션\n(관광펜션)', value: 100, level: 1 },
    { category: '농어촌\n민박업', value: 70, level: 2 },
    { category: '캠핑장\n(야영장)', value: 60, level: 3 },
  ]);
  const [tabText1, setTabText1] = useState('');
  const [tabText2, setTabText2] = useState('');

  const getReportData = async () => {
    try {
      setLoading(true);
      const res = await ReportService.getReport(reportIdx4);
      setGrade(res.result.grade);
      setData(res.result.list);
      const filteredData = res.result.list.sort(
        (a: any, b: any) => b.score - a.score,
      );
      const filteredDataItem = filteredData[0];
      setFilteredData(filteredDataItem);
      setTabText1(filteredData[0].type);
      setTabText2(filteredData[1].type);
      const categoryMap: { [key: string]: string } = {
        S: '태양광\n발전',
        W: '창고',
        P: '주차장\n차고지',
        C: '펜션\n(관광펜션)',
        L: '농어촌\n민박업',
        E: '캠핑장\n(야영장)',
      };

      const formatDataSet = res.result.list.map((item: IReportData) => {
        return {
          category: categoryMap[item.type],
          value: item.score,
          level: item.level,
        };
      });
      setChartDataSet(formatDataSet);
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getReportData();
  }, [reportIdx4]);

  const sortedChartDataSet = chartDataSet.sort((a, b) => a.level - b.level);

  const CustomTick = React.memo((props: any) => {
    const { payload, x, y, textAnchor } = props;
    const lines = payload.value.split('\n');

    const level =
      chartDataSet.find((item) => item.category === payload.value)?.level || 0;

    const levelColor =
      level === 1 ? '#00a676' : level === 2 ? '#E78B3E' : '#D84836';

    return (
      <text
        x={x}
        y={y + 5}
        textAnchor="middle"
        fill={levelColor}
        fontSize={10}
        fontFamily="Pretendard-Medium"
      >
        {lines.map((line: string, index: number) => (
          <tspan key={index} x={x} dy={index === 0 ? 0 : 15}>
            {line}
          </tspan>
        ))}
      </text>
    );
  });

  const makeFilterData = (tab: number) => {
    let filteredDataItem;

    switch (tab) {
      case 0:
        filteredDataItem = data.sort((a, b) => b.score - a.score)[0];
        break;
      case 1:
        filteredDataItem = data.sort((a, b) => b.score - a.score)[1];
        break;
      default:
        filteredDataItem = data.sort((a, b) => b.score - a.score)[0];
        break;
    }

    if (filteredDataItem) {
      setFilteredData(filteredDataItem);
    }
  };

  useEffect(() => {
    makeFilterData(tab);
  }, [tab, reportIdx4]);

  const changeText = (text: string) => {
    if (text === 'S') {
      return '태양광발전';
    } else if (text === 'W') {
      return '창고';
    } else if (text === 'P') {
      return '주차장';
    } else if (text === 'C') {
      return '캠핑장';
    } else if (text === 'L') {
      return '농어촌민박';
    } else if (text === 'E') {
      return '펜션';
    }
  };

  return (
    <div className="land-use-report-block">
      <div className="land-use-report-block-title">토지활용 가능성 AI 진단</div>
      <div className="level-box">
        <span>토지활용 등급</span>
        <span className="land-use-level">{grade}</span>
      </div>
      <div className="chart-box">
        <div className="chart-box-inner">
          <ResponsiveContainer width={'100%'} height={300}>
            <RadarChart
              data={chartDataSet}
              outerRadius={isMobile(window.innerWidth) ? '70%' : '90%'}
            >
              <PolarGrid
                stroke="rgba(112, 115, 124, 0.16)"
                strokeDasharray="5 5"
              />
              <PolarAngleAxis dataKey="category" tick={<CustomTick />} />
              <Radar
                name="Category"
                dataKey="value"
                stroke="#00a676"
                fill="#00a676"
                fillOpacity={0.1}
              />
            </RadarChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="land-use-gray-box">
        <div className="land-use-gray-box-inner">
          {sortedChartDataSet.map((item, index) => (
            <div className="land-use-gray-box-item" key={index}>
              <div className="land-use-gray-box-item-title">
                <span
                  className="level-color-dot"
                  style={{
                    backgroundColor:
                      item.level === 1
                        ? '#1E9B6B'
                        : item.level === 2
                          ? '#E78B3E'
                          : '#D84836',
                  }}
                ></span>
                <span>{item.category.replace('\n', '')}</span>
              </div>
              <span
                className="level-color-text"
                style={{
                  color:
                    item.level === 1
                      ? '#1E9B6B'
                      : item.level === 2
                        ? '#E78B3E'
                        : '#D84836',
                }}
              >
                {item.level === 1 ? '높음' : item.level === 2 ? '보통' : '낮음'}
              </span>
            </div>
          ))}
        </div>
      </div>
      <div
        className="land-use-gray-box"
        style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}
      >
        <div className="land-use-tab-box">
          <span
            className={tab === 0 ? 'land-use-tab-active' : 'land-use-tab'}
            onClick={() => {
              setTab(0);
            }}
          >
            {changeText(tabText1)} 경쟁력
          </span>
          <span
            className={tab === 1 ? 'land-use-tab-active' : 'land-use-tab'}
            onClick={() => {
              setTab(1);
            }}
          >
            {changeText(tabText2)} 경쟁력
          </span>
        </div>
        <div className="land-use-tab-point">
          상위 <span>{filteredData?.score}점</span>
        </div>
        <div className="land-use-prograssbar-box">
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">지역 위치</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.location}%` }}
              ></span>
            </div>
            <span>{filteredData?.location}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">용도 지역</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.zoning}%` }}
              ></span>
            </div>
            <span>{filteredData?.zoning}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">토지 종류</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.category}%` }}
              ></span>
            </div>
            <span>{filteredData?.category}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">토지 모양</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.shape}%` }}
              ></span>
            </div>
            <span>{filteredData?.shape}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">경사도</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.slope}%` }}
              ></span>
            </div>
            <span>{filteredData?.slope}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">도로 유형</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.roadsurface}%` }}
              ></span>
            </div>
            <span>{filteredData?.roadsurface}점</span>
          </div>
          <div className="land-use-prograssbar-item">
            <span className="land-use-prograssbar-title">토지 면적</span>
            <div className="land-use-prograssbar">
              <span
                className="land-use-prograssbar-per"
                style={{ width: `${filteredData?.area}%` }}
              ></span>
            </div>
            <span>{filteredData?.area}점</span>
          </div>
        </div>
        <div className="land-use-prograssbar-desc">
          동사의 토지활용 가능성 진단은 <br />
          <span>빅데이터 기반 AI 분석</span>
          으로 <br />
          추가적으로 상세 분석이 필요합니다
        </div>
      </div>
    </div>
  );
};

export default LandPotential;
