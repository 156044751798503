import Banner from '../../../component/AIIntroduction/Footer';
import { TopNav2 } from '../../../component/mobile/Topnav';

const RefundPolicy = ({ search, setClose }: any) => {
  return (
    <div
      className={`flex-column ${search ? `w-360 of-scroll z-1 ` : `w-100per `}`}
    >
      <TopNav2 text="환불 안내" hamburger back />
      {search && (
        <div className="flex-row space-between padding-12-16 center-y">
          <div></div>
          <div className="m-b1-16-b color-dark-primary">환불 안내</div>
          <img
            src="/asset/images/close/CloseBlack24.svg"
            className="pointer"
            alt="close"
            onClick={() => {
              setClose(false);
            }}
          />
        </div>
      )}
      <div
        className={`flex-column gap-24 mb-80 ${
          search ? `padding-32-16-0-16` : `padding-32-16-0-16 browser-padding-0`
        }`}
      >
        <ul className="flex-column gap-24">
          <li>
            <div className="font-bold f-size-20 mb-16 color-text-primary">
              환불 기준
            </div>
            <table className="refund-policy w-100per">
              <tbody>
                <tr>
                  <th className="text-left padding-16">
                    마지막 충전 후 주소지 조회, 분석 <br />
                    서비스 이용 이력이 없는 경우
                  </th>
                  <td className="text-left padding-16">
                    7일 내 취소 시 전액 환불, 7일 후 취소 시 90% 환불
                  </td>
                </tr>
                <tr>
                  <th className="text-left padding-16">
                    마지막 충전 후 주소지 조회, 분석 <br /> 서비스 이용 이력이
                    있는 경우
                  </th>
                  <td className="text-left padding-16">
                    서비스 이용 장애로 인한 유료 충전 포인트 차감 시 차감된
                    포인트만큼 추가 충전
                  </td>
                </tr>
              </tbody>
            </table>
          </li>
          <li>
            <div className="font-bold f-size-20 mb-16 color-text-primary">
              환불 요청 방법
            </div>
            <div>
              help@transfarmer.co.kr 메일로 환불 요청 및 환불 사유를 보내주세요.
              <br />
              환불 요청 내용에는 회원ID와 연락처를 꼭 적어주세요.
            </div>
          </li>
          <li>
            <div className="font-bold f-size-20 mb-16 color-text-primary">
              환불 처리 기간
            </div>
            <div>
              환불 요청을 받은 날로부터 3영업일 이내에 결제수단별 사업자에게
              대금의 청구 정지 내지 취소를 요청하고, 결제가 이루어진 동일
              결제수단으로 환불해 드립니다. <br />
              결제 대행사의 환불 기간 규정에 따라 최대 14 영업일이 소요될 수
              있습니다.
            </div>
          </li>
          <li>
            <div className="font-bold f-size-20 mb-16 color-text-primary">
              기타
            </div>
            <div>무료로 지급된 포인트는 환불 대상이 되지 않습니다.</div>
          </li>
        </ul>
      </div>
      <Banner />
    </div>
  );
};

export default RefundPolicy;
