const Tab3 = ({ houseRemodel, houseNew, houseTransaction }: any) => {
  return (
    <div className="flex-row gap-8 of-x-auto padding-y-16 browser-gap-16 browser-padding-0 none tablet-flex ">
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 no-wrap"
        style={{ width: '33%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            실거래
          </div>
          <img src="/asset/images/main/houseTab1.svg" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {houseTransaction.map((el: any, i: number) => (
            <div className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.gugun}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 browser-gap-24  no-wrap"
        style={{ width: '33%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            신축
          </div>
          <img src="/asset/images/main/house_new.png" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {houseNew.map((el: any, i: number) => (
            <div className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.gugun}
              </div>
            </div>
          ))}
        </div>
      </div>
      <div
        className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 browser-gap-24  no-wrap"
        style={{ width: '33%' }}
      >
        <div className="flex-column space-between center-y">
          <div className="font-bold f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
            리모델링
          </div>
          <img src="/asset/images/main/house_remodel.png" alt="" />
        </div>
        <div className="flex-column gap-4 ">
          {houseRemodel.map((el: any, i: number) => (
            <div className="flex-row gap-8">
              <div className="font-medium f-size-14 browser-f-size-16 line-h-20 browser-line-h-26 color-text-primary">
                {i + 1}
              </div>
              <div className="font-medium f-size-14 browser-f-size-16 lien-h-20 browser-line-h-26 color-text-secondary">
                {el.gugun}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Tab3;
