import Request from '../../@api/request';
import { useAuth } from '../../utilities/auth';

// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;

export const request = new Request(baseURL);
export default class PayService {
  // static SignUpSNS = async (data: SignUpSNSData) => {
  //   return await request.post("users/sign", data);
  // };

  static getIframe = async (amount: any, depositor: any) => {
    const result = await request.post('pay/regist', {
      amount: amount,
      depositor: depositor,
    });
    return result;
  };

  static getParam = async (amount: any, depositor: any, path: any) => {
    const result = await request.post('pay/mobile/param', {
      amount: amount,
      depositor: depositor,
      host: path,
    });
    return result.data;
  };
  static Refund = async (paymentIdx: any) => {
    const result = await request.post('/pay/refund', {
      paymentIdx: paymentIdx,
    });
    return result.data;
  };
}
