import { useEffect } from 'react';
import Spinner from '../../component/browser/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { KakaoCode } from '../../store/codeSlice';
import { redirect, useNavigate } from 'react-router-dom';
import { LoginId, UserName } from '../../store/user2Slice';
import GetActService from '../../service/act/Act';
import { useAuth, GetUserInfo } from '../../utilities/auth';
import UserService from '../../service/checkAccount/CheckAccount';
import { SignUpType } from '../../store/signUpInfoSlice';

const KakaoRedirect = () => {
  const { onLogin } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const kakaoCode = useSelector((state: any) => {
    {
      return state.code.kakaoCode;
    }
  });
  const redirectUrl = useSelector((state: any) => {
    return state.code.kakaoUrl;
  });

  const KakaoGetCode = () => {
    const code = new URLSearchParams(window.location.search).get('code')!;
    dispatch(KakaoCode(code));
  };

  const KakaoGetAct = async () => {
    const code = kakaoCode;
    const provider = 'kakao';
    const data = {
      redirectUrl: sessionStorage.getItem('url'),
      // redirectUrl: redirectUrl,
    };
    try {
      const result = await GetActService.getAct(code, provider, data as any);
      if (result.data.accessToken === undefined) {
        dispatch(SignUpType('K'));
        navigate('/signUpSNS');
        sessionStorage.setItem('email', result.data.email);
        sessionStorage.setItem('name', result.data.name);
        sessionStorage.setItem('phone', result.data.phoneNumber);
      } else {
        sessionStorage.setItem('email', result.data.email);
        // sessionStorage.setItem("name", result.data.name);
        // sessionStorage.setItem("phone", result.data.phoneNumber);
        onLogin(result.data.accessToken);
        // 추후에 변경
        GetUserInfo();
      }
    } catch (error) {
      navigate('/');
      console.log(error);
    }
  };

  useEffect(() => {
    KakaoGetCode();
  }, []);

  useEffect(() => {
    if (kakaoCode && kakaoCode !== '') {
      KakaoGetAct();
    }
  }, [kakaoCode]);

  return (
    <div>
      <Spinner />
    </div>
  );
};

export default KakaoRedirect;
