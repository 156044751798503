import { useEffect, useState } from 'react';
import Tab1 from './search/Tab1';
import MainService from '../../../service/main/Main';
import Tab2 from './search/Tab2';
import Tab3 from './search/Tab3';

const Main2 = ({
  field,
  paddy,
  fruit,
  houseTransaction,
  houseNew,
  houseRemodel,
  top5FacilityCrop,
  top5FieldCrop,
}: any) => {
  const [tab, setTab] = useState(1);
  return (
    <div className="bg-FFFFFF flex-row center-x">
      <div className="padding-32-16 tablet-padding-32-40 browser-padding-80-60 flex-column gap-16 browser-gap-24 bg-FFFFFF w-100per center-y  browser-max-w-1320">
        <div className="flex-column browser-max-w-1200 w-100per gap-16 browser-gap-24 ">
          <div className="flex-row end-y space-between">
            <div className="font-bold f-size-18 line-h-26 color-text-primary browser-f-size-20 browser-line-h-33">
              인기 검색 지역
            </div>
            <div className="font-medium f-size-12 line-h-14 color-text-secondary browser-f-size-14 browser-line-h-20">
              최근 1개월 기준
            </div>
          </div>
          <div className="flex-column gap-24 mobile-flex">
            <div className="flex-column ">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                농지
              </div>
              <div className="flex-row gap-8 of-x-auto padding-y-16">
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      전
                    </div>
                    <img src="/asset/images/main/farm1.svg" alt="" />
                  </div>
                  <div className="flex-column gap-4 ">
                    {field.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8 center-y">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.searchWord}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16  no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      답
                    </div>
                    <img src="/asset/images/main/farm2.svg" alt="" />
                  </div>
                  <div className="flex-column gap-4">
                    {paddy.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8 center-y">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.searchWord}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16  no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      과수
                    </div>
                    <img src="/asset/images/main/farm1.svg" alt="" />
                  </div>
                  <div className="flex-column gap-4">
                    {fruit.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8 center-y">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.searchWord}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                1/3
              </div> */}
            </div>
            <div className="flex-column ">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                작물 재배지
              </div>
              <div className="flex-row gap-8 of-x-auto padding-y-16">
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      노지작물
                    </div>
                    <img src="/asset/images/main/fieldcrop.svg" alt="" />
                  </div>
                  <div className="flex-column gap-4 ">
                    {top5FieldCrop.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.region} / {el.cropName}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16  no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      시설작물
                    </div>
                    <img src="/asset/images/main/facilitycrop.svg" alt="" />
                  </div>
                  <div className="flex-column gap-4 ">
                    {top5FacilityCrop.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.region} / {el.cropName}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                1/3
              </div> */}
            </div>
            <div className="flex-column ">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                전원주택
              </div>
              <div className="flex-row gap-8 of-x-auto padding-y-16">
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16 no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      실거래
                    </div>
                    <img src="/asset/images/main/houseTab1.svg" alt="" />
                  </div>
                  <div className="flex-column gap-4 ">
                    {houseTransaction.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8 center-y">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.gugun}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16  no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      신축
                    </div>
                    <img src="/asset/images/main/house_new.png" alt="" />
                  </div>
                  <div className="flex-column gap-4 ">
                    {houseNew.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8 center-y">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.gugun}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="flex-row border-radius-8 border-1-171E2610 padding-16 gap-16  no-wrap">
                  <div className="flex-column space-between center-y">
                    <div className="font-bold f-size-14 line-h-20 color-text-primary">
                      리모델링
                    </div>
                    <img src="/asset/images/main/house_remodel.png" alt="" />
                  </div>
                  <div className="flex-column gap-4 ">
                    {houseRemodel.map((el: any, i: number) => (
                      <div key={i} className="flex-row gap-8 center-y">
                        <div className="font-medium f-size-14 line-h-20 color-text-primary">
                          {i + 1}
                        </div>
                        <div className="font-medium f-size-14 lien-h-20 color-text-secondary">
                          {el.gugun}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              {/* <div className="font-medium f-size-14 line-h-20 color-text-secondary">
                1/3
              </div> */}
            </div>
          </div>
          <div className="flex-column browser-gap-24 none tablet-flex">
            <div className="flex-row gap-8">
              <div
                className="padding-4-16 gap-10 border-radius-999 hover"
                onClick={() => {
                  setTab(1);
                }}
                style={{
                  background: tab === 1 ? '#000000' : '',
                  border: tab !== 1 ? '1px solid #171e2610' : '',
                }}
              >
                <div
                  className="font-bold f-size-16 line-h-24 browser-f-size-18 browser-line-h-30"
                  style={{ color: tab === 1 ? '#FFFFFF' : '#171e2678' }}
                >
                  농지
                </div>
              </div>
              <div
                className="padding-4-16 gap-10 border-radius-999 hover"
                onClick={() => {
                  setTab(2);
                }}
                style={{
                  background: tab === 2 ? '#000000' : '',
                  border: tab !== 2 ? '1px solid #171e2610' : '',
                }}
              >
                <div
                  className="font-bold f-size-16 line-h-24 browser-f-size-18 browser-line-h-30"
                  style={{ color: tab === 2 ? '#FFFFFF' : '#171e2678' }}
                >
                  작물 재배지
                </div>
              </div>
              <div
                className="padding-4-16 gap-10 border-radius-999 hover"
                onClick={() => {
                  setTab(3);
                }}
                style={{
                  background: tab === 3 ? '#000000' : '',
                  border: tab !== 3 ? '1px solid #171e2610' : '',
                }}
              >
                <div
                  className="font-bold f-size-16 line-h-24 browser-f-size-18 browser-line-h-30"
                  style={{ color: tab === 3 ? '#FFFFFF' : '#171e2678' }}
                >
                  전원주택
                </div>
              </div>
            </div>
            {tab === 1 ? (
              <Tab1 field={field} fruit={fruit} paddy={paddy} />
            ) : tab === 2 ? (
              <Tab2
                top5FacilityCrop={top5FacilityCrop}
                top5FieldCrop={top5FieldCrop}
              />
            ) : (
              <Tab3
                houseRemodel={houseRemodel}
                houseTransaction={houseTransaction}
                houseNew={houseNew}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main2;
