import { useNavigate } from 'react-router-dom';
import { isMobile } from '../../../component/function/function';
import { useEffect, useState } from 'react';
import { SideTopnav } from '../../../component/browser/SideTopnav';
import PointHistory from '../history/PointHistory';
import { ReportDetail } from '../report/ReportDetail';
import HouseReportMain from '../../reportResultHouse/Main';
import SearchReportMain from '../../reportResultSearch/Main';
import PensionReportMain from '../../reportResultPension/Main';
import { searchData } from '../../../dummy/dummy';
import RefundPolicy from '../refund/RefundPolicy';
import Notice from '../../notice/Notice';
import UserService from '../../../service/checkAccount/CheckAccount';

import { Calendar } from '../calendar/Calendar';
import { set } from 'lodash';
import FreePoint from '../freePoint/FreePoint';
import Edit from '../edit/Edit';
import FarmingReportMain from '../../aiFarming/report/Main';
import PriceReportMain from '../../reportResultPrice/Main';
import AuctionReportMain from '../../reportResultSale/Main';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
export const MypageProfile = ({
  email,
  createdDate,
  point,
  search,
  tab,
  setTab,
  refundCheck,
}: any) => {
  const [onCalendar, setOnCalendar] = useState(false);
  const date = new Date();
  const [address, setAddress] = useState('');
  const [des, setDes] = useState('');
  const [auctionType, setAuctionType] = useState('' as string);
  const formattedStartDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-01`;
  const formattedEndDate = `${date.getFullYear()}-${String(
    date.getMonth() + 1,
  ).padStart(2, '0')}-${new Date(
    date.getFullYear(),
    date.getMonth() + 1,
    0,
  ).getDate()}`;
  const [startDate, setStartDate] = useState(formattedStartDate);
  const [endDate, setEndDate] = useState(formattedEndDate);
  const navigate = useNavigate();

  const pointHistoryOpenHandler = (tabName: string) => {
    setPointDetail(false);
    if (tabName === 'point') {
      setClosePoint(true);
      setClose(true);
    } else if (tabName === 'freepoint') {
      setClosePoint(false);
      setCloseFreePoint(true);
      setClose(true);
    } else if (tabName === 'edit') {
      setClosePoint(false);
      setCloseFreePoint(false);
      setClose(true);
      setCloseEdit(true);
    }

    if (search) {
      sessionStorage.setItem('mypageTab', tabName);
    } else {
      setTab(tabName);
    }
  };
  const [pointDetail, setPointDetail] = useState(false);
  const [type, setType] = useState('');
  const [reportIdx, setReportIdx] = useState(0);
  const [close, setClose] = useState(false);
  const [closePoint, setClosePoint] = useState(false);
  const [closeEdit, setCloseEdit] = useState(false);
  const [closeFreePoint, setCloseFreePoint] = useState(false);
  const [freePoint, setFreePoint] = useState(0);
  const [paidPoint, setPaidPoint] = useState(0);
  const mypageTabHandler = (tabName: string) => {
    // sessionStorage.setItem("mypageTab", tabName);
    // window.location.reload();
    setTab(tabName);
  };
  useEffect(() => {
    console.log('type', type);
  }, [type]);
  const getPoint = async () => {
    try {
      const result = await UserService.checkPoint();
      setFreePoint(result.data.data.freePoint);
      setPaidPoint(result.data.data.paidPoint);

      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getPoint();
  }, []);
  useEffect(() => {
    if (search) {
      if (!close) {
        setClosePoint(false);
        setCloseFreePoint(false);
        setPointDetail(false);
        setCloseEdit(false);
      }
    }
  }, [close]);
  useDidMountEffect(() => {
    if (refundCheck) {
      getPoint();
    }
  }, [refundCheck]);
  return (
    <div
      className={` ${isMobile(window.innerWidth) && `w-100per`} ${
        search
          ? `flex-row fixed z-100 l-0 t-0 bg-FFFFFF w-1024-h-953 ${
              !close ? `browser-w-449` : `browser-w-793`
            } `
          : `flex-column `
      } `}
    >
      {onCalendar && (
        <Calendar
          onCalendar={onCalendar}
          setOnCalendar={setOnCalendar}
          setStartDate={setStartDate}
          setEndDate={setEndDate}
        />
      )}
      {search && <SideTopnav />}
      <div
        className={` w-100per flex-column gap-40 padding-32-16  tablet-padding-32-40 w-1024-padding-32-16
        border-b-171E26 ${
          search ? `w-1024-w-375 border-r-171E2610` : ` w-1024-w-296`
        }
        
      `}
        style={
          window.innerWidth >= 1024 && !search
            ? {
                borderRadius: '16px',
                border: '1px solid #171E2610',
                boxShadow: '0px 4px 8px 0px #0000001A',
              }
            : { height: `${window.innerHeight}px`, overflowY: 'auto' }
        }
      >
        <div className="flex-column w-100per">
          <div className="m-c1-12-m end-x color-text-tertiary">
            {createdDate && createdDate.split('T')[0]} 가입
          </div>
          <div className="flex-column gap-8 flex-center">
            <img
              className="w-72 h-72"
              alt="profile"
              src="/asset/images/userimage/defaultUser.svg"
            />
            <div className="flex-column flex-center">
              <div className="m-t2-18-m color-dark-primary">
                {localStorage.getItem('username')}님
              </div>
              <div className="m-b2-14-m color-dark-secondary">{email}</div>
            </div>
          </div>

          <div className="flex-column mt-16 flex-center pointer">
            <div
              className="editButton"
              onClick={() => {
                isMobile(window.innerWidth)
                  ? navigate('/mypage/edit')
                  : !search
                    ? mypageTabHandler('edit')
                    : pointHistoryOpenHandler('edit');

                // setCloseNotice(true);
              }}
            >
              정보 수정
            </div>
          </div>
        </div>
        <div className="flex-column gap-24">
          <div className="flex-column gap-16">
            <div className="m-b2-14-b color-dark-secondary">MY 포인트</div>
            <div
              className="padding-16 border-radius-16 bg-light-gray-100"
              style={{
                boxShadow: '0px 4px 8px 0px #0000001A',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <div className="m-t1-20-b color-text-primary">
                {(freePoint + paidPoint).toLocaleString()}P
              </div>
              <div
                className="bg-primary-300 padding-8-16 border-radius-8 flex-row gap-2 pointer"
                onClick={() => {
                  navigate('/point/recharge');
                  sessionStorage.setItem('searchPointRecharge', 'N');
                }}
              >
                <div className="m-b2-14-b color-FFFFFF">충전소</div>
                <img src="/asset/images/icon/flower.svg" alt="flower" />
              </div>
            </div>
          </div>
          <div
            className="space-between flex-row pointer"
            onClick={() => {
              isMobile(window.innerWidth)
                ? navigate('/mypage/point')
                : !search
                  ? mypageTabHandler('point')
                  : pointHistoryOpenHandler('point');
            }}
          >
            <div className="flex-row gap-4">
              <div className="m-b1-16-m color-dark-primary">포인트 내역</div>
            </div>
            <img src="/asset/images/arrow/ArrowRightBlack.svg" alt="arrow" />
          </div>
        </div>
      </div>
      {search &&
        closePoint &&
        sessionStorage.getItem('mypageTab') === 'point' && (
          <PointHistory
            setPointDetail={setPointDetail}
            setTypeSearch={setType}
            setReportIdxSearch={setReportIdx}
            setClose={setClose}
            search
            setOnPointHistory={setClosePoint}
            setOnCalendar={setOnCalendar}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            setDes={setDes}
            setAddress={setAddress}
            setAuctionType={setAuctionType}
          />
        )}
      {search &&
        closeEdit &&
        sessionStorage.getItem('mypageTab') === 'edit' && (
          <Edit
            search
            setPointDetail={setPointDetail}
            setTypeSearch={setType}
            setReportIdxSearch={setReportIdx}
            setClose={setClose}
          />
        )}
      {pointDetail && (
        <div
          style={{
            position: 'fixed',

            left: '448px',
            backgroundColor: '#FFFFFFF',
            width: '360px',
            zIndex: '99999',
            borderLeft: '1px solid #171E2610',
            height: '100%',
            overflow: 'hidden',
          }}
        >
          {type === '농지 찾기' ? (
            <SearchReportMain
              search
              reportIdx={reportIdx}
              setCloseSearchPointDetail={setPointDetail}
              setOnPointHistory={setClose}
              mypage
            />
          ) : type === '내 집 짓기' ? (
            <HouseReportMain
              setClose={setClose}
              search
              reportIdx={reportIdx}
              setCloseSearchPointDetail={setPointDetail}
            />
          ) : type === '내 농장 진단' ? (
            <FarmingReportMain
              reportIdx={reportIdx}
              setCloseSearchPointDetail={setPointDetail}
              setOnPointHistory={setClose}
              search
              mypage
            />
          ) : type === '가격분석' ? (
            <PriceReportMain
              mypage
              reportIdx={reportIdx}
              address2={address}
              des={des}
              setCloseSearchPointDetail={setPointDetail}
              setOnPointHistory={setClose}
              search
            />
          ) : type === '매물 분석' ? (
            <AuctionReportMain
              reportIdx={reportIdx}
              setClose={setClose}
              auctionType={auctionType}
              setCloseSearchPointDetail={setPointDetail}
              setOnPointHistory={setClose}
              search
              mypage
            />
          ) : (
            <PensionReportMain
              search
              reportIdx={reportIdx}
              setCloseSearchPointDetail={setPointDetail}
              setOnPointHistory={setClose}
            />
          )}
        </div>
      )}
    </div>
  );
};
