import { useDispatch, useSelector } from 'react-redux';
import { SetPopUpOn } from '../../store/onOffSlice';
import { ChangePriceType } from '../../store/typeSlice';
import { useNavigate, useLocation } from 'react-router-dom';

const PopUp = ({ type }: any) => {
  const navigate = useNavigate();
  const state = useSelector((state: any) => {
    return state.onOff;
  });

  const dispatch = useDispatch();
  const location = useLocation();
  // useEffect(() => {
  //   setTimeout(() => {
  //     if (location.pathname === "/price/map") {
  //       window.location.reload();
  //     }
  //   }, 3000);
  // }, [state.PopUp]);
  return (
    <div
      className="fixed z-20 center-x center-y padding-24"
      style={{
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        background: '#00000075',
      }}
    >
      <div className="bg-FFFFFF border-radius-16 flex-column w-312">
        <div
          style={{
            padding: '16px 16px 24px 16px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
          }}
        >
          <div
            style={{ width: '100%', textAlign: 'end', cursor: 'pointer' }}
            onClick={() => {
              dispatch(SetPopUpOn(false));
              dispatch(ChangePriceType('land'));
            }}
          >
            <img src="/asset/images/close/CloseGray24-1.svg" alt="닫기" />
          </div>
          <div className="gap-8 flex-center flex-column">
            <div className="font-bold f-size-16 line-h-24 color-1B1B1B w-100per text-center">
              {type === 'price'
                ? '원하는 가격 분석 항목을 선택하세요.'
                : '연금 분석을 원하는 농지를 선택하세요.'}
            </div>
            <div
              className="font medium f-size-14 line-h-20 color-text-secondary text-center"
              style={{ whiteSpace: 'pre-line' }}
            >
              {type === 'price'
                ? '주소 검색을 위해 지도 화면으로 이동합니다.'
                : `주소 검색을 위해 지도 화면으로 이동합니다.`}
            </div>
          </div>
        </div>

        <div className="flex-row border-t-00000010">
          <div className="w-50per padding-16-24 border-r-00000010">
            <div
              className="font-bold f-size-16 line-h-20 color-primary-500 text-center hover"
              onClick={() => {
                dispatch(SetPopUpOn(false));
                dispatch(ChangePriceType('land'));

                sessionStorage.setItem('temp', 'temp');
                // setTimeout(() => {
                //   window.location.pathname === "/price/map" &&
                //     window.location.reload();
                // }, 2000);
              }}
            >
              {type === 'price' ? '농지' : '내 농지'}
            </div>
          </div>
          <div className="w-50per padding-16-24">
            <div
              className="font-bold f-size-16 line-h-20 color-primary-500 text-center hover"
              onClick={() => {
                dispatch(SetPopUpOn(false));
                dispatch(ChangePriceType('house'));
                sessionStorage.setItem('temp', 'temp');

                // setTimeout(() => {
                //   window.location.pathname === "/price/map" &&
                //     window.location.reload();
                // }, 2000);
              }}
            >
              {type === 'price' ? '전원주택' : '경공매 농지'}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopUp;
