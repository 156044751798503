import { useState } from 'react';
// import { ITotalData } from '../Main';
import { useSelector } from 'react-redux';
import { RowTable5 } from '../../../component/report/Row';
import { formatDateTime, isMobile } from '../../../component/function/function';
import { info } from 'console';
import { TriangleTooltip } from '../../../component/common/tooltip';

const ReportInfo = ({
  data,
  mypage,
  des,
}: {
  data: any;
  mypage?: any;
  des?: string;
}) => {
  const state = useSelector((state: any) => {
    return state.type;
  });
  const [areaType, setAreaType] = useState('P');
  const [dateTooltip, setDateTooltip] = useState(true);
  const facilityCode = {
    O: '노지',
    P: '비닐하우스',
    S: '스마트팜',
    W: '기후맞춤스마트팜',
  };
  const infoArea = () => {
    if (des === '가격분석 - 농지' || state.priceType === 'land') {
      return (
        data.area &&
        (
          Math.round(data.area * (areaType === 'P' ? 0.3025 : 1) * 10) / 10
        ).toLocaleString()
      );
    } else {
      return (
        data.totalFloorArea &&
        (
          Math.round(
            data.totalFloorArea * (areaType === 'P' ? 0.3025 : 1) * 10,
          ) / 10
        ).toLocaleString()
      );
    }
  };

  const infoChar = () => {
    if (des === '가격분석 - 농지' || state.priceType === 'land') {
      return data.category;
    } else {
      return (
        `${data.year_int}년 (노후 ${
          Number(new Date().getFullYear()) - Number(data.year_int)
        }년)` || '1997.06.05 (노후 26년)'
      );
    }
  };

  return (
    <div className="padding-24-16 gap-16 flex-column">
      <div
        className={` ${`gap-16 flex-column`}`}
        style={{ position: 'relative' }}
      >
        <RowTable5
          title="주소"
          value={data && data.address}
          value2={data && data.roadAddr}
        />
        <RowTable5
          title="분석일"
          value={
            data.createdTime.split('T')[0] +
            ' ' +
            data.createdTime.split('T')[1].split('.')[0]
          }
        />
        {mypage && dateTooltip && (
          <TriangleTooltip
            text="분석 시점의 결과는 현재와 다를 수 있어요."
            text2="최신 분석 결과는 주소지 조회 후 다시 이용해 주세요."
            top={data.roadAddr ? '95' : '80'}
            left="50"
            on={true}
            off={() => setDateTooltip(false)}
            xImg={true}
          />
        )}
      </div>
      <div className={` ${`gap-16 flex-column`}`}>
        <RowTable5
          title={
            mypage
              ? des === '가격분석 - 농지'
                ? '농지 면적'
                : '연면적'
              : state.priceType === 'land'
                ? '농지 면적 '
                : '연면적'
          }
          value={
            <div className="font-medium f-size-16 line-h-24 color-text-secondary flex-row gap-3">
              {infoArea()}
              {areaType === 'P' ? '평' : 'm²'}

              <div
                className="hover bg-FFFFFF padding-2-8 cneter-x center-y flex-row border-radius-100 border-1-e2e2e2 font-medium f-size-12 line-h-20 color-text-tertiary"
                onClick={() =>
                  setAreaType((prev) => (prev !== 'P' ? 'P' : 'M'))
                }
              >
                <img src="/asset/images/arrow/Refresh20.svg" alt="" />
                {areaType === 'P' ? 'm²' : '평'}
              </div>
            </div>
          }
        />
        <RowTable5
          title={state.priceType === 'land' ? '지목' : '특징'}
          value={infoChar()}
        />
      </div>
    </div>
  );
};

export default ReportInfo;
