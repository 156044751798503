import Request from '../../@api/request';

// import { request } from "../service"
// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

export default class PriceService {
  static getReportSave = async (data: any) => {
    const result = await request.post('price/report', data);
    return result.data;
  };
  static farm = async (address: string) => {
    const result = await request.get(`price/farm?address=${address}`);

    return result.data;
  };

  static house = async (address: string) => {
    const result = await request.get(`price/house?address=${address}`);

    return result.data;
  };
  static surrounding = async (pnuCode: string, type: string) => {
    const result = await request.get(
      `/price/surrounding/analysis?pnuCode=${pnuCode}&type=${type}`,
    );
    return result;
  };
  static pyeongRegion = async (address: string, type: string) => {
    const result = await request.get(
      `price/pyeongRegion?address=${address}&type=${type}`,
    );
    return result.data;
  };

  static trendList = async (address: string, type: string) => {
    const result = await request.get(
      `price/trend?address=${address}&type=${type}`,
    );
    return result.data;
  };
  static origin = async () => {
    const result = await request.get('/price/origin');
    return result.data;
  };
  static getData = async (reportIdx: number) => {
    const result = await request.get(`/price/report/${reportIdx}`);
    return result.data;
  };

  static getPdfData = async (type: string, address: string) => {
    const result = await request.get(
      `/price/report/pdf/data?type=${type}&address=${address}`,
    );
    return result.data;
  };
}
