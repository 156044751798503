import {
  MouseEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import { isMobile } from '../../../component/function/function';
import Toast from '../../../component/common/Toast';

const ReportNav = ({ refList, menu, setMenu, isValid, mypage }: any) => {
  const tab1 = useRef<HTMLDivElement>(null);
  const tab2 = useRef<HTMLDivElement>(null);
  const [tabWidth, setTabWidth] = useState(0);
  const [left, setLeft] = useState(0);
  const [subMenu, setSubMenu] = useState('menu1');
  const [dragging, setDragging] = useState(false);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [clickPoint, setClickPoint] = useState(0);
  // // 클릭시 이동
  // const moveMenu = (tab: string) => {
  //   setSubMenu(tab);
  // };

  useLayoutEffect(() => {
    const obj = {
      tab1: tab1 as any,
      tab2: tab2,
    };
    const tab = obj[menu as 'tab1'];
    if (tab?.current) {
      setLeft(
        tab.current.getBoundingClientRect().left -
          (isMobile(window.innerWidth) ? 0 : 375 + 78),
      );
      setTabWidth(tab.current.offsetWidth);
    }
  }, [menu]);

  const containerRef = useRef<HTMLDivElement>(null);
  const handleMouseDownEvent = (e: MouseEvent<HTMLDivElement>) => {
    // 마우스 클릭하면
    setDragging(true);
    if (containerRef.current) {
      setClickPoint(e.pageX); // clickPoint는 처음 클릭한 지점
      setScrollLeft(containerRef.current.scrollLeft); // 스크롤움직인 거리, 처음은 0
    }
  };

  const handleMouseMoveEvent = (e: MouseEvent<HTMLDivElement>) => {
    if (!dragging) return; // onMouseDownEvent에서 dragging=true가 아니면 작동하지 않음

    e.preventDefault();

    if (containerRef.current) {
      // clickPoint는 onMouseDown에서 처음 클릭한 위치가 할당된다.
      // walk = 마우스를 끌고간 최종 위치 - 처음 마우스를 클릭한 위치
      // 오른쪽에서 왼쪽으로 끌면 음수이다.
      const walk = e.pageX - clickPoint;

      // walk가 음수, 즉 오른쪽에서 왼쪽으로 끌었을때 scrollLeft - walk는 양수가 되면서
      // containerRef의 scrollLeft가 증가하면서 스크롤이 오른쪽으로 움직인다.
      containerRef.current.scrollLeft = scrollLeft - walk;
    }
  };
  const [onToast, setOnToast] = useState(false);
  useEffect(() => {
    if (onToast) {
      setTimeout(() => {
        setOnToast(false);
      }, 3000);
    }
    console.log(onToast);
  }, [onToast]);

  return (
    <div
      className="w-100per flex-column z-20 bg-FFFFFF"
      style={{
        position: 'sticky',
        top: isMobile(window.innerWidth) ? '0' : '0',
      }}
    >
      {' '}
      {onToast && (
        <div className="flex-center z-100 ">
          <Toast text="만 60세 이상의 가입나이를 입력해주세요." bottom="100" />
        </div>
      )}
      <div className="flex-row gap-24 padding-x-16 start-x border-b-1-171E2610 relative">
        {/* //padding-22-16-0  */}
        {[
          { ty: 'tab1', t: '농지연금 계산기', r: tab1, i: 'tab1' },
          { ty: 'tab2', t: '연금 시뮬레이션', r: tab2, i: 'tab2' },
        ].map((el) => (
          <div
            className="flex-row center-x center-y padding-y-8 hover"
            onClick={() => {
              // moveMenu(el.i);
              if (el.ty === 'tab2') {
                if (isValid) {
                  setMenu(el.ty);
                } else {
                  setOnToast(true);
                  return;
                }
              }
              setMenu(el.ty);
              // if (el.ty === "tab1") {
              //   scrollTo("menuRef1");
              // } else {
              //   scrollTo("menuRef3");
              // }
            }}
          >
            <div
              className="font-bold f-size-18  line-h-26 nowrap"
              style={{ color: menu === el.ty ? '#171E26' : '#171E2666' }}
              ref={el.r}
            >
              {el.t}
            </div>
          </div>
        ))}
        {!mypage && (
          <div
            className="slider-black2"
            style={{ width: tabWidth, left: left }}
          />
        )}
      </div>
    </div>
  );
};
export default ReportNav;
