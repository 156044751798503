import { useState } from 'react';
import {
  RowTableGreenBold,
  RowSectionTitle,
  RowTableValue2,
  RowTableValue,
  RowTableValueBold,
} from '../../../../component/report/Row';
import { TextSectionTitle } from '../../../../component/report/Text';
import { transNum } from '../../../../component/function/function';
import { ICostAnalysis, ILandInfo } from '../../Main';
import { Tooltip2 } from '../../../../component/common/tooltip';
import { BoxGreen1, BoxTable4 } from '../../../../component/report/Box';

const ReportDetail6 = ({
  data,
  data2,
  selectedDesignFloor,

  mypage,
}: {
  data: ICostAnalysis;
  data2: ILandInfo;
  selectedDesignFloor?: number;

  mypage?: boolean;
}) => {
  console.log('data', data.eligibility);
  const [onToolTip, setOnToolTip] = useState(false);

  const table1 = {
    th: ['구분', '금액(원)', '비고'],
    tb: [
      [
        '주택 매입비',
        transNum(
          data.ownHouseYN === 'N'
            ? data.houseCostLoanInfo.details.houseCost
            : 0,
        ),
        'AI 주택 추정가',
      ],
      [
        '건축물취득세',
        transNum(
          data.ownHouseYN === 'N'
            ? data.houseCostLoanInfo.details.pHouseAcquisitionTax
            : 0,
        ),
        '주택 매입비 × 1.0%',
      ],
      [
        '지방교육세',
        transNum(
          data.ownHouseYN === 'N'
            ? data.houseCostLoanInfo.details.pLocalEducationTax
            : 0,
        ),
        data2.landArea <= 85 ? '주택 매입비 × 0.1%' : '주택 매입비 × 0.3%',
      ],
      [
        '농어촌특별세',
        transNum(
          data.ownHouseYN === 'N'
            ? data.houseCostLoanInfo.details.pFarmSpecialTax
            : 0,
        ),
        data2.landArea <= 85 ? '주택 매입비 × 0.1%' : '주택 매입비 × 0.3%',
      ],
    ],
    tf: [
      '합계',
      transNum(
        data.ownHouseYN === 'N' ? data.houseCostLoanInfo.totalHouseCost : 0,
      ),
    ],
  };
  const table2 = {
    th: ['구분', '금액(원)', '비고'],
    tb: [
      [
        '경계측량비',
        transNum(data.constructionLoanInfo.details.boundaryCost),
        '시세 기준',
      ],
      [
        '설계비',
        transNum(data.constructionLoanInfo.details.designCost),
        '10만원/평 × 주택면적',
      ],
      [
        '건축인허가비',
        transNum(data.constructionLoanInfo.details.housePermitFee),
        '10만원/평 × 주택면적',
      ],
      [
        '공사비',
        transNum(data.constructionLoanInfo.details.constructionCost),
        '600만원/평 × 주택면적',
      ],
      [
        '건축물취득세',
        transNum(data.constructionLoanInfo.details.cHouseAcquisitionTax),
        '공사비 × 2%',
      ],
      [
        '농어촌특별세',
        transNum(data.constructionLoanInfo.details.cFarmSpecialTax),
        '건축물취득세 × 10%',
      ],
      [
        '등록세',
        transNum(data.constructionLoanInfo.details.registrationTax),
        '공사비 × 0.8',
      ],
      [
        '지방교육세',
        transNum(data.constructionLoanInfo.details.cLocalEducationTax),
        '등록세 × 20%',
      ],
    ],
    tf: ['합계', transNum(data.constructionLoanInfo.totalConstructionCost)],
  };
  console.log('twtw', selectedDesignFloor);
  return (
    <div className="padding-b-24 browser-bg-FFFFFF">
      <RowSectionTitle>
        <TextSectionTitle>6. 사업비 진단</TextSectionTitle>
      </RowSectionTitle>
      <div className="padding-16-16-0">
        <div className="flex-row">
          <RowTableGreenBold title="건축법규 적합 여부" value="적합" />
        </div>
      </div>

      <div className="padding-8-16 flex-column gap-8">
        <RowTableValue2
          title="주택설계면적"
          value={`${Math.round(data.myTotalFloorArea * 10) / 10} m²`}
        />
        <RowTableValue2
          title="용적률"
          value={`${Math.round(data.myFloorAreaRatio * 10) / 10} %`}
        />
        <RowTableValue2
          title="건폐율"
          value={
            (mypage ? data.eligibility.floor : selectedDesignFloor) === 1
              ? `${Math.round(data.myBuildingToLandRatio * 10) / 10} %`
              : `${Math.round(data.myBuildingToLandRatio * 10) / 10 / 2} %`
          }
        />
      </div>

      <div className="padding-8-16 gap-10">
        <BoxGreen1>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            해당 지역의 법정 용적률은{' '}
            {Math.round(data.stdFloorAreaRatio * 10) / 10}%, <br />
            법정 건폐율은 {Math.round(data.stdBuildingToLandRatio * 10) / 10}%
            입니다. <br />
            선택하신 건축설계는{' '}
            <span
              className="font-bold f-size-16 line-h-24 "
              style={{
                color: true ? '#1E9B6B' : '#d62554',
              }}
            >
              지역 건축법규에 적합합니다.
            </span>
          </div>
        </BoxGreen1>
      </div>
      <div className="padding-16 ">
        <div
          style={{ background: '#171e2610', width: '100%', height: '1px' }}
        ></div>
      </div>
      <div className="padding-16-16-0 flex-row relative">
        <div className="font-bold f-size-16 line-h-24 color-text-secondary">
          예상 사업비
        </div>
        <img
          className="pointer"
          src="/asset/images/warn/Warn20.svg"
          alt=""
          onClick={() => {
            setOnToolTip(!onToolTip);
          }}
        />
        <div style={{ position: 'absolute', zIndex: '1' }}>
          <Tooltip2
            top="30"
            left="0"
            width
            on={onToolTip}
            off={() => setOnToolTip(false)}
            text="2023년도 국토부 고시기준 건물신축단가표 용도별 평균값 적용"
          />
        </div>
      </div>

      <div className="padding-8-16-16 gap-8 flex-column">
        <RowTableValueBold
          title="1) 총 사업비"
          value={`${transNum(
            (data.ownHouseYN === 'N'
              ? data.houseCostLoanInfo.totalHouseCost
              : 0) + data.constructionLoanInfo.totalConstructionCost,
          )}원`}
        />
        {data.ownHouseYN === 'N' ? (
          <RowTableValue
            title={`주택 매입비 (${
              Math.round(
                ((data.houseCostLoanInfo.totalHouseCost * 100) /
                  (data.houseCostLoanInfo.totalHouseCost +
                    data.constructionLoanInfo.totalConstructionCost)) *
                  10,
              ) / 10
            }%)`}
            value={`${transNum(data.houseCostLoanInfo.totalHouseCost)}원`}
          />
        ) : (
          ''
        )}
        <RowTableValue
          title={`시공비 (${
            Math.round(
              ((data.constructionLoanInfo.totalConstructionCost * 100) /
                ((data.ownHouseYN === 'N'
                  ? data.houseCostLoanInfo.totalHouseCost
                  : 0) +
                  data.constructionLoanInfo.totalConstructionCost)) *
                10,
            ) / 10
          }%)`}
          value={`${transNum(
            data.constructionLoanInfo.totalConstructionCost,
          )}원`}
        />
      </div>
      <div className="padding-8-16">
        <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
          ※ 평균가에 기반한 상기 견적은 참고용으로 제공되며, 실제 견적과는
          차이가 있을 수 있음 <br />
          ※ 공인중개사, 법무 등 수수료는 별도 고려해야 함 <br />※ 조경 및 가구
          인테리어 비용은 제외함
        </div>
      </div>
      {data.ownHouseYN === 'N' ? (
        <>
          <div className="padding-8-16">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              2) 주택 매입비 세부 내역
            </div>
          </div>
          <div className="flex-column padding-8-16">
            <BoxTable4 table={table1} />
          </div>
        </>
      ) : (
        ''
      )}
      <div className="padding-8-16">
        <div className="font-bold f-size-16 line-h-24 color-text-secondary">
          {data.ownHouseYN === 'Y' ? '2' : '3'}) 시공비 세부 내역
        </div>
      </div>
      <div className="flex-column padding-8-16">
        <BoxTable4 table={table2} temp />
      </div>
    </div>
  );
};

export default ReportDetail6;
