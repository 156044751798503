import { createSlice } from '@reduxjs/toolkit';

interface BoardDataType {
  inquiryData: Array<Object>;
  communityCurrentPage: number;
  inquiryCurrentPage: number;
  auctionCurrentPage: number;
  auctionTotal: number;
  communityTotal: number;
  inquiryTotal: number;
  boardCategory: string;
  boardTitle: string;
  boardContent: string;
  [props: string]: any;
}

const boardInitialState: BoardDataType = {
  communityCurrentPage: 1,
  inquiryCurrentPage: 1,
  auctionCurrentPage: 1,
  auctionTotal: 0,
  communityTotal: 0,
  inquiryTotal: 0,
  boardCategory: '역경매',
  inquiryData: [],
  boardContent: '',
  boardTitle: '',
};

const boardSlice = createSlice({
  name: 'board',
  initialState: boardInitialState,
  reducers: {
    Page(state, action) {
      if (state.boardCategory === '역경매') {
        state.auctionCurrentPage = action.payload;
      } else if (state.boardCategory === '문의 내역') {
        state.inquiryCurrentPage = action.payload;
      } else if (state.boardCategory === '커뮤니티') {
        state.communityCurrentPage = action.payload;
      }
    },
    BoardCategory(state, action) {
      if (action.payload === '역경매') {
        state.boardCategory = '역경매';
      } else if (action.payload === '문의 내역') {
        state.boardCategory = '문의 내역';
      } else if (action.payload === '커뮤니티') {
        state.boardCategory = '커뮤니티';
      }
    },
    GetCommunityTotal(state, action) {
      state.communityTotal = action.payload;
    },
    GetInquiryTotal(state, action) {
      state.inquiryTotal = action.payload;
    },
    GetAuctionTotal(state, action) {
      state.auctionTotal = action.payload;
    },

    GetInquiryData(state, action) {
      state.inquiryData = action.payload;
    },
    GetBoardTitle(state, action) {
      state.boardTitle = action.payload;
    },
    GetBoardContent(state, action) {
      state.boardContent = action.payload;
    },
  },
});

export const {
  Page,
  BoardCategory,
  GetCommunityTotal,
  GetInquiryTotal,
  GetAuctionTotal,
  GetBoardContent,
  GetInquiryData,
} = boardSlice.actions;
export default boardSlice.reducer;
