import { useEffect, useState } from 'react';
import LandService from '../../../../../service/land/landApi';
import './LandUseOption.css';

interface LandUseOptionProps {
  pnu: string;
  setIsAvaiableLandUse: (value: boolean) => void;
}

interface OptionDataProps {
  type: string;
  score: number;
  isRecommended: string;
}

const changeTypeText = (type: string) => {
  if (type === 'S') {
    return '태양광발전';
  }
  if (type === 'W') {
    return '창고';
  }
  if (type === 'P') {
    return '주차장/차고지';
  }
  if (type === 'C') {
    return '캠핑장(야영장)';
  }
  if (type === 'L') {
    return '농어촌민박업';
  }
  if (type === 'E') {
    return '펜션(관광펜션)';
  }
};
const renderImage = (type: string) => {
  if (type === 'S') {
    return '/asset/images/landUse/solar.svg';
  }
  if (type === 'W') {
    return '/asset/images/landUse/garage.svg';
  }
  if (type === 'P') {
    return '/asset/images/landUse/parking.svg';
  }
  if (type === 'C') {
    return '/asset/images/landUse/camping.svg';
  }
  if (type === 'L') {
    return '/asset/images/landUse/farmstay.svg';
  }
  if (type === 'E') {
    return '/asset/images/landUse/pension.svg';
  }
};

const LandUseOption = ({ pnu, setIsAvaiableLandUse }: LandUseOptionProps) => {
  const [data, setData] = useState<OptionDataProps[]>([]);

  const getLandUseOption = async () => {
    try {
      const res = await LandService.getLandUseOption(pnu);
      setData(res.result);
      if (res.result.length === 0) {
        setIsAvaiableLandUse(false);
      } else {
        setIsAvaiableLandUse(true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getLandUseOption();
  }, [pnu]);

  return (
    <div className="land-option-container">
      <div className="land-option-title">토지활용 옵션</div>
      {data.length > 0 ? (
        <div className="land-option-content-box">
          {data.map((item, index) => (
            <div key={index} className="land-option-content-item">
              <img src={renderImage(item.type)} alt="" />
              <div className="land-option-content-item-text-box">
                <div className="land-option-content-item-text">
                  <div className="land-option-content-item-title">
                    <span>{changeTypeText(item.type)}</span>
                    {item.isRecommended === 'Y' && (
                      <span className="ai-recommend-tag">AI추천</span>
                    )}
                  </div>
                  <span
                    className="land-option-percatage"
                    style={{
                      color:
                        item.isRecommended === 'Y'
                          ? '#1E9B6B'
                          : 'rgba(55, 56, 60, 0.28)',
                    }}
                  >
                    {item.score}%
                  </span>
                </div>
                <div className="land-option-content-item-bar">
                  <div
                    className="per-bar"
                    style={{
                      width: `${item.score}%`,
                      backgroundColor:
                        item.isRecommended === 'Y'
                          ? '#1e9b6b'
                          : 'rgba(55, 56, 60, 0.16)',
                    }}
                  ></div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className="no-data-message">
          해당 토지에 적합한 활용방안이 없습니다.
        </div>
      )}
    </div>
  );
};

export default LandUseOption;
