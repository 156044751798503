const Intro = () => {
  return (
    <div className="padding-x-16">
      <div className="flex-column report_result_box bg-white browser-bg-FFFFFF  border-radius-8 gap-8 padding-16">
        <div className="flex-coloumn gap-4">
          <img src="/asset/images/logo/Logo24.svg" />
          <div className="font-bold f-size-16 line-h-24 color-text-primary">
            내 농지만 있으면 가입할 수 있나요?
          </div>
          <div className="font-medium f-size-14 line-h-20 color-text-secondary">
            '연령, 영농, 대상 농지' 조건을 모두 갖춰야 해요. <br />
            하지만 현재 가입조건 미충족이더라도 괜찮아요. <br />
            가상으로 농지연금 수령 계획을 세워볼 수 있어요!
          </div>
        </div>
      </div>
    </div>
  );
};

export default Intro;
