import { createSlice } from '@reduxjs/toolkit';

const scrollInitialState = {
  isDrag: false,
  startX: '',
};

const scrollSlice = createSlice({
  name: 'scroll',
  initialState: scrollInitialState,
  reducers: {
    Drag(state, action) {
      state.isDrag = action.payload;
    },
    StartX(state, action) {
      state.startX = action.payload;
    },
  },
});

export const { Drag, StartX } = scrollSlice.actions;
export default scrollSlice.reducer;
