import { useEffect, useState } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  ComposedChart,
  Line,
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  Scatter,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { isMobile, transNum } from '../../../component/function/function';
import { ITotaldata } from '../Main';
import PensionService from '../../../service/pension/pensionApi';
import Toast from '../../../component/common/Toast';
import { RowTableX } from '../../../component/report/Row';
import { ButtonSelect3, ButtonSelect4 } from '../../../component/report/Button';
import { BoxGreen1, BoxTable3M } from '../../../component/report/Box';
import CunsultingDetail from './CunsultingDetail';

const Cunsulting = ({
  data,
  period,
  reportIdx,
  report,
  mypage,
  divRef4,
  divRef5,
  divRef6,
  tmpAuctionPrice,
  setTmpAuctionPrice,
  setPurpose,
  saveReport,
}: {
  data: ITotaldata;
  period: any;
  reportIdx: any;
  report: any;
  mypage?: boolean;
  divRef4?: any;
  divRef5?: any;
  divRef6?: any;
  tmpAuctionPrice: any;
  setTmpAuctionPrice: any;
  setPurpose?: any;
  saveReport?: any;
}) => {
  const divRefArr = [divRef4, divRef5, divRef6];
  let arr: any = [];
  const [pensionType, setPensionType] = useState('');
  const [pensionTypeArr, setPensionTypeArr]: Array<any> = useState([]);
  const table = {
    th: [
      { mw: '26px', value: '회차' },
      { mw: '60px', value: '연도' },
      { mw: '108px', value: '농지연금 채무 누계' },
      { mw: '108px', value: '예상 농지가격' },
      { mw: '60px', value: '채무비율' },
    ],
    tb: arr,
  };

  const [tableR, setTableR] = useState(table);
  const [tableR1, setTableR1] = useState(table);
  const [tooltip, setTooltip] = useState(false);
  let pensionTab = {
    fixedAmountForLife: {
      title: '종신정액형',
      select: pensionType === 'fixedAmountForLife',
      data: data.fixedAmountForLife?.fixedAmountForLife,
      pension: data.fixedAmountForLife?.pension,
      investRate: data.fixedAmountForLife?.investRate,
      investInfo: data.fixedAmountForLife?.investInfo,
    },
    backAndForthThinning: {
      title: '전후후박형',
      select: pensionType === 'backAndForthThinning',
      data: data.backAndForthThinning?.backAndForthThinning,
      pensionBefore: data.backAndForthThinning?.pension?.before,
      pensionAfter: data.backAndForthThinning?.pension?.after,
      investRateBefore: data.backAndForthThinning?.investRate?.before,
      investInfoBefore: data.backAndForthThinning?.investInfo?.before,
      investRateAfter: data.backAndForthThinning?.investRate?.after,
      investInfoAfter: data.backAndForthThinning?.investInfo?.after,
      investInfo: data.backAndForthThinning?.investInfo,
    },
    occasionalWithdrawal: {
      title: '수시인출형',
      select: pensionType === 'occasionalWithdrawal',
      data: data.occasionalWithdrawal?.occasionalWithdrawal,
      pension: data.occasionalWithdrawal?.pension,
      investRate: data.occasionalWithdrawal?.investRate,
      investInfo: data.occasionalWithdrawal?.investInfo,
    },
    fixedTerm: {
      title: '기간정액형',
      select: pensionType === 'fixedTerm',
      data: data.fixedTerm?.fixedTerm?.[
        `year_${period.fixedTypePeriod}` as 'year_15'
      ],
      pension:
        data.fixedTerm?.pension?.[
          `year_${period.fixedTypePeriod}` as 'year_15'
        ],
      investRate:
        data.fixedTerm?.investRate?.[
          `year_${period.fixedTypePeriod}` as 'year_15'
        ],
      investInfo:
        data.fixedTerm?.investInfo?.[
          `year_${period.fixedTypePeriod}` as 'year_15'
        ],
    },
    managementTransfer: {
      title: '경영이양형',
      select: pensionType === 'managementTransfer',
      data: data.managementTransfer?.managementTransfer?.[
        `year_${period.managementTypePeriod}` as 'year_15'
      ],
      pension:
        data.managementTransfer?.pension?.[
          `year_${period.managementTypePeriod}` as 'year_15'
        ],
      investRate:
        data.managementTransfer?.investRate?.[
          `year_${period.managementTypePeriod}` as 'year_15'
        ],
      investInfo:
        data.managementTransfer?.investInfo?.[
          `year_${period.managementTypePeriod}` as 'year_15'
        ],
    },
  };
  const [currentPension, setCurrentPension]: any = useState(pensionTab);

  const [farmValueIncrease, setFarmValueIncrease] = useState('A');

  useEffect(() => {
    const keyArr = Object.keys(pensionTab).sort(
      (a, b) =>
        pensionTab[b as 'fixedAmountForLife'].pension -
        pensionTab[a as 'fixedAmountForLife'].pension,
    );
    let obj: any = {};
    keyArr.forEach((el: any) => {
      obj[el] = pensionTab[el as 'fixedAmountForLife'];
    });
    console.log('obj', obj);
    setCurrentPension(obj);
    const tempKeyArr = keyArr.filter((el: string) => {
      if (el === 'fixedTerm' && !period.fixedTypePeriod) {
        return false;
      }
      if (el === 'managementTransfer' && !period.managementTypePeriod) {
        return false;
      }
      return true;
    });

    setPensionTypeArr(tempKeyArr);
    setPensionType(keyArr[0]);
    setTmpAuctionPrice(data.tmpAuctionPrice / 10000);
  }, [data]);

  useEffect(() => {
    const keyArr = Object.keys(pensionTab).sort(
      (a, b) =>
        pensionTab[b as 'fixedAmountForLife'].pension -
        pensionTab[a as 'fixedAmountForLife'].pension,
    );
    let obj: any = {};
    keyArr.forEach((el: any) => {
      obj[el] = pensionTab[el as 'fixedAmountForLife'];
    });
    setCurrentPension(obj);
    let x = [];
    let y = [];
    if (pensionType !== '') {
      x = obj[pensionType as 'fixedTerm']?.data?.debtRatioResult.map(
        (el: any) => {
          return [
            { mw: '26px', value: el.round },
            { mw: '60px', value: el.year },
            { mw: '108px', value: `${transNum(el.sumOfPension)}원` },
            { mw: '108px', value: `${transNum(el.farmValue)}원` },
            { mw: '60px', value: `${el.debtRatio}%` },
          ];
        },
      );
    }
    let tab = {
      th: [
        { mw: '26px', value: '회차' },
        { mw: '60px', value: '연도' },
        { mw: '108px', value: '농지연금 채무 누계' },
        { mw: '108px', value: '예상 농지가격' },
        { mw: '60px', value: '채무비율' },
      ],
      tb: x,
    };

    if (pensionType !== '') {
      y = obj[pensionType as 'fixedTerm']?.data?.pensionResult.map(
        (el: any) => {
          return [
            { mw: '26px', value: el.round },
            { mw: '60px', value: el.year },
            { mw: '88px', value: `${transNum(el.pension)}원` },
            { mw: '88px', value: `${transNum(el.interest)}원` },
            { mw: '88px', value: `${transNum(el.riskCharge)}원` },
            { mw: '88px', value: `${transNum(el.sum)}원` },
          ];
        },
      );
    }
    let tab2 = {
      th: [
        { mw: '26px', value: '회차' },
        { mw: '60px', value: '연도' },
        { mw: '88px', value: '연금' },
        { mw: '88px', value: '이자' },
        { mw: '88px', value: '위험부담금' },
        { mw: '88px', value: '채무액 누계' },
      ],
      tb: y,
    };

    const farmValueArr = obj[pensionType as 'fixedTerm']?.data?.debtRatioResult;

    if (Array.isArray(farmValueArr)) {
      const calFarmValueIncreaseRate =
        ((farmValueArr[farmValueArr.length - 1].farmValue -
          farmValueArr[0].farmValue) /
          farmValueArr[0].farmValue /
          (farmValueArr.length - 1)) *
        100;
      if (calFarmValueIncreaseRate >= 4) {
        setFarmValueIncrease('A');
      } else if (calFarmValueIncreaseRate >= 1) {
        setFarmValueIncrease('B');
      } else {
        setFarmValueIncrease('C');
      }
    }
    setTableR(tab);
    setTableR1(tab2);
  }, [pensionType]);
  const [isFocus, setIsFocus] = useState(false);
  // for (let key in data.officialPriceList) {
  //   arr.push([{ value: "", mW: "2px" }]);
  //   arr2.push({
  //     key: key,
  //     price:
  //       areaType === "P"
  //         ? data.officialPriceList[key as "2014"]
  //         : data.officialPriceList[key as "2014"] * 3.3,
  //   });
  // }
  // arr = arr.sort((a, b) => Number(b[0]) - Number(a[0]));

  const patchTmpAuctionPrice = async (tmpAuctionPrice: any) => {
    try {
      if (tmpAuctionPrice >= 0) {
        await PensionService.patchTmpAuctionPrice(
          tmpAuctionPrice * 10000,
          reportIdx,
        );
        await report();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const [onToast, setOnToast] = useState(false);
  useEffect(() => {
    if (onToast) {
      setTimeout(() => {
        setOnToast(false);
      }, 3000);
    }
  }, [onToast]);

  return (
    <div className="flex-column">
      {onToast && (
        <div className="flex-center z-100 ">
          <Toast
            text="내 농지연금 계산기에서 수령기간을 선택해주세요."
            bottom={mypage ? '150' : '100'}
          />
        </div>
      )}
      {data.type === 'A' ? (
        <>
          <div>
            <div className="padding-16-16-0">
              <div className="font-bold f-size-16 line-h-24 color-text-primary">
                잠깐! 농지를 어떻게 활용하실 계획이신가요?
              </div>
            </div>
            <div className="padding-16 gap-8 flex-row">
              <ButtonSelect3
                onClick={() => setPurpose('F')}
                children="농사"
                selected={data.purpose === 'F'}
                width
              />
              <ButtonSelect3
                onClick={() => setPurpose('R')}
                children="농지 임대"
                selected={data.purpose === 'R'}
                width
              />
            </div>
            <div className="padding-8-16">
              <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
                ※ 공사에 농지를 임대하는 기간 중 월지급금의 5%를 추가
                지급해주며, 임대기간이 종료되면 우대율 적용 또한 종료됩니다.
              </div>
            </div>
          </div>
          <div style={{ height: '16px', background: '#F7F8F9' }} />
        </>
      ) : (
        ''
      )}
      <div className="padding-24-16-8">
        <div className="font-bold f-size-18 line-h-26 color-text-primary">
          농지연금 상품별 예상 수익 분석
        </div>
      </div>
      <div className="padding-16 flex-row gap-8 flex-wrap">
        {Object.keys(currentPension).map((el: string) => (
          <ButtonSelect4
            onClick={() => {
              if (el === 'fixedTerm' && !period.fixedTypePeriod) {
                setOnToast(true);
                return;
              }
              if (el === 'managementTransfer' && !period.managementTypePeriod) {
                setOnToast(true);
                return;
              }

              setPensionType(el);
            }}
            children={currentPension[el as 'fixedAmountForLife'].title}
            selected={currentPension[el as 'fixedAmountForLife'].select}
          />
        ))}
      </div>
      <div className="padding-16-16-0">
        <div className="font-bold f-size-16 line-h-24 color-text-primary">
          1. 연금 수익
        </div>
      </div>
      {data.type === 'A' ? (
        <>
          <div className="padding-16-16-8 gap-8 flex-column">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              1) 농지연금
            </div>
            <RowTableX
              title="경공매 감정가액"
              value={`${transNum(data.farmValue)}원`}
            />
            <RowTableX
              title="예상 낙찰가액"
              value={
                isFocus ? (
                  <div
                    className="flex-row border-radius-8 border-171E26 padding-6-8 gap-4 "
                    style={{ justifyContent: 'end' }}
                  >
                    <input
                      className="border-none text-end font-medium f-size-14 line-h-24 color-text-primary "
                      placeholder="입력해주세요"
                      style={{ width: '152px' }}
                      value={
                        typeof tmpAuctionPrice === 'number'
                          ? Number(tmpAuctionPrice).toLocaleString()
                          : ''
                      }
                      onChange={(e: any) => {
                        let num = Number(e.target.value.split(',').join(''));
                        // for (let a = 0; num < 1000; a++) {
                        //   if (num === 0) {
                        //     break;
                        //   }
                        //   num = num * 10;
                        // }
                        setTmpAuctionPrice(num);
                      }}
                      onBlur={() => {
                        console.log('onblur');
                        saveReport('N');
                        setIsFocus(false);
                      }}
                    />
                    <div className="font-medium f-size-14 line-h-24 color-text-primary">
                      만원
                    </div>
                  </div>
                ) : (
                  <div
                    className="flex-row border-radius-8 border-171E26 padding-6-8 gap-4 "
                    style={{ justifyContent: 'end' }}
                  >
                    <input
                      className="border-none text-end font-medium f-size-14 line-h-24 color-text-primary "
                      style={{ width: '152px' }}
                      placeholder="입력해주세요"
                      value={Number(tmpAuctionPrice || 0).toLocaleString()}
                      type="string"
                      onFocus={() => setIsFocus(true)}
                    />
                    <div className="font-medium f-size-14 line-h-24 color-text-primary">
                      만원
                    </div>
                  </div>
                )
              }
            />
            {pensionType === 'backAndForthThinning' ? (
              <RowTableX
                start
                title="연간 연금수령액"
                value={
                  <div className="">
                    <div>
                      {transNum(
                        currentPension[pensionType || 'fixedAmountForLife']
                          .pensionBefore,
                      )}
                      원 (10년간)
                    </div>
                    <div>
                      {transNum(
                        currentPension[pensionType || 'fixedAmountForLife']
                          .pensionAfter,
                      )}
                      원 (10년후)
                    </div>
                  </div>
                }
              />
            ) : pensionType === 'occasionalWithdrawal' ? (
              <>
                <RowTableX
                  title="연간 연금수령액"
                  value={`${transNum(
                    currentPension[pensionType || 'fixedAmountForLife'].pension,
                  )}원`}
                />
                <RowTableX
                  title="목돈 인출금"
                  value={`${transNum(
                    currentPension[pensionType || 'fixedAmountForLife'].data
                      .withdrawal,
                  )}원`}
                />
              </>
            ) : (
              <RowTableX
                title="연간 연금수령액"
                value={`${transNum(
                  currentPension[pensionType || 'fixedAmountForLife'].pension,
                )}원`}
              />
            )}
          </div>
          {data.purpose === 'F' ? (
            <div className="padding-16-16-8 gap-8 flex-column relative">
              <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                2) 농사수입
              </div>
              {/* <RowTableX
                warn
                title='예상 농사 순이익'
                value={`${transNum(data.farmRealIncome)}원`}
              /> */}
              <div className="flex-row space-between ">
                <div className="flex-row gap-2">
                  <div className="m-b1-16-m color-text-tertiary">
                    예상 농사 순이익
                  </div>
                  <img
                    className="hover"
                    onClick={() => {
                      setTooltip(!tooltip);
                    }}
                    src="/asset/images/warn/Warn24.svg"
                    alt=""
                  />
                </div>
                <div className="m-b1-16-b color-dark-secondary">
                  {transNum(data.farmRealIncome)}원
                </div>
              </div>
              {tooltip && (
                <div
                  className={`${
                    tooltip
                      ? 'tooltip absolute speech-bubble-pension font-bold f-size-11 line-h-12 border-radius-2 flex-row gap-8 visible fade-in animate-tooltip'
                      : ''
                  }`}
                  style={{
                    backgroundColor: '#3D5066',
                    borderRadius: '8px',
                    right: '60px',
                    padding: '8px',
                    marginLeft: 0,
                    marginBottom: 0,
                    top: '80px',
                  }}
                >
                  <div className="m-c1-12-b color-FFFFFF">
                    분석 시점의 결과는 현재와 다를 수 있어요. <br />
                    최신 분석 결과는 주소지 조회 후 다시 이용해 주세요.
                  </div>
                  <img
                    className="pointer"
                    alt="close"
                    src="/asset/images/close/CloseWhite12.svg"
                    onClick={() => {
                      setTooltip(false);
                    }}
                  />
                </div>
              )}
            </div>
          ) : (
            ''
          )}
          <div className="padding-16-16-8 gap-8 flex-column">
            <div className="font-bold f-size-16 line-h-24 color-text-secondary">
              {data.purpose === 'F' ? 3 : 2}) 투자수익률
            </div>
            {data.purpose === 'F' ? (
              <>
                <RowTableX
                  title="총 투자금"
                  value={
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investInfo
                      ? `${transNum(data.tmpAuctionPrice || 0)}원`
                      : '예상 낙찰가액을 입력해주세요'
                  }
                />
                <RowTableX
                  start
                  title="총 연간 수입액"
                  value={
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investInfo ||
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investInfoBefore ? (
                      pensionType === 'backAndForthThinning' ? (
                        <>
                          <div>
                            {transNum(
                              currentPension[
                                pensionType || 'fixedAmountForLife'
                              ].investInfoBefore.totalIncome,
                            )}
                            원 (10년간)
                          </div>
                          <div>
                            {transNum(
                              currentPension[
                                pensionType || 'fixedAmountForLife'
                              ].investInfoAfter.totalIncome,
                            )}
                            원 (10년후)
                          </div>
                        </>
                      ) : (
                        `${transNum(
                          currentPension[pensionType || 'fixedAmountForLife']
                            .investInfo.totalIncome,
                        )}원`
                      )
                    ) : (
                      '예상 낙찰가액을 입력해주세요'
                    )
                  }
                />
              </>
            ) : (
              <>
                <RowTableX
                  title="투자금"
                  value={
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investInfo ||
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investInfoBefore
                      ? `${transNum(data.tmpAuctionPrice || 0)}원`
                      : '예상 낙찰가액을 입력해주세요'
                  }
                />
                <RowTableX
                  title="담보농지 가치"
                  value={`${transNum(data.farmValue)}원`}
                />
                {pensionType === 'backAndForthThinning' ? (
                  <RowTableX
                    start
                    title="연간 연금수령액"
                    value={
                      <>
                        <div>
                          {transNum(
                            currentPension[pensionType || 'fixedAmountForLife']
                              .pensionBefore,
                          )}
                          원 (10년간)
                        </div>
                        <div>
                          {transNum(
                            currentPension[pensionType || 'fixedAmountForLife']
                              .pensionAfter,
                          )}
                          원 (10년후)
                        </div>
                      </>
                    }
                  />
                ) : pensionType === 'occasionalWithdrawal' ? (
                  <>
                    <RowTableX
                      title="연간 연금수령액"
                      value={`${transNum(
                        currentPension[pensionType || 'fixedAmountForLife']
                          .pension,
                      )}원`}
                    />
                    <RowTableX
                      title="목돈 인출금"
                      value={`${transNum(
                        currentPension[pensionType || 'fixedAmountForLife']
                          .withdrawal,
                      )}원`}
                    />
                  </>
                ) : (
                  <RowTableX
                    title="연간 연금수령액"
                    value={`${transNum(
                      currentPension[pensionType || 'fixedAmountForLife']
                        .pension,
                    )}원`}
                  />
                )}
              </>
            )}
            {pensionType === 'backAndForthThinning' ? (
              <RowTableX
                start
                title="연간 투자수익률"
                value={
                  currentPension[pensionType || 'fixedAmountForLife']
                    .investInfoBefore ? (
                    <>
                      <div>
                        {
                          currentPension[pensionType || 'fixedAmountForLife']
                            .investInfoBefore.investRate
                        }
                        % (10년간)
                      </div>
                      <div>
                        {
                          currentPension[pensionType || 'fixedAmountForLife']
                            .investInfoAfter.investRate
                        }
                        % (10년후)
                      </div>
                    </>
                  ) : (
                    '예상 낙찰가액을 입력해주세요'
                  )
                }
              />
            ) : (
              <RowTableX
                title="연간 투자수익률"
                value={
                  currentPension[pensionType || 'fixedAmountForLife'].investInfo
                    ? `${
                        currentPension[pensionType || 'fixedAmountForLife']
                          .investInfo.investRate
                      }%`
                    : '예상 낙찰가액을 입력해주세요'
                }
              />
            )}
          </div>
          <div className="padding-8-16 flex-column gap-4">
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 해당 농지의 농사 유형 및 작물 별로 농사 순이익은 변동합니다.
            </div>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 비닐하우스 등 시설농사 시, 투자 수익률은 상승할 수 있습니다.
            </div>
          </div>
          {currentPension[pensionType || 'fixedAmountForLife'].investInfo ||
          currentPension[pensionType || 'fixedAmountForLife']
            .investInfoBefore ? (
            <>
              <div className="padding-16-16-8  flex-row space-between">
                <div className="font-bold f-size-16 line-h-24 color-text-secondary">
                  4) 투자원금 대비 투자수익 BEP 분석
                </div>
                <div className="font-bold f-size-16 line-h-24 color-primary-400">
                  BEP 약{' '}
                  {
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investInfo.timeToBEP
                  }
                  년
                </div>
              </div>

              <ComposedChart
                className="flex-medium f-size-12 center-x"
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                width={
                  isMobile(window.innerWidth)
                    ? window.innerWidth - 32
                    : 360 - 16
                }
                height={181}
                data={(() => {
                  let arr =
                    pensionType === 'backAndForthThinning'
                      ? [
                          ...currentPension[pensionType as 'fixedAmountForLife']
                            ?.investInfoBefore.BEP,
                          currentPension[pensionType as 'fixedAmountForLife']
                            ?.investInfoAfter.BEP,
                        ]
                      : currentPension[pensionType as 'fixedAmountForLife']
                          ?.investInfo.BEP;
                  if (Array.isArray(arr) && arr.length > 0) {
                    arr[0] = {
                      ...arr[0],
                      totalInvest: Math.round(data.tmpAuctionPrice * 10) / 10,
                    };
                  }
                  return arr;
                })()}
              >
                <XAxis dataKey="year" scale="point" padding={{ left: 20 }} />
                <YAxis />
                <Tooltip
                  content={({ active, payload, label }) => {
                    if (active && payload && payload.length) {
                      return (
                        <div className="padding-8 border-1-171E26 flex-column gap-8 bg-FFFFFF">
                          <div className="font-medium f-size-11 line-h-12">{`${label}년차`}</div>
                          {payload[1] ? (
                            <div className="flex-column gap-8">
                              <div
                                className="font-medium f-size-11 line-h-12"
                                style={{ color: payload[0].color }}
                              >{`투자원금 : ${transNum(
                                Number(payload[0].value),
                              )}원`}</div>
                              <div
                                className="font-medium f-size-11 line-h-12"
                                style={{ color: payload[1].color }}
                              >{`수익 : ${transNum(
                                Number(payload[1].value),
                              )}원`}</div>
                            </div>
                          ) : (
                            <div
                              className="font-medium f-size-11 line-h-12"
                              style={{ color: payload[0].color }}
                            >{`수익 : ${transNum(
                              Number(payload[0].value),
                            )}원`}</div>
                          )}
                        </div>
                      );
                    }
                  }}
                />
                <ReferenceLine
                  y={Math.round(data.tmpAuctionPrice * 10) / 10}
                  label="BEP"
                  stroke="red"
                />
                <Bar dataKey="totalInvest" barSize={20} fill="#869CB6" />
                <Line type="monotone" dataKey="investIncome" stroke="#09CE7D" />
              </ComposedChart>
            </>
          ) : (
            ''
          )}
        </>
      ) : (
        <div className="padding-8-16-16 gap-8 flex-column">
          <RowTableX
            title="담보농지 가치"
            value={`${transNum(data.farmValue)}원`}
          />
          <RowTableX
            title="연간 연금수령액"
            start
            value={
              pensionType === 'backAndForthThinning' ? (
                <>
                  <div>
                    {transNum(
                      currentPension[pensionType || 'fixedAmountForLife']
                        .pensionBefore,
                    )}
                    원 (10년간)
                  </div>
                  <div>
                    {transNum(
                      currentPension[pensionType || 'fixedAmountForLife']
                        .pensionAfter,
                    )}
                    원 (10년후)
                  </div>
                </>
              ) : (
                `${transNum(
                  currentPension[pensionType || 'fixedAmountForLife'].pension,
                )}원`
              )
            }
          />
          {pensionType === 'occasionalWithdrawal' ? (
            <RowTableX
              title="목돈 인출금"
              value={`${transNum(
                currentPension[pensionType || 'fixedAmountForLife'].data
                  .withdrawal,
              )}원`}
            />
          ) : (
            ''
          )}
          <RowTableX
            title="연간 투자수익률"
            start
            value={
              pensionType === 'backAndForthThinning' ? (
                <>
                  <div>
                    {Math.round(
                      currentPension[pensionType || 'fixedAmountForLife']
                        .investRateBefore * 10,
                    ) / 10}
                    % (10년간)
                  </div>
                  <div>
                    {Math.round(
                      currentPension[pensionType || 'fixedAmountForLife']
                        .investRateAfter * 10,
                    ) / 10}
                    % (10년후)
                  </div>
                </>
              ) : (
                `${
                  Math.round(
                    currentPension[pensionType || 'fixedAmountForLife']
                      .investRate * 10,
                  ) / 10
                }%`
              )
            }
          />
        </div>
      )}

      <div className="padding-16-16-0">
        <div className="font-bold f-size-16 line-h-24 color-text-primary">
          2. 연금 채무 상환금 계획
        </div>
      </div>
      <div className="padding-24-16 flex-column gap-16">
        <div className="flex- row padding-12-16 gap-8 end-x">
          <div className="flex-row gap-4 center-y">
            <div
              style={{
                width: '12px',
                height: '2px',
                background: '#09CE7D',
              }}
            />
            <div className="font-medium f-size-11 line-h-12 color-primary-400">
              연금
            </div>
          </div>
          <div className="flex-row gap-4 center-y">
            <div
              style={{
                width: '12px',
                height: '2px',
                background: '#869CB6',
              }}
            />
            <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
              이자+위험부담금
            </div>
          </div>
        </div>
        <AreaChart
          className="flex-medium f-size-12 center-x"
          width={
            isMobile(window.innerWidth) ? window.innerWidth - 32 : 360 - 16
          }
          height={181}
          data={
            currentPension[pensionType as 'fixedAmountForLife']?.data
              .pensionResult
          }
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="0.6">
              <stop offset="5%" stopColor="#53df89" stopOpacity={1} />
              <stop offset="95%" stopColor="#53df89" stopOpacity={0.2} />
            </linearGradient>
          </defs>
          <defs>
            <linearGradient id="colorPv2" x1="0" y1="0" x2="0" y2="0.6">
              <stop offset="5%" stopColor="#869CB6" stopOpacity={1} />
              <stop offset="95%" stopColor="#869CB6" stopOpacity={0.4} />
            </linearGradient>
          </defs>
          <XAxis dataKey="year" />
          <YAxis tickFormatter={(value) => value.toLocaleString()} />
          <Tooltip
            labelFormatter={(label) => `${label}년`}
            formatter={(value) => `${value.toLocaleString()}원`}
          />
          <Area
            type="monotone"
            dataKey="sumOfPension"
            stroke="#53df89"
            fillOpacity={1}
            fill="url(#colorPv)"
            name="연금"
          />
          <Area
            type="monotone"
            dataKey="totalInterestAndRiskCharge"
            stroke="#869CB6"
            fillOpacity={1}
            fill="url(#colorPv2)"
            name="이자+위험부담금"
          />
        </AreaChart>
        <BoxTable3M table={tableR1} />
      </div>

      <div className="padding-16-16-0">
        <div className="font-bold f-size-16 line-h-24 color-text-primary">
          3. 실익 분석
        </div>
      </div>
      <div className="padding-16-16-0">
        <div className="font-bold f-size-14 line-h-20 color-text-secondary">
          1) 농지가치 vs 농지연금
        </div>
      </div>
      <div className="padding-24-16 flex-column gap-24">
        <div className="flex-column">
          <div className="flex-column gap-16">
            <div className="flex-row gap-8 center-y">
              {/* <div className="padding-4-8 border-radius-8 border-1-1E9B6B">
                <div className="font-bold f-size-12 line-h-14 color-primary-500">
                  유형{farmValueIncrease}
                </div>
              </div> */}
              <div className="font-bold f-size-14 line-h-20 color-text-secondary">
                {farmValueIncrease === 'A'
                  ? '고'
                  : farmValueIncrease === 'B'
                    ? '중'
                    : '저'}
                성장형 농지{' '}
                <span className="font-medium">
                  (연
                  {farmValueIncrease === 'A'
                    ? '4% 이상'
                    : farmValueIncrease === 'B'
                      ? '1~4% 이내'
                      : '1% 이내'}
                  )
                </span>
              </div>
            </div>
            <div className="flex-column ">
              <div className="flex- row padding-12-16 gap-8 end-x">
                <div className="flex-row gap-4 center-y">
                  <div
                    style={{
                      width: '12px',
                      height: '2px',
                      background: '#09CE7D',
                    }}
                  />
                  <div className="font-medium f-size-11 line-h-12 color-primary-400">
                    예상 농지 가격
                  </div>
                </div>
                <div className="flex-row gap-4 center-y">
                  <div
                    style={{
                      width: '12px',
                      height: '2px',
                      background: '#869CB6',
                    }}
                  />
                  <div className="font-medium f-size-11 line-h-12 color-blue-gray-500">
                    연채무액 누계
                  </div>
                </div>
              </div>
              <LineChart
                className="flex-medium f-size-11 center-x"
                width={
                  isMobile(window.innerWidth)
                    ? window.innerWidth - 32
                    : 360 - 16
                }
                height={181}
                data={
                  currentPension[pensionType as 'fixedAmountForLife']?.data
                    .farmValueResult
                }
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="0.6">
                    <stop offset="5%" stopColor="#53df89" stopOpacity={1} />
                    <stop offset="95%" stopColor="#53df89" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis dataKey="year" />
                <YAxis tickFormatter={(value) => value.toLocaleString()} />
                <Tooltip
                  labelFormatter={(label) => `${label}년`}
                  formatter={(value) => `${value.toLocaleString()}원`}
                />
                <Line
                  type="monotone"
                  dataKey="sum"
                  stroke="#dadada"
                  fillOpacity={1}
                  name="연채무액 누계"
                />
                <Line
                  type="monotone"
                  dataKey="farmValue"
                  stroke="#53df89"
                  fillOpacity={1}
                  name="예상 농지 가격"
                />
              </LineChart>
            </div>
          </div>
          <div className="flex-column gap-16">
            <BoxGreen1>
              <div className="flex-row gap-4 start-y">
                <img src="/asset/images/check/Check24Green2.svg" alt="" />
                {farmValueIncrease === 'A' ? (
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    해당 농지는 고성장형 농지로 분류됩니다.
                  </div>
                ) : farmValueIncrease === 'B' ? (
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    해당 농지는 중성장형 농지로 분류됩니다.
                  </div>
                ) : (
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    해당 농지는 저성장형 농지로 분류되어 장기적으로 농지 가치가
                    농 지연금 총 수령액을 넘지 못할 것으로 예상됩니다.
                  </div>
                )}
              </div>
              <div className="flex-row gap-4 start-y">
                <img src="/asset/images/check/Check24Green2.svg" alt="" />
                {farmValueIncrease === 'A' ? (
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    농지연금 수령 중에 농지 가치가 상승하면 연금 중도 해지 후
                    담보 농지의 재평가로 수령액을 늘릴 수 있습니다. 다만
                    기지급된 연금 의 채무액은 전액 상환되어야 중도 해지가
                    가능합니다.
                  </div>
                ) : farmValueIncrease === 'B' ? (
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    농지연금은 농지 시세 하락에도 영향을 받지 않아 해당 농지의
                    단 순 보유보다 유리한 선택으로 분석됩니다.
                  </div>
                ) : (
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    농지연금은 농지 시세가 하락하더라도 연금 수령에 영향을
                    미치지 않기 때문에 농지를 단순 보유하고 있는 것보다 유리한
                    선택으로 분석됩니다.
                  </div>
                )}
              </div>
              {farmValueIncrease === 'B' ? (
                <div className="flex-row gap-4 start-y">
                  <img src="/asset/images/check/Check24Green2.svg" alt="" />
                  <div className="font-bold f-size-14 line-h-20 clolr-text-secondary">
                    연금 수령 중에 농지 가치가 급상승하면 연금 중도 해지 후
                    담보농 지의 재평가를 통해 수령액을 늘릴 수 있습니다. 다만
                    기지급된 연 금의 채무액은 전액 상환되어야 중도 해지가
                    가능합니다.
                  </div>
                </div>
              ) : (
                <></>
              )}
            </BoxGreen1>
            <div className="font-medium f-size-14 line-h-20 color-text-tertiary">
              ※ 해당 농지가치 성장률 = 10년간 공시지가 상승률
            </div>
          </div>
        </div>
        <div className="padding-t-16">
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            2) 농지연금 채무비율 추이
          </div>
        </div>
        <div className="flex-column">
          <LineChart
            className="flex-medium f-size-11 center-x"
            width={
              isMobile(window.innerWidth) ? window.innerWidth - 32 : 360 - 16
            }
            height={181}
            data={
              currentPension[pensionType as 'fixedAmountForLife']?.data
                .debtRatioResult
            }
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="0.6">
                <stop offset="5%" stopColor="#53df89" stopOpacity={1} />
                <stop offset="95%" stopColor="#53df89" stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis dataKey="year" />
            <YAxis tickFormatter={(value) => value.toLocaleString()} />
            <Tooltip
              labelFormatter={(label) => `${label}년`}
              formatter={(value) => `${value.toLocaleString()}%`}
            />
            <Line
              type="monotone"
              dataKey="debtRatio"
              name="채무비율"
              stroke="#53df89"
              fillOpacity={1}
            />
          </LineChart>
          <div className="flex-column of-y-auto" style={{ maxHeight: '224px' }}>
            <BoxTable3M table={tableR} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cunsulting;
