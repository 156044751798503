import './Terms.css';
import { TopNav2, TopNavWithTerms } from '../../../component/mobile/Topnav';
import Banner from '../../../component/AIIntroduction/Footer';
import Footer from '../../../component/browser/Footer';
import Select from 'react-select';
import { TermService } from '../../../service/term/Term';
import { useEffect, useState } from 'react';
import ScrollToTopButton from '../../../component/common/ScrollToTop';

const ServiceTerms = ({ SNS, setTermsOpen }: any) => {
  interface ContentItem {
    contentIdx: number;
    seq: number;
    content: string;
  }

  interface Term {
    termsIdx: number;
    title: string;
    titleNumber: number;
    content: ContentItem[];
    regDate: string;
  }

  const [dateList, setDateLists] = useState<{ regDate: string }[]>([]);
  const [selectedDate, setSelectedDate] = useState<{
    label: string;
    value: string;
  } | null>(null);
  const [terms, setTerms] = useState<Term[]>([]);

  console.log(selectedDate);

  const getDateList = async () => {
    try {
      const result = await TermService.UpdateList();
      setDateLists(result.list);
      setSelectedDate({
        label: result.list[0].regDate,
        value: result.list[0].regDate,
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getDateList();
  }, []);

  const getTerms = async (regDate: string) => {
    try {
      const result = await TermService.getTerms(regDate);
      setTerms(result.list);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (selectedDate) {
      getTerms(selectedDate.label);
    }
  }, [selectedDate]);

  console.log(terms);

  return (
    <div className="terms-wrap">
      <ScrollToTopButton bottom={'30px'} mbottom={'30px'} isVisible={true} />
      <TopNav2 text="이용 약관" hamburger back />
      <TopNavWithTerms />
      <div className="terms-inner">
        <div className="terms-title">
          서비스 이용 약관
          <Select
            className="font-medium f-size-16 line-h-24 color-text-primary border-radius-5 pointer w-160"
            options={dateList.map((item) => ({
              label: item.regDate,
              value: item.regDate,
            }))}
            value={selectedDate}
            onChange={(selectedDate: any) => {
              setSelectedDate(selectedDate);
            }}
          />
        </div>
        <div className="terms-content-box">
          {terms.map((term) => (
            <div className="terms-content-item">
              <div
                className="terms-content-item-title"
                dangerouslySetInnerHTML={{
                  __html: term.title.replace(/\r\n/g, '<br>'),
                }}
              ></div>
              <div className="terms-content-item-text">
                {term.content.map((contentItem) => (
                  <p
                    dangerouslySetInnerHTML={{
                      __html: contentItem.content.replace(/\r\n/g, '<br>'),
                    }}
                  ></p>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
      <Banner />
      <Footer />
    </div>
  );
};

export default ServiceTerms;
