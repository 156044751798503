import { styled } from 'styled-components';
interface InputType {
  width: string;
}
interface BtnType {
  on: boolean;
}

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

export const InfoMiddleContainer = styled.div`
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  width: 28%;
`;

export const MiddleTextTopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 72px;
`;

export const WarningWrapper = styled.div`
  display: flex;
  padding-left: 16px;
  justify-content: start;
  width: 100%;
`;

export const InfoBtn = styled.button<BtnType>`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) =>
    props.on ? 'rgba(0, 175, 135, 1)' : '#00af87'};
  width: 500px;
  height: 56px;
  color: white;
  font-family: Pretendard-Medium;
  font-size: 16px;
  line-height: 22px;
  border: none;
  opacity: ${(props) => (props.on ? '100%' : '40%')};
  border-radius: 13px;
  margin-top: 64px;
  margin-bottom: 72px;
  cursor: pointer;
`;
