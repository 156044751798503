import { set } from 'lodash';
import LandService from '../../service/land/landApi';
import './ReportResultLanduse.css';
import { useCallback, useEffect, useRef, useState } from 'react';

interface IProps {
  reportIdx4: number;
}
interface DataProps {
  address: string;
  category: string;
  distance: number;
  img: null;
  zoning: string;
  type: string;
}

const NearbyLandExamples = ({ reportIdx4 }: IProps) => {
  const [tab, setTab] = useState('S');
  const [data, setData] = useState<DataProps[]>([]);
  const [total, setTotal] = useState('');
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(6);
  const [offset, setOffset] = useState(0);
  const listContainerRef = useRef<HTMLDivElement | null>(null);

  const tabItem = [
    {
      name: '태양광발전',
      label: 'S',
      onClick: () => {
        setTab('S');
      },
    },
    {
      name: '창고',
      label: 'W',
      onClick: () => {
        setTab('W');
      },
    },
    {
      name: '주차장',
      label: 'P',
      onClick: () => {
        setTab('P');
      },
    },
    {
      name: '캠핑장',
      label: 'C',
      onClick: () => {
        setTab('C');
      },
    },
    {
      name: '농어촌민박',
      label: 'L',
      onClick: () => {
        setTab('L');
      },
    },
    {
      name: '펜션',
      label: 'E',
      onClick: () => {
        setTab('E');
      },
    },
  ];
  const changeText = (text: string) => {
    if (text === 'S') {
      return '태양광발전';
    } else if (text === 'W') {
      return '창고';
    } else if (text === 'P') {
      return '주차장';
    } else if (text === 'C') {
      return '캠핑장';
    } else if (text === 'L') {
      return '농어촌민박';
    } else if (text === 'E') {
      return '펜션';
    }
  };

  const getData = async (
    reportIdx: number,
    type: string,
    limit: number,
    offset: number,
  ) => {
    try {
      setLoading(true);
      const res = await LandService.nearbyCase(reportIdx, type, limit, offset);
      const prevList = offset === 0 ? [] : data;
      setData([...prevList, ...res.result.list]);
      setTotal(res.result.total);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setOffset(0);
    setData([]);
    setTotal('');
    setLoading(false);
    if (listContainerRef.current) {
      listContainerRef.current.scrollTop = 0;
    }
    getData(reportIdx4, tab, limit, 0);
  }, [tab]);

  useEffect(() => {
    if (offset === 0 && data.length === 0) return;
    getData(reportIdx4, tab, limit, offset);
  }, [offset]);

  const handleScroll = useCallback(
    (e: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const target = e.target as HTMLDivElement;
      const paddingToBottom = 1;

      if (loading || data.length >= Number(total)) return;

      if (
        target.scrollTop + target.clientHeight + paddingToBottom >=
        target.scrollHeight
      ) {
        setLoading(true);
        setOffset((prev: number) => prev + limit);
      }
    },
    [loading, data.length, Number(total)],
  );

  const renderSampleImg = (type: string) => {
    if (type === 'S') {
      return '/asset/images/landUse/Sample_img_solar.png';
    }
    if (type === 'W') {
      return '/asset/images/landUse/Sample_img_warehouse.png';
    }
    if (type === 'P') {
      return '/asset/images/landUse/Sample_img_parkinglot.png';
    }
    if (type === 'C') {
      return '/asset/images/landUse/Sample_img_camping.png';
    }

    if (type === 'L') {
      return '/asset/images/landUse/Sample_img_b&b.png';
    }
    if (type === 'E') {
      return '/asset/images/landUse/Sample_img_vacationhome.png';
    }
  };

  return (
    <div className="nearby-land-example">
      <div className="nearby-land-example-title-box">
        <span>인근 토지활용 사례</span>
        <div className="nearby-land-example-tab-box">
          <div className="nearby-land-example-tab-box-inner">
            {tabItem.map((item, index) => (
              <div
                key={index}
                className={
                  tab === item.label
                    ? 'nearby-land-tab-item-active'
                    : 'nearby-land-tab-item'
                }
                onClick={item.onClick}
              >
                {item.name}
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="nearby-land-example-totalcount">
        <span>총</span>
        <span className="nearby-land-example-totalcount-text">
          {Number(total).toLocaleString()}개
        </span>
      </div>
      <div
        className="nearby-land-example-list-box"
        ref={listContainerRef}
        onScroll={(e) => {
          handleScroll(e);
        }}
        style={{ height: data.length > 0 ? '345px' : '100px' }}
      >
        {data.length === 0 && (
          <div className="no-data-box">해당 사례가 없습니다.</div>
        )}
        {data.length > 0 &&
          data.map((item: any, index: number) => (
            <div key={index} className="nearby-land-example-list-item">
              <div className="nearby-land-example-list-item-top">
                <div className="nearby-land-example-list-item-top-left">
                  <span>반경</span>{' '}
                  <span style={{ color: '#222' }}>{item.distance}m</span>
                </div>
                <span>{changeText(item.type)}</span>
              </div>
              <div className="nearby-land-example-list-item-middle">
                <div className="nearby-land-example-list-item-middle-text">
                  <span>{item.address}</span>
                  <span className="nearby-land-item-doro">{item.address}</span>
                </div>
                <img src={renderSampleImg(item.type)} alt="" />
              </div>
              <div className="nearby-land-example-list-item-bottom">
                <span>{item.category}</span>
                <span>{item.zoning}</span>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
export default NearbyLandExamples;
