import { useDispatch, useSelector } from 'react-redux';
import { Font } from '../../asset/styles/browser/font/font';
import { FullContainer } from '../../asset/styles/mobile/common/Common';
import {
  CheckBoxContainer,
  CloseWrapper,
  ModalContainer,
} from '../../asset/styles/mobile/modal/Modal';
import Modal from 'react-modal';
import { ModalOn } from '../../store/signUpInfoSlice';
import { useNavigate } from 'react-router-dom';
const SignUpModal = () => {
  const state = useSelector((state: any) => {
    return state.signUpInfo;
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return (
    <FullContainer>
      <Modal
        isOpen={state.modal}
        shouldCloseOnOverlayClick={true}
        // onAfterOpen={afterOpenModal}
        ariaHideApp={false}
        style={{
          //   overlay: {
          //     position: "fixed",
          //     backgroundColor: "rgba(0, 0, 0, 0.6)",

          //     outline: "none",
          //   },
          content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-50%, -50%)',
            // color: "black",
            backgroundColor: 'white',
            whiteSpace: 'normal',
            width: '80%',
          },
        }}
      >
        <ModalContainer>
          <Font fontSize="15" fontWeight="400" lineHeight="20" color="black">
            설문에 응답해 주셔서 감사합니다.
          </Font>
          <Font
            fontSize="15"
            fontWeight="400"
            lineHeight="20"
            color="black"
            textAlign="start"
          >
            AI 분석 서비스 무료 이용권 1회를 지급하였습니다.
          </Font>
          <CloseWrapper
            className=" hover"
            onClick={() => {
              dispatch(ModalOn(false));
            }}
          >
            <Font fontSize="15" fontWeight="400" lineHeight="20" color="black">
              [닫기]
            </Font>
          </CloseWrapper>
        </ModalContainer>
      </Modal>
    </FullContainer>
  );
};

export default SignUpModal;
