import { useEffect, useState } from 'react';
import { Content1, Footer, ReportTitle } from '../component';
import MapContainerReport2 from '../../aiFarming/map/MapContainerReport2';
import LandController from '../../../controller/land/LandController';
import MapContainerReport3 from '../../aiFarming/map/MapContainerReport3';
import useDidMountEffect from '../../../hooks/UseDidMountEffect';
interface PropsType {
  data: any;
  address: string;
}
export const UsageArea = ({ data, address }: PropsType) => {
  const [stickPolygon, setStickPolygon]: any = useState(() => {});
  const [currentMarker, setCurrentMarker]: any = useState();
  const [areaList, setAreaList]: any = useState([]);
  const [fixMarker, setFixMarker]: any = useState(); //function
  const [panTo, setPanTo]: any = useState(); //function
  const [markerArray, setMarkerArray]: any = useState([]);
  const [panToAndMarker, setPanToAndMarker]: any = useState(); //function
  const [panToAndMarker2, setPanToAndMarker2]: any = useState(); //function
  const [isSearch, setIsSearch] = useState(true);

  const content = [
    `본 물건의 이용상황은 ${data && data.useStatus}으로 확인됨`,
    `토지이용계획상 ${data && data.useStatus}에 속하여, 용도지역은 ${
      data && data.zoning
    }임`,
  ];
  useEffect(() => {
    setTimeout(() => {
      LandController.coordinate2(panToAndMarker, address, true);
      LandController.coordinate2(panToAndMarker2, address, true);
    }, 1000);
  }, [panToAndMarker]);

  return (
    <div className="flex-column w-100per h-100per space-between">
      <div className="flex-column  w-100per" style={{ height: '90%' }}>
        <ReportTitle address={address} category="물건 사진 및 용도 지역" />
        <div className="flex-column padding-24 gap-48">
          <Content1 content={content} />
          <div className="flex-row gap-8 w-100per">
            <div className="w-50per flex">
              <MapContainerReport2
                setStickPolygon={setStickPolygon}
                location={
                  window.location.pathname.slice(-1) === '/'
                    ? window.location.pathname.slice(0, -1)
                    : window.location.pathname
                }
                setCurrentMarker={setCurrentMarker}
                setFixMarker={setFixMarker}
                setMarkerArray={setMarkerArray}
                setAreaList={setAreaList}
                setPanTo={setPanTo}
                setPanToAndMarker={setPanToAndMarker2}
                setIsSearch={setIsSearch}
                hash={address}
                address={address}
              />
            </div>
            <div className="w-50per flex">
              <MapContainerReport3
                setStickPolygon={setStickPolygon}
                location={
                  window.location.pathname.slice(-1) === '/'
                    ? window.location.pathname.slice(0, -1)
                    : window.location.pathname
                }
                setCurrentMarker={setCurrentMarker}
                setFixMarker={setFixMarker}
                setMarkerArray={setMarkerArray}
                setAreaList={setAreaList}
                setPanTo={setPanTo}
                setPanToAndMarker={setPanToAndMarker}
                setIsSearch={setIsSearch}
                hash={address}
                address={address}
              />
            </div>
          </div>
          <div className="flex-center">
            <div className="w-80per flex-center flex-column">
              {data.landUsePlan.map((item: any, index: number) => {
                return (
                  <div className="w-100per  flex-row border-t-171E26 border-b-171E26">
                    <div className="flex-center bg-light-gray-100 w-40per padding-8">
                      <div className="m-b1-16-m">{item.useStatus}</div>
                    </div>
                    <div className="w-60per flex-center padding-8">
                      <div className="m-b1-16-m">{item.info}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <Footer number={2} />
    </div>
  );
};
