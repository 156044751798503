import Request from '../../@api/request';

// import { request } from "../service"
// const baseURL = "http://localhost:3333/";
// const baseURL = "https://back.transfarmer.kr/";
const baseURL = `${process.env.REACT_APP_HOST}`;
export const request = new Request(baseURL);

interface IAnalyzeData {
  pnuCodes: Array<string>;
  type: 'S' | 'O'; //검색: S, 운영: O
  facility: 'O' | 'P' | 'S' | 'W'; //시설 ex) 노지: O, 비닐하우스: P, 스마트팜: S, 기후맞춤스마트팜: W
  cropName: string; //작물이름(노지포도)
  crops: number; //기작
  landCostType: 'P' | 'R'; //매입: "P", 임대: "R"
  quantity: number; //생산량
  unitPrice: number; //단가
  landPrice: number; //토지 매입비 or 농지 임차료
  landPurchaseCost: number;
  avgLandPurchaseCost: number;
  landRentalCost: number;
  avgLandRentalCost: number;
  facilityCost: number; //시설비
  avgFacilityCost: number;
  farmMachineryCost: number; //농기계 구입비
  avgFarmMachineryCost: number;
  ownLaborYN: 'Y' | 'N'; //자가 노동비 포함: "Y", 제외: "N"
  laborCost: number;
  avgEmpLaborCost: number;
  avgOwnLaborCost: number;
  materialCost: number;
  avgMaterialCost: number;
  orchardCost: number;
  avgOrchardCost: number;
  waterHeatingCost: number;
  avgWaterHeatingCost: number;
  farmEquipmentCost: number;
  avgFarmEquipmentCost: number;
  SGA: number;
  avgSGA: number;
  // empLaborCost: number;                       //고용노동비
  // ownLaborCost: number;                       //자가노동비
  // materialCost: number;                       //재료비
  // orchardCost: number;                        //과수원 조성비
  // waterHeatingCost: number;                   //수도광열비
  // farmEquipmentCost: number;                //농기구렌탈비
  // SGA: number;                                //판관비
  // capitalRate: number                         //자기 자금 비율
  loanInfo: Array<{
    loanName: string;
    loanDate: string;
    loanRate: string;
    loanCost: string;
    retentionPeriod: string;
    redemptionPeriod: string;
  }>; // 대출정보
  // interestRate: number                        //이율
  // loanType: string                            //대출종류(담보대출, 신용대출...)
  // loanDate: string;                           //대출발생연월 2023 -08-01
  // retentionPeriod: number;                    //거치기간(60)
  // redemptionPeriod: number;                   //상환기간(120)
  // ownMoney: number;                           //자기자금
  // loanFund: number;                           //대출금
}
interface DeleteSearch {
  address: string;
  service: string;
}
export default class LandService {
  static search = async (address: string) => {
    const result = await request.post('land/search', { address });
    return result.data;
  };

  static searchRoad1st = async (address: string) => {
    const result = await request.post('land/search', {
      address,
      isRoad1st: true,
    });
    return result.data;
  };

  static search2 = async (address: string) => {
    const result = await request.post('land/search2', { address });
    return result.data;
  };

  static info = async (address: string, service: string) => {
    if (address) {
      const result = await request.get(
        `land?address=${address}&service=${service}`,
      );
      return result.data;
    }
  };
  static infoPension = async (address: string) => {
    if (address) {
      const result = await request.get('land/pension?address=' + address);
      return result.data;
    }
  };
  static analysis = async (data: any) => {
    const result = await request.post('land/analysis', data);
    return result.data;
  };

  static analysisResult = async (data: IAnalyzeData) => {
    for (let key in data) {
      if (data[key as 'landPurchaseCost'] === null) {
        data[key as 'SGA'] = 0;
      }
      data.type = 'O';
    }
    const result = await request.post('report', data);
    return result.data;
  };

  static cropList = async (
    pnuCodes: Array<{ pnuCode: string; area: number }>,
    facility: string,
    recommend: string,
  ) => {
    const result = await request.post('land/crop/list', {
      pnuCodes,
      facility,
      recommend: recommend,
    });
    return result.data;
  };

  static cropListAll = async (
    pnuCodes: Array<{ pnuCode: string; area: number }>,
    facility: string,
  ) => {
    const result = await request.post('land/crop/listAll', {
      pnuCodes,
      facility,
    });
    return result.data;
  };

  static coordinate = async (address: string) => {
    sessionStorage.removeItem('errMsg2');
    const result = await request.post('land/coordinate', { address });
    if (result.data.message !== null) {
      sessionStorage.setItem('errMsg2', result.data.message);
    }
    console.log('result', result.data.message);
    return result.data;
  };
  static getLandInfo = async (address: string) => {
    const result = await request.get(`/land?address=${address}`);
    return result.data.result;
  };
  static getSelectWord = async (sido: string) => {
    const result = await request.get(`/land/search?sido=${sido}`);
    return result.data.list[0];
  };
  static deleteSearchList = async (data: DeleteSearch) => {
    const result = await request.post('/search/delete', data);
    return result.data;
  };
  static deleteSearchListAll = async () => {
    const result = await request.delete('/search/delete/all');
    return result.data;
  };
  static getLandUseOption = async (pnu: string) => {
    const result = await request.get(`/land/use/option?pnu=${pnu}`);
    return result.data;
  };
  static getLandReportIdx = async (pnu: string) => {
    const result = await request.post(`/land/use/report`, { pnu });
    return result.data;
  };
  static nearbyCase = async (
    reportIdx: number,
    type: string,
    limit: number,
    offset: number,
  ) => {
    const result = await request.get(
      `/land/use/case?reportIdx=${reportIdx}&type=${type}&limit=${limit}&offset=${offset}`,
    );
    return result.data;
  };
}
