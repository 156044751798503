import search from './../../asset/images/notice/searchIcon.svg';
import filter from './../../asset/images/community/filter.svg';
import sample from './../../asset/images/community/sample.svg';

import floatingBtn from './../../asset/images/community/a.svg';
import '../../asset/styles/SaleInfo.css';
import { BottomNav } from '../../component/mobile/BottomNav';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState, useRef, useLayoutEffect } from 'react';
import Spinner from '../../component/browser/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { GetCommunityTotal } from '../../store/boardSlice';
import InfiniteScroll from 'react-infinite-scroll-component';
import BoardService from '../../service/board/Board';
import { Topnav } from '../../component/mobile/Topnav';
interface communityData {
  articleIdx: number;
  likeAmount: number | null;
  commentAmount: number | null;
  content: string;
  email: string;
  title: string;
  views: number;
  writeTime: string;
}

interface communityDataArray extends Array<communityData> {}

const Community = () => {
  useEffect(() => {
    sessionStorage.clear();
    getCommunityApi();
  }, []);
  useEffect(() => {
    window.addEventListener(
      'scroll',
      () => {
        handleScroll();
      },
      {
        capture: true,
      },
    );
    return () => {
      window.removeEventListener('scroll', () => handleScroll());
    };
  }, []);
  const [community, setCommunity] = useState<communityDataArray>([]);
  const [limit, setLimit] = useState(10);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const ref = useRef<HTMLInputElement>(null);

  const getCommunityApi = async () => {
    // setLoading(true);
    let board = 40;
    let offset = 0;
    try {
      const result = await BoardService.GetBoardList(limit, offset, board);
      setCommunity(result.rows);
      sessionStorage.setItem('offset', '10');
      sessionStorage.setItem('totalCount', result.totalCount);
      // setLoading(false);
    } catch (error) {
      console.log(error);
      // setLoading(false);
    }
  };

  const getCommunityScrollApi = async () => {
    let offset = Number(sessionStorage.getItem('offset'));
    let totalCount = Number(sessionStorage.getItem('totalCount'));
    if (offset < totalCount) {
      setLoading(true);
      let board = 40;
      try {
        const result = await BoardService.GetBoardList(limit, offset, board);
        setCommunity((prev) => [...prev, ...result.rows]);
        setLoading(false);
        sessionStorage.setItem('offset', (offset + limit).toString());
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const handleScroll = () => {
    if (ref.current) {
      if (
        Math.round(
          (ref.current as any).offsetHeight + (ref.current as any).scrollTop,
        ) === (ref.current as any).scrollHeight
      ) {
        getCommunityScrollApi();
      }
    }
  };

  return (
    <div
      className="flex-column w-100per overflow-auto"
      style={{
        paddingBottom: '75px',
        height: window.innerHeight,
      }}
      ref={ref}
    >
      <Topnav text="커뮤니티" />
      {loading && <Spinner />}
      <div className="flex-column w-100per padding-0-20 gap-20">
        <div
          className="flex-row space-between w-100per mt-20"
          style={{
            backgroundColor: 'white',
            borderRadius: '10px',
            padding: '10px 20px',
            border: '1px solid #d4d4d4',
          }}
        >
          <input
            className="font-normal"
            style={{
              border: 'none',
              display: 'flex',
              backgroundColor: 'white',
            }}
            placeholder="관심 있는 글 검색."
          />
          <img src={search} />
        </div>
      </div>
      <div className="flex-column w-100per  gap-20 mt-20">
        <div
          className="flex-row space-between w-100per padding-20-20"
          style={{ backgroundColor: '#FAFAFA' }}
        >
          <div className="flex-row gap-10 center-y">
            <img src={filter} />
            <div className="font-normal">필터</div>
          </div>
          <div className="flex-row gap-20">
            <div className="font-normal">최신순</div>
            <div className="font-normal">인기순</div>
            <div className="font-normal">조회수</div>
          </div>
        </div>
        <div className="flex-column gap-20">
          {/* content start */}
          {community &&
            community.map((item: any) => {
              const id = item.articleIdx;
              return (
                <div
                  className="flex-column  gap-20 border-bottom-10px "
                  style={{ padding: '0px 20px 20px 20px' }}
                >
                  <div className="flex-row space-between center-y">
                    <div className="flex-row center-y gap-10">
                      <img src={sample} />
                      <div className="flex-column">
                        <div
                          className="font-normal"
                          style={{ fontWeight: '700' }}
                        >
                          강원도
                        </div>
                        <div
                          className="font-normal"
                          style={{ color: '#999B9F' }}
                        >
                          {item.email}
                        </div>
                        <div
                          className="font-normal"
                          style={{ color: '#999B9F' }}
                        >
                          {item.writeTime}
                        </div>
                      </div>
                    </div>
                    <div className="flex-row gap-10">
                      <div className="font-normal" style={{ color: '#999B9F' }}>
                        ♥
                      </div>
                    </div>
                  </div>
                  <div
                    className="flex-column gap-20"
                    onClick={() => {
                      navigate(`/community/detail/${id}`, {
                        state: { idx: item.articleIdx, email: item.email },
                      });
                    }}
                  >
                    <div className="font-bold">{item.title}</div>
                    <div>
                      <div className="font-normal" style={{ fontSize: '20' }}>
                        {item.content}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>

        {/* content-end */}
      </div>
      <div>
        <div
          style={{
            display: 'inline-block',
            position: 'fixed',
            backgroundColor: '#00AF87',
            padding: '20px 30px',
            borderRadius: '30px',
            bottom: '15%',
            left: '60%',
          }}
          onClick={() => {
            navigate('/community/write');
          }}
        >
          <img src={floatingBtn} />
        </div>
      </div>
    </div>
  );
};

export default Community;
