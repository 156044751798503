import { useParams } from 'react-router-dom';
import { TopNav2 } from '../../component/mobile/Topnav';
import ReportInfo from './components/Info';
import ReportNav from './components/Nav';
import { useDispatch } from 'react-redux';
import { LoadingDot } from '../../store/loadingSlice';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { isMobile } from '../../component/function/function';
import React from 'react';
import Calculator from './components/Calculator';
import PensionService from '../../service/pension/pensionApi';

import Cunsulting from './components/Cunsulting';
import ReportDetail10 from '../reportResultPrice/components/detail/ReportDetail10';
import { SaveBtn } from '../../component/common/saveBtn';
import PriceService from '../../service/price/priceApi';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import useDidMountEffect from '../../hooks/UseDidMountEffect';
import { SaveReportPopUp } from '../../component/common/saveReportPopUp';
import { set } from 'lodash';
const PensionReportMain = ({
  reportIdx,
  setClose,
  search,
  setOnPointHistory,
  setCloseSearchPointDetail,
  mypage,
}: any) => {
  const [purpose, setPurpose] = useState('');
  let param: any;
  const param2 = useParams();

  if (isMobile(window.innerWidth)) {
    param = { reportIdx: param2.reportIdx };
  } else {
    param = { reportIdx: reportIdx };
  }
  const [onsSavePopUp, setOnSavePopUp] = useState(false);
  const [fixedTab, setFixedTab] = useState(0);
  const [manageTab, setManageTab] = useState(0);
  useEffect(() => {
    console.log('manatab', manageTab);
  }, [manageTab]);
  const [menu, setMenu] = useState('tab1');
  const [originData, setOriginData] = useState();
  const [tmpAuctionPrice, setTmpAuctionPrice]: any = useState();
  const [totalData, setTotalData]: any = useState({
    createdAt: null,
    address: null,
    type: null,
    age: null,
    career: null,
    farmRealIncome: 0,
    tmpAuctionPrice: null,
    farmValue: null,
    fixedAmountForLife: {
      fixedAmountForLife: {
        pensionResult: [
          {
            sumOfPension: 0,
            round: 1,
            year: 2023,
            pension: 72581280,
            interest: 19469332.798846602,
            riskCharge: 362906.4,
            sum: 92413519.19884661,
          },
        ],
        farmValueResult: [
          {
            year: 2023,
            farmValue: 1766789629.5,
            sum: 92413519.19884661,
          },
        ],
        debtRatioResult: [
          {
            round: 1,
            year: 2023,
            sumOfPension: 72581280,
            farmValue: 1766789629.5,
            debtRatio: 4.1,
          },
        ],
      },
      pension: null,
      investRate: null,
      investInfo: null,
    },
    longTermFarming: {
      longTermFarming: {
        pensionResult: [
          {
            sumOfPension: 0,
            round: 1,
            year: 2023,
            pension: 72581280,
            interest: 19469332.798846602,
            riskCharge: 362906.4,
            sum: 92413519.19884661,
          },
        ],
        farmValueResult: [
          {
            year: 2023,
            farmValue: 1766789629.5,
            sum: 92413519.19884661,
          },
        ],
        debtRatioResult: [
          {
            round: 1,
            year: 2023,
            sumOfPension: 72581280,
            farmValue: 1766789629.5,
            debtRatio: 4.1,
          },
        ],
      },
      pension: null,
      investRate: null,
      investInfo: null,
    },
    backAndForthThinning: {
      backAndForthThinning: {
        pensionResult: [
          {
            sumOfPension: 0,
            round: 1,
            year: 2023,
            pension: 72581280,
            interest: 19469332.798846602,
            riskCharge: 362906.4,
            sum: 92413519.19884661,
          },
        ],
        farmValueResult: [
          {
            year: 2023,
            farmValue: 1766789629.5,
            sum: 92413519.19884661,
          },
        ],
        debtRatioResult: [
          {
            round: 1,
            year: 2023,
            sumOfPension: 72581280,
            farmValue: 1766789629.5,
            debtRatio: 4.1,
          },
        ],
      },
      pension: {
        before: null,
        after: null,
      },
      investRate: {
        before: null,
        after: null,
      },
      investInfo: null,
    },
    occasionalWithdrawal: {
      occasionalWithdrawal: {
        pensionResult: [
          {
            sumOfPension: 0,
            round: 1,
            year: 2023,
            pension: 72581280,
            interest: 19469332.798846602,
            riskCharge: 362906.4,
            sum: 92413519.19884661,
          },
        ],
        farmValueResult: [
          {
            year: 2023,
            farmValue: 1766789629.5,
            sum: 92413519.19884661,
          },
        ],
        debtRatioResult: [
          {
            round: 1,
            year: 2023,
            sumOfPension: 72581280,
            farmValue: 1766789629.5,
            debtRatio: 4.1,
          },
        ],
      },
      pension: null,
      investRate: null,
      investInfo: null,
      withdrawal: null,
    },
    fixedTerm: {
      fixedTerm: {
        pensionResult: [
          {
            sumOfPension: 0,
            round: 1,
            year: 2023,
            pension: 72581280,
            interest: 19469332.798846602,
            riskCharge: 362906.4,
            sum: 92413519.19884661,
          },
        ],
        farmValueResult: [
          {
            year: 2023,
            farmValue: 1766789629.5,
            sum: 92413519.19884661,
          },
        ],
        debtRatioResult: [
          {
            round: 1,
            year: 2023,
            sumOfPension: 72581280,
            farmValue: 1766789629.5,
            debtRatio: 4.1,
          },
        ],
      },
      pension: {
        year_20: null,
        year_15: null,
        year_10: null,
        year_5: null,
      },
      investRate: {
        year_20: null,
        year_15: null,
        year_10: null,
        year_5: null,
      },
      investInfo: null,
    },
    managementTransfer: {
      managementTransfer: {
        pensionResult: [
          {
            sumOfPension: 0,
            round: 1,
            year: 2023,
            pension: 72581280,
            interest: 19469332.798846602,
            riskCharge: 362906.4,
            sum: 92413519.19884661,
          },
        ],
        farmValueResult: [
          {
            year: 2023,
            farmValue: 1766789629.5,
            sum: 92413519.19884661,
          },
        ],
        debtRatioResult: [
          {
            round: 1,
            year: 2023,
            sumOfPension: 72581280,
            farmValue: 1766789629.5,
            debtRatio: 4.1,
          },
        ],
      },
      pension: {
        year_20: null,
        year_15: null,
        year_10: null,
        year_5: null,
      },
      investRate: {
        year_20: null,
        year_15: null,
        year_10: null,
        year_5: null,
      },
      investInfo: null,
    },
    managementTypePeriod: null,
    fixedTypePeriod: null,
  });

  const [tmpAge, setTmpAge] = useState();
  const dispatch = useDispatch();

  const report = async () => {
    try {
      dispatch(LoadingDot('W'));
      const data = await PensionService.load(param.reportIdx);
      console.log('waef', data.result);
      setTotalData(data.result);
      setTmpAuctionPrice(data.result.tmpAuctionPrice / 10000);
      setPurpose(data.result.purpose);
      setFixedTab(
        data.result.fixedTypePeriod === '5'
          ? 1
          : data.result.fixedTypePeriod === '10'
            ? 2
            : data.result.fixedTypePeriod === '15'
              ? 3
              : data.result.fixedTypePeriod === '20'
                ? 4
                : 0,
      );
      setManageTab(
        data.result.managementTypePeriod === '5'
          ? 1
          : data.result.managementTypePeriod === '10'
            ? 2
            : data.result.managementTypePeriod === '15'
              ? 3
              : data.result.managementTypePeriod === '20'
                ? 4
                : 0,
      );

      dispatch(LoadingDot('N'));
    } catch (err) {
      dispatch(LoadingDot('N'));
      console.log(err);
    }
  };

  useEffect(() => {
    report();
  }, []);
  const conRef = useRef<HTMLDivElement>(null);
  const menuRef1 = useRef<HTMLDivElement>(null);
  const menuRef2 = useRef<HTMLDivElement>(null);
  const menuRef3 = useRef<HTMLDivElement>(null);
  const menuRef4 = useRef<HTMLDivElement>(null);
  const menuRef5 = useRef<HTMLDivElement>(null);
  const menuRef6 = useRef<HTMLDivElement>(null);
  const menuRef7 = useRef<HTMLDivElement>(null);
  const menuRef8 = useRef<HTMLDivElement>(null);
  const menuRef9 = useRef<HTMLDivElement>(null);
  const menuRef10 = useRef<HTMLDivElement>(null);
  const divRef = useRef<HTMLDivElement>(null);
  const divRef2 = useRef<HTMLDivElement>(null);
  const divRef3 = useRef<HTMLDivElement>(null);
  const divRef4 = useRef<HTMLDivElement>(null);
  const divRef5 = useRef<HTMLDivElement>(null);
  const divRef6 = useRef<HTMLDivElement>(null);

  const temp = useRef<HTMLDivElement>(null);

  const [fixedTypePeriod, setFixedTypePeriod] = useState();
  const [managementTypePeriod, setManagementTypePeriod] = useState();
  const getOriginData = async () => {
    try {
      const result = await PriceService.origin();
      setOriginData(result.list);
      console.log(result);
    } catch (error) {
      console.log('origin', error);
    }
  };
  const [isValid, setIsValid] = useState(false);
  function calculateAge(birthdate: string) {
    var currentDate = new Date();

    var birthdateDate = new Date(birthdate);

    var age = currentDate.getFullYear() - birthdateDate.getFullYear();

    if (
      currentDate.getMonth() < birthdateDate.getMonth() ||
      (currentDate.getMonth() === birthdateDate.getMonth() &&
        currentDate.getDate() < birthdateDate.getDate())
    ) {
      age--;
    }
    return age;
  }

  const saveReport = async (saveYN: string) => {
    try {
      if (!mypage) {
        if (saveYN === 'Y') {
          dispatch(LoadingDot('S'));
        } else {
          dispatch(LoadingDot('W'));
        }
      } else {
        dispatch(LoadingDot('W2'));
      }
      const data = {
        reportIdx: param.reportIdx,
        tmpAge: Number(tmpAge) || 0,
        tmpAuctionPrice: tmpAuctionPrice * 10000 || '',
        fixedTypePeriod:
          fixedTab === 1
            ? '5'
            : fixedTab === 2
              ? '10'
              : fixedTab === 3
                ? '15'
                : fixedTab === 4
                  ? '20'
                  : '',
        managementTypePeriod:
          manageTab === 1
            ? '5'
            : manageTab === 2
              ? '10'
              : manageTab === 3
                ? '15'
                : manageTab === 4
                  ? '20'
                  : '',
        purpose: purpose || '',
        saveYN: saveYN,
      };
      const result = await PensionService.saveReport(data);
      console.log('result', result);
      setTotalData(result.result);
      dispatch(LoadingDot('N'));
      if (saveYN === 'Y') {
        setOnSavePopUp(true);
      }
    } catch (error) {
      console.log(error);
      dispatch(LoadingDot('N'));
    }
  };
  // useEffect(() => {
  //   console.log('tab', fixedTab, manageTab);
  //   saveReport('N');
  // }, [fixedTab, manageTab]);

  useDidMountEffect(() => {
    console.log(purpose, tmpAge);
    saveReport('N');
  }, [purpose, fixedTab, manageTab, tmpAge]);
  useEffect(() => {
    if (calculateAge(totalData.age) >= 60 || totalData.tmpAge >= 60) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [totalData]);

  useEffect(() => {
    getOriginData();
    setTmpAge(totalData.age);
  }, []);

  return (
    <div
      style={{
        height: search ? '100%' : mypage ? '90%' : window.innerHeight - 50,
        width: '100%',
        backgroundColor: search && '#FFFFFF',
        paddingBottom: search && '50px',
      }}
    >
      {onsSavePopUp && <SaveReportPopUp setSavePopUp={setOnSavePopUp} />}
      {/* <button
        onClick={() => {
          handleButtonClick();
        }}
      >
        다운
      </button> */}
      {!search ? (
        <TopNav2
          browser
          text="농지연금 분석보고서"
          closeBack
          close
          mypage={mypage}
          setClose={setClose}
        />
      ) : (
        <div className="flex-row space-between padding-12-16 center-y">
          <img
            src="/asset/images/arrow/arrowBack.svg"
            alt="arrow"
            className="pointer"
            onClick={() => {
              setCloseSearchPointDetail(false);
            }}
          />
          <div className="m-b1-16-b color-dark-primary">
            농지연금 분석보고서
          </div>
          <img
            src="/asset/images/close/CloseBlack24.svg"
            className="pointer"
            alt="close"
            onClick={() => {
              if (mypage) {
              } else {
                setCloseSearchPointDetail(false);
                setOnPointHistory(false);
              }
            }}
          />
        </div>
      )}

      <div
        className="of-y-auto "
        style={{ height: '100%', overflowX: 'hidden' }}
        ref={temp}
      >
        <div ref={divRef}>
          <ReportInfo
            data={totalData}
            reportIdx={param.reportIdx}
            report={report}
            tmpAge={tmpAge}
            setTmpAge={setTmpAge}
            saveReport={saveReport}
            mypage={mypage}
          />
        </div>
        <ReportNav
          isValid={isValid}
          menu={menu}
          setMenu={setMenu}
          refList={{
            conRef,
            menuRef1,
            menuRef2,
            menuRef3,
            menuRef4,
            menuRef5,
            menuRef6,
            menuRef7,
            menuRef8,
            menuRef9,
            menuRef10,

            temp,
          }}
          mypage={mypage}
        />

        <div className="padding-24-16 flex-column gap-4">
          <div className="font-bold f-size-16 line-h-24 color-text-primary">
            {menu === 'tab1'
              ? '연금 수령 방식은 가입자 상황에 따라 선택 가능해요.'
              : '농지연금 가입하면 얼마나 수익을 볼 수 있을까?'}
          </div>
          <div className="font-bold f-size-14 line-h-20 color-text-secondary">
            {menu === 'tab1'
              ? ''
              : // ? "가입자의 자금 수요에 따라 수령방식을 선택할 수 있습니다."
                '농지의 예상 가치와 연금 수익을 비교해보세요.'}
          </div>
        </div>
        <div style={{ height: '16px', background: '#F7F8F9' }} />
        {menu === 'tab1' ? (
          <Calculator
            mypage={mypage && true}
            isValid={isValid}
            data={totalData}
            reportIdx={param.reportIdx}
            report={report}
            period={{
              fixedTypePeriod,
              setFixedTypePeriod,
              managementTypePeriod,
              setManagementTypePeriod,
            }}
            setManageTab={setManageTab}
            setFixedTab={setFixedTab}
            fixedTab={fixedTab}
            manageTab={manageTab}
            saveReport={saveReport}
          />
        ) : (
          <Cunsulting
            data={totalData}
            period={{ fixedTypePeriod, managementTypePeriod }}
            reportIdx={param.reportIdx}
            report={report}
            tmpAuctionPrice={tmpAuctionPrice}
            setTmpAuctionPrice={setTmpAuctionPrice}
            setPurpose={setPurpose}
            saveReport={saveReport}
            mypage={mypage}
          />
        )}
        <div
          style={{
            height: '16px',
            background: '#F7F8F9',
          }}
        />
        {!mypage && <ReportDetail10 originData={originData} />}
        {!mypage && !search && (
          <div className="w-100per flex-center padding-b-16">
            <SaveBtn
              onClick={() => {
                saveReport('Y');
              }}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default PensionReportMain;

export interface ITotaldata {
  tmpAge?: any;
  type?: any;
  tmpAuctionPrice: any;
  farmRealIncome: any;
  managementTypePeriod?: any;
  purpose?: any;
  fixedTypePeriod?: any;
  createdAt?: any;
  address?: any;
  age?: any;
  career?: any;
  farmValue?: any;
  fixedAmountForLife?: {
    fixedAmountForLife?: {
      pensionResult?: Array<{
        round?: any;
        sumOfPension?: any;
        year?: any;
        pension?: any;
        interest?: any;
        riskCharge?: any;
        sum?: any;
      }>;
      farmValueResult?: Array<{
        year?: any;
        farmValue?: any;
        sum?: any;
      }>;
      debtRatioResult?: Array<{
        round?: any;
        year?: any;
        sumOfPension?: any;
        farmValue?: any;
        debtRatio?: any;
      }>;
    };
    pension?: any;
    investRate?: any;

    investInfo?: any;
  };
  longTermFarming?: {
    longTermFarming?: {
      pensionResult?: Array<{
        round?: any;
        sumOfPension?: any;
        year?: any;
        pension?: any;
        interest?: any;
        riskCharge?: any;
        sum?: any;
      }>;
      farmValueResult?: Array<{
        year?: any;
        farmValue?: any;
        sum?: any;
      }>;
      debtRatioResult?: Array<{
        round?: any;
        year?: any;
        sumOfPension?: any;
        farmValue?: any;
        debtRatio?: any;
      }>;
    };
    pension?: any;
    investRate?: any;
    investInfo?: any;
  };
  backAndForthThinning?: {
    backAndForthThinning?: {
      pensionResult?: Array<{
        round?: any;
        sumOfPension?: any;
        year?: any;
        pension?: any;
        interest?: any;
        riskCharge?: any;
        sum?: any;
      }>;
      farmValueResult?: Array<{
        year?: any;
        farmValue?: any;
        sum?: any;
      }>;
      debtRatioResult?: Array<{
        round?: any;
        year?: any;
        sumOfPension?: any;
        farmValue?: any;
        debtRatio?: any;
      }>;
    };
    pension?: {
      before?: any;
      after?: any;
    };
    investRate?: {
      before?: any;
      after?: any;
    };
    investInfo?: any;
  };
  occasionalWithdrawal?: {
    occasionalWithdrawal?: {
      pensionResult?: Array<{
        round?: any;
        sumOfPension?: any;
        year?: any;
        pension?: any;
        interest?: any;
        riskCharge?: any;
        sum?: any;
      }>;
      farmValueResult?: Array<{
        year?: any;
        farmValue?: any;
        sum?: any;
      }>;
      debtRatioResult?: Array<{
        round?: any;
        year?: any;
        sumOfPension?: any;
        farmValue?: any;
        debtRatio?: any;
      }>;
      withdrawal?: any;
    };
    pension?: any;
    investRate?: any;
    investInfo?: any;
    withdrawal?: any;
  };
  fixedTerm?: {
    fixedTerm?: {
      year_20?: any;
      year_15?: any;
      year_10?: any;
      year_5?: any;
    };
    pension?: {
      year_20?: any;
      year_15?: any;
      year_10?: any;
      year_5?: any;
    };
    investRate?: {
      year_20?: any;
      year_15?: any;
      year_10?: any;
      year_5?: any;
    };
    investInfo?: any;
  };
  managementTransfer?: {
    managementTransfer?: {
      year_20?: any;

      year_15?: any;
      year_10?: any;
      year_5?: any;
    };
    pension?: {
      year_20?: any;
      year_15?: any;
      year_10?: any;
      year_5?: any;
    };
    investRate?: {
      year_20?: any;
      year_15?: any;
      year_10?: any;
      year_5?: any;
    };
    investInfo?: any;
  };
}
